import { NotificationManager } from 'react-notifications';
import CalendarService from '../services/calendar.service';
import moment from 'moment';

export const licensedInOtherStatesOptions = [
  'AL',
  'AK',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DE',
  'DC',
  'FL',
  'GA',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'OH',
  'OK',
  'OR',
  'PA',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY',
];

export const providerAlertMapping = [
  { providerId: '20', alert: 'Alert message for Provider A' },
  {
    providerId: 'Danielle Rooney, Psy.D.',
    alert:
      'Please Make Sure  Client Knows This Provider is Leaving PsychCare in Mid July',
  },
  // Add more providers as needed
];

export const therapyAlertMapping = [
  { insurances: 'Private Pay', alert: '' },
  { insurances: 'Aetna', alert: '' },
  {
    insurances: 'Aetna EAP',
    alert:
      'Remind client to use group name and group Tax ID when obtaining their auth',
  },
  { insurances: 'Aetna Medicare Advantage', alert: '' },
  { insurances: 'BCBS', alert: '' },
  { insurances: 'CIGNA', alert: '' },
  { insurances: 'CIGNA EAP', alert: '' },
  { insurances: 'Johns Hopkins EHP', alert: '' },
  { insurances: 'Johns Hopkins USFHP', alert: '' },
  { insurances: 'Medical Assistance', alert: '' },
  {
    insurances: 'Medicare',
    alert:
      'Make sure office location selection in TA scheduling  matches: Novitas must have Bal/Col selected and Medicare DC must have SS selected as office location',
  },
  { insurances: 'Tricare Standard/Indeminity (NOT Prime)', alert: '' },
];

export const testingAlertMapping = [
  { insurances: 'Private Pay', alert: '' },
  {
    insurances: 'Aetna',
    alert:
      'Self Funded - Virtual Testing Not Covered - 1st Ses-Virtual / 2nd Session In Person. Commercial - Virtual Testing Covered - Can Schedule 1- 3 Hour Virtual Session',
  },
  {
    insurances: 'Aetna EAP',
    alert:
      'Virtual Testing Not Covered - 1st Ses-Virtual / 2nd Session In Person.',
  },
  {
    insurances: 'Aetna Medicare Advantage',
    alert:
      'Virtual Testing Not Covered - 1st Ses-Virtual / 2nd Session In Person.',
  },

  {
    insurances: 'BCBS',
    alert:
      'Virtual Testing Not Covered - 1st Ses-Virtual / 2nd Session In Person.',
  },
  {
    insurances: 'CIGNA',
    alert:
      'U Plans - Virtual Testing Covered - Can Schedule 1- 3 Hour Virtual Session Non U Plans - Virtual Testing Not Covered - 1st Ses-Virtual / 2nd Session In Person',
  },
  { insurances: 'CIGNA EAP', alert: '' },
  {
    insurances: 'Johns Hopkins EHP',
    alert:
      'Virtual Testing Not Covered - 1st Ses-Virtual / 2nd Session In Person.',
  },
  {
    insurances: 'Johns Hopkins USFHP',
    alert:
      'Virtual Testing Not Covered - 1st Ses-Virtual / 2nd Session In Person.',
  },
  {
    insurances: 'Medical Assistance',
    alert:
      'Virtual Testing Not Covered - 1st Ses-Virtual / 2nd Session In Person.',
  },
  {
    insurances: 'Medicare',
    alert:
      'Virtual Testing Not Covered - 1st Ses-Virtual / 2nd Session In Person.',
  },
  { insurances: 'Tricare Standard/Indeminity (NOT Prime)', alert: '' },
];

export const riskTherapyAlertMapping = [
  {
    question: 'Suicidal Thoughts',
    alert:
      '1. Please note this in TA in the corrosponding Question. 2. A YES answer for the suicide question precludes the client from seeing a clinician for remote sessions. The filters below have been adjust to exclude remote session and for providers that work clients in person that have a recent history of ACTIVE suicidal thoughts',
  },
  {
    question: 'Self-harm',
    alert:
      '1. Please note this in TA in the corrosponding Question. 2. A YES answer for the self-harm question does NOT automatically preclude the client from being seen remotely as some providers will see clients with a history of self-harm.  The filters below have been adjusted to only show such providers',
  },
  {
    question: 'Psychiatric Hospitalization',
    alert:
      '1. Please note this in TA in the corrosponding Question. A YES answer for the recent psychiatric hospitlization question precludes the client from seeing a clinician for remote sessions. The filters below have been adjust to exclude remote session and for provides that work in-person for such clients',
  },
];

export const riskTestingAlertMapping = [
  {
    question: 'Suicidal Thoughts',
    alert: 'Please note this in TA in the corrosponding Question',
  },
  {
    question: 'Self-harm',
    alert: 'Please note this in TA in the corrosponding Question',
  },
  {
    question: 'Psychiatric Hospitalization',
    alert: 'Please note this in TA in the corrosponding Question',
  },
];

export const yesNoOptions = ['', 'No', 'Yes'];

export const durations = {
  15: '15 min',
  30: '30 min',
  45: '45 min',
  60: '1 hr',
  75: '1 hr 15 min',
  90: '1 hr 30 min',
  105: '1 hr 45 min',
  120: '2 hr',
  135: '2 hr 15 min',
  150: '2 hr 30 min',
  165: '2 hr 45 min',
  180: '3 hr',
  195: '3 hr 15 min',
  210: '3 hr 30 min',
  225: '3 hr 45 min',
  240: '4 hr',
  255: '4 hr 15 min',
  270: '4 hr 30 min',
  285: '4 hr 45 min',
  300: '5 hr',
  315: '5 hr 15 min',
  330: '5 hr 30 min',
  345: '5 hr 45 min',
  360: '6 hr',
};

export const entryTypeFormValuesValid = (entryTypeFormValues) => {
  let {
    entryType,
    existingClientOptions,
    returningClientQ1,
    cancellationOptions,
  } = entryTypeFormValues;

  if (entryType === 'new') {
    return true;
  }

  if (entryType === 'existing') {
    if (existingClientOptions === 'Transfer') {
      return true;
    }

    if (existingClientOptions === 'Testing Client Coming to Office') {
      return true;
    }

    if (existingClientOptions === 'Therapy Client Coming to Office') {
      return true;
    }

    if (existingClientOptions === 'Turning 18 years-Old') {
      return true;
    }
  }

  if (entryType === 'returning') {
    if (returningClientQ1) {
      return true;
    }
  }

  if (entryType === 'cancellation') {
    if (cancellationOptions) {
      return true;
    }
  }

  // Check if the entry type is 'waitlist' and return true if so
  if (entryType === 'waitlist') {
    return true;
  }

  return false;
};

export const cases = (entryTypeFormValues, services) => {
  return {
    case1:
      entryTypeFormValues.entryType === 'existing' &&
      entryTypeFormValues.existingClientOptions === 'Transfer',

    // since transferQuestion is eliminated, case2 never fires
    case2:
      entryTypeFormValues.entryType === 'existing' &&
      entryTypeFormValues.existingClientOptions === 'Transfer' &&
      entryTypeFormValues.transferQuestion === 'After July 2021',

    // note that covid addendum question is now removed from therapy client coming to office option. so the case conditions have been changed. TODO: revisit case 3,4,5,6

    case3:
      (entryTypeFormValues.entryType === 'existing' &&
        entryTypeFormValues.existingClientOptions ===
          'Therapy Client Coming to Office') ||
      (entryTypeFormValues.existingClientOptions ===
        'Testing Client Coming to Office' &&
        // entryTypeFormValues.covidAddendum === 'Yes' &&
        !services?.includes('Bariatric Surgical Psychological Evaluations')),

    case4:
      (entryTypeFormValues.entryType === 'existing' &&
        entryTypeFormValues.existingClientOptions ===
          'Therapy Client Coming to Office') ||
      (entryTypeFormValues.existingClientOptions ===
        'Testing Client Coming to Office' &&
        services?.includes('Bariatric Surgical Psychological Evaluations')),

    // Since we removed the Covid Addendum - case 5 below will not be fired and commented out first part of case 5 since it's redundant with case 4
    case5:
      entryTypeFormValues.existingClientOptions ===
        'Testing Client Coming to Office' &&
      entryTypeFormValues.covidAddendum === 'No' &&
      services?.includes('Bariatric Surgical Psychological Evaluations'),

    // since we removed the COVID Addendum case 6 will not be fired
    case6:
      (entryTypeFormValues.entryType === 'existing' &&
        entryTypeFormValues.existingClientOptions ===
          'Therapy Client Coming to Office') ||
      (entryTypeFormValues.existingClientOptions ===
        'Testing Client Coming to Office' &&
        entryTypeFormValues.covidAddendum === 'No' &&
        !services?.includes('Bariatric Surgical Psychological Evaluations')),

    case7:
      entryTypeFormValues.entryType === 'returning' &&
      entryTypeFormValues.returningClientQ1 === 'Yes' &&
      entryTypeFormValues.returningClientQ2?.indexOf('Nota') === -1,
    case8:
      entryTypeFormValues.entryType === 'returning' &&
      entryTypeFormValues.returningClientQ1 === 'Yes' &&
      entryTypeFormValues.returningClientQ2?.indexOf('Nota') > -1,
    case9:
      entryTypeFormValues.entryType === 'returning' &&
      entryTypeFormValues.returningClientQ1 === 'No' &&
      entryTypeFormValues.returningClientQ2?.indexOf('Nota') === -1,

    // case 10 never fires since returningClientQ2 is eliminated
    case10:
      entryTypeFormValues.entryType === 'returning' &&
      entryTypeFormValues.returningClientQ1 === 'No' &&
      entryTypeFormValues.returningClientQ2?.indexOf('Nota') > -1,

    case11:
      entryTypeFormValues.entryType === 'existing' &&
      entryTypeFormValues.existingClientOptions === 'Turning 18 years-Old',

    // two cases below will not be fired since we removed COVID Addendum

    caseCovidAddendumYes:
      entryTypeFormValues.entryType === 'existing' &&
      entryTypeFormValues.existingClientOptions ===
        'Testing Client Coming to Office' &&
      entryTypeFormValues.covidAddendum === 'Yes',

    caseCovidAddendumNo:
      entryTypeFormValues.entryType === 'existing' &&
      entryTypeFormValues.existingClientOptions ===
        'Testing Client Coming to Office' &&
      entryTypeFormValues.covidAddendum === 'No',
  };
};

export const createNotification = (type) => {
  return () => {
    switch (type) {
      case 'info':
        NotificationManager.info('Info message');
        break;
      case 'success':
        NotificationManager.success('Success message', 'Title here');
        break;
      case 'warning':
        NotificationManager.warning(
          'Warning message',
          'Close after 3000ms',
          3000
        );
        break;
      case 'error':
        NotificationManager.error('Error message', 'Click me!', 5000, () => {
          alert('callback');
        });
        break;
      default:
        return;
    }
  };
};

export const isValidEmail = (email) => {
  if (!email) {
    return false;
  }
  /* eslint-disable no-useless-escape */
  var validRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  if (email.match(validRegex)) {
    return true;
  }
  return false;
};

export const tsToLocalDtStr = (ts) => {
  return moment(ts).format('MM-DD-YYYY HH:mm a');
};
export const additionalLanguagesSpokenOptions = [
  'Arabic',
  'Cantonese',
  'French',
  'Hebrew',
  'Korean',
  'Mandarin',
  'Persian',
  'Spanish',
];

export const degreesAndLicenseOptions = [
  'Fully Licensed',
  'Non-Fully Licensed',
  'Master Level',
  'Doctoral Level',
];

export const isAdmin = (currentUser) => {
  return (
    currentUser &&
    currentUser.roles &&
    currentUser.roles.indexOf('ROLE_ADMIN') > -1
  );
};

export const isCareCoordinator = (currentUser) => {
  return (
    currentUser &&
    currentUser.roles &&
    currentUser.roles.indexOf('ROLE_CARE_COORDINATOR') > -1
  );
};

export const isAdminOrCareCoordinator = (currentUser) => {
  return isAdmin(currentUser) || isCareCoordinator(currentUser);
};

export const isSupervisee = (currentUser) => {
  return (
    currentUser &&
    currentUser.roles &&
    currentUser.roles.indexOf('ROLE_SUPERVISEE') > -1
  );
};

export const isSupervisorORSupervisee = (currentUser) => {
  return (
    currentUser &&
    currentUser.roles &&
    (currentUser.roles.indexOf('ROLE_SUPERVISOR') > -1 ||
      currentUser.roles.indexOf('ROLE_SUPERVISEE') > -1)
  );
};

export const isOnlyDoctorOrSupervisor = (currentUser) => {
  return (
    currentUser &&
    currentUser.roles &&
    currentUser.roles.length === 2 &&
    currentUser.roles.indexOf('ROLE_SUPERVISOR') > -1 &&
    currentUser.roles.indexOf('ROLE_DOCTOR') > -1
  );
};

export const isDoctorAdminSupervisor = (currentUser) => {
  return (
    currentUser &&
    currentUser.roles &&
    currentUser.roles.indexOf('ROLE_DOCTOR') > -1 &&
    currentUser.roles.indexOf('ROLE_ADMIN') > -1 &&
    currentUser.roles.indexOf('ROLE_SUPERVISOR') > -1
  );
};

export const isDoctor = (currentUser) => {
  return (
    currentUser &&
    currentUser.roles &&
    currentUser.roles.indexOf('ROLE_DOCTOR') > -1 &&
    !isDoctorAdminSupervisor(currentUser)
  );
};

export const isOnlyDoctor = (currentUser) => {
  return (
    currentUser &&
    currentUser.roles &&
    currentUser.roles.length === 1 &&
    currentUser.roles.indexOf('ROLE_DOCTOR') > -1
  );
};

export const isExtern = (currentUser) => {
  return (
    currentUser &&
    currentUser.roles &&
    currentUser.roles.length === 1 &&
    currentUser.roles.indexOf('ROLE_EXTERN') > -1
  );
};

export const isOnlyDoctorOrSupervisee = (currentUser) => {
  return (
    currentUser &&
    currentUser.roles &&
    currentUser.roles.length < 3 &&
    (currentUser.roles.indexOf('ROLE_DOCTOR') > -1 ||
      currentUser.roles.indexOf('ROLE_SUPERVISEE') > -1) &&
    currentUser.roles.indexOf('ROLE_SUPERVISOR') < 0
  );
};

export const isCredentialer = (currentUser) => {
  return (
    currentUser &&
    currentUser.roles &&
    currentUser.roles.indexOf('ROLE_CREDENTIALER') > -1
  );
};

export const isBiller = (currentUser) => {
  return (
    currentUser &&
    currentUser.roles &&
    currentUser.roles.indexOf('ROLE_BILLER') > -1
  );
};

export const isHIM = (currentUser) => {
  return (
    currentUser &&
    currentUser.roles &&
    currentUser.roles.indexOf('ROLE_HIM') > -1
  );
};

export const isAuditor = (currentUser) => {
  return (
    currentUser &&
    currentUser.roles &&
    currentUser.roles.indexOf('ROLE_AUDITOR') > -1
  );
};

export const isAdminOrAuditor = (currentUser) => {
  return isAdmin(currentUser) || isAuditor(currentUser);
};

export const isClaimsManager = (currentUser) => {
  return (
    currentUser &&
    currentUser.roles &&
    currentUser.roles.indexOf('ROLE_CLAIMS_MANAGER') > -1
  );
};

export const isPayrollSpecialist = (currentUser) => {
  return (
    currentUser &&
    currentUser.roles &&
    currentUser.roles.indexOf('ROLE_PAYROLL_SPECIALIST') > -1
  );
};

export const hasSuperAdminAccess = (currentUser) => {
  return (
    // eslint-disable-next-line eqeqeq
    currentUser.id == process.env.REACT_APP_USERID1_FOR_LOGS_AND_USER_MANAGE ||
    // eslint-disable-next-line eqeqeq
    currentUser.id == process.env.REACT_APP_USERID2_FOR_LOGS_AND_USER_MANAGE
  );
};

export const hasCredentialingAccess = (currentUser) => {
  return (
    // eslint-disable-next-line eqeqeq
    currentUser.id == process.env.REACT_APP_USERID1_FOR_LOGS_AND_USER_MANAGE ||
    // eslint-disable-next-line eqeqeq
    currentUser.id == process.env.REACT_APP_USERID2_FOR_LOGS_AND_USER_MANAGE ||
    isCredentialer(currentUser)
  );
};

export const isUserAdminOrCareCoordinator = (user) => {
  // Admin has roleId 3 and Care Coordinator has roleId 6
  const allowedRoleIds = [3, 6];
  return allowedRoleIds.includes(user.roleId);
};

export const getUserRoleInfo = (user) => {
  const roles = [
    { label: 'Admin', color: '#f44336' },
    { label: 'Provider', color: '#4caf50' },
    { label: 'Care Coordinator', color: '#2196f3' },
    { label: 'Unknown', color: '#757575' },
  ];

  if (user.isProvider === 1) {
    return roles[1];
  } else if (user.isAdmin === 1) {
    return roles[0];
  } else if (user.isCareCoordinator === 1) {
    return roles[2];
  } else {
    return roles[3];
  }
};

export const isSelectedUserSupervisee = (userDetails) => {
  return (
    Array.isArray(userDetails.degrees_and_license) &&
    !userDetails.degrees_and_license.includes('Fully Licensed')
  );
};

export const isUserCareCoordinator = (user) => {
  const allowedRoleIds = [6];
  return allowedRoleIds.includes(user.roleId);
};

export const removeNullUndefinedValues = (obj) => {
  const keys = Object.keys(obj);

  return keys.reduce((result, key) => {
    if (obj[key] !== null && obj[key] !== undefined) {
      result[key] = obj[key];
    }
    return result;
  }, {});
};

// Helper function to check if the date is the next business day
export const getIsNextBusinessDay = (date, today) => {
  const dayOfWeek = date.getDay();

  // Check if the date is the next business day (Monday to Friday) and if it's after today
  return dayOfWeek >= 1 && dayOfWeek <= 5 && date > today;
};

// Helper function to calculate business days between two dates
export const calculateBusinessDays = (startDate, endDate) => {
  let count = 0;
  const current = new Date(startDate);
  const end = new Date(endDate);

  while (current <= end) {
    const dayOfWeek = current.getDay();
    if (dayOfWeek >= 1 && dayOfWeek <= 5) {
      count++;
    }
    current.setDate(current.getDate() + 1);
  }

  return count;
};
export const isDST = (date) => {
  const year = date.getFullYear();

  const secondSundayInMarch = new Date(year, 2, 8);

  secondSundayInMarch.setDate(8 + (7 - secondSundayInMarch.getDay()));

  const firstSundayInNovember = new Date(year, 10, 1);

  firstSundayInNovember.setDate(1 + (7 - firstSundayInNovember.getDay()));

  return date >= secondSundayInMarch && date < firstSundayInNovember;
};

export const addBusinessDays = (date, numDays) => {
  let count = 0;
  while (count < numDays) {
    date.setDate(date.getDate() + 1);
    const dayOfWeek = date.getDay();
    if (dayOfWeek >= 1 && dayOfWeek <= 5) {
      count++;
    }
  }
  return date;
};
export const getFirstCharacters = (str) => {
  const words = str?.split(' ');

  if (words?.length >= 2) {
    const firstCharacters = words?.map((word) => word[0]);

    return firstCharacters?.slice(0, 2)?.join('');
  } else {
    return '';
  }
};

export const parseDockHealthPatientDetails = (details) => {
  const keyValuePairs = details.split('\n');
  let parsedMessage = {};

  keyValuePairs.forEach((pair) => {
    let separatorIndex = pair.indexOf(':');
    let key = pair.slice(0, separatorIndex).trim();
    let value = pair.slice(separatorIndex + 1).trim();

    if (separatorIndex === -1) {
      const parts = pair.split(':');
      key = parts[0].trim();
      value = parts.slice(1).join(':').trim();
    }

    switch (key.toLowerCase()) {
      case 'first name':
      case 'name':
        const names = value.split(' ');
        parsedMessage['first_name'] = names[0];
        parsedMessage['last_name'] = names.slice(1).join(' ');
        break;
      case 'last name':
        parsedMessage['last_name'] = value;
        break;
      case 'dob':
        parsedMessage['date_of_birth'] = value;
        break;
      case 'phone number':
      case 'phone':
        const phoneNumber = value.replace(/[^0-9]/g, '');
        parsedMessage['phone'] = phoneNumber;
        break;
      case 'email':
        parsedMessage['email'] = value;
        break;
      case 'issue':
      case 'description for seeking help':
      case 'reason for seeking services':
        parsedMessage['issue'] = value;
        break;

      default:
        parsedMessage[key] = value;
        break;
    }
  });

  return parsedMessage;
};

export const extractEmailAddress = (inputString) => {
  const emailRegex = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/;

  const match = inputString?.match(emailRegex);

  if (match) {
    return match[0];
  }

  return inputString;
};

export const formatDate = (dateString) => {
  if (!dateString) return ''; // Handle case where date is not available

  const formattedDate = moment.utc(dateString).format('MM/DD/YY');
  return formattedDate;
};

export const formatDateCalendarFormat = (dateString) => {
  if (!dateString) return ''; // Handle case where date is not available

  const formattedDate = moment.utc(dateString).format('YYYY-MM-DD');
  return formattedDate;
};

export const getDocsDueTime = (docsDueDate) => {
  const today = new Date();
  const givenDateObj = new Date(docsDueDate);
  const todayYear = today.getFullYear();
  const todayMonth = today.getMonth();
  const todayDay = today.getDate();

  const givenYear = givenDateObj.getFullYear();
  const givenMonth = givenDateObj.getMonth();
  const givenDay = givenDateObj.getDate();

  if (
    todayYear === givenYear &&
    todayMonth === givenMonth &&
    todayDay === givenDay
  ) {
    return '4:00 PM';
  } else {
    return '10:00 AM';
  }
};

export const getDocsDue = (
  apptDate,
  step03FormValues,
  overRideDocDate = null
) => {
  if (overRideDocDate) {
    const dueDate = overRideDocDate;
    let docsDue = moment
      .utc(dueDate)
      .subtract(4, 'hours')
      .format('ddd, MMM DD, YYYY');
    return `${docsDue} ${getDocsDueTime(docsDue)}`;
  } else if (apptDate) {
    const dueDate =
      step03FormValues.serviceGroup === 'Therapy'
        ? substractWorkdays(apptDate, 2)
        : getFirstFuDate(apptDate);
    let docsDue = moment.utc(dueDate).format('ddd, MMM DD, YYYY');
    return `${docsDue} ${getDocsDueTime(docsDue)}`;
  }
};

export const substractWorkdays = (d, numToSubstract) => {
  if (d && numToSubstract) {
    const nd = new Date(d);
    for (let i = 1; i <= numToSubstract; i++) {
      nd.setDate(nd.getDate() - 1);
      if (nd.getDay() === 6) {
        nd.setDate(nd.getDate() - 1);
      } else if (nd.getDay() === 0) {
        nd.setDate(nd.getDate() - 2);
      }
    }
    return nd;
  }
};

export const getFirstFuDate = (dt) => {
  if (dt) {
    const today = new Date();
    const apptDate = typeof dt === 'string' ? new Date(dt) : dt;

    const isNextBusinessDay = getIsNextBusinessDay(apptDate, today);

    const timeDifference = apptDate - today;

    const businessDaysDifference = calculateBusinessDays(today, apptDate);

    let firstFuContactDate;

    if (timeDifference >= 4 * 7 * 24 * 60 * 60 * 1000) {
      const apptDay = apptDate.getDay();
      const daysUntilPreviousMonday = apptDay > 1 ? apptDay - 1 : apptDay + 6;
      const threeMondaysBack = daysUntilPreviousMonday + 14;
      firstFuContactDate = new Date(
        apptDate.getTime() - threeMondaysBack * 24 * 60 * 60 * 1000
      );
    } else if (timeDifference >= 8 * 24 * 60 * 60 * 1000) {
      firstFuContactDate = addBusinessDays(
        today,
        Math.floor(businessDaysDifference / 2) - 1
      );
    } else if (
      timeDifference >= 2 * 24 * 60 * 60 * 1000 &&
      timeDifference <= 7 * 24 * 60 * 60 * 1000
    ) {
      firstFuContactDate = addBusinessDays(
        today,
        Math.floor(businessDaysDifference / 2) - 1
      );
    } else if (isNextBusinessDay && today.getHours() < 12) {
      firstFuContactDate = today;
    }

    return firstFuContactDate;
  }
};

export const compareSlotDateWithToday = (availableSlot) => {
  const availableDate = new Date(availableSlot.start_date_time);
  const today = new Date();

  const availableDateOnly = new Date(
    availableDate.getFullYear(),
    availableDate.getMonth(),
    availableDate.getDate()
  );

  // Get the date for the immediate next day
  const nextDay = new Date(today);
  nextDay.setDate(nextDay.getDate() + 1);
  const nextDayOnly = new Date(
    nextDay.getFullYear(),
    nextDay.getMonth(),
    nextDay.getDate()
  );

  // Check if it's after 12 PM today
  const isAfter12PM = today.getHours() >= 12;

  // Check if the available date is the immediate next day
  const isNextDay = availableDateOnly.getTime() === nextDayOnly.getTime();

  // Check if it's after 12 PM and the available date is the immediate next day
  if (isAfter12PM && isNextDay) {
    return true;
  }

  // Check if the available date is today (considering year, month, and day)
  if (
    availableDateOnly.getDate() === today.getDate() &&
    availableDateOnly.getMonth() === today.getMonth() &&
    availableDateOnly.getFullYear() === today.getFullYear()
  ) {
    return true;
  }

  return false;
};

export const filterDocuments = (docs, filters, userSessionDetails) => {
  // First, filter all documents according to the given criteria
  const filteredDocs = docs.filter((doc) => {
    const matches = {
      serviceGroup:
        doc.service_group === 'All' ||
        !filters.serviceGroup ||
        doc.service_group.toLowerCase().trim() ===
          filters.serviceGroup.toLowerCase().trim(),
      serviceType:
        doc.service_sub_type === 'All' ||
        !filters.serviceType ||
        doc.service_sub_type
          .toLowerCase()
          .trim()
          .split(', ')
          .includes(filters.serviceType.toLowerCase().trim()),
      clientType:
        doc.client_type === 'All' ||
        !filters.clientType ||
        doc.client_type
          .toLowerCase()
          .trim()
          .split(', ')
          .includes(filters.clientType.toLowerCase().trim()),
      ageRange:
        doc.age_range === 'All' ||
        !filters.age ||
        (doc.age_range === '18+' ? filters.age >= 18 : filters.age < 18),
      licenseStatus:
        doc.license_status === 'All' ||
        !filters.licenseStatus ||
        doc.license_status.toLowerCase().trim() ===
          filters.licenseStatus.toLowerCase().trim(),
      specialScenario:
        doc.special_scenario === null ||
        isSpecialScenarioMet(doc, userSessionDetails),
    };

    return Object.values(matches).every(Boolean);
  });

  // Modify here: Exclude the influence of `isTurning18` from excluding other documents
  if (userSessionDetails.isTurning18) {
    return filteredDocs; // If `isTurning18` is true, do not exclude any documents that meet the criteria
  } else {
    // Handle other special scenarios that might require exclusions
    const specialScenarioDocs = filteredDocs.filter(
      (doc) => doc.special_scenario
    );
    const regularDocs = filteredDocs.filter((doc) => !doc.special_scenario);

    return [...new Set([...specialScenarioDocs, ...regularDocs])]; // Combine and remove duplicates
  }
};

// The below function sorts the documents based on their doc_name
export const sortDocuments = (documents) => {
  return documents.sort((a, b) => {
    const aPrefixMatch = a.doc_name.match(/^(\d+)\./);
    const bPrefixMatch = b.doc_name.match(/^(\d+)\./);

    if (aPrefixMatch && bPrefixMatch) {
      return parseInt(aPrefixMatch[1]) - parseInt(bPrefixMatch[1]);
    }
    if (aPrefixMatch) {
      return -1;
    }
    if (bPrefixMatch) {
      return 1;
    }
    return a.doc_name.localeCompare(b.doc_name);
  });
};

export const isSpecialScenarioMet = (document, userSessionDetails) => {
  if (document.special_scenario && document.special_scenario.trim() !== '') {
    switch (document.special_scenario.trim()) {
      case 'Pro Bono':
        return userSessionDetails.isProBono;

      case 'Adoptions':
        return userSessionDetails.isAdoptions; // Assuming you have a flag for simple 'Adoptions'

      case 'Adult ADHD':
        return userSessionDetails.isAdultADHD;

      case 'Adult Autism':
        return userSessionDetails.isAdultAutism;

      case 'Bariatrics - Hopkins':
        return userSessionDetails.isBariatricHopkins;

      case 'Bariatrics - Other':
        return userSessionDetails.isBariatricNOTHopkins;

      case 'Couples - Insurance':
        return userSessionDetails.isCouplesInsurance; // Assuming you add this flag

      case 'Couples - PP':
        return userSessionDetails.isCouplesPP; // Assuming you add this flag

      case 'Families - Insurance':
        return userSessionDetails.isFamiliesInsurance; // Assuming you add this flag

      case 'Families - PP':
        return userSessionDetails.isFamiliesPP; // Assuming you add this flag

      case 'Turning 18':
        return userSessionDetails.isTurning18;

      default:
        return false; // Return false for unhandled scenarios
    }
  }
  return true; // If special_scenario is empty or not set, return true to ignore special scenario filtering
};

// The below is the utility function for creating or updating birthday event
export const createOrUpdateBirthdayEvent = async (name, dob) => {
  // Split the full name into parts based on spaces
  const nameParts = name.split(' ');

  // Extract the initial of the first name
  const firstNameInitial = nameParts[0][0];

  // Find the index of the part that contains the comma
  const commaIndex = nameParts.findIndex((part) => part.includes(','));
  console.log('Index of comma:', commaIndex); // Log the index of the comma

  // Determine the last name based on the presence of a comma
  // If a comma exists, use the part immediately before the comma as the last name
  // Since the last name "Holster," includes the comma, remove it for proper formatting
  const lastName =
    commaIndex > 0
      ? nameParts[commaIndex].replace(',', '')
      : nameParts[nameParts.length - 1];
  console.log('Assumed last name:', lastName); // Log the assumed last name

  // Check if the last name ends in 's' and adjust the possessive accordingly
  const possessiveSuffix = lastName.endsWith('s') ? "'" : "'s";
  console.log('Possessive suffix:', possessiveSuffix); // Log the possessive suffix

  // Construct the event title with the initial of the first name and correct possessive suffix
  const eventTitle = `${firstNameInitial}. ${lastName}${possessiveSuffix} Birthday`;
  console.log('Event title:', eventTitle); // Log the final event title

  const birthDate = moment(dob);
  const formattedStart = birthDate.format('YYYY-MM-DD'); // Ensure consistent formatting
  console.log('Formatted Start Date:', formattedStart); // Log the formatted start date

  const eventData = {
    summary: eventTitle,
    description: `Birthday of ${name}`,
    start: {
      date: formattedStart,
      timeZone: 'America/New_York', // Adding time zone
    },
    end: {
      date: formattedStart,
      timeZone: 'America/New_York', // Adding time zone
    },
    recurrence: ['RRULE:FREQ=YEARLY'],
  };

  console.log('Event data being sent:', eventData); // Log the full event data

  try {
    const events = await CalendarService.findEventByName(eventTitle);
    console.log('Found events:', events); // Log found events
    // Finding the event from the database
    const existingEvent = events.find((event) => event.summary === eventTitle);
    console.log('Existing event:', existingEvent); // Log if an existing event is found

    // if event exists, delete the existing event
    if (existingEvent) {
      for (let i = 0; i < events.length; i++) {
        await CalendarService.deleteEvent(events[i].id);
        console.log('Deleted event ID:', events[i].id); // Log the ID of each deleted event
      }
    }
    // creating the new event
    await CalendarService.createEvent(eventData);
    console.log('New event created successfully'); // Log successful creation
  } catch (error) {
    console.error('Error creating or updating calendar event:', error);
    if (error.response) {
      // Handle HTTP errors from Axios
      console.log('Error status:', error.response.status);
      console.log('Error data:', error.response.data);
    }
  }
};

// The below is the utility function for getting and deleteing birthday event
export const getBirthdayEventsAndDelete = async (name) => {
  console.log('Received name for deletion:', name); // Log the input name

  // Split the full name into parts based on spaces
  const nameParts = name.split(' ');
  console.log('Name parts for deletion:', nameParts); // Log the split name parts

  // Extract the initial of the first name
  const firstNameInitial = nameParts[0][0];
  console.log('First name initial for deletion:', firstNameInitial); // Log the initial of the first name

  // Find the index of the part that contains the comma
  const commaIndex = nameParts.findIndex((part) => part.includes(','));
  console.log('Index of comma:', commaIndex); // Log the index of the comma

  // Determine the last name based on the presence of a comma
  // If a comma exists, use the part immediately before the comma as the last name
  // Since the last name might include the comma, remove it for proper formatting
  const lastName =
    commaIndex > 0
      ? nameParts[commaIndex].replace(',', '')
      : nameParts[nameParts.length - 1];
  console.log('Assumed last name for deletion:', lastName); // Log the assumed last name

  // Check if the last name ends in 's' and adjust the possessive accordingly
  const possessiveSuffix = lastName.endsWith('s') ? "'" : "'s";
  console.log('Possessive suffix for deletion:', possessiveSuffix); // Log the possessive suffix

  // Construct the event title with the initial of the first name and correct possessive suffix
  const eventTitle = `${firstNameInitial}. ${lastName}${possessiveSuffix} Birthday`;
  console.log('Event title for deletion:', eventTitle); // Log the final event title

  try {
    const events = await CalendarService.findEventByName(eventTitle);
    console.log('Found events for deletion:', events); // Log found events
    for (let i = 0; i < events.length; i++) {
      await CalendarService.deleteEvent(events[i].id);
      console.log('Deleted event ID:', events[i].id); // Log the ID of each deleted event
    }
  } catch (error) {
    console.error('Error during event deletion:', error);
    if (error.response) {
      // Handle HTTP errors from Axios
      console.log('Error status:', error.response.status);
      console.log('Error data:', error.response.data);
    }
  }
};

// The below is the utility function for creating or updating license. push
export const createOrUpdateLicenseEvent = async (
  name,
  expirationDate,
  state = null
) => {
  console.log('Received name:', name);

  const nameParts = name.split(' ');
  console.log('Name parts:', nameParts);

  const firstNameInitial = nameParts[0][0];
  console.log('First name initial:', firstNameInitial);

  const commaIndex = nameParts.findIndex((part) => part.includes(','));
  console.log('Index of comma:', commaIndex);

  const lastName =
    commaIndex > 0
      ? nameParts[commaIndex].replace(',', '')
      : nameParts[nameParts.length - 1];
  console.log('Assumed last name:', lastName);

  const possessiveSuffix = lastName.endsWith('s') ? "'" : "'s";
  console.log('Possessive suffix:', possessiveSuffix);

  const eventTitle = `${firstNameInitial}. ${lastName}${possessiveSuffix} ${
    state ? `${state} License` : 'Professional Liability'
  } Expires`;
  console.log('Event title:', eventTitle);

  const formattedExpirationDate = moment
    .utc(expirationDate)
    .format('YYYY-MM-DD');
  console.log('Formatted Expiration Date:', formattedExpirationDate);

  const eventData = {
    summary: eventTitle,
    description: `${
      state ? `${state} License Expires` : 'Professional Liability'
    }`,
    start: {
      date: formattedExpirationDate,
      timeZone: 'America/New_York',
    },
    end: {
      date: formattedExpirationDate,
      timeZone: 'America/New_York',
    },
  };

  try {
    const events = await CalendarService.findLicenseEventByName(eventTitle);
    console.log('Found events:', events);

    const existingEvent = events.find((event) => event.summary === eventTitle);
    console.log('Existing event:', existingEvent);

    if (existingEvent) {
      for (let event of events) {
        await CalendarService.deleteLicenseEvent(event.id);
        console.log('Deleted event ID:', event.id);
      }
    }

    await CalendarService.createLicenseEvent(eventData);
    console.log('New event created successfully');
  } catch (error) {
    console.error('Error creating or updating calendar event:', error);
    if (error.response) {
      console.log('Error status:', error.response.status);
      console.log('Error data:', error.response.data);
    }
  }
};

// The below utility function for getting and deleting the License Event
export const getLicenseEventsAndDelete = async (name, state = null) => {
  console.log('Received name:', name);

  const nameParts = name.split(' ');
  console.log('Name parts:', nameParts);

  const firstNameInitial = nameParts[0][0];
  console.log('First name initial:', firstNameInitial);

  const commaIndex = nameParts.findIndex((part) => part.includes(','));
  console.log('Index of comma:', commaIndex);

  const lastName =
    commaIndex > 0
      ? nameParts[commaIndex].replace(',', '')
      : nameParts[nameParts.length - 1];
  console.log('Assumed last name:', lastName);

  const possessiveSuffix = lastName.endsWith('s') ? "'" : "'s";
  console.log('Possessive suffix:', possessiveSuffix);

  const eventTitle = `${firstNameInitial}. ${lastName}${possessiveSuffix} ${
    state ? `${state} License` : 'Professional Liability'
  } Expires`;
  console.log('Event title:', eventTitle);

  try {
    const events = await CalendarService.findLicenseEventByName(eventTitle);
    console.log('Found events:', events);

    for (let event of events) {
      await CalendarService.deleteLicenseEvent(event.id);
      console.log('Deleted event ID:', event.id);
    }
  } catch (error) {
    console.error('Error deleting calendar events:', error);
    if (error.response) {
      console.log('Error status:', error.response.status);
      console.log('Error data:', error.response.data);
    }
  }
};

// The below function is responsible for creation and updation of Anniversary
export const createOrUpdateAnniversaryEvent = async (name, anniversaryDate) => {
  console.log('Received name:', name);

  const nameParts = name.split(' ');
  console.log('Name parts:', nameParts);

  const firstNameInitial = nameParts[0][0];
  console.log('First name initial:', firstNameInitial);

  const commaIndex = nameParts.findIndex((part) => part.includes(','));
  console.log('Index of comma:', commaIndex);

  const lastName =
    commaIndex > 0
      ? nameParts[commaIndex].replace(',', '')
      : nameParts[nameParts.length - 1];
  console.log('Assumed last name:', lastName);

  const possessiveSuffix = lastName.endsWith('s') ? "'" : "'s";
  console.log('Possessive suffix:', possessiveSuffix);

  const eventTitle = `${firstNameInitial}. ${lastName}${possessiveSuffix} Work Anniversary`;
  console.log('Event title:', eventTitle);

  const formattedAnniversaryDate = moment
    .utc(anniversaryDate)
    .format('YYYY-MM-DD');
  console.log('Formatted Anniversary Date:', formattedAnniversaryDate);

  const eventData = {
    summary: eventTitle,
    description: `Anniversary Date`,
    start: {
      date: formattedAnniversaryDate,
      timeZone: 'America/New_York',
    },
    end: {
      date: formattedAnniversaryDate,
      timeZone: 'America/New_York',
    },
    recurrence: ['RRULE:FREQ=YEARLY'],
  };

  console.log('Event data being sent:', eventData);

  try {
    const events = await CalendarService.findAnniversaryEventByName(eventTitle);
    console.log('Found events:', events);

    const existingEvent = events.find((event) => event.summary === eventTitle);
    console.log('Existing event:', existingEvent);

    if (existingEvent) {
      for (let event of events) {
        await CalendarService.deleteAnniversaryEvent(event.id);
        console.log('Deleted event ID:', event.id);
      }
    }

    await CalendarService.createAnniversaryEvent(eventData);
    console.log('New event created successfully');
  } catch (error) {
    console.error('Error creating or updating calendar event:', error);
    if (error.response) {
      console.log('Error status:', error.response.status);
      console.log('Error data:', error.response.data);
    }
  }
};

// The below function is responsible for getting anniversary events and delete it
export const getAnniversaryEventsAndDelete = async (name) => {
  console.log('Received name:', name);

  const nameParts = name.split(' ');
  console.log('Name parts:', nameParts);

  const firstNameInitial = nameParts[0][0];
  console.log('First name initial:', firstNameInitial);

  const commaIndex = nameParts.findIndex((part) => part.includes(','));
  console.log('Index of comma:', commaIndex);

  const lastName =
    commaIndex > 0
      ? nameParts[commaIndex].replace(',', '')
      : nameParts[nameParts.length - 1];
  console.log('Assumed last name:', lastName);

  const possessiveSuffix = lastName.endsWith('s') ? "'" : "'s";
  console.log('Possessive suffix:', possessiveSuffix);

  const eventTitle = `${firstNameInitial}. ${lastName}${possessiveSuffix} Work Anniversary`;
  console.log('Event title:', eventTitle);

  try {
    const events = await CalendarService.findAnniversaryEventByName(eventTitle);
    console.log('Found events:', events);

    for (let event of events) {
      await CalendarService.deleteAnniversaryEvent(event.id);
      console.log('Deleted event ID:', event.id);
    }
  } catch (error) {
    console.error('Error deleting calendar events:', error);
    if (error.response) {
      console.log('Error status:', error.response.status);
      console.log('Error data:', error.response.data);
    }
  }
};

export const replaceIndexWithZero = (array) => {
  array[1] = array[0];

  // Remove the first element to ensure there are only three elements
  array.splice(0, 1);

  // Return the modified array
  return array;
};

export const getAbsoluteURL = (url) => {
  // Check if URL already contains a valid protocol
  if (/^https?:\/\//i.test(url)) {
    return url;
  }
  return `https://${url}`; // Assuming https if no protocol is specified
};

export const getUpdatedLicenseEmailCases = (
  licenseEmailCases,
  changesMadeForEvents
) => {
  // Clone the licenseEmailCases object
  const licenseEmailCasesClone = { ...licenseEmailCases };

  // Check if Maryland is in changesMadeForEvents and is true
  if (changesMadeForEvents?.Maryland === true) {
    // Remove all keys starting with 'case'
    Object.keys(licenseEmailCasesClone).forEach((key) => {
      if (key.startsWith('case')) {
        delete licenseEmailCasesClone[key];
      }
    });
  }

  // Iterate through the other keys in changesMadeForEvents (excluding Maryland)
  Object.keys(changesMadeForEvents).forEach((eventKey) => {
    // Skip Maryland since we handled it separately
    if (eventKey === 'Maryland') return;

    // If changesMadeForEvents[eventKey] is true, we remove corresponding keys from licenseEmailCasesClone
    if (changesMadeForEvents[eventKey] === true) {
      Object.keys(licenseEmailCasesClone).forEach((key) => {
        // If the key in licenseEmailCasesClone starts with the eventKey (e.g., "AL" for "AL1", "AL2", etc.)
        if (key.startsWith(eventKey)) {
          delete licenseEmailCasesClone[key];
        }
      });
    }
  });

  return licenseEmailCasesClone;
};

/*
 The below function filters only Active Providers checking for both flags 
 if providerActiveStatus or is_Active flag
 */
export const getActiveProviders = (users) => {
  return users.filter(
    (user) => user.providerActiveStatus === 1 || user.is_active === 1
  );
};

/*
The below function sorts providers on the basis of LastName
 */
export const getSortedProvidersOnLastName = (providers) => {
  const sortedProviders = providers?.sort((a, b) => {
    const lastNameA =
      a?.provider_name?.split(' ')[1]?.trim()?.toLowerCase() || '';
    const lastNameB =
      b?.provider_name?.split(' ')[1]?.trim()?.toLowerCase() || '';

    if (lastNameA < lastNameB) return -1;
    if (lastNameA > lastNameB) return 1;
    return 0;
  });
  return sortedProviders;
};

/*
The below function sorts careCoordinators on the basis of LastName
 */
export const getSortedCareCoordinatorsOnLastName = (careCoordinators) => {
  const sortedCareCoordinators = careCoordinators?.sort((a, b) => {
    const lastNameA =
      a?.care_coordinator_name?.split(' ')[1]?.trim()?.toLowerCase() || '';
    const lastNameB =
      b?.care_coordinator_name?.split(' ')[1]?.trim()?.toLowerCase() || '';

    if (lastNameA < lastNameB) return -1;
    if (lastNameA > lastNameB) return 1;
    return 0;
  });
  return sortedCareCoordinators;
};
