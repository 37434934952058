import React, { useState, useEffect } from 'react';
import {
  Modal,
  Box,
  Typography,
  Button,
  Select,
  MenuItem,
  TextField,
  IconButton,
} from '@mui/material';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'; // Change this line
import {
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
  Delete as DeleteIcon,
} from '@mui/icons-material';

const daysOfWeek = ['Sun', 'Mon', 'Tues', 'Wed', 'Thurs', 'Fri'];

const formatDays = (daysArray) => {
  const indices = daysArray
    .map((day) => daysOfWeek.indexOf(day))
    .sort((a, b) => a - b);

  if (indices.length === 5 && indices[0] === 0 && indices[4] === 4) {
    return 'Weekdays'; // Sunday to Thursday
  } else if (indices.length > 1) {
    let consecutive = true;
    for (let i = 1; i < indices.length; i++) {
      if (indices[i] !== indices[i - 1] + 1) {
        consecutive = false;
        break;
      }
    }
    if (consecutive) {
      return `${daysOfWeek[indices[0]]}-${
        daysOfWeek[indices[indices.length - 1]]
      }`;
    }
  }
  return daysArray.join(', '); // Default to joining the array
};

const getDefaultStartTime = () => {
  const startTime = new Date();
  startTime.setHours(9, 0, 0); // Sets the time to 9:00 AM
  return startTime;
};

const getDefaultEndTime = () => {
  const endTime = new Date();
  endTime.setHours(17, 0, 0); // Sets the time to 5:00 PM
  return endTime;
};

function SchedulingInfoModal({ open, onClose, onSave, schedule }) {
  const [editableSchedule, setEditableSchedule] = useState([
    {
      days: [],
      startTime: getDefaultStartTime(),
      endTime: getDefaultEndTime(),
      setting: '',
      collapsed: false,
    },
  ]);

  const isScheduleComplete = (schedule) => {
    return (
      schedule.days.length > 0 &&
      schedule.startTime &&
      schedule.endTime &&
      schedule.setting
    );
  };

  const handleScheduleChange = (index, field, value) => {
    const newSchedule = [...editableSchedule];
    newSchedule[index][field] = value;
    setEditableSchedule(newSchedule);
  };

  const toggleCollapse = (index) => {
    const newSchedule = editableSchedule.map((sch, i) => ({
      ...sch,
      collapsed: i === index ? !sch.collapsed : sch.collapsed,
    }));
    setEditableSchedule(newSchedule);
  };

  const deleteSchedule = (event, index) => {
    event.stopPropagation();
    const updatedSchedule = editableSchedule.filter((_, i) => i !== index);
    setEditableSchedule(updatedSchedule);
  };

  const cancelNewSchedule = () => {
    setEditableSchedule(
      editableSchedule.filter((schedule) => isScheduleComplete(schedule))
    );
  };

  const addSchedule = () => {
    // Collapse all current schedules
    const updatedSchedule = editableSchedule.map((sch) => ({
      ...sch,
      collapsed: true, // Collapse existing schedules
    }));

    // Append a new schedule with default values
    updatedSchedule.push({
      days: [],
      startTime: getDefaultStartTime(),
      endTime: getDefaultEndTime(),
      setting: '',
      collapsed: false, // Newly added schedule is expanded
    });

    setEditableSchedule(updatedSchedule);
  };

  useEffect(() => {
    if (Array.isArray(schedule) && schedule.length > 0) {
      const formattedSchedule = schedule.map((sch) => ({
        ...sch,
        startTime: sch.startTime
          ? new Date(sch.startTime)
          : getDefaultStartTime(),
        endTime: sch.endTime ? new Date(sch.endTime) : getDefaultEndTime(),
        collapsed: false,
      }));
      setEditableSchedule(formattedSchedule);
    } else {
      // Maintain default state or reset to default when no schedule is provided
      setEditableSchedule([
        {
          days: [],
          startTime: getDefaultStartTime(),
          endTime: getDefaultEndTime(),
          setting: '',
          collapsed: false,
        },
      ]);
    }
  }, [schedule]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 600,
          bgcolor: 'background.paper',
          p: 4,
          border: '2px solid #000',
          boxShadow: 24,
        }}
      >
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Manage Schedule
        </Typography>

        {Array.isArray(editableSchedule) &&
          editableSchedule.map((sch, index) => (
            <Box
              key={index}
              sx={{
                mb: 2,
                border: '1px solid gray',
                p: 2,
                borderRadius: '4px',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
                onClick={() => toggleCollapse(index)}
              >
                <Typography
                  sx={{
                    color: sch.collapsed ? 'blue' : 'text.primary',
                    flexGrow: 1,
                  }}
                >
                  {index + 1}. {formatDays(sch.days) || 'Select Day(s)'},{' '}
                  {sch.startTime.toLocaleTimeString([], {
                    hour: '2-digit',
                    minute: '2-digit',
                  })}{' '}
                  -{' '}
                  {sch.endTime.toLocaleTimeString([], {
                    hour: '2-digit',
                    minute: '2-digit',
                  })}
                  , {sch.setting || 'Select Setting'}
                </Typography>
                <IconButton
                  onClick={(event) => deleteSchedule(event, index)}
                  size="small"
                  sx={{
                    visibility: isScheduleComplete(sch) ? 'visible' : 'hidden',
                  }}
                >
                  <DeleteIcon />
                </IconButton>
                <IconButton onClick={() => toggleCollapse(index)}>
                  {sch.collapsed ? <ExpandMoreIcon /> : <ExpandLessIcon />}
                </IconButton>
              </Box>

              {!sch.collapsed && (
                <Box>
                  {sch.days.length === 0 ? (
                    <Select
                      multiple
                      value={sch.days}
                      onChange={(e) =>
                        handleScheduleChange(index, 'days', e.target.value)
                      }
                      sx={{
                        width: '100%',
                        mb: 1,
                        '.MuiSelect-select': {
                          color: sch.days.length > 0 ? 'inherit' : 'red',
                        },
                      }} // Apply red color conditionally
                      displayEmpty // This prop ensures that the placeholder shows when the array is empty
                      renderValue={(selected) =>
                        selected.length > 0 ? (
                          selected.join(', ')
                        ) : (
                          <span style={{ color: 'red' }}>Select Day(s)</span>
                        )
                      } // Custom rendering
                    >
                      {['Sun', 'Mon', 'Tues', 'Wed', 'Thurs', 'Fri'].map(
                        (day) => (
                          <MenuItem key={day} value={day}>
                            {day}
                          </MenuItem>
                        )
                      )}
                    </Select>
                  ) : (
                    <Select
                      multiple
                      value={sch.days}
                      onChange={(e) =>
                        handleScheduleChange(index, 'days', e.target.value)
                      }
                      sx={{ width: '100%', mb: 1 }}
                      displayEmpty
                    >
                      {['Sun', 'Mon', 'Tues', 'Wed', 'Thurs', 'Fri'].map(
                        (day) => (
                          <MenuItem key={day} value={day}>
                            {day}
                          </MenuItem>
                        )
                      )}
                    </Select>
                  )}

                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <TimePicker
                      label="Start Time"
                      value={sch.startTime}
                      onChange={(time) =>
                        handleScheduleChange(index, 'startTime', time)
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          sx={{
                            width: '45%',
                            mr: 1,
                            '.MuiInputBase-input': {
                              color: sch.startTime ? 'inherit' : 'red',
                            },
                          }}
                        />
                      )}
                    />
                    <TimePicker
                      label="End Time"
                      value={sch.endTime}
                      onChange={(time) =>
                        handleScheduleChange(index, 'endTime', time)
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          sx={{
                            width: '45%',
                            '.MuiInputBase-input': {
                              color: sch.endTime ? 'inherit' : 'red',
                            },
                          }}
                        />
                      )}
                    />
                  </LocalizationProvider>

                  <Select
                    value={sch.setting}
                    onChange={(e) =>
                      handleScheduleChange(index, 'setting', e.target.value)
                    }
                    displayEmpty
                    sx={{
                      width: '100%',
                      mt: 1,
                      '.MuiSelect-select': {
                        color: sch.setting ? 'inherit' : 'red',
                      },
                    }}
                  >
                    <MenuItem value="">
                      <em>Select Setting</em>
                    </MenuItem>
                    <MenuItem value="Remote">Remote</MenuItem>
                    <MenuItem value="Office - Baltimore">
                      Office - Baltimore
                    </MenuItem>
                    <MenuItem value="Office - Columbia">
                      Office - Columbia
                    </MenuItem>
                    <MenuItem value="Office - Silver Spring">
                      Office - Silver Spring
                    </MenuItem>
                  </Select>
                </Box>
              )}
            </Box>
          ))}

        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          {editableSchedule.length >
            editableSchedule.filter(isScheduleComplete).length && (
            <Button onClick={cancelNewSchedule} color="error">
              Cancel
            </Button>
          )}
          {editableSchedule.every(isScheduleComplete) && (
            <>
              <Button onClick={addSchedule}>Add Another Schedule</Button>
              <Button onClick={() => onSave(editableSchedule)}>Save</Button>
            </>
          )}
        </Box>
      </Box>
    </Modal>
  );
}

export default SchedulingInfoModal;
