import React, { useEffect, useCallback } from 'react';
import * as MUIv6 from '@mui/material';
import EventBus from '../../common/EventBus';
import CareCoordinatorPersonalInfo from '../Input/CareCoordinatorPersonalInfo';
import CareCoordinatorSystemInfo from '../Input/CareCoordinatorSystemInfo';
import Autocomplete from '@mui/material/Autocomplete';
import { useSelector, useDispatch } from 'react-redux';
import { clearLoading, setLoading } from '../../actions/loading';
import {
  isDoctor,
  isSupervisee,
  isDoctorAdminSupervisor,
  isCareCoordinator,
} from '../../common/utility';
import '../../styles/ProviderConfigHomeStyles.css';
import CareCoordinatorPracticeInfo from '../Input/CareCoordinatorPracticeInfo';
import { careCoordinatorService } from '../../services';

export default function CareCoordinatorConfigHome() {
  const [value, setValue] = React.useState('personal_info');
  const [users, setUsers] = React.useState([]);
  const [selectedUser, setSelectedUser] = React.useState();
  const [selectedUserDetail, setSelectedUserDetail] = React.useState();
  const [showActiveCareCoodinatorOnly, setShowActiveCareCoordinatorOnly] =
    React.useState(true);
  const [shouldShow, setShouldShow] = React.useState(false);
  const { user: currentUser } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const showLoading = useCallback(() => {
    dispatch(setLoading());
  }, [dispatch]);

  const hideLoading = useCallback(() => {
    dispatch(clearLoading());
  }, [dispatch]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getCareCoordinators = async (status) => {
    try {
      const response = await careCoordinatorService.getCareCoordinators(status);

      let careCoordinators = response?.data ?? [];
      careCoordinators = careCoordinators.sort((a, b) => {
        const nameA = a.care_coordinator_name.split(' ')[1] || '';
        const nameB = b.care_coordinator_name.split(' ')[1] || '';
        return nameA.localeCompare(nameB);
      });

      // If the user is a care coordinator, filter to only include their data
      if (isCareCoordinator(currentUser)) {
        careCoordinators = careCoordinators.filter(
          (cc) => cc.care_coordinator_email === currentUser.email // comparison is happening on the basis of email
        );
        setSelectedUserDetail(careCoordinators[0]); // Assuming the first index is the current user
      }

      setUsers(careCoordinators);
    } catch (error) {
      console.error('Error fetching care coordinators:', error);
      if (error.response && error.response.status === 401) {
        EventBus.dispatch('logout');
      }
    } finally {
      hideLoading();
    }
  };

  const getAllCareCoordinators = async () => {
    try {
      const response = await careCoordinatorService.getAllCareCoordinators();

      let careCoordinators = response?.data ?? [];
      careCoordinators = careCoordinators.sort((a, b) => {
        const nameA = a.care_coordinator_name.split(' ')[1] || '';
        const nameB = b.care_coordinator_name.split(' ')[1] || '';
        return nameA.localeCompare(nameB);
      });

      // If the user is a care coordinator, filter to only include their data
      if (isCareCoordinator(currentUser)) {
        careCoordinators = careCoordinators.filter(
          (cc) => cc.care_coordinator_email === currentUser.email // comparison is happening on the basis of email
        );
        setSelectedUserDetail(careCoordinators[0]); // Assuming the first index is the current user
      }

      setUsers(careCoordinators);
    } catch (error) {
      console.error('Error fetching care coordinators:', error);
      if (error.response && error.response.status === 401) {
        EventBus.dispatch('logout');
      }
    } finally {
      hideLoading();
    }
  };

  useEffect(() => {
    if (
      // eslint-disable-next-line eqeqeq
      currentUser.id == process.env.REACT_APP_USERID1_FOR_PROVIDER_STATUS ||
      // eslint-disable-next-line eqeqeq
      currentUser.id == process.env.REACT_APP_USERID2_FOR_PROVIDER_STATUS
    ) {
      setShouldShow(true);
    } else {
      setShouldShow(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    showLoading();
    if (showActiveCareCoodinatorOnly) {
      getCareCoordinators('active');
    } else {
      getAllCareCoordinators();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser, showActiveCareCoodinatorOnly]); // Dependencies

  useEffect(() => {
    if (users.length > 1) {
      // not a doctor. so return
      return;
    }
    if (isDoctor(currentUser) && !isDoctorAdminSupervisor(currentUser)) {
      onTagsChange(null, users[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users]);

  const onTagsChange = async (event, values) => {
    let enObj = users.find((obj) => {
      return obj?.id === values?.id;
    });
    setSelectedUser(enObj);
    if (enObj?.id) {
      try {
        const careCoordinatorDetail =
          await careCoordinatorService.getCareCoordinatorDetail(
            enObj.id,
            enObj.email
          );
        if (careCoordinatorDetail && careCoordinatorDetail.data) {
          careCoordinatorDetail.data.careCoordinator.username = enObj.username;
          setSelectedUserDetail(careCoordinatorDetail.data.careCoordinator);
        }
      } catch (error) {
        console.error('Error fetching care coordinator details:', error);
        setSelectedUserDetail(null); // Ensure UI consistency by clearing stale data on error
      }
    } else {
      setSelectedUserDetail(null); // Clear details if no user is selected
    }
  };

  const updateCareCoordinatorDetail = async () => {
    const careCoordinatorDetail =
      await careCoordinatorService.getCareCoordinatorDetail(
        selectedUser.id,
        selectedUser.email
      );
    if (careCoordinatorDetail && careCoordinatorDetail.data) {
      setSelectedUserDetail(careCoordinatorDetail?.data?.careCoordinator);
    }
  };

  return (
    <React.Fragment>
      <div className="mainContainer">
        <MUIv6.Grid container spacing={3}>
          <MUIv6.Grid item xs={12}>
            {(!isDoctor(currentUser) || !isCareCoordinator(currentUser)) && (
              <Autocomplete
                disablePortal
                defaultValue={selectedUser}
                id="careCoordinator"
                options={users}
                onChange={onTagsChange}
                renderInput={(params) => (
                  <MUIv6.TextField
                    className="widerProviderDropdown"
                    variant="outlined"
                    {...params}
                    name="careCoordinator_"
                    label="Care Coordinator"
                  />
                )}
                getOptionLabel={(option) => option.care_coordinator_name || ''}
              />
            )}
            {isDoctor(currentUser) && !isDoctorAdminSupervisor(currentUser) && (
              <>
                <h3>{currentUser.username}</h3>
                <h5>{currentUser.email}</h5>
              </>
            )}
          </MUIv6.Grid>
        </MUIv6.Grid>
        {selectedUser && (
          <MUIv6.Box className="tab-container">
            <MUIv6.Tabs
              onChange={handleChange}
              value={value}
              aria-label="Tabs where selection follows focus"
              selectionFollowsFocus
            >
              <MUIv6.Tab value="personal_info" label="Personal Info" />
              <MUIv6.Tab value="carecoordinator_systems" label="Systems" />
              <MUIv6.Tab value="practice_info" label="PRACTICE INFO" />
            </MUIv6.Tabs>
            {/* The below are the tabs implementation*/}
            {value === 'personal_info' && (
              <CareCoordinatorPersonalInfo
                isProvider={isDoctor(currentUser)}
                isSupervisee={isSupervisee(currentUser)} // Add this line
                selectedUser={selectedUser}
                selectedUserDetail={selectedUserDetail}
                updateCareCoordinatorDetail={updateCareCoordinatorDetail}
                showLoading={showLoading}
                hideLoading={hideLoading}
              />
            )}

            {value === 'carecoordinator_systems' && (
              <CareCoordinatorSystemInfo
                isProvider={isDoctor(currentUser)}
                isSupervisee={isSupervisee(currentUser)} // Add this line
                selectedUser={selectedUser}
                selectedUserDetail={selectedUserDetail}
                updateCareCoordinatorDetail={updateCareCoordinatorDetail}
                showLoading={showLoading}
                hideLoading={hideLoading}
              />
            )}

            {value === 'practice_info' && (
              <CareCoordinatorPracticeInfo
                isProvider={isDoctor(currentUser)}
                isSupervisee={isSupervisee(currentUser)} // Add this line
                selectedUser={selectedUser}
                selectedUserDetail={selectedUserDetail}
                updateCareCoordinatorDetail={updateCareCoordinatorDetail}
                showLoading={showLoading}
                hideLoading={hideLoading}
              />
            )}
          </MUIv6.Box>
        )}
        {!selectedUser && !isCareCoordinator(currentUser) && (
          <div>Please select a Care Coordinator.</div>
        )}

        {shouldShow && !selectedUser && !isCareCoordinator(currentUser) && (
          <div
            style={{
              flexGrow: 1,
              flexBasis: 'calc(33.333% - 24px)',
              maxWidth: 'calc(33.333% - 24px)',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '20px',
            }}
          >
            <MUIv6.Switch
              checked={showActiveCareCoodinatorOnly}
              onChange={(e) =>
                setShowActiveCareCoordinatorOnly(e.target.checked)
              }
            />
            <span
              style={{
                fontSize: '1.1rem',
                fontWeight: 'bold',
              }}
            >
              {showActiveCareCoodinatorOnly ? (
                <span> Show Active Care Coordinators</span>
              ) : (
                <span>Show All Care Coordinators</span>
              )}
            </span>
          </div>
        )}
      </div>
    </React.Fragment>
  );
}
