import { useSelector, useDispatch } from 'react-redux';
import React, { useState, useEffect, useCallback, useRef, memo } from 'react';
import {
  Grid2,
  TextField,
  OutlinedInput,
  InputLabel,
  MenuItem,
  FormControl,
  ListItemText,
  Select,
  Checkbox,
  IconButton,
  FormHelperText,
  Button,
  Slider,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Switch,
  Tooltip,
  FormControlLabel,
} from '@mui/material';
import {
  Lock as LockIcon,
  LockOpen as LockOpenIcon,
  HelpOutline as HelpOutlineIcon,
} from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import RiskQuestionDialog from '../Dialogs/RiskQuestionDialog';
import SubPopulationDialog from '../Dialogs/SubPopulationDialog';
import TherapyConfigDialog from '../Dialogs/TherapyConfigDialog';
import ReasonDialog from '../Dialogs/ReasonDialog';
import HistoryDialog from '../Dialogs/HistoryDialog';
import HistoryShowingComponentSuperivsee from '../UI/HistoryShowingComponentSupervisee';
import EventBus from '../../common/EventBus';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import '../../styles/ProviderConfig.css';
import { createConfirmation } from 'react-confirm';
import ConfirmDialog from '../UI/ConfirmDialog';
import { clearLoading, setLoading } from '../../actions/loading';
import {
  isAdmin,
  isSupervisee,
  isOnlyDoctorOrSupervisor,
  isAdminOrCareCoordinator,
  isSupervisorORSupervisee,
} from '../../common/utility';
import { socket } from '../../common/sockets';
import {
  userService,
  providerService,
  definitionService,
  notificationService,
} from '../../services';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const theme = createTheme();

const StatusShowingComponentSupervisee = ({ currentStatus }) => {
  return (
    <>
      <Typography
        component="span"
        style={{
          background: '#e0e0e0', // label-like background color
          padding: '4px 8px', // padding inside the label
          borderRadius: '4px', // rounded corners for the label
          display: 'inline-block',
          marginRight: '26px', // space before the button
        }}
      >
        <b>Status:</b>
        {currentStatus.status}
      </Typography>

      <IconButton
        onClick={(event) => event.stopPropagation()} // Prevents the accordion from expanding or collapsing on icon click
        edge="end"
      >
        {currentStatus.lockState === 'locked' ? (
          <LockIcon color={currentStatus.lockColor} />
        ) : (
          <LockOpenIcon color={currentStatus.lockColor} />
        )}
      </IconButton>
    </>
  );
};

const commonOptions = ['Yes', 'No', 'Yes, But...'];

const therapyModalitiesOptions = [
  'Do Not Use',
  'Borrow & Integrate',
  'Primary Modality',
];

const defaultValues = {
  subPopulations: [],
  locations: [],
  status: [],
  insurances: [],
  insurances_t: [],
  'Under 18 1st Appt Policy': [],
  Individuals: [],
  Couples: [],
  Groups: [],
  Families: [],
  'Deaf or Hard of Hearing': [],
  'Working with Mental Health Professionals': [],
  'Working with Orthodox Jews': [],
  'Life Coaching': [],
  'ADHD Therapy/Coaching': [],
  'Parenting Therapy': [],
  Mediation: [],
  'CBT Only Therapy': [],
  'DBT Therapy - DBT Certified Therapist': [],
  'DBT Therapy - Primarily Use DBT in Therapy': [],
  'DBT Therapy - Occasionally use DBT Skills when appropriate': [],
  'Feminist Therapy': [],
  'Premarital Counseling': [],
  'Academic Underachievement': [],
  'ADHD (Therapy)': [],
  'ADHD (Coaching)': [],
  Addiction: [],
  Adjustments: ['Yes'],
  Adoption: [],
  'Alcohol Abuse': [],
  "Alzheimer's": [],
  'Anger Management': [],
  'Animal Support - Writing Certification Letter': [],
  'Antisocial Personality ': [],
  'Anxiety or Fears': ['Yes'],
  'Aspergers Syndrome': [],
  Autism: [],
  'Behavioral Issues': [],
  'Bipolar Disorder': [],
  'Body Dysmorphic Disorder': [],
  'Borderline Personality': [],
  'Bullying (being bullied)': [],
  'Bullying (being the bully)': [],
  'Caregiver Stress': [],
  'Career Counseling': [],
  'Child or Adolescent': [],
  'Chronic Impulsivity': [],
  'Chronic Pain or Illness': [],
  'Chronic Relapse': [],
  'Coping Skills': ['Yes'],
  Depression: ['Yes'],
  'Developmental Disorders': [],
  'Dissociative Identity (DID)': [],
  Divorce: [],
  'Domestic Abuse': [],
  'Domestic Violence (Current)': [],
  'Domestic Violence (Past)': [],
  'Drug Abuse (as primary issue)': [],
  'Dual Diagnosis': [],
  'Eating Disorder: Anorexia/Bulimia (Mild-Moderate), as #1 Therapy Issue': [],
  'Eating Disorder: Anorexia/Bulimia (Moderate-Severe), as #1 Therapy Issue':
    [],
  'Eating Disorder: Anorexia/Bulimia, as #2 Therapy Issue': [],
  'Eating Disorder: Binge Eating Disorder': [],
  Entitlement: [],
  'Family Conflict': [],
  Gambling: [],
  Hoarding: [],
  'Incest - Perpetrator': [],
  'Incest - Victim': [],
  'Infidelity - Perpetrator': [],
  'Infidelity - Victim': [],
  Infertility: [],
  'Internet Addiction': [],
  'Learning Disability': [],
  'LGBTQIAA+ (issues; identity development, coping with oppression)': [],
  'Loss or Grief': [],
  'Medical Detox': [],
  'Mens Issues': [],
  'Mental Retardation (Treatment)': [],
  'Narcissistic Personality Disorder': [],
  'Obesity (Presenting Issue)': [],
  OCD: [],
  'Oppositional Defiance': [],
  Parenting: [],
  'Peer Relationships': ['Yes'],
  'Post Partum Depression': [],
  'Pregnancy / Prenatal': [],
  'Racial Identity': [],
  'Relationship Issues (General)': ['Yes'],
  'Relationships - Open Relationships': [],
  'School Issues': [],
  'Self Esteem': ['Yes'],
  'Sex Therapy': [],
  'Sexual Abuse (Presenting Issue)': [],
  'Sexual Abuse in Military (MST)': [],
  'Sexual Addiction': [],
  'Sexuality - BDSM': [],
  'Sleep or Insomnia': [],
  Sociopathy: [],
  'Substance Abuse': [],
  'Teen Violence': [],
  'Transgender/Sex Surgery - Writing Letter of Recommendation': [],
  'Trauma and PTSD - Childhood': [],
  'Trauma and PTSD - General': [],
  'Traumatic Brain Injury': [],
  Trichotillomania: [],
  'Video Game Addiction': [],
  'Weight Loss': [],
  'Womens Issues': [],

  'Acceptance and Commitment Therapy (ACT)': [],
  'ACT Informed Therapy': [],
  Adlerian: [],
  'Applied Behavioral Analysis': [],
  'Art Therapy': [],
  'Attachment-based': [],
  'Christian Counseling': [],
  Coaching: [],
  'Cognitive Behavioral Therapy (CBT)': [],
  DBT: [],
  EMDR: [],
  Eclectic: [],
  'Emotionally Focused Therapy (EFT)': [],
  Existential: [],
  'Experiential Therapy': [],
  'Expressive Arts': [],
  'Exposure and Response Prevention (ERP)': [],
  Feminist: [],
  Gestalt: [],
  'Gottman Method': [],
  Humanistic: [],
  Hypnotherapy: [],
  Imago: [],
  Integrative: [],
  'Internal Family Systems (IFS)': [],
  Interpersonal: [],
  Jungian: [],
  'Mindfulness-based (MBCT)': [],
  'Motivational Interviewing': [],
  Narrative: [],
  'Neuro-Linguistic': [],
  Neurofeedback: [],
  'Parent-Child Interaction Therapy (PCIT)': [],
  'Person-Centered': [],
  'Play Therapy': [],
  'Positive Psychology': [],
  'Prolonged Exposure Therapy': [],
  Psychoanalytic: [],
  Psychodynamic: [],
  'Rational Emotive Behavior Therapy (REBT)': [],
  Relational: [],
  'Sand Play': [],
  'Solution Focused Brief (SFBT)': [],
  Somatic: [],
  'Structural Family Therapy': [],
  therapy_type: [],
  // "risk_questions": [],
  special_considerations: [],
  specialized_therapies: [],
  therapy_issues: [],
  topIssues: { issue1: '', issue2: '', issue3: '' },
  therapy_modalities: [],
  populationValue: [20, 40],
  therapy_initial_survey: '',
  riskQuestions: [
    {
      id: 1,
      question:
        'Are you comfortable working with clients who have had PASSIVE suicidal thoughts?',
      answer: 'Yes',
      needsReview: true, // Indicates this question is pending review
    },
    {
      id: 2,
      question:
        'Are you comfortable working with clients IN-PERSON who have had ACTIVE suicidal thoughts MORE THAN 6 months ago?',
      answer: 'No',
    },
    {
      id: 3,
      question:
        'Are you comfortable working with clients VIRTUALLY who have had Active suicidal thoughts MORE THAN 6 months ago?',
      answer: 'No',
    },
    {
      id: 4,
      question:
        'Are you comfortable working with clients IN-PERSON who have had ACTIVE suicidal thoughts LESS THAN 6 months ago?',
      answer: 'No',
    },
    {
      id: 5,
      question:
        'Are you comfortable working with clients IN-PERSON who have been self-harming?',
      answer: 'No',
    },
    {
      id: 6,
      question:
        'Are you comfortable working with clients VIRTUALLY who have been self-harming?',
      answer: 'No',
    },
    {
      id: 7,
      question:
        'Are you comfortable working with clients IN PERSON who have been hospitalized for a psychiatric concern LESS THAN 6 months ago?',
      answer: 'No',
    },
  ],
  therapy_post_survey: '',
};

const TherapyConfig = (props) => {
  const [triggerCallForSupervisee, setTriggerCallForSupervisee] = useState();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [expanded, setExpanded] = React.useState('panel1');

  const handleChangeAccordion = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);

  const [openDialog, setOpenDialog] = useState(false);
  const [toDelete, setToDelete] = useState(null);

  const [reasonDialogOpen, setReasonDialogOpen] = useState(false);

  // This below useState is for tracking the previous value which is getting changed
  const [previousValue, setPreviousValue] = useState('');
  // This below useState is for tracking the currentValue which has been changed
  const [currentValue, setCurrentValue] = useState('');

  // The below state is use for checking ig the selected user is supervisee
  const [isSelectedUserSupervisee, setIsSelectedUserSupervisee] =
    useState(false);

  // The below state is for capturing supervisor reasons for changing
  const [supervisorReasons, setSupervisorReasons] = useState([]);

  // The below useState is for recording the areaOfChange for supervisor
  const [areaOfChange, setAreaOfChange] = useState();

  // The below useState is use for managing definitions with each service
  const [definitions, setDefinitions] = useState({});

  const [reviewState, setReviewState] = useState({
    statusIndex: 0,
    statuses: [
      {
        status: 'Initial Entry',
        lockColor: 'success',
        lockState: 'unlocked',
      },
      {
        status: 'Initial Entry Submitted for Review',
        lockColor: 'warning',
        lockState: 'locked',
      },
      {
        status: 'Initial Entry Reviewed & Approved',
        lockColor: 'error',
        lockState: 'locked',
      },
      {
        status: 'Modification Requested',
        lockColor: 'warning',
        lockState: 'locked',
      },
      {
        status: 'Modification Reviewed & Approved',
        lockColor: 'error',
        lockState: 'locked',
      },
    ],
  });

  const currentStatus = reviewState.statuses[reviewState.statusIndex];

  const [historyDialogOpen, setHistoryDialogOpen] = useState(false);
  const [historyData, setHistoryData] = useState([]);

  const handleOpenHistory = (question) => {
    const supervisionInitialModifications =
      formValues?.supervision?.supervision_initial_modifications;

    const supervisionToAndFroModitications =
      formValues?.supervision?.modifications_history;

    let valuesToBePushed = []; // Values to be pushed to the history Object

    for (let i = 0; i < supervisionInitialModifications?.length; i++) {
      const propertyName = 'area' + i;
      const areaValue = supervisionInitialModifications[i][propertyName];
      const reasonValue = supervisionInitialModifications[i]['reason'];

      if (areaValue?.toLowerCase().includes(question?.toLowerCase())) {
        valuesToBePushed.push({
          area: areaValue,
          reason: reasonValue,
          date: supervisionInitialModifications[i].date,
          currentValue: supervisionInitialModifications[i].currentValue,
          previousValue: supervisionInitialModifications[i].previousValue,
          isSelectedUserSupervisee:
            supervisionInitialModifications[i].isSelectedUserSupervisee,
        });
        setHistoryData([...valuesToBePushed]);
        break;
      }
    }

    // The below is the case for supervision To and Fro
    for (let i = 0; i < supervisionToAndFroModitications?.length; i++) {
      const currentObject = supervisionToAndFroModitications[i];
      for (const key in currentObject) {
        if (key.startsWith('area')) {
          const areaValue = currentObject[key];
          const reasonValue = currentObject['reason'];

          if (areaValue?.toLowerCase().includes(question?.toLowerCase())) {
            valuesToBePushed.push({
              date: supervisionToAndFroModitications[i].date,
              currentValue: supervisionToAndFroModitications[i].currentValue,
              previousValue: supervisionToAndFroModitications[i].previousValue,
              area: areaValue,
              reason: reasonValue,
              isSelectedUserSupervisee:
                supervisionToAndFroModitications[i].isSelectedUserSupervisee,
            });
            setHistoryData([...valuesToBePushed]);
            break;
          }
        }
      }
    }
    setHistoryDialogOpen(true);
  };

  const handleCloseHistory = () => {
    setHistoryDialogOpen(false);
  };

  // const [populationValue, setPopulationValue] = React.useState([20, 37]);
  const handlePopulationChange = (event, newValue) => {
    setFormValues({
      ...formValues,
      populationValue: newValue,
    });
    setChanges({
      ...changes,
      populationValue: newValue,
    });
    // Opening the reasons for both cases
    if (
      (isSelectedUserSupervisee &&
        (formValues?.supervision?.initial_request ||
          formValues?.supervision?.initial_approval)) ||
      (isSupervisee(currentUser) &&
        (formValues?.supervision?.initial_request ||
          formValues?.supervision?.initial_approval))
    ) {
      setReasonDialogOpen(true); //opening the reasons dialog
      setAreaOfChange('populationValue');

      //setting previous and current values here
      setPreviousValue(formValues.populationValue);
      setCurrentValue(newValue);
    }
  };

  const areAdminFieldsComplete = () => {
    return (
      formValues['locations']?.length > 0 &&
      formValues['status']?.length > 0 &&
      formValues['insurances']?.length > 0 &&
      formValues['insurances_t']?.length > 0
    );
  };

  const handleSubPopulationChange = (index) => (event, newValue) => {
    if (formValues?.subPopulations.length === 0) {
      let tempPopulation = [];
      tempPopulation.push({ value: newValue });
      setChanges((prevChanges) => ({
        ...prevChanges,
        subPopulations: tempPopulation,
      }));
      setFormValues({
        ...formValues,
        subPopulations: tempPopulation,
      });
    } else {
      if (index <= formValues?.subPopulations?.length - 1) {
        setFormValues({
          ...formValues,
          subPopulations: formValues?.subPopulations?.map((sub, subIndex) =>
            subIndex === index ? { ...sub, value: newValue } : sub
          ),
        });
        setChanges((prevChanges) => ({
          ...prevChanges,
          subPopulations: formValues?.subPopulations,
        }));
      } else {
        let tempPopulation = [...formValues?.subPopulations];
        tempPopulation.push({ value: newValue });
        setFormValues({
          ...formValues,
          subPopulations: tempPopulation,
        });
        setChanges((prevChanges) => ({
          ...prevChanges,
          subPopulations: tempPopulation,
        }));
      }
    }
  };

  const handleSubPopulationTextChange = (index) => (event) => {
    let newSubPopulations = [...subPopulations];
    newSubPopulations[index].text = event.target.value;
    setSubPopulations(newSubPopulations);

    if (formValues?.subPopulations.length === 0) {
      let tempPopulation = [];
      tempPopulation.push({
        text: event.target.value,
        value: formValues.populationValue,
      });

      setChanges((prevChanges) => ({
        ...prevChanges,
        subPopulations: tempPopulation,
      }));
      setFormValues({
        ...formValues,
        subPopulations: tempPopulation,
      });
    } else {
      if (index <= formValues?.subPopulations?.length - 1) {
        setFormValues({
          ...formValues,
          subPopulations: formValues?.subPopulations?.map((sub, subIndex) =>
            subIndex === index ? { ...sub, text: event.target.value } : sub
          ),
        });
        setChanges((prevChanges) => ({
          ...prevChanges,
          subPopulations: formValues?.subPopulations,
        }));
      } else {
        let tempPopulation = [...formValues?.subPopulations];
        tempPopulation.push({
          text: event.target.value,
          value: formValues.populationValue,
        });
        setFormValues({
          ...formValues,
          subPopulations: tempPopulation,
        });
        setChanges((prevChanges) => ({
          ...prevChanges,
          subPopulations: tempPopulation,
        }));
      }
    }
  };

  const addSubSlider = () => {
    setSubPopulations([
      ...subPopulations,
      { value: [...populationValue], text: '' },
    ]);
  };

  const removeSubSlider = () => {
    // Create a new array that excludes the sub population at the given index
    const newSubPopulations = subPopulations.filter((_, i) => i !== toDelete);

    // Update both subPopulations and formValues.subPopulations
    setSubPopulations(newSubPopulations);

    setFormValues({
      ...formValues,
      subPopulations: formValues.subPopulations.filter(
        (_, i) => i !== toDelete
      ),
    });

    setChanges({
      ...changes,
      subPopulations: formValues.subPopulations.filter(
        (_, i) => i !== toDelete
      ),
    });

    // Reset the toDelete state and close the dialog
    setToDelete(null);
    setOpenDialog(false);
  };

  const openDeleteDialog = (index) => {
    // Set the toDelete state and open the dialog
    setToDelete(index);
    setOpenDialog(true);
  };

  const handleCloseConfirm1 = () => {
    setOpen1(false);

    if (
      (isSelectedUserSupervisee &&
        (formValues?.supervision?.initial_request ||
          formValues?.supervision?.initial_approval)) ||
      (isSupervisee(currentUser) &&
        (formValues?.supervision?.initial_request ||
          formValues?.supervision?.initial_approval))
    ) {
      setReasonDialogOpen(true);
    }
  };

  const handleCloseConfirm = () => {
    setOpen(false);
    if (
      (isSelectedUserSupervisee &&
        (formValues?.supervision?.initial_request ||
          formValues?.supervision?.initial_approval)) ||
      (isSupervisee(currentUser) &&
        (formValues?.supervision?.initial_request ||
          formValues?.supervision?.initial_approval))
    ) {
      setReasonDialogOpen(true);
    }
  };

  const showLoading = useCallback(() => {
    dispatch(setLoading());
  }, [dispatch]);

  const hideLoading = useCallback(() => {
    dispatch(clearLoading());
  }, [dispatch]);

  const handleClose = (save = false) => {
    setOpen(false);
    if (!save) {
      let formValuesClone = { ...formValues };
      delete formValuesClone[latestField];
      setReason(null);
      setFormValues(formValuesClone);
    }
    if (
      (isSelectedUserSupervisee &&
        (formValues?.supervision?.initial_request ||
          formValues?.supervision?.initial_approval)) ||
      (isSupervisee(currentUser) &&
        (formValues?.supervision?.initial_request ||
          formValues?.supervision?.initial_approval))
    ) {
      setReasonDialogOpen(true);
    }
  };

  const handleClose1 = (save = false) => {
    setOpen1(false);
    if (!save) {
      const questionId = localStorage.getItem('questionId');
      const updatedRiskQuestions = formValues.riskQuestions.map(
        (riskQuestionsObj) => {
          if (riskQuestionsObj.id === questionId) {
            riskQuestionsObj.answer = '';
          }
          return riskQuestionsObj;
        }
      );
      setFormValues({
        ...formValues,
        riskQuestions: [...updatedRiskQuestions],
      });
      setReason(null);
    }
  };
  const [providerConfigOption, setProviderConfigOption] = React.useState({});
  const issuesWarningRef = useRef(null);
  const { user: currentUser } = useSelector((state) => state.auth);
  const { loading } = useSelector((state) => state.loading); // getting loading from redux state

  // Below I am comparing if currentUser is supervisee
  if (
    isSupervisee(currentUser) ||
    props?.selectedUserDetailForProvider?.degreesAndLicense?.includes(
      'Non-Fully Licensed'
    )
  ) {
    defaultValues.supervision = {
      initial_request: false,
      date_of_initial_request: '',
      initial_approval: false,
      date_of_initial_approval: '',
      supervision_initial_modifications: {
        area1: {
          previousValue: 'original value for area1',
          currentValue: 'current value for area1',
          reason: 'reason for modification in area1',
        },
        area2: {
          previousValue: 'original value for area2',
          currentValue: 'current value for area2',
          reason: 'reason for modification in area2',
        },
      },
      modification_request: false,
      modification_approval: false,
      modifications_history: [],
      latest_modifications: [], // This is the latest modification array introduced to show the modification
    };
  }

  const [formValues, setFormValues] = React.useState(defaultValues);
  const [reason, setReason] = React.useState(null);
  const [selectedField, setSelectedField] = React.useState();
  const [populationValue] = React.useState(defaultValues.populationValue);
  const [subPopulations, setSubPopulations] = useState([]);
  const [latestField, setLatestField] = React.useState(null);
  const [changes, setChanges] = React.useState({});
  const confirm = createConfirmation(ConfirmDialog);
  const [submitDisabled, setSubmitDisabled] = React.useState(true);

  const handleTopIssuesChange = (issueKey, event) => {
    // Update topIssues
    const prevTopIssues = formValues.topIssues;

    if (
      Object.values(prevTopIssues).find(
        (prevTopIssuesValues) =>
          prevTopIssuesValues.toLowerCase() === event.target.value.toLowerCase()
      )
    ) {
      issuesWarningRef.current.style.display = 'block';
    } else {
      issuesWarningRef.current.style.display = 'none';
      const newTopIssues = {
        ...prevTopIssues,
        [issueKey]: event.target.value,
      };

      // Making sure that the supervisor is updating supervisee
      if (
        (isSelectedUserSupervisee &&
          formValues?.supervision?.initial_request) ||
        (isSupervisee(currentUser) && formValues?.supervision?.initial_request)
      ) {
        setReasonDialogOpen(true); //opening the reasons dialog
        setAreaOfChange(issueKey);

        // setting the previous and current values
        setPreviousValue(formValues[issueKey]);
        setCurrentValue(event.target.value);
      }

      // Also update formValues
      setFormValues((prevFormValues) => ({
        ...prevFormValues,
        topIssues: newTopIssues,
      }));

      // Update changes
      setChanges((prevChanges) => ({
        ...prevChanges,
        [issueKey]: event.target.value,
      }));
    }
  };

  const handleRiskQuestionsChange = (e, questionId) => {
    let riskQuestions = formValues.riskQuestions;
    let RiskQuestionsPreviousResponse = formValues.riskQuestions; // saving this for previousResponse

    let updatedRiskQuestions = riskQuestions.map((riskQuestionsObj) => {
      return riskQuestionsObj.id === questionId
        ? {
            id: questionId,
            question: riskQuestionsObj.question,
            answer: e.target.value,
          }
        : riskQuestionsObj;
    });
    riskQuestions = [...updatedRiskQuestions];
    setFormValues({
      ...formValues,
      riskQuestions: riskQuestions,
    });

    if (e.target.value === 'Yes, But...') {
      // setLatestField(name);

      localStorage.setItem('questionId', questionId);
      setOpen1(true);

      // The below condition opens if Supervisor is updating supervisee
      if (
        isSelectedUserSupervisee ||
        (isSupervisee(currentUser) && formValues?.supervision?.initial_approval)
      ) {
        setAreaOfChange(updatedRiskQuestions[questionId - 1].question);
        setPreviousValue(RiskQuestionsPreviousResponse[questionId - 1].answer);
        setCurrentValue(e.target.value);
      }
    } else {
      // The below condition opens if Supervisor is updating supervisee
      if (
        (isSelectedUserSupervisee &&
          (formValues?.supervision?.initial_request ||
            formValues?.supervision?.initial_approval)) ||
        (isSupervisee(currentUser) &&
          (formValues?.supervision?.initial_request ||
            formValues?.supervision?.initial_approval))
      ) {
        setReasonDialogOpen(true); //opening the reasons dialog
        setAreaOfChange(updatedRiskQuestions[questionId - 1].question);

        // setting the previous and current values
        setPreviousValue(RiskQuestionsPreviousResponse[questionId - 1].answer);
        setCurrentValue(e.target.value);
      }
    }

    const latestChanges = {
      [questionId + updatedRiskQuestions[questionId - 1].question]: [
        e.target.value,
      ],
    };
    setChanges({ ...changes, ...latestChanges });
  };

  const getChangesFromLocalStorage = () => {
    const localChanges = localStorage.getItem('changedFormValuesTherapy');
    if (localChanges) {
      return JSON.parse(localChanges)[props?.selectedUser?.id];
    }
    return {};
  };
  const handleChange = (event, type, particularFormType = null) => {
    // particularFormType is for not opening reason dialog for location and therapy provider
    if (type) setSelectedField(type);
    let {
      target: { name, value },
    } = event;
    if (value === 'Yes, But...') {
      value = ['Yes, But...'];
      setLatestField(name);
      setOpen(true);

      // The below condition opens if Supervisor is updating supervisee
      if (
        isSelectedUserSupervisee ||
        (isSupervisee(currentUser) && formValues?.supervision?.initial_approval)
      ) {
        setAreaOfChange(name);

        // The below I am setting the previous and the current values
        setPreviousValue(formValues[name][0]);
        setCurrentValue(event.target.value);
      }
    } else {
      // The below condition opens if Supervisor is updating supervisee
      if (
        (isSelectedUserSupervisee &&
          (formValues?.supervision?.initial_request ||
            formValues?.supervision?.initial_approval)) ||
        (isSupervisee(currentUser) &&
          (formValues?.supervision?.initial_request ||
            formValues?.supervision?.initial_approval))
      ) {
        if (
          particularFormType !== 'location' &&
          particularFormType !== 'therapy_provider_status'
        ) {
          setReasonDialogOpen(true); //opening the reasons dialog
        }

        setAreaOfChange(name);
        // The below I am setting the previous and the current values
        setPreviousValue(formValues[name][0]);
        setCurrentValue(event.target.value);
      }
    }
    let theValue = typeof value === 'string' ? value.split(',') : value;
    setFormValues({
      ...formValues,
      [name]: theValue,
    });
    setChanges({ ...changes, [name]: theValue });
  };

  useEffect(() => {
    const fetchDefinitions = async () => {
      try {
        const response = await definitionService.getAllDefinitions();
        // The below it is converting to Object
        const definitionsData = response.data.reduce(
          (acc, cur) => ({
            ...acc,
            [cur.term]: cur.definition, // Assuming 'definition' is the field with the definition text
          }),
          {}
        );

        setDefinitions(definitionsData);
      } catch (error) {
        console.error('Failed to fetch definitions:', error);
      }
    };

    fetchDefinitions();
  }, []);

  // The below it will display the definition of each Therapy Issue
  const renderDefinitionToolTip = (term) => {
    const definition = definitions[term];
    if (definition && definition.trim() !== '') {
      return (
        <Tooltip title={definition}>
          <IconButton aria-label="help">
            <HelpOutlineIcon />
          </IconButton>
        </Tooltip>
      );
    }
    return null;
  };

  useEffect(() => {
    async function effect() {
      setChanges({});
      try {
        let response = await providerService.getTherapyConfigOptions();
        let providerConfigOption = response?.data?.providerConfigOption ?? {};
        setProviderConfigOption(providerConfigOption);
        let therapyConfig = await providerService.getTherapyConfig(
          props?.selectedUser?.id
        );
        const therapyClone = {
          ...defaultValues,
          ...(therapyConfig?.data?.therapy ?? {}),
        };
        // fields added later on
        therapyClone['therapy_initial_survey'] =
          therapyClone['therapy_initial_survey'] || '';
        therapyClone['therapy_post_survey'] =
          therapyClone['therapy_post_survey'] || '';
        let localStorageFormValues = localStorage.getItem('formValuesTherapy');
        if (localStorageFormValues) {
          let formValuesStored = JSON.parse(localStorageFormValues);
          if (formValuesStored[props?.selectedUser?.id]) {
            setFormValues({
              ...therapyClone,
              ...formValuesStored[props.selectedUser.id],
            });
          } else {
            setFormValues(therapyClone);
          }
        } else {
          setFormValues(therapyClone);
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          EventBus.dispatch('logout');
        }
      }
    }

    showLoading();
    effect().finally(() => hideLoading());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.selectedUser?.id, triggerCallForSupervisee]);

  useEffect(() => {
    if (formValues?.supervision?.initial_request) {
      setReviewState((prevState) => ({
        ...prevState,
        statusIndex: 1,
      }));
    } else if (formValues?.supervision?.modification_request) {
      setReviewState((prevState) => ({
        ...prevState,
        statusIndex: 3,
      }));
    } else if (formValues?.supervision?.modification_approval) {
      setReviewState((prevState) => ({
        ...prevState,
        statusIndex: 4,
      }));
    } else if (formValues?.supervision?.initial_approval) {
      setReviewState((prevState) => ({
        ...prevState,
        statusIndex: 2,
      }));
    } else {
      setReviewState((prevState) => ({
        ...prevState,
        statusIndex: 0,
      }));
    }
  }, [formValues]);

  useEffect(() => {
    const keys = Object.keys(formValues);
    const emptyFields = keys.find((obj) => {
      // Checking if the fields in the formValues are empty
      if (
        formValues[obj]?.length === 0 &&
        obj !== 'therapy_issues' &&
        obj !== 'therapy_modalities' &&
        obj !== 'therapy_type' &&
        obj !== 'special_considerations' &&
        obj !== 'Deaf / Hard of Hearing' &&
        obj !== 'specialized_therapies' &&
        obj !== 'Deaf or Hard of Hearing' &&
        obj !== 'Working with Orthodox Jews' &&
        obj !== 'Working with Mental Health Professionals' &&
        obj !== 'Under 18 1st Appt Policy' &&
        obj !== 'topIssues' && // Check the topIssues as a whole, not individual issues
        obj !== 'subPopulations' // Added subPopulations to the ignore list
      ) {
        if (obj !== 'Deaf / Hard of Hearing') {
          console.log('Empty field found:', obj); // Log the empty field
          return true;
        }
      }

      // Extra check for topIssues to make sure all issues have a value
      if (
        obj === 'Deaf or Hard of Hearing' ||
        obj === 'Working with Orthodox Jews' ||
        obj === 'Working with Mental Health Professionals' ||
        obj === 'topIssues'
      ) {
        // const issueKeys = Object.keys(formValues[obj]);
        // const emptyIssue = issueKeys.find(
        //   (issueKey) => formValues[obj][issueKey] === ''
        // );
        if (
          formValues[obj].length === 0 ||
          formValues[obj].issue1 === '' ||
          formValues[obj].issue2 === '' ||
          formValues[obj].issue3 === ''
        ) {
          return true;
        }
      }

      return false;
    });

    if (emptyFields) {
      console.log('Submit button disabled due to empty fields'); // Log when the button is disabled
      setSubmitDisabled(true);
    } else {
      setSubmitDisabled(false);
    }
  }, [formValues]);

  const handleSaveDraft = async (event) => {
    // confirm changes
    let changeJson = { ...changes, ...getChangesFromLocalStorage() };

    let r = await confirm({
      confirmation: confirmContent(changeJson, formValues), //passing formValues as an extra parameter
      title: 'Summary Of Changes:',
    });
    if (!r) return;
    // user pressed yes
    let formValuesStored = localStorage.getItem('formValuesTherapy');
    if (formValuesStored) {
      formValuesStored = JSON.parse(formValuesStored);
      formValuesStored = {
        ...formValuesStored,
        [props.selectedUser.id]: formValues,
      };
      localStorage.setItem(
        'formValuesTherapy',
        JSON.stringify(formValuesStored)
      );
    } else {
      localStorage.setItem(
        'formValuesTherapy',
        JSON.stringify({ [props.selectedUser.id]: formValues })
      );
    }
    localStorage.setItem(
      'changedFormValuesTherapy',
      JSON.stringify({ [props.selectedUser.id]: changeJson })
    );
    setChanges({});
    enqueueSnackbar('Draft saved successfully!', {
      variant: 'success',
      timeout: 3000,
    });
  };

  const handleOnSubmit = async (event) => {
    try {
      let shouldSendSuperviseeEmail = false; // This boolean keeps track of sending supervisee the email

      if (props.selectedUser.id) {
        // Confirm changes with the user
        let changeJson = { ...changes, ...getChangesFromLocalStorage() };

        let confirmationResult = await confirm({
          confirmation: confirmContent(changeJson, formValues),
          title: 'Submit Changes',
        });

        console.log('Confirmation modal result:', confirmationResult);

        // Exit if user cancels
        if (!confirmationResult) {
          console.log('Confirmation modal cancelled');
          return;
        }

        // Continue with the submission if the user confirms
        console.log('Proceeding with data submission');

        // Prepare the data to submit
        let dataToSubmit = { ...formValues, ...changeJson };

        // Check if the current user is an admin
        if (isAdmin(currentUser)) {
          dataToSubmit = { ...formValues };
        } else {
          const keys = Object.keys(formValues);
          const emptyFields = keys.find((obj) => {
            if (obj === 'topIssues') {
              // Check that all the fields inside "topIssues" are filled
              return Object.values(formValues[obj]).some((issue) => !issue);
            } else {
              return (
                formValues[obj]?.length === 0 &&
                obj !== 'subPopulations' &&
                obj !== 'Deaf / Hard of Hearing' &&
                obj !== 'therapy_issues' &&
                obj !== 'therapy_modalities' &&
                obj !== 'therapy_type' &&
                obj !== 'special_considerations' &&
                obj !== 'specialized_therapies' &&
                obj !== 'Under 18 1st Appt Policy' &&
                obj !== 'Working with Mental Health Professionals' &&
                obj !== 'Insurance' && // Check for 'Insurance' not empty
                obj !== 'Insurance_t' // Check for 'Insurance_t' not empty
              );
            }
          });

          // If there are empty fields, do not proceed
          if (emptyFields) {
            console.log('Empty fields found:', emptyFields);
            enqueueSnackbar(
              `Please fill in all required fields. The field "${emptyFields}" is missing or incomplete.`,
              {
                variant: 'error',
                timeout: 3000,
              }
            );
            return;
          }
        }

        // Log the data just before submitting it to the backend
        console.log(
          'Data prepared for submission (before additional checks):',
          dataToSubmit
        );
        console.log('Final data to submit:', dataToSubmit);

        // here I am now checking if the user who is submitting is supervisee and updating the supervisee object
        if (
          (isSupervisee(currentUser) ||
            props?.selectedUserDetailForProvider?.degreesAndLicense?.includes(
              'Non-Fully Licensed'
            )) &&
          !formValues.supervision.initial_approval
        ) {
          dataToSubmit.supervision.initial_request = true;
          dataToSubmit.supervision.date_of_initial_request = new Date();
        }

        // The below is the case that checks if the supervisee is requesting modification again  once initial approval was granted
        if (
          (isSupervisee(currentUser) ||
            props?.selectedUserDetailForProvider?.degreesAndLicense?.includes(
              'Non-Fully Licensed'
            )) &&
          formValues.supervision.initial_approval
        ) {
          // setting the modification_request=true
          dataToSubmit.supervision.modification_request = true;
          // Appending the modification history object again
          dataToSubmit.supervision.modifications_history = [
            ...formValues?.supervision?.modifications_history,
          ];

          for (let i = 0; i < supervisorReasons.length; i++) {
            dataToSubmit.supervision.modifications_history.push({
              ['area' + i]: supervisorReasons[i].area,
              reason: supervisorReasons[i].reason,
              previousValue: supervisorReasons[i].previousValue,
              currentValue: supervisorReasons[i].currentValue,
              date: supervisorReasons[i].date,
              isSelectedUserSupervisee: false, // setting it false
            });
          }

          for (let i = 0; i < supervisorReasons.length; i++) {
            dataToSubmit.supervision.latest_modifications.push({
              ['area' + i]: supervisorReasons[i].area,
              reason: supervisorReasons[i].reason,
              previousValue: supervisorReasons[i].previousValue,
              currentValue: supervisorReasons[i].currentValue,
              date: supervisorReasons[i].date,
              isSelectedUserSupervisee: false, // setting it false
            });
          }
        }

        // The below condition checks if supervisor is filling supervisee and actually the revision was requested
        if (
          isSelectedUserSupervisee &&
          isOnlyDoctorOrSupervisor(currentUser) &&
          dataToSubmit.supervision.initial_request &&
          !dataToSubmit.supervision.initial_approval
        ) {
          dataToSubmit.supervision.supervision_initial_modifications = [];

          for (let i = 0; i < supervisorReasons.length; i++) {
            dataToSubmit.supervision.supervision_initial_modifications.push({
              ['area' + i]: supervisorReasons[i].area,
              reason: supervisorReasons[i].reason,
              previousValue: supervisorReasons[i].previousValue,
              currentValue: supervisorReasons[i].currentValue,
              date: supervisorReasons[i].date,
              isSelectedUserSupervisee: true,
            });
          }

          dataToSubmit.supervision.date_of_initial_approval =
            new Date().toString();
          dataToSubmit.supervision.initial_approval = true;
          dataToSubmit.supervision.initial_request = false;
          shouldSendSuperviseeEmail = true;
        }

        // The below condition checks if supervisor is filling supervisee and actually modification was requested by the supervisee
        if (
          isSelectedUserSupervisee &&
          isOnlyDoctorOrSupervisor(currentUser) &&
          dataToSubmit?.supervision?.modification_request &&
          formValues?.supervision?.initial_approval
        ) {
          dataToSubmit.supervision.modifications_history = [
            ...formValues?.supervision?.modifications_history,
          ];

          for (let i = 0; i < supervisorReasons.length; i++) {
            dataToSubmit.supervision.modifications_history.push({
              ['area' + i]: supervisorReasons[i].area,
              reason: supervisorReasons[i].reason,
              previousValue: supervisorReasons[i].previousValue,
              currentValue: supervisorReasons[i].currentValue,
              date: supervisorReasons[i].date,
              isSelectedUserSupervisee: true,
            });
          }

          dataToSubmit.supervision.modification_request = false;
          dataToSubmit.supervision.modification_approval = true;
          dataToSubmit.supervision.latest_modifications = [];
          shouldSendSuperviseeEmail = true;
        }

        // Submit the data to the backend for both admin and non-admin
        const response = await providerService.updateTherapyConfig(
          props.selectedUser.id,
          dataToSubmit
        );
        console.log('Backend submission response:', response);

        // Notification logic for care coordinators, alerts, and socket emissions
        await notificationService.createNotification({
          target_role_name: 'ROLE_CARE_COORDINATOR',
          notification_obj: {
            message: `User ${currentUser.username} updated ${props.selectedUser.username}'s Appropriateness configuration`,
            fields: changeJson,
          },
          target_user_id:
            props.selectedUser.id === currentUser.id
              ? null
              : props.selectedUser.id,
        });

        socket.emit('provider_config_updated', {
          user: currentUser,
          message: `User ${currentUser.username} updated ${props.selectedUser.username}'s Appropriateness configuration`,
          fields: changeJson,
          timestamp: new Date().getTime(),
        });

        enqueueSnackbar('Therapy configuration saved successfully!', {
          variant: 'success',
          timeout: 3000,
        });

        // The below condition checks if supervisor is filling supervisee and in that case an email needs to be send
        if (shouldSendSuperviseeEmail) {
          await providerService.sendApprovalEmail({
            superviseeEmail: props.selectedUser.email,
            superviseeName: props.selectedUser.username,
            supervisorEmail: currentUser.email,
            supervisorReasons: supervisorReasons,
            timestamp: new Date().getTime(),
          });
        }

        // Clear local storage
        let formValuesStored = localStorage.getItem('formValuesTherapy');
        if (formValuesStored) {
          formValuesStored = JSON.parse(formValuesStored);
          delete formValuesStored[props?.selectedUser?.id];
          localStorage.setItem(
            'formValuesTherapy',
            JSON.stringify(formValuesStored)
          );
        }
        localStorage.removeItem('changedFormValuesTherapy');

        // Reset changes state
        setChanges({});
        setSupervisorReasons([]);

        // This condition doesn't refresh but for supervisee locks the screen
        setTriggerCallForSupervisee(
          (triggerCallForSupervisee) => !triggerCallForSupervisee
        );
      }
    } catch (error) {
      console.log('handleOnSubmit.error', error.message);
    }
  };

  const getTheReason = (key) => {
    const keyWords = key.split(' '); // Split the key into individual words
    for (let i = 0; i < supervisorReasons.length; i++) {
      const areaWords = supervisorReasons[i].area.split(' '); // Split the area into individual words
      // Check if any word from the key is included in the area words
      if (keyWords.some((word) => areaWords.includes(word))) {
        return supervisorReasons[i].reason;
      }
    }
    return '';
  };

  const confirmContent = (content, formValues) => {
    let keys = Object.keys(content);

    return (
      <div>
        {isSelectedUserSupervisee &&
          formValues?.supervision?.latest_modifications?.length > 0 && (
            <>
              {formValues.supervision.latest_modifications.map(
                (modification, i) => {
                  const areaKey = Object.keys(modification).find((key) =>
                    key.startsWith('area')
                  );

                  const areaValue = modification[areaKey];

                  // Check if the area is "populationValue"
                  if (areaValue === 'populationValue') {
                    // Split previousValue and currentValue into separate variables
                    const [prevValue1, prevValue2] = modification.previousValue;
                    const [curValue1, curValue2] = modification.currentValue;

                    return (
                      <React.Fragment key={i}>
                        Supervisee Requested - <b>{areaValue}</b> - from -{' '}
                        <b>
                          {prevValue1}, {prevValue2}
                        </b>{' '}
                        to{' '}
                        <b>
                          {curValue1}, {curValue2}
                        </b>
                        <br />
                      </React.Fragment>
                    );
                  } else {
                    return (
                      <React.Fragment key={i}>
                        Supervisee Requested - <b>{areaValue}</b> - from -{' '}
                        <b>{modification.previousValue}</b> to{' '}
                        <b>{modification.currentValue}</b>
                        <br />
                      </React.Fragment>
                    );
                  }
                }
              )}
              <br />
            </>
          )}

        <p>
          {' '}
          The following is a list of the specific items you just changed. Please
          review all of the items to confirm that all of the changes are indeed
          accurate.
        </p>
        {keys.length > 0 ? (
          keys.map((key) => {
            return (
              <div key={key}>
                <li>
                  <b>{key}</b>:{JSON.stringify(content[key])}
                  {(isSelectedUserSupervisee ||
                    (isSupervisee(currentUser) &&
                      formValues?.supervision?.initial_approval)) && (
                    <span>
                      {' '}
                      <b>Reason</b>: {getTheReason(key)}
                    </span>
                  )}
                </li>
              </div>
            );
          })
        ) : (
          <h3>No Changes</h3>
        )}
        <br />
        <p>
          {!isSupervisee(currentUser) ? (
            <i>
              Notice. The changes that you have made will take effect
              immediately and directly impact the clients that you are assigned
              as this information is used by our care coordinators when they
              schedule new clients.
            </i>
          ) : (
            <i>
              As you are supervisee, so this will be submitted for a review..
            </i>
          )}
        </p>
        <p>
          <i>
            By clicking on the "Submit Changes" button below, you are
            acknowledging that you are aware of this. If you need to go back and
            edit your entries, please click on the " Back" button below.
          </i>
        </p>
      </div>
    );
  };

  // The below function is used for finding the submitted Reason
  const findSubmittedReason = (value) => {
    for (let i = 0; i < supervisorReasons.length; i++) {
      const area = supervisorReasons[i].area.toLowerCase().trim();
      if (area === value.toLowerCase().trim()) {
        return `Reason- ${supervisorReasons[i].reason}`;
      }
    }
    return null; // If no match found
  };

  useEffect(() => {
    setSubPopulations([...formValues.subPopulations]);
    if (props.displayingThroughNewuser) {
      // Confirming here that this is getting displayed through new user
      props.setAddNewUserTherapyConfigurationValues({ ...formValues });
      // In the below if condition I am checking if the values for theray configuration are filled
      if (
        formValues.locations.length > 0 &&
        formValues.status.length > 0 &&
        formValues.insurances.length > 0 &&
        formValues.insurances_t.length > 0 &&
        formValues.therapy_initial_survey &&
        formValues.therapy_post_survey
      ) {
        props.setTherapyConfigurationValuesFilled(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues]);

  useEffect(() => {
    if (props?.selectedUser?.id) {
      // passing both selectedUser.id
      userService
        .isSelectedUserSupervisee(props?.selectedUser?.id)
        .then((response) => {
          setIsSelectedUserSupervisee(response.data);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.selectedUser?.id]);

  return (
    <React.Fragment>
      {props.isProvider && !props.isSupervisee && (
        <Typography variant="body1" className="providerNotice">
          There are some parts of the "Approp-Therapy" section that are
          read-only. Please reach out to Levi or Victoria if there is any
          information in the read-only sections that you would like to update.
        </Typography>
      )}
      {props.isSupervisee && (
        <Typography variant="body1" className="providerNotice">
          First few parts of the "Approp-Therapy" section are read-only for
          supervisees. Please reach out to your supervisor if there is any
          information in the read-only sections that you would like to update
          and then reach out back to Levi or Victoria.
        </Typography>
      )}

      <div className="mainContainer">
        <Box sx={{ width: '100%' }}>
          <div>
            <Accordion
              expanded={expanded === 'Locations'}
              onChange={handleChangeAccordion('Locations')}
            >
              <AccordionSummary
                aria-controls="panel1d-content"
                id="panel1d-header"
              >
                <Typography>Locations</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <>
                  <FormControl
                    sx={{ m: 1, width: 1000 }}
                    error={formValues['locations']?.length > 0 ? false : true}
                  >
                    <InputLabel id="demo-multiple-checkbox-label">
                      Locations
                    </InputLabel>
                    <Select
                      labelId="demo-multiple-checkbox-label"
                      name="locations"
                      label="Age *"
                      multiple
                      value={formValues['locations']}
                      onChange={(e) => {
                        handleChange(e, false, 'location');
                      }}
                      input={<OutlinedInput label="Locations*" />}
                      renderValue={(selected) => selected.join(', ')}
                      MenuProps={MenuProps}
                      disabled={props.isSupervisee || props.isProvider}
                    >
                      {providerConfigOption &&
                        providerConfigOption.locations &&
                        Array.isArray(providerConfigOption.locations) &&
                        providerConfigOption.locations.map((name) => (
                          <MenuItem key={name} value={name}>
                            <Checkbox
                              checked={
                                formValues['locations'] &&
                                formValues['locations'].indexOf(name) > -1
                              }
                            />
                            <ListItemText primary={name} />
                          </MenuItem>
                        ))}
                    </Select>
                    {formValues['locations']?.length === 0 && (
                      <FormHelperText>Required field</FormHelperText>
                    )}
                  </FormControl>
                </>
              </AccordionDetails>
            </Accordion>

            <Accordion
              expanded={expanded === 'Therapy Provider Status'}
              onChange={handleChangeAccordion('Therapy Provider Status')}
            >
              <AccordionSummary
                aria-controls="panel2d-content"
                id="panel2d-header"
              >
                <Typography>Therapy Provider Status</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <>
                  <FormControl
                    sx={{ m: 1, width: 1000 }}
                    error={formValues['status']?.length > 0 ? false : true}
                  >
                    <InputLabel id="demo-multiple-checkbox-label">
                      Therapy Provider Status
                    </InputLabel>
                    <Select
                      required
                      labelId="demo-multiple-checkbox-label"
                      name="status"
                      value={formValues['status']}
                      disabled={props.isSupervisee || props.isProvider}
                      onChange={(e) => {
                        handleChange(e, false, 'therapy_provider_status');
                      }}
                      input={<OutlinedInput label="Therapy Provider Status" />}
                      renderValue={(selected) => {
                        return selected.join(', ');
                      }}
                      MenuProps={MenuProps}
                    >
                      {providerConfigOption &&
                        providerConfigOption.status &&
                        Array.isArray(providerConfigOption.status) &&
                        providerConfigOption.status.map((name) => (
                          <MenuItem key={name} value={name}>
                            <Checkbox
                              checked={
                                formValues['status'] &&
                                formValues['status'].indexOf(name) > -1
                              }
                            />
                            <ListItemText primary={name} />
                          </MenuItem>
                        ))}
                    </Select>
                    {formValues['status']?.length === 0 && (
                      <FormHelperText>Required field</FormHelperText>
                    )}
                  </FormControl>

                  {
                    <FormControl
                      sx={{ m: 1, width: 1000 }}
                      error={
                        formValues['insurances']?.length > 0 ? false : true
                      }
                    >
                      <InputLabel id="demo-multiple-checkbox-label">
                        Insurances - Practical
                      </InputLabel>
                      <Select
                        multiple
                        labelId="demo-multiple-checkbox-label"
                        name="insurances"
                        value={formValues['insurances']}
                        disabled={props.isSupervisee || props.isProvider}
                        onChange={(e) => {
                          handleChange(e, false, 'therapy_provider_status');
                        }}
                        input={<OutlinedInput label="Insurances - Practical" />}
                        renderValue={(selected) => selected.join(', ')}
                        MenuProps={MenuProps}
                      >
                        {providerConfigOption &&
                          providerConfigOption.insurances &&
                          Array.isArray(providerConfigOption.insurances) &&
                          providerConfigOption.insurances.map((name) => (
                            <MenuItem key={name} value={name}>
                              <Checkbox
                                checked={
                                  formValues['insurances'] &&
                                  formValues['insurances'].indexOf(name) > -1
                                }
                              />
                              <ListItemText primary={name} />
                            </MenuItem>
                          ))}
                      </Select>
                      {formValues['insurances']?.length === 0 && (
                        <FormHelperText>Required field</FormHelperText>
                      )}
                    </FormControl>
                  }

                  {
                    <FormControl
                      sx={{ m: 1, width: 1000 }}
                      error={
                        formValues['insurances_t']?.length > 0 ? false : true
                      }
                    >
                      <InputLabel id="demo-multiple-checkbox-label">
                        Insurances - Theoretical{' '}
                      </InputLabel>
                      <Select
                        multiple
                        labelId="multiple-checkbox-label"
                        name="insurances_t"
                        value={formValues['insurances_t'] ?? []}
                        disabled={props.isSupervisee || props.isProvider}
                        onChange={(e) => {
                          handleChange(e, false, 'therapy_provider_status');
                        }}
                        input={
                          <OutlinedInput label="Insurances - Theoretical" />
                        }
                        renderValue={(selected) => selected.join(', ')}
                        MenuProps={MenuProps}
                      >
                        {providerConfigOption &&
                          providerConfigOption.insurances_t &&
                          Array.isArray(providerConfigOption.insurances_t) &&
                          providerConfigOption.insurances_t.map((name) => (
                            <MenuItem key={name} value={name}>
                              <Checkbox
                                checked={
                                  formValues['insurances_t'] &&
                                  formValues['insurances_t'].indexOf(name) > -1
                                }
                              />
                              <ListItemText primary={name} />
                            </MenuItem>
                          ))}
                      </Select>
                      {formValues['insurances_t']?.length === 0 && (
                        <FormHelperText>Required field</FormHelperText>
                      )}
                    </FormControl>
                  }
                </>
              </AccordionDetails>
            </Accordion>

            {!props.displayingThroughNewuser && (
              <React.Fragment>
                <Accordion
                  expanded={expanded === 'Population'}
                  onChange={handleChangeAccordion('Population')}
                >
                  <AccordionSummary
                    aria-controls="panel3d-content"
                    id="panel3d-header"
                  >
                    <Typography>Population</Typography>

                    <div style={{ flexGrow: 1 }}></div>
                    {/* The below condition make sures that it is being displayed to only supervisee and when a supervisor selects a supervisee and Admin or careCoordinators*/}
                    {!loading &&
                      isSelectedUserSupervisee &&
                      (isSupervisorORSupervisee(currentUser) ||
                        isAdminOrCareCoordinator(currentUser)) && (
                        <StatusShowingComponentSupervisee
                          currentStatus={currentStatus}
                        />
                      )}
                  </AccordionSummary>

                  <AccordionDetails>
                    <Box sx={{ width: 900 }}>
                      <ThemeProvider theme={theme}>
                        <Slider
                          variant="outlined"
                          step={1}
                          getAriaLabel={() => 'Population range'}
                          value={formValues.populationValue}
                          onChange={handlePopulationChange}
                          valueLabelDisplay="on"
                          disabled={
                            (formValues?.supervision?.initial_request ||
                              formValues?.supervision?.modification_request) &&
                            isSupervisee(currentUser)
                          } // This prop disables the field when submitted for initial review or modification request and the current user is a supervisee
                        />
                      </ThemeProvider>

                      {/*Supervisor Reasons*/}
                      <div>{findSubmittedReason('populationValue')}</div>

                      <div>
                        {!loading &&
                          isSelectedUserSupervisee &&
                          (isSupervisorORSupervisee(currentUser) ||
                            isAdminOrCareCoordinator(currentUser)) && (
                            <HistoryShowingComponentSuperivsee
                              question={'populationValue'}
                              formValues={formValues}
                              setHistoryData={setHistoryData}
                              handleOpenHistory={() =>
                                handleOpenHistory('populationValue')
                              }
                            />
                          )}
                      </div>

                      <Button
                        onClick={addSubSlider}
                        variant="contained"
                        color="primary"
                        disabled={
                          (formValues?.supervision?.initial_request ||
                            formValues?.supervision?.modification_request) &&
                          isSupervisee(currentUser)
                        }
                        // This prop disables the field when submitted for initial review or modification request and the current user is a supervisee
                      >
                        Add Age Group
                      </Button>
                      <Typography sx={{ marginTop: 2, fontStyle: 'italic' }}>
                        <span className={{ fontSize: 'bold' }}>
                          {' '}
                          Adding an Age Group is optional. It allows you to
                          create specific Age Groups within your main Age Group.
                          For example a Sub-Population 10-15 can have a note
                          that says "Parents must attend 1st session alone
                          without client"{' '}
                        </span>
                      </Typography>
                    </Box>
                    {subPopulations.map((sub, index) => (
                      <Accordion key={index}>
                        <AccordionSummary
                          aria-controls={`panel${index}-content`}
                          id={`panel${index}-header`}
                        >
                          <Typography>Age Group {index + 1}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Box sx={{ width: 900 }}>
                            <ThemeProvider theme={theme}>
                              <Slider
                                variant="outlined"
                                step={1}
                                getAriaLabel={() => `Age Group ${index + 1}`}
                                value={sub.value}
                                min={formValues.populationValue[0]}
                                max={formValues.populationValue[1]}
                                onChange={handleSubPopulationChange(index)}
                                valueLabelDisplay="on"
                                disabled={
                                  (formValues?.supervision?.initial_request ||
                                    formValues?.supervision
                                      ?.modification_request) &&
                                  isSupervisee(currentUser)
                                } // This prop disables the field when submitted for initial review or modification request and the current user is a supervisee
                              />
                            </ThemeProvider>

                            <div>
                              <TextField
                                label={`Note for sub population ${index + 1}`}
                                variant="outlined"
                                value={sub.text}
                                required
                                sx={{ width: '900px' }} // the TextField will now be 500px wide
                                onChange={handleSubPopulationTextChange(index)}
                                style={{ width: '100%' }}
                              />
                            </div>
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                marginTop: 2,
                              }}
                            >
                              <Button
                                onClick={() => openDeleteDialog(index)}
                                variant="contained"
                                color="secondary"
                              >
                                Remove this Age Group
                              </Button>
                              <Button
                                onClick={addSubSlider}
                                variant="contained"
                                color="primary"
                              >
                                Add Another Age Group
                              </Button>
                            </Box>
                          </Box>
                        </AccordionDetails>
                      </Accordion>
                    ))}
                  </AccordionDetails>
                </Accordion>

                <Accordion
                  expanded={expanded === 'Special Considerations'}
                  onChange={handleChangeAccordion('Special Considerations')}
                >
                  <AccordionSummary
                    aria-controls="panel3d-content"
                    id="panel3d-header"
                  >
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        flexGrow: 1,
                      }}
                    >
                      <Typography>Special Considerations</Typography>

                      <div style={{ flexGrow: 1 }}></div>
                      {/* The below condition make sures that it is being displayed to only supervisee and when a supervisor selects a supervisee and Admin and Carecoordinators*/}
                      {!loading &&
                        isSelectedUserSupervisee &&
                        (isSupervisorORSupervisee(currentUser) ||
                          isAdminOrCareCoordinator(currentUser)) && (
                          <StatusShowingComponentSupervisee
                            currentStatus={currentStatus}
                          />
                        )}
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <>
                      {providerConfigOption &&
                        providerConfigOption.special_considerations &&
                        Array.isArray(
                          providerConfigOption.special_considerations
                        ) &&
                        providerConfigOption.special_considerations.map(
                          (specialConsiderationsObj, index) => {
                            return (
                              <FormControl
                                sx={{ m: 1, width: 1000 }}
                                error={
                                  formValues[specialConsiderationsObj]?.length >
                                  0
                                    ? false
                                    : true
                                }
                                key={index}
                              >
                                <InputLabel id="demo-multiple-checkbox-label">
                                  {specialConsiderationsObj}
                                </InputLabel>
                                <Select
                                  labelId="demo-multiple-checkbox-label"
                                  name={specialConsiderationsObj}
                                  value={formValues[specialConsiderationsObj]}
                                  onChange={(e) => {
                                    handleChange(e, specialConsiderationsObj);
                                  }}
                                  input={
                                    <OutlinedInput
                                      label={specialConsiderationsObj}
                                    />
                                  }
                                  renderValue={(selected) =>
                                    selected.join(', ')
                                  }
                                  MenuProps={MenuProps}
                                  disabled={
                                    (formValues?.supervision?.initial_request ||
                                      formValues?.supervision
                                        ?.modification_request) &&
                                    isSupervisee(currentUser)
                                  } // This prop disabled the field when submitted for initial review
                                >
                                  {commonOptions.map((name) => (
                                    <MenuItem key={name} value={name}>
                                      <Checkbox
                                        checked={
                                          formValues[
                                            specialConsiderationsObj
                                          ] &&
                                          formValues[
                                            specialConsiderationsObj
                                          ].indexOf(name) > -1
                                        }
                                      />
                                      <ListItemText primary={name} />
                                    </MenuItem>
                                  ))}
                                </Select>
                                {!(
                                  formValues[specialConsiderationsObj]?.length >
                                  0
                                ) && (
                                  <FormHelperText>
                                    Required field
                                  </FormHelperText>
                                )}
                                <div>
                                  {formValues[
                                    `${specialConsiderationsObj}`
                                  ][0] === 'Yes, But...' &&
                                    formValues[
                                      `${specialConsiderationsObj}_reason`
                                    ]}
                                </div>

                                {/* The below is for showing the Reasons same like yes,but.. */}
                                {findSubmittedReason(
                                  `${specialConsiderationsObj}`
                                )}
                                {/*The below component for showing history to selective users */}
                                <div>
                                  {!loading &&
                                    isSelectedUserSupervisee &&
                                    (isSupervisorORSupervisee(currentUser) ||
                                      isAdminOrCareCoordinator(
                                        currentUser
                                      )) && (
                                      <HistoryShowingComponentSuperivsee
                                        question={`${specialConsiderationsObj}`}
                                        formValues={formValues}
                                        setHistoryData={setHistoryData}
                                        handleOpenHistory={() =>
                                          handleOpenHistory(
                                            `${specialConsiderationsObj}`
                                          )
                                        }
                                      />
                                    )}
                                </div>
                              </FormControl>
                            );
                          }
                        )}
                    </>
                  </AccordionDetails>
                </Accordion>

                <Accordion
                  expanded={expanded === 'Therapy Type'}
                  onChange={handleChangeAccordion('Therapy Type')}
                >
                  <AccordionSummary
                    aria-controls="panel3d-content"
                    id="panel3d-header"
                  >
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        flexGrow: 1,
                      }}
                    >
                      <Typography>Therapy Type</Typography>

                      <div style={{ flexGrow: 1 }}></div>

                      {/* The below condition make sures that it is being displayed to only supervisee and when a supervisor selects a supervisee, Admin or careCoordinators*/}
                      {!loading &&
                        isSelectedUserSupervisee &&
                        (isSupervisorORSupervisee(currentUser) ||
                          isAdminOrCareCoordinator(currentUser)) && (
                          <StatusShowingComponentSupervisee
                            currentStatus={currentStatus}
                          />
                        )}
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <>
                      {providerConfigOption &&
                        providerConfigOption.therapy_type &&
                        Array.isArray(providerConfigOption.therapy_type) &&
                        providerConfigOption.therapy_type.map(
                          (therapyTypeObj, index) => {
                            return (
                              <FormControl
                                sx={{ m: 1, width: 1000 }}
                                error={
                                  formValues[therapyTypeObj]?.length > 0
                                    ? false
                                    : true
                                }
                                key={index}
                              >
                                <InputLabel id="demo-multiple-checkbox-label">
                                  {therapyTypeObj}
                                </InputLabel>
                                <Select
                                  labelId="demo-multiple-checkbox-label"
                                  name={therapyTypeObj}
                                  value={formValues[therapyTypeObj]}
                                  onChange={(e) => {
                                    handleChange(e, therapyTypeObj);
                                  }}
                                  input={
                                    <OutlinedInput label={therapyTypeObj} />
                                  }
                                  renderValue={(selected) =>
                                    selected.join(', ')
                                  }
                                  MenuProps={MenuProps}
                                  disabled={
                                    (formValues?.supervision?.initial_request ||
                                      formValues?.supervision
                                        ?.modification_request) &&
                                    isSupervisee(currentUser)
                                  } // This prop disabled the field when submitted for initial review
                                >
                                  {commonOptions.map((name) => (
                                    <MenuItem key={name} value={name}>
                                      <Checkbox
                                        checked={
                                          formValues[therapyTypeObj] &&
                                          formValues[therapyTypeObj].indexOf(
                                            name
                                          ) > -1
                                        }
                                      />
                                      <ListItemText primary={name} />
                                    </MenuItem>
                                  ))}
                                </Select>
                                {!(formValues[therapyTypeObj]?.length > 0) && (
                                  <FormHelperText>
                                    Required field
                                  </FormHelperText>
                                )}
                                <div>
                                  {formValues[`${therapyTypeObj}`][0] ===
                                    'Yes, But...' &&
                                    formValues[`${therapyTypeObj}_reason`]}
                                </div>

                                {/* The below is for showing the Reasons same like yes,but.. */}
                                {findSubmittedReason(`${therapyTypeObj}`)}

                                {/*The below component for showing history to selective users */}
                                <div>
                                  {!loading &&
                                    isSelectedUserSupervisee &&
                                    (isSupervisorORSupervisee(currentUser) ||
                                      isAdminOrCareCoordinator(
                                        currentUser
                                      )) && (
                                      <HistoryShowingComponentSuperivsee
                                        question={`${therapyTypeObj}`}
                                        formValues={formValues}
                                        setHistoryData={setHistoryData}
                                        handleOpenHistory={() =>
                                          handleOpenHistory(`${therapyTypeObj}`)
                                        }
                                      />
                                    )}
                                </div>
                              </FormControl>
                            );
                          }
                        )}
                    </>
                  </AccordionDetails>
                </Accordion>

                <Accordion
                  expanded={expanded === 'Specialized Therapies'}
                  onChange={handleChangeAccordion('Specialized Therapies')}
                >
                  <AccordionSummary
                    aria-controls="panel3d-content"
                    id="panel3d-header"
                  >
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        flexGrow: 1,
                      }}
                    >
                      <Typography>Specialized Therapies</Typography>

                      <div style={{ flexGrow: 1 }}></div>

                      {/* The below condition make sures that it is being displayed to only supervisee and when a supervisor selects a supervisee, Admin or carecoordinators*/}
                      {!loading &&
                        isSelectedUserSupervisee &&
                        (isSupervisorORSupervisee(currentUser) ||
                          isAdminOrCareCoordinator(currentUser)) && (
                          <StatusShowingComponentSupervisee
                            currentStatus={currentStatus}
                          />
                        )}
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <>
                      {providerConfigOption &&
                        providerConfigOption.specialized_therapies &&
                        Array.isArray(
                          providerConfigOption.specialized_therapies
                        ) &&
                        providerConfigOption.specialized_therapies.map(
                          (specializedTherapiesObj, index) => {
                            return (
                              <FormControl
                                sx={{ m: 1, width: 1000 }}
                                error={
                                  formValues[specializedTherapiesObj]?.length >
                                  0
                                    ? false
                                    : true
                                }
                                key={index}
                              >
                                <InputLabel id="demo-multiple-checkbox-label">
                                  {specializedTherapiesObj}
                                </InputLabel>
                                <Select
                                  labelId="demo-multiple-checkbox-label"
                                  name={specializedTherapiesObj}
                                  value={formValues[specializedTherapiesObj]}
                                  onChange={(e) => {
                                    handleChange(e, specializedTherapiesObj);
                                  }}
                                  input={
                                    <OutlinedInput
                                      label={specializedTherapiesObj}
                                    />
                                  }
                                  renderValue={(selected) =>
                                    selected.join(', ')
                                  }
                                  MenuProps={MenuProps}
                                  disabled={
                                    (formValues?.supervision?.initial_request ||
                                      formValues?.supervision
                                        ?.modification_request) &&
                                    isSupervisee(currentUser)
                                  } // This prop disabled the field when submitted for initial review
                                >
                                  {commonOptions.map((name) => (
                                    <MenuItem key={name} value={name}>
                                      <Checkbox
                                        checked={
                                          formValues[specializedTherapiesObj] &&
                                          formValues[
                                            specializedTherapiesObj
                                          ].indexOf(name) > -1
                                        }
                                      />
                                      <ListItemText primary={name} />
                                    </MenuItem>
                                  ))}
                                </Select>
                                {!(
                                  formValues[specializedTherapiesObj]?.length >
                                  0
                                ) && (
                                  <FormHelperText>
                                    Required field
                                  </FormHelperText>
                                )}
                                <div>
                                  {formValues[
                                    `${specializedTherapiesObj}`
                                  ][0] === 'Yes, But...' &&
                                    formValues[
                                      `${specializedTherapiesObj}_reason`
                                    ]}
                                </div>

                                {/* The below is for showing the Reasons same like yes,but.. */}
                                {findSubmittedReason(
                                  `${specializedTherapiesObj}`
                                )}

                                {/*The below component for showing history to selective users */}
                                <div>
                                  {!loading &&
                                    isSelectedUserSupervisee &&
                                    (isSupervisorORSupervisee(currentUser) ||
                                      isAdminOrCareCoordinator(
                                        currentUser
                                      )) && (
                                      <HistoryShowingComponentSuperivsee
                                        question={`${specializedTherapiesObj}`}
                                        formValues={formValues}
                                        setHistoryData={setHistoryData}
                                        handleOpenHistory={() =>
                                          handleOpenHistory(
                                            `${specializedTherapiesObj}`
                                          )
                                        }
                                      />
                                    )}
                                </div>
                              </FormControl>
                            );
                          }
                        )}
                    </>
                  </AccordionDetails>
                </Accordion>

                <Accordion
                  expanded={expanded === 'Therapy Issues'}
                  onChange={handleChangeAccordion('Therapy Issues')}
                >
                  <AccordionSummary
                    aria-controls="panel3d-content"
                    id="panel3d-header"
                  >
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        flexGrow: 1,
                      }}
                    >
                      <Typography>Therapy Issues</Typography>

                      <div style={{ flexGrow: 1 }}></div>

                      {/* The below condition make sures that it is being displayed to only supervisee and when a supervisor selects a supervisee Admin and carecoordinators*/}
                      {!loading &&
                        isSelectedUserSupervisee &&
                        (isSupervisorORSupervisee(currentUser) ||
                          isAdminOrCareCoordinator(currentUser)) && (
                          <StatusShowingComponentSupervisee
                            currentStatus={currentStatus}
                          />
                        )}
                    </div>
                  </AccordionSummary>

                  <AccordionDetails>
                    <>
                      {providerConfigOption &&
                        providerConfigOption.therapy_issues &&
                        Array.isArray(providerConfigOption.therapy_issues) &&
                        providerConfigOption.therapy_issues.map(
                          (therapyIssuesObj, index) => {
                            return (
                              <FormControl
                                sx={{ m: 1, width: 1000 }}
                                error={
                                  formValues[therapyIssuesObj]?.length > 0
                                    ? false
                                    : true
                                }
                                key={index}
                              >
                                <InputLabel
                                  id={`multiple-checkbox-label-${index}`}
                                >
                                  {therapyIssuesObj}
                                  {renderDefinitionToolTip(therapyIssuesObj)}
                                </InputLabel>
                                <Select
                                  labelId="demo-multiple-checkbox-label"
                                  name={therapyIssuesObj}
                                  value={formValues[therapyIssuesObj] || []}
                                  onChange={(e) => {
                                    handleChange(e, therapyIssuesObj);
                                  }}
                                  input={
                                    <OutlinedInput label={therapyIssuesObj} />
                                  }
                                  renderValue={(selected) =>
                                    selected.join(', ')
                                  }
                                  MenuProps={MenuProps}
                                  disabled={
                                    (formValues?.supervision?.initial_request ||
                                      formValues?.supervision
                                        ?.modification_request) &&
                                    isSupervisee(currentUser)
                                  } // This prop disabled the field when submitted for initial review
                                >
                                  {commonOptions.map((name) => (
                                    <MenuItem key={name} value={name}>
                                      <Checkbox
                                        checked={
                                          formValues[therapyIssuesObj] &&
                                          formValues[therapyIssuesObj].indexOf(
                                            name
                                          ) > -1
                                        }
                                      />
                                      <ListItemText primary={name} />
                                    </MenuItem>
                                  ))}
                                </Select>
                                {formValues[therapyIssuesObj]?.length === 0 && (
                                  <FormHelperText>
                                    Required field
                                  </FormHelperText>
                                )}
                                <div>
                                  {formValues[`${therapyIssuesObj}`][0] ===
                                    'Yes, But...' &&
                                    formValues[`${therapyIssuesObj}_reason`]}
                                </div>

                                {/* The below is for showing the Reasons same like yes,but.. */}
                                {findSubmittedReason(`${therapyIssuesObj}`)}

                                {/*The below component for showing history to selective users */}
                                <div>
                                  {!loading &&
                                    isSelectedUserSupervisee &&
                                    (isSupervisorORSupervisee(currentUser) ||
                                      isAdminOrCareCoordinator(
                                        currentUser
                                      )) && (
                                      <HistoryShowingComponentSuperivsee
                                        question={`${therapyIssuesObj}`}
                                        formValues={formValues}
                                        setHistoryData={setHistoryData}
                                        handleOpenHistory={() =>
                                          handleOpenHistory(
                                            `${therapyIssuesObj}`
                                          )
                                        }
                                      />
                                    )}
                                </div>
                              </FormControl>
                            );
                          }
                        )}
                    </>
                  </AccordionDetails>
                </Accordion>

                {/* Below is added for top 3 issues*/}

                <Accordion
                  expanded={expanded === 'Top 3 Therapy Issues'}
                  onChange={handleChangeAccordion('Top 3 Therapy Issues')}
                  style={{ width: '100%' }}
                >
                  <AccordionSummary
                    aria-controls="panel4d-content"
                    id="panel4d-header"
                  >
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        flexGrow: 1,
                      }}
                    >
                      <Typography>Top 3 Therapy Issues</Typography>

                      <div style={{ flexGrow: 1 }}></div>

                      {/* The below condition make sures that it is being displayed to only supervisee and when a supervisor selects a supervisee admin or carecoordinators*/}
                      {!loading &&
                        isSelectedUserSupervisee &&
                        (isSupervisorORSupervisee(currentUser) ||
                          isAdminOrCareCoordinator(currentUser)) && (
                          <StatusShowingComponentSupervisee
                            currentStatus={currentStatus}
                          />
                        )}
                    </div>
                  </AccordionSummary>
                  <AccordionDetails style={{ flexDirection: 'column' }}>
                    <FormControl sx={{ m: 1, width: 800 }}>
                      <InputLabel id="top-issue-1-label">1st Issue</InputLabel>
                      <Select
                        labelId="top-issue-1-label"
                        value={formValues.topIssues.issue1} // Changed to use object
                        onChange={(event) =>
                          handleTopIssuesChange('issue1', event)
                        } // Changed handler
                        disabled={
                          (formValues?.supervision?.initial_request ||
                            formValues?.supervision?.modification_request) &&
                          isSupervisee(currentUser)
                        } // This prop disabled the field when submitted for initial review
                      >
                        {providerConfigOption?.therapy_issues?.map(
                          (issue, index) => (
                            <MenuItem key={index} value={issue}>
                              {issue}
                            </MenuItem>
                          )
                        )}
                      </Select>

                      {/* The below is for showing the Reasons same like yes,but.. */}
                      {findSubmittedReason('issue1')}

                      {/*The below component for showing history to selective users */}
                      <div>
                        {!loading &&
                          isSelectedUserSupervisee &&
                          (isSupervisorORSupervisee(currentUser) ||
                            isAdminOrCareCoordinator(currentUser)) && (
                            <HistoryShowingComponentSuperivsee
                              question={'issue1'}
                              formValues={formValues}
                              setHistoryData={setHistoryData}
                              handleOpenHistory={() =>
                                handleOpenHistory('issue1')
                              }
                            />
                          )}
                      </div>
                    </FormControl>

                    <FormControl sx={{ m: 1, width: 800 }}>
                      <InputLabel id="top-issue-2-label">2nd Issue</InputLabel>
                      <Select
                        labelId="top-issue-2-label"
                        value={formValues.topIssues.issue2} // Changed to use object
                        onChange={(event) =>
                          handleTopIssuesChange('issue2', event)
                        } // Changed handler
                        style={{ width: '100%' }}
                        disabled={
                          (formValues?.supervision?.initial_request ||
                            formValues?.supervision?.modification_request) &&
                          isSupervisee(currentUser)
                        } // This prop disabled the field when submitted for initial review
                      >
                        {providerConfigOption?.therapy_issues?.map(
                          (issue, index) => (
                            <MenuItem key={index} value={issue}>
                              {issue}
                            </MenuItem>
                          )
                        )}
                      </Select>

                      {/* The below is for showing the Reasons same like yes,but.. */}
                      {findSubmittedReason('issue2')}

                      {/*The below component for showing history to selective users */}
                      <div>
                        {!loading &&
                          isSelectedUserSupervisee &&
                          (isSupervisorORSupervisee(currentUser) ||
                            isAdminOrCareCoordinator(currentUser)) && (
                            <HistoryShowingComponentSuperivsee
                              question={'issue2'}
                              formValues={formValues}
                              setHistoryData={setHistoryData}
                              handleOpenHistory={() =>
                                handleOpenHistory('issue2')
                              }
                            />
                          )}
                      </div>
                    </FormControl>

                    <FormControl sx={{ m: 1, width: 800 }}>
                      <InputLabel id="top-issue-3-label">3rd Issue</InputLabel>
                      <Select
                        labelId="top-issue-3-label"
                        value={formValues.topIssues.issue3} // Changed to use object
                        onChange={(event) =>
                          handleTopIssuesChange('issue3', event)
                        } // Changed handler
                        style={{ width: '100%' }}
                        disabled={
                          (formValues?.supervision?.initial_request ||
                            formValues?.supervision?.modification_request) &&
                          isSupervisee(currentUser)
                        } // This prop disabled the field when submitted for initial review
                      >
                        {providerConfigOption?.therapy_issues?.map(
                          (issue, index) => (
                            <MenuItem key={index} value={issue}>
                              {issue}
                            </MenuItem>
                          )
                        )}
                      </Select>

                      {/* The below is for showing the Reasons same like yes,but.. */}
                      {findSubmittedReason('issue3')}

                      {/*The below component for showing history to selective users */}
                      <div>
                        {!loading &&
                          isSelectedUserSupervisee &&
                          (isSupervisorORSupervisee(currentUser) ||
                            isAdminOrCareCoordinator(currentUser)) && (
                            <HistoryShowingComponentSuperivsee
                              question={'issue3'}
                              formValues={formValues}
                              setHistoryData={setHistoryData}
                              handleOpenHistory={() =>
                                handleOpenHistory('issue3')
                              }
                            />
                          )}
                      </div>
                    </FormControl>
                  </AccordionDetails>
                  <div
                    className="alert alert-danger"
                    role="alert"
                    style={{ display: 'none' }}
                    ref={issuesWarningRef}
                  >
                    You are Selecting same Issue again which is not allowed!
                  </div>
                </Accordion>

                <Accordion
                  expanded={expanded === 'Therapy Modalities'}
                  onChange={handleChangeAccordion('Therapy Modalities')}
                >
                  <AccordionSummary
                    aria-controls="panel3d-content"
                    id="panel3d-header"
                  >
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        flexGrow: 1,
                      }}
                    >
                      <Typography>Therapy Modalities</Typography>

                      <div style={{ flexGrow: 1 }}></div>

                      {/* The below condition make sures that it is being displayed to only supervisee and when a supervisor selects a supervisee Admin and carecoordinators*/}
                      {!loading &&
                        isSelectedUserSupervisee &&
                        (isSupervisorORSupervisee(currentUser) ||
                          isAdminOrCareCoordinator(currentUser)) && (
                          <StatusShowingComponentSupervisee
                            currentStatus={currentStatus}
                          />
                        )}
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <>
                      {providerConfigOption &&
                        providerConfigOption.therapy_modalities &&
                        Array.isArray(
                          providerConfigOption.therapy_modalities
                        ) &&
                        providerConfigOption.therapy_modalities.map(
                          (therapyModalitiesObj, index) => {
                            return (
                              <FormControl
                                sx={{ m: 1, width: 1000 }}
                                error={
                                  formValues[therapyModalitiesObj]?.length > 0
                                    ? false
                                    : true
                                }
                                key={index}
                              >
                                <InputLabel id="demo-multiple-checkbox-label">
                                  {therapyModalitiesObj}
                                </InputLabel>
                                <Select
                                  labelId="demo-multiple-checkbox-label"
                                  name={therapyModalitiesObj}
                                  value={formValues[therapyModalitiesObj]}
                                  onChange={(e) => {
                                    handleChange(e, therapyModalitiesObj);
                                  }}
                                  input={
                                    <OutlinedInput
                                      label={therapyModalitiesObj}
                                    />
                                  }
                                  renderValue={(selected) =>
                                    selected.join(', ')
                                  }
                                  MenuProps={MenuProps}
                                  disabled={
                                    (formValues?.supervision?.initial_request ||
                                      formValues?.supervision
                                        ?.modification_request) &&
                                    isSupervisee(currentUser)
                                  } // This prop disabled the field when submitted for initial review
                                >
                                  {therapyModalitiesOptions.map((name) => (
                                    <MenuItem key={name} value={name}>
                                      <ListItemText primary={name} />
                                    </MenuItem>
                                  ))}
                                </Select>
                                {formValues[therapyModalitiesObj]?.length ===
                                  0 && (
                                  <FormHelperText>
                                    Required field
                                  </FormHelperText>
                                )}
                                {/* <div>{formValues[`${therapyModalitiesObj}`] == 'Yes, But...' && formValues[`${therapyModalitiesObj}_reason`]}</div> */}
                                {/* <FormHelperText>test</FormHelperText> */}

                                {/* The below is for showing the Reasons same like yes,but.. */}
                                {findSubmittedReason(`${therapyModalitiesObj}`)}

                                {/*The below component for showing history to selective users */}
                                <div>
                                  {!loading &&
                                    isSelectedUserSupervisee &&
                                    (isSupervisorORSupervisee(currentUser) ||
                                      isAdminOrCareCoordinator(
                                        currentUser
                                      )) && (
                                      <HistoryShowingComponentSuperivsee
                                        question={`${therapyModalitiesObj}`}
                                        formValues={formValues}
                                        setHistoryData={setHistoryData}
                                        handleOpenHistory={() =>
                                          handleOpenHistory(
                                            `${therapyModalitiesObj}`
                                          )
                                        }
                                      />
                                    )}
                                </div>
                              </FormControl>
                            );
                          }
                        )}
                    </>
                  </AccordionDetails>
                </Accordion>

                <Accordion
                  expanded={expanded === 'Risk Questions'}
                  onChange={handleChangeAccordion('Risk Questions')}
                >
                  <AccordionSummary
                    aria-controls="panel3d-content"
                    id="panel3d-header"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      backgroundColor: '#f4f4f4', // added a background color to make the label stand out
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        flexGrow: 1,
                      }}
                    >
                      <Typography
                        component="span"
                        style={{ marginRight: '8px' }} // makes "Risk Questions" bold
                      >
                        Risk Questions
                      </Typography>

                      <div style={{ flexGrow: 1 }}></div>
                      {/* The below condition make sures that it is being displayed to only supervisee and when a supervisor selects a supervisee, Admin and careccordinators*/}
                      {!loading &&
                        isSelectedUserSupervisee &&
                        (isSupervisorORSupervisee(currentUser) ||
                          isAdminOrCareCoordinator(currentUser)) && (
                          <StatusShowingComponentSupervisee
                            currentStatus={currentStatus}
                          />
                        )}
                    </div>
                  </AccordionSummary>

                  <AccordionDetails className="accordion-section">
                    <Typography variant="h5" gutterBottom>
                      {' '}
                      Suicide Risk
                    </Typography>
                    <Typography variant="body2">
                      {' '}
                      Examples: <b> PASSIVE</b> Suicidal Thoughts e.g. Sometimes
                      I wish I didn't wake up in the morning. {<br />}{' '}
                      <b>ACTIVE</b> Suicidal Thoughts e.g. I was thinking of
                      killing myself
                    </Typography>
                    {/* Integrated Dialog for History */}
                    <HistoryDialog
                      historyDialogOpen={historyDialogOpen}
                      handleCloseHistory={handleCloseHistory}
                      historyData={historyData}
                    />

                    <Grid2 size={{ xs: 12 }}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Are you comfortable working with clients{' '}
                          <b>IN-PERSON</b> who have had <b>ACTIVE</b> suicidal
                          thoughts <b>MORE THAN</b> 6 months ago?
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={formValues?.riskQuestions[1]?.answer}
                          label="Sucidial Thoughts - Active - In-Person + 6 Months"
                          onChange={(e) => handleRiskQuestionsChange(e, 2)}
                          disabled={
                            (formValues?.supervision?.initial_request ||
                              formValues?.supervision?.modification_request) &&
                            isSupervisee(currentUser)
                          } // This prop disabled the field when submitted for initial review
                        >
                          <MenuItem value={'Yes'}>Yes</MenuItem>
                          <MenuItem value={'No'}>No</MenuItem>
                          <MenuItem value={'Yes, But...'}>Yes, But...</MenuItem>
                        </Select>
                        {formValues.riskQuestions[1].answer === '' && (
                          <FormHelperText style={{ color: 'red' }}>
                            Required field
                          </FormHelperText>
                        )}
                        <div>
                          {formValues.riskQuestions[1].answer ===
                            'Yes, But...' && formValues.riskQuestions[1].reason}
                        </div>

                        {/* The below is for showing the Reasons same like yes,but.. */}
                        {findSubmittedReason(
                          `Are you comfortable working with clients IN-PERSON who have had ACTIVE suicidal thoughts MORE THAN 6 months ago?`
                        )}

                        {/*The below component for showing history to selective users */}
                        <div>
                          {!loading &&
                            isSelectedUserSupervisee &&
                            (isSupervisorORSupervisee(currentUser) ||
                              isAdminOrCareCoordinator(currentUser)) && (
                              <HistoryShowingComponentSuperivsee
                                question={`Are you comfortable working with clients IN-PERSON who have had ACTIVE suicidal thoughts MORE THAN 6 months ago?`}
                                formValues={formValues}
                                setHistoryData={setHistoryData}
                                handleOpenHistory={() =>
                                  handleOpenHistory(
                                    `Are you comfortable working with clients IN-PERSON who have had ACTIVE suicidal thoughts MORE THAN 6 months ago?`
                                  )
                                }
                              />
                            )}
                        </div>
                      </FormControl>
                    </Grid2>
                    <Grid2 size={{ xs: 12 }}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Are you comfortable working with clients{' '}
                          <b>VIRTUALLY</b> who have had <b>Active</b> suicidal
                          thoughts <b>MORE THAN</b> 6 months ago?
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={formValues?.riskQuestions[2]?.answer}
                          label="Sucidial Thoughts - Active - Virtual - More than 6 Months"
                          onChange={(e) => handleRiskQuestionsChange(e, 3)}
                          disabled={
                            (formValues?.supervision?.initial_request ||
                              formValues?.supervision?.modification_request) &&
                            isSupervisee(currentUser)
                          } // This prop disabled the field when submitted for initial review
                        >
                          <MenuItem value={'Yes'}>Yes</MenuItem>
                          <MenuItem value={'No'}>No</MenuItem>
                          <MenuItem value={'Yes, But...'}>Yes, But...</MenuItem>
                        </Select>
                        {formValues.riskQuestions[2].answer === '' && (
                          <FormHelperText style={{ color: 'red' }}>
                            Required field
                          </FormHelperText>
                        )}
                        <div>
                          {formValues.riskQuestions[2].answer ===
                            'Yes, But...' && formValues.riskQuestions[2].reason}
                        </div>

                        {/* The below is for showing the Reasons same like yes,but.. */}
                        {findSubmittedReason(
                          `Are you comfortable working with clients VIRTUALLY who have had ACTIVE suicidal thoughts MORE THAN 6 months ago?`
                        )}

                        {/*The below component for showing history to selective users */}
                        <div>
                          {!loading &&
                            isSelectedUserSupervisee &&
                            (isSupervisorORSupervisee(currentUser) ||
                              isAdminOrCareCoordinator(currentUser)) && (
                              <HistoryShowingComponentSuperivsee
                                question={`Are you comfortable working with clients VIRTUALLY who have had ACTIVE suicidal thoughts MORE THAN 6 months ago?`}
                                formValues={formValues}
                                setHistoryData={setHistoryData}
                                handleOpenHistory={() =>
                                  handleOpenHistory(
                                    `Are you comfortable working with clients VIRTUALLY who have had ACTIVE suicidal thoughts MORE THAN 6 months ago?`
                                  )
                                }
                              />
                            )}
                        </div>
                      </FormControl>
                    </Grid2>
                    <Grid2 size={{ xs: 12 }}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Are you comfortable working with clients{' '}
                          <b>IN-PERSON</b> who have had <b>ACTIVE</b> suicidal
                          thoughts <b>LESS THAN</b> 6 months ago?
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={formValues?.riskQuestions[3]?.answer}
                          label="Sucidial Thoughts - Active - In-Person - Less Than 6 Months"
                          onChange={(e) => handleRiskQuestionsChange(e, 4)}
                          disabled={
                            (formValues?.supervision?.initial_request ||
                              formValues?.supervision?.modification_request) &&
                            isSupervisee(currentUser)
                          } // This prop disabled the field when submitted for initial review
                        >
                          <MenuItem value={'Yes'}>Yes</MenuItem>
                          <MenuItem value={'No'}>No</MenuItem>
                          <MenuItem value={'Yes, But...'}>Yes, But...</MenuItem>
                        </Select>
                        {formValues.riskQuestions[3].answer === '' && (
                          <FormHelperText style={{ color: 'red' }}>
                            Required field
                          </FormHelperText>
                        )}
                        <div>
                          {formValues.riskQuestions[3].answer ===
                            'Yes, But...' && formValues.riskQuestions[3].reason}
                        </div>

                        {/* The below is for showing the Reasons same like yes,but.. */}
                        {findSubmittedReason(
                          `Are you comfortable working with clients IN-PERSON who have had ACTIVE suicidal thoughts LESS THAN 6 months ago?`
                        )}

                        {/*The below component for showing history to selective users */}
                        <div>
                          {!loading &&
                            isSelectedUserSupervisee &&
                            (isSupervisorORSupervisee(currentUser) ||
                              isAdminOrCareCoordinator(currentUser)) && (
                              <HistoryShowingComponentSuperivsee
                                question={`Are you comfortable working with clients IN-PERSON who have had ACTIVE suicidal thoughts LESS THAN 6 months ago?`}
                                formValues={formValues}
                                setHistoryData={setHistoryData}
                                handleOpenHistory={() =>
                                  handleOpenHistory(
                                    `Are you comfortable working with clients IN-PERSON who have had ACTIVE suicidal thoughts LESS THAN 6 months ago?`
                                  )
                                }
                              />
                            )}
                        </div>
                      </FormControl>
                    </Grid2>
                  </AccordionDetails>

                  <AccordionDetails className="accordion-section">
                    <Typography variant="h5" gutterBottom>
                      {' '}
                      Self-Harm{' '}
                    </Typography>

                    <Grid2 size={{ xs: 12 }}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Are you comfortable working with clients{' '}
                          <b>IN-PERSON </b>
                          who have been self-harming?
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Self Harm - In-Person "
                          value={formValues?.riskQuestions[4]?.answer}
                          onChange={(e) => handleRiskQuestionsChange(e, 5)}
                          disabled={
                            (formValues?.supervision?.initial_request ||
                              formValues?.supervision?.modification_request) &&
                            isSupervisee(currentUser)
                          } // This prop disabled the field when submitted for initial review
                        >
                          <MenuItem value={'Yes'}>Yes</MenuItem>
                          <MenuItem value={'No'}>No</MenuItem>
                          <MenuItem value={'Yes, But...'}>Yes, But...</MenuItem>
                        </Select>
                        {formValues.riskQuestions[4].answer === '' && (
                          <FormHelperText style={{ color: 'red' }}>
                            Required field
                          </FormHelperText>
                        )}
                        <div>
                          {formValues.riskQuestions[4].answer ===
                            'Yes, But...' && formValues.riskQuestions[4].reason}
                        </div>

                        {/* The below is for showing the Reasons same like yes,but.. */}
                        {findSubmittedReason(
                          `Are you comfortable working with clients IN-PERSON who have been self-harming?`
                        )}

                        {/*The below component for showing history to selective users */}
                        <div>
                          {!loading &&
                            isSelectedUserSupervisee &&
                            (isSupervisorORSupervisee(currentUser) ||
                              isAdminOrCareCoordinator(currentUser)) && (
                              <HistoryShowingComponentSuperivsee
                                question={`Are you comfortable working with clients IN-PERSON who have been self-harming?`}
                                formValues={formValues}
                                setHistoryData={setHistoryData}
                                handleOpenHistory={() =>
                                  handleOpenHistory(
                                    `Are you comfortable working with clients IN-PERSON who have been self-harming?`
                                  )
                                }
                              />
                            )}
                        </div>
                      </FormControl>
                    </Grid2>

                    <Grid2 size={{ xs: 12 }}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Are you comfortable working with clients{' '}
                          <b>VIRTUALLY</b> who have been self-harming?
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={formValues?.riskQuestions[5]?.answer}
                          label="Self Harm - Virtually "
                          onChange={(e) => handleRiskQuestionsChange(e, 6)}
                          disabled={
                            (formValues?.supervision?.initial_request ||
                              formValues?.supervision?.modification_request) &&
                            isSupervisee(currentUser)
                          } // This prop disabled the field when submitted for initial review
                        >
                          <MenuItem value={'Yes'}>Yes</MenuItem>
                          <MenuItem value={'No'}>No</MenuItem>
                          <MenuItem value={'Yes, But...'}>Yes, But...</MenuItem>
                        </Select>
                        {formValues.riskQuestions[5].answer === '' && (
                          <FormHelperText style={{ color: 'red' }}>
                            Required field
                          </FormHelperText>
                        )}
                        <div>
                          {formValues.riskQuestions[5].answer ===
                            'Yes, But...' && formValues.riskQuestions[5].reason}
                        </div>

                        {/* The below is for showing the Reasons same like yes,but.. */}
                        {findSubmittedReason(
                          `Are you comfortable working with clients VIRTUALLY who have been self-harming?`
                        )}

                        {/*The below component for showing history to selective users */}
                        <div>
                          {!loading &&
                            isSelectedUserSupervisee &&
                            (isSupervisorORSupervisee(currentUser) ||
                              isAdminOrCareCoordinator(currentUser)) && (
                              <HistoryShowingComponentSuperivsee
                                question={`Are you comfortable working with clients VIRTUALLY who have been self-harming?`}
                                formValues={formValues}
                                setHistoryData={setHistoryData}
                                handleOpenHistory={() =>
                                  handleOpenHistory(
                                    `Are you comfortable working with clients VIRTUALLY who have been self-harming?`
                                  )
                                }
                              />
                            )}
                        </div>
                      </FormControl>
                    </Grid2>
                  </AccordionDetails>

                  <AccordionDetails className="accordion-section">
                    <Typography variant="h5" gutterBottom>
                      {' '}
                      Psychiatric Hospitlization{' '}
                    </Typography>

                    <Grid2 size={{ xs: 12 }}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Are you comfortable working with clients{' '}
                          <b>IN PERSON</b> who have been hospitalized for a
                          psychiatric concern <b>LESS THAN</b> 6 months ago?
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={formValues?.riskQuestions[6]?.answer}
                          label="Psychiatric Hospitlization - In-Person "
                          onChange={(e) => handleRiskQuestionsChange(e, 7)}
                          disabled={
                            (formValues?.supervision?.initial_request ||
                              formValues?.supervision?.modification_request) &&
                            isSupervisee(currentUser)
                          } // This prop disabled the field when submitted for initial review
                        >
                          <MenuItem value={'Yes'}>Yes</MenuItem>
                          <MenuItem value={'No'}>No</MenuItem>
                          <MenuItem value={'Yes, But...'}>Yes, But...</MenuItem>
                        </Select>
                        {formValues.riskQuestions[6].answer === '' && (
                          <FormHelperText style={{ color: 'red' }}>
                            Required field
                          </FormHelperText>
                        )}
                        <div>
                          {formValues.riskQuestions[6].answer ===
                            'Yes, But...' && formValues.riskQuestions[6].reason}
                        </div>

                        {/* The below is for showing the Reasons same like yes,but.. */}
                        {findSubmittedReason(
                          `Are you comfortable working with clients IN PERSON who have been hospitalized for a psychiatric concern LESS THAN 6 months ago?`
                        )}

                        {/*The below component for showing history to selective users */}
                        <div>
                          {!loading &&
                            isSelectedUserSupervisee &&
                            (isSupervisorORSupervisee(currentUser) ||
                              isAdminOrCareCoordinator(currentUser)) && (
                              <HistoryShowingComponentSuperivsee
                                question={`Are you comfortable working with clients IN PERSON who have been hospitalized for a psychiatric concern LESS THAN 6 months ago?`}
                                formValues={formValues}
                                setHistoryData={setHistoryData}
                                handleOpenHistory={() =>
                                  handleOpenHistory(
                                    `Are you comfortable working with clients IN PERSON who have been hospitalized for a psychiatric concern LESS THAN 6 months ago?`
                                  )
                                }
                              />
                            )}
                        </div>
                      </FormControl>
                    </Grid2>
                  </AccordionDetails>
                </Accordion>
              </React.Fragment>
            )}

            {!props.isProvider && (
              <Accordion
                expanded={expanded === 'Links'}
                onChange={handleChangeAccordion('Links')}
              >
                <AccordionSummary
                  aria-controls="panel1d-content"
                  id="panel1d-header"
                >
                  <Typography>Links</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <>
                    <FormControl
                      sx={{ m: 1, width: 1000 }}
                      error={
                        formValues['therapy_initial_survey']?.length > 0
                          ? false
                          : true
                      }
                    >
                      <TextField
                        autoComplete="off"
                        required
                        fullWidth
                        variant="outlined"
                        label="Therapy Initial Survey"
                        value={formValues['therapy_initial_survey']}
                        name="therapy_initial_survey"
                        onChange={(e) => {
                          setFormValues({
                            ...formValues,
                            [e.target.name]: e.target.value,
                          });
                          setChanges({
                            ...changes,
                            [e.target.name]: e.target.value,
                          });
                        }}
                        disabled={props.isProvider}
                      />
                      {formValues['therapy_initial_survey']?.length === 0 && (
                        <FormHelperText>Required field</FormHelperText>
                      )}
                    </FormControl>
                    <FormControl
                      sx={{ m: 1, width: 1000 }}
                      error={
                        formValues['therapy_post_survey']?.length > 0
                          ? false
                          : true
                      }
                    >
                      <TextField
                        autoComplete="off"
                        required
                        fullWidth
                        variant="outlined"
                        label="Therapy Post Survey"
                        value={formValues['therapy_post_survey']}
                        name="therapy_post_survey"
                        onChange={(e) => {
                          setFormValues({
                            ...formValues,
                            [e.target.name]: e.target.value,
                          });
                          setChanges({
                            ...changes,
                            [e.target.name]: e.target.value,
                          });
                        }}
                        disabled={props.isProvider}
                      />
                      {formValues['therapy_post_survey']?.length === 0 && (
                        <FormHelperText>Required field</FormHelperText>
                      )}
                    </FormControl>
                  </>
                </AccordionDetails>
              </Accordion>
            )}

            {!props.isProvider && (
              <Accordion
                expanded={expanded === 'PriorityScheduling'}
                onChange={handleChangeAccordion('PriorityScheduling')}
              >
                <AccordionSummary
                  aria-controls="panel2d-content"
                  id="panel2d-header"
                >
                  <Typography>Priority Scheduling</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={formValues['providerPriorityScheduling']}
                        onChange={(e) => {
                          const value = e.target.checked;
                          setFormValues({
                            ...formValues,
                            providerPriorityScheduling: value,
                          });
                          setChanges({
                            ...changes,
                            providerPriorityScheduling: value,
                          });
                        }}
                        name="providerPriorityScheduling"
                        color="primary"
                        disabled={props.isProvider}
                      />
                    }
                    label="Enable Priority Scheduling"
                  />
                </AccordionDetails>
              </Accordion>
            )}
          </div>
        </Box>
      </div>
      {!props.displayingThroughNewuser && (
        <React.Fragment>
          <Grid2 size={{ xs: 6 }}>
            <button
              className="btn btn-primary w-25"
              type="button"
              onClick={handleSaveDraft}
            >
              Save Draft
            </button>

            <button
              className="btn btn-primary w-25 ml-1"
              type="button"
              disabled={
                !isAdmin(currentUser)
                  ? submitDisabled
                  : !areAdminFieldsComplete()
              }
              onClick={handleOnSubmit}
            >
              {formValues?.supervision?.modification_request &&
              isSelectedUserSupervisee
                ? 'Review Modifications'
                : 'Submit'}
            </button>
          </Grid2>
        </React.Fragment>
      )}
      {/** here have to put dialog */}
      <TherapyConfigDialog
        open={open}
        handleClose={handleClose}
        setReason={setReason}
        selectedField={selectedField}
        setFormValues={setFormValues}
        setChanges={setChanges}
        formValues={formValues}
        changes={changes}
        handleCloseConfirm={handleCloseConfirm}
        reason={reason}
      />

      {/* Here to put Risk Questions Dialog */}
      <RiskQuestionDialog
        open1={open1}
        handleClose1={handleClose1}
        reason={reason}
        handleCloseConfirm1={handleCloseConfirm1}
        formValues={formValues}
        setFormValues={setFormValues}
        setReason={setReason}
      />

      <SubPopulationDialog
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        removeSubSlider={removeSubSlider}
      />

      <ReasonDialog
        reasonDialogOpen={reasonDialogOpen}
        setReasonDialogOpen={setReasonDialogOpen}
        supervisorReasons={supervisorReasons}
        areaOfChange={areaOfChange}
        setSupervisorReasons={setSupervisorReasons}
        setPreviousValue={setPreviousValue}
        setAreaOfChange={setAreaOfChange}
        setCurrentValue={setCurrentValue}
        previousValue={previousValue}
        currentValue={currentValue}
        isSelectedUserSupervisee={isSelectedUserSupervisee}
      />
    </React.Fragment>
  );
};

export default memo(TherapyConfig);
