import React, { useCallback, useEffect, useState } from 'react';
import {
  Link,
  Typography,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Popover,
} from '@mui/material';
import { Col, Row } from 'reactstrap';
import EventBus from '../../common/EventBus';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { green, purple } from '@mui/material/colors';
import AppropAlertButton from '../Buttons/AppropAlertButton/AppropAlertButton';
import {
  SchedulingStep01,
  SchedulingStep03,
  SchedulingStep03B,
  SchedulingStep05,
} from '../Scheduling';
import { entryTypeFormValuesValid, yesNoOptions } from '../../common/utility';
import { useDispatch } from 'react-redux';
import { clearLoading, setLoading } from '../../actions/loading';
import {
  appointmentService,
  providerService,
  userService,
} from '../../services';

export default function Approp() {
  const dispatch = useDispatch();
  const [isManual, setIsManual] = React.useState(true);
  const [entryTypeFormValues] = React.useState({
    entryType: 'new',
  });

  const [step01FormValues, setStep01FormValues] = React.useState({});
  const [step03FormValues, setStep03FormValues] = React.useState({
    therapyModalitySpecialization: 'Primary OR Borrow & Integrate',
  });
  const [step05FormValues, setStep05FormValues] = React.useState({});
  const [triggerPsyPactFilter, setTriggerPsyPactFilter] = React.useState(false);
  const [, setUnderEighteenForm] = React.useState(false);
  const [localFormValues, setLocalFormValues] = useState({
    ageLow: 18,
    ageHigh: 64,
  });
  const [users, setUsers] = React.useState([]);
  // thesetUsersFilter does not yield results
  const [usersFilter, setUsersFilter] = React.useState([]);
  const [globalUserFilter, setGlobalUserFilter] = React.useState([]);
  const [, setAvailableSlots] = React.useState([
    'Thu, 06/02: 1:00PM - 2:00PM [King @ Baltimore]',
    'Thu, 06/02: 5:00PM - 6:00PM [King @ Baltimore]',
    'Fri, 06/03: 2:30PM - 3:30PM [King @ Baltimore]',
    'Thu, 06/09: 1:00PM - 2:00PM [King @ Baltimore]',
    'Thu, 06/09: 5:00PM - 6:00PM [King @ Baltimore]',
    'Fri, 06/10: 2:30PM - 3:30PM [King @ Baltimore]',
  ]);

  const [providers, setProviders] = React.useState([]);

  const [popoverState] = useState({
    open: false,
    text: null,
    anchorEl: null,
  });

  const [, setOpenCancellation] = React.useState(false);
  const [shouldShowStep03B, setShouldShowStep03B] = useState(false);
  const [, setAllProviders] = useState([]);
  const [serviceTesting, setServiceTesting] = React.useState(false);
  const [suicidalThoughts, setSuicidalThoughts] = useState(yesNoOptions[0]);
  const [
    psychiatricHospitalizationHistory,
    setPsychiatricHospitalizationHistory,
  ] = useState(yesNoOptions[0]);
  const [totalAvailableSlots, setTotalAvailableSlots] = useState(0);
  const [functionExecuted, setFunctionExecuted] = useState(false);
  const [selfHarm, setSelfHarm] = useState(yesNoOptions[0]);
  const [globalUsers, setGlobalUsers] = useState([]);
  const [formValues, setFormValues] = useState({ ageLow: 18, ageHigh: 64 });
  const [riskQuestionsFilter, setRiskQuestionsFilter] = useState([]);
  const [sepcializedTherapyFilter, setSpecializedTherapyFilter] =
    React.useState(false);
  const [trigger, setTrigger] = useState(false);

  const [riskQuestionDB, setRiskQuestionDB] = useState({
    'History of Suicidal Thoughts': '',
    'Suicidal Thoughts Details': '',
    'Time of Last Suicidal Thought': '',
    'What Type of Therapy Setting Are You Looking For?': '',
    'History of Self-Harm Within The Last 6 Months': '',
    'What Type of Setting for Therapy Are You Looking For?': '',
    'History of Psychiatric Hospitalization Within Last 6 Months': '',
  });
  const [specialConsiderationSelected, setSpecialConsiderationSelected] =
    useState(null);

  const handlePopulationChange = (event, newValue) => {
    setLocalFormValues({ ageLow: newValue[0], ageHigh: newValue[1] });
    // Add console.log statements to check the values

    let filterProvider = [];
    for (const provider of providers) {
      if (provider && provider.therapy && provider.therapy.populationValue) {
        if (
          localFormValues.ageHigh <= provider.therapy.populationValue[1] &&
          localFormValues.ageLow >= provider.therapy.populationValue[0]
        ) {
          // Ensure provider has a slots property and it's an array
          provider.slots = provider.slots || [];
          filterProvider.push(provider);
        }
      }
    }

    let scannedUsers = [];
    for (let i = 0; i < globalUserFilter.length; i++) {
      if (
        filterProvider.find(
          (obj) => obj.provider_email === globalUserFilter[i].email
        )
      ) {
        scannedUsers.push(globalUserFilter[i]);
      }
    }
    setUsersFilter([...scannedUsers]);
  };

  const showLoading = useCallback(() => {
    dispatch(setLoading());
  }, [dispatch]);

  const hideLoading = useCallback(() => {
    dispatch(clearLoading());
  }, [dispatch]);

  useEffect(() => {
    if (formValues.populationValue) {
      const [newAgeLow, newAgeHigh] = formValues.populationValue;
      setFormValues((prevValues) => ({
        ...prevValues,
        ageLow: newAgeLow,
        ageHigh: newAgeHigh,
      }));
    }
  }, [formValues.populationValue]);

  useEffect(() => {
    showLoading();
    async function fetchUsersAndProviders() {
      await userService.getAllUsers().then(
        (response) => {
          let users = response?.data?.users ?? [];
          let providers = users
            .filter((user) => {
              return user.roleId === 5 && user.is_active === 1; // filtering provider from users and that provider which is active
            })
            .sort((user1, user2) => {
              const name1 = user1?.username?.split(' ')[1] ?? '';
              const name2 = user2?.username?.split(' ')[1] ?? '';
              return name1.localeCompare(name2);
            });
          // The above filtering providers from users and setting providers.
          setUsers(providers);
          setUsersFilter([]);
        },
        (error) => {
          const _content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          if (error.response && error.response.status === 401) {
            EventBus.dispatch('logout');
          }
          console.log('_content', _content);
        }
      );

      await providerService.getProviders().then((response) => {
        let providers = response?.data?.providers ?? [];
        setAllProviders(providers);
      });
    }
    fetchUsersAndProviders().finally(() => hideLoading());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (formValues.populationValue) {
      const [newAgeLow, newAgeHigh] = formValues.populationValue;
      setFormValues((prevValues) => ({
        ...prevValues,
        ageLow: newAgeLow,
        ageHigh: newAgeHigh,
      }));
    }
  }, [formValues.populationValue]);

  function compareUsersFilterForRiskQuestions() {
    let filteredUsersWithSlots;
    if (!isManual) {
      filteredUsersWithSlots = usersFilter.filter(
        (usersFilterObject) => usersFilterObject.slots.length > 0
      );
    } else {
      filteredUsersWithSlots = globalUsers;
    }
    const providersTobeCompared = [];
    for (let i = 0; i < filteredUsersWithSlots.length; i++) {
      const foundProvider = providers.find(
        (providerObj) =>
          providerObj.provider_email === filteredUsersWithSlots[i].email
      );
      providersTobeCompared.push(foundProvider);
    }
    let finalProvidersTobeCompared = [];
    if (riskQuestionsFilter?.length > 0) {
      providersTobeCompared.map((providersTobeComparedObj) => {
        return riskQuestionsFilter?.map((riskQuestionsFilterObj) => {
          return providersTobeComparedObj?.therapy?.riskQuestions?.find(
            // eslint-disable-next-line array-callback-return
            (providersRiskQuestionObj) => {
              if (
                providersRiskQuestionObj.id === riskQuestionsFilterObj.id &&
                providersRiskQuestionObj.answer.includes(
                  riskQuestionsFilterObj.answer
                )
              ) {
                return finalProvidersTobeCompared.push(
                  providersTobeComparedObj
                );
              }
            }
          );
        });
      });
      let finalFilteredUsers = [];
      for (let i = 0; i < filteredUsersWithSlots.length; i++) {
        const foundProvider = finalProvidersTobeCompared.find(
          (finalProviderObj) =>
            finalProviderObj.provider_email === filteredUsersWithSlots[i].email
        );
        if (foundProvider) {
          finalFilteredUsers.push(filteredUsersWithSlots[i]);
        }
      }
      setUsersFilter([...finalFilteredUsers]);
      setTrigger(false);
    }
  }

  useEffect(() => {
    if (users.length > 0) {
      fetchProviders();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step03FormValues, step05FormValues, users, specialConsiderationSelected]);

  const fetchProviders = async (filters) => {
    try {
      showLoading();
      appointmentService
        .getProviderAppts(step03FormValues, step05FormValues)
        .then(
          (response) => {
            let providers = response?.data?.providers ?? [];
            const slots = response?.data?.availableSlots ?? [];
            let usersFilterLocal = users
              .filter((obj) => {
                const provider = providers.find((providerObj) => {
                  return obj.id === providerObj.id;
                });
                return provider;
              })
              .map((slotObj) => {
                const slotFilters = slots.filter((slotFilterObj) => {
                  return slotFilterObj.provider_id === slotObj.id;
                });
                return { ...slotObj, slots: slotFilters };
              })
              .sort((obj1, obj2) => {
                const name1 = obj1?.username?.split(' ')[1] ?? '';
                const name2 = obj2?.username?.split(' ')[1] ?? '';
                return name1.localeCompare(name2);
              });

            // Add this function to log all provider populationValues
            const listProviderPopulationValues = () => {
              let filterProvider = [];
              for (const provider of providers) {
                if (
                  provider &&
                  provider.therapy &&
                  provider.therapy.populationValue
                ) {
                  if (
                    localFormValues.ageHigh <=
                      provider.therapy.populationValue[1] &&
                    localFormValues.ageLow >=
                      provider.therapy.populationValue[0]
                  ) {
                    // Ensure provider has a slots property and it's an array
                    provider.slots = provider.slots || [];
                    filterProvider.push(provider);
                  }
                }
              }
              let scannedUsers = [];
              setGlobalUserFilter([...usersFilterLocal]);
              for (let i = 0; i < usersFilterLocal.length; i++) {
                if (
                  filterProvider.find(
                    (obj) => obj.provider_email === usersFilterLocal[i].email
                  )
                ) {
                  scannedUsers.push(usersFilterLocal[i]);
                }
              }
              setUsersFilter([...scannedUsers]);
            };
            listProviderPopulationValues();

            const licenseArray = step05FormValues?.license?.map(
              (licenseObj) => licenseObj?.title
            );

            function checkArrayElementsExist(arr1, arr2) {
              arr1 = arr1 || [];
              arr2 = arr2 || [];
              setFunctionExecuted(true);
              if (arr1?.length === 1) {
                return arr2?.includes(arr1[0]);
              } else {
                arr1 = arr1.sort();
                arr2 = arr2.sort();
                for (let i = 0; i < arr1?.length; i++) {
                  if (arr1[i] !== arr2[i]) {
                    return false;
                  }
                }
                return true;
              }
            }
            let licenseFilteredProvider = [];

            for (let i = 0; i < providers?.length; i++) {
              if (
                checkArrayElementsExist(
                  licenseArray,
                  providers[i]?.degrees_and_license
                )
              ) {
                licenseFilteredProvider.push(providers[i]);
              }
            }
            if (
              licenseFilteredProvider.length > 0 ||
              functionExecuted === true
            ) {
              setProviders(licenseFilteredProvider);
            } else {
              setProviders(providers);
            }

            let scannedUsers = [];
            for (let i = 0; i < globalUsers.length; i++) {
              if (
                licenseFilteredProvider.find(
                  (obj) => obj.provider_email === globalUsers[i].email
                )
              ) {
                scannedUsers.push(globalUsers[i]);
              }
            }
            if (scannedUsers.length > 0 || functionExecuted === true) {
              setUsersFilter([...scannedUsers]);
            } else {
              setUsersFilter(usersFilterLocal);
              setGlobalUsers(usersFilterLocal);
            }

            // setProviders(providers);
            setAvailableSlots(slots);

            if (
              step03FormValues.therapySpecialties !== undefined &&
              step03FormValues.therapySpecialties !== 'All'
            ) {
              let filteredProvider = [];

              providers.map((provider) => {
                // eslint-disable-next-line array-callback-return
                return Object.keys(provider.therapy).find((key) => {
                  if (
                    key.toString().toLowerCase() ===
                    step03FormValues.therapySpecialties.toLowerCase()
                  ) {
                    if (
                      provider.therapy[key].toString().toLowerCase() === 'yes'
                    ) {
                      filteredProvider.push(provider);
                      return provider;
                    }
                  }
                });
              });
              let FilteredUsersT = [];
              if (sepcializedTherapyFilter) {
                for (let i = 0; i < globalUsers.length; i++) {
                  if (
                    filteredProvider?.find(
                      (obj) => obj?.provider_email === globalUsers[i]?.email
                    )
                  ) {
                    FilteredUsersT.push(globalUsers[i]);
                  }
                }
              } else {
                for (let i = 0; i < usersFilter.length; i++) {
                  if (
                    filteredProvider?.find(
                      (obj) => obj?.provider_email === usersFilter[i]?.email
                    )
                  ) {
                    FilteredUsersT.push(usersFilter[i]);
                  }
                }
              }
              setUsersFilter([...FilteredUsersT]);
              setSpecializedTherapyFilter(true);
            }

            if (specialConsiderationSelected) {
              let filteredProviders = [];
              for (let i = 0; i < providers.length; i++) {
                if (
                  specialConsiderationSelected === 'Deaf or Hard of Hearing'
                ) {
                  if (
                    providers[i].therapy['Deaf or Hard of Hearing'].includes(
                      'Yes'
                    )
                  ) {
                    filteredProviders.push(providers[i]);
                  }
                } else if (
                  specialConsiderationSelected ===
                  'Working with Mental Health Professionals'
                ) {
                  if (
                    providers[i].therapy[
                      'Working with Mental Health Professionals'
                    ].includes('Yes')
                  ) {
                    filteredProviders.push(providers[i]);
                  }
                } else if (
                  specialConsiderationSelected === 'Working with Orthodox Jews'
                ) {
                  if (
                    providers[i].therapy['Working with Orthodox Jews'].includes(
                      'Yes'
                    )
                  ) {
                    filteredProviders.push(providers[i]);
                  }
                }
              }
              let fileredUserConsideration = [];

              for (let i = 0; i < usersFilter.length; i++) {
                if (
                  filteredProviders?.find(
                    (obj) => obj?.provider_email === usersFilter[i]?.email
                  )
                ) {
                  fileredUserConsideration.push(usersFilter[i]);
                }
              }
              setUsersFilter([...fileredUserConsideration]);
            }
            if (
              step05FormValues.psyPactProvider ||
              step05FormValues?.provider_other_licensed_state?.length > 0
            ) {
              setTriggerPsyPactFilter(
                (triggerPsyPactFilter) => !triggerPsyPactFilter
              );
            }
          },
          (error) => {
            const _content =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();

            console.log('_content: ', _content);
            if (error.response && error.response.status === 401) {
              EventBus.dispatch('logout');
            }
          }
        )
        .finally(() => hideLoading());
    } catch (error) {
      // console.log('fetchProviders.error', error)
    }
  };

  const getProviderYesMaybeText = (userFilter) => {
    let provider = providers.find((it) => it.id === userFilter.id);

    let reasonText = [];
    if (provider) {
      let therapy = provider['therapy'];
      let services = (step03FormValues.services || []).map((it) => it.title);
      let serviceTypes = step03FormValues.serviceType;
      let serviceGroup = step03FormValues.serviceGroup;

      if (therapy) {
        let keys = Object.keys(therapy);
        for (let i = 0; i < keys.length; i++) {
          let k = keys[i];
          if (k.endsWith('_reason')) {
            if (
              services?.indexOf(k.replace('_reason', '')) > -1 ||
              serviceGroup?.indexOf(k.replace('_reason', '')) > -1 ||
              serviceTypes?.indexOf(k.replace('_reason', '')) > -1
            ) {
              let replaced = k.replace('_reason', '');
              if (therapy[replaced]?.indexOf('Yes, But...') > -1) {
                reasonText.push(`${replaced}: ${therapy[k]} <br>`);
              }
            }
          }
        }
        if (provider.therapy.subPopulations) {
          provider.therapy.subPopulations.forEach((subPopulation) => {
            const [low, high] = subPopulation.value;
            if (
              localFormValues.ageHigh >= low &&
              localFormValues.ageLow <= high
            ) {
              reasonText.push(`Age Note: ${subPopulation.text} <br>`);
            }
          });
        }
      }

      let testing = provider['testing'];
      if (testing) {
        let keys = Object.keys(testing);
        for (let i = 0; i < keys.length; i++) {
          let k = keys[i];
          if (k.endsWith('_reason')) {
            if (
              services?.indexOf(k.replace('_reason', '')) > -1 ||
              serviceGroup?.indexOf(k.replace('_reason', '')) > -1 ||
              serviceTypes?.indexOf(k.replace('_reason', '')) > -1
            ) {
              let replaced = k.replace('_reason', '');
              if (therapy[replaced]?.indexOf('Yes, But...') > -1) {
                reasonText.push(`${replaced}: ${therapy[k]} <br>`);
              }
            }
          }
        }
      }
      let riskQuestions = provider['therapy']?.riskQuestions;
      if (riskQuestionsFilter?.length > 0) {
        if (riskQuestions?.length > 0) {
          let reasons = riskQuestions
            // eslint-disable-next-line array-callback-return
            .map((riskQuestion) => {
              if (riskQuestion.reason)
                if (riskQuestion.question.includes('suicidal')) {
                  return 'Risk Suicide: ' + riskQuestion.reason + '<br>';
                } else if (riskQuestion.question.includes('self-harming')) {
                  return 'Risk Self-Harm: ' + riskQuestion.reason + '<br>';
                } else {
                  return (
                    'Risk Psych Hospitalization: ' +
                    riskQuestion.reason +
                    '<br>'
                  );
                }
            })
            .filter((element) => element !== undefined);
          if (reasons?.length > 0) {
            reasons = reasons.join('\n');
            reasonText.push(reasons);
          }
        }
      }
    }
    let out = reasonText.join('\n');
    return out;
  };

  useEffect(() => {
    const { entryType, existingClientOptions } = entryTypeFormValues;
    if (entryType === 'existing' && existingClientOptions === 'Transfer') {
      setIsManual(true);
    }
  }, [entryTypeFormValues]);

  function shouldShowSegmentOrNot(label) {
    const { entryType, cancellationOptions } = entryTypeFormValues;
    if (label === 'Service Info') {
      // if (entryType === 'existing' && existingClientOptions === 'Turning 18 years-Old') {
      //   return false;
      // }
      if (entryType === 'cancellation' && cancellationOptions === 'new') {
        return false;
      }
    }
    return true;
  }

  useEffect(() => {
    let availableSlotsCount = 0;
    usersFilter.forEach((user) => {
      availableSlotsCount += user.slots.length;
    });

    setTotalAvailableSlots(availableSlotsCount);
  }, [usersFilter]);

  function clearRiskFilter(id) {
    // expecting the id to be clear from risk filter
    let riskFilters = riskQuestionsFilter;
    let filteredRisk = riskFilters.filter(
      (riskFilterObj) => !id.includes(riskFilterObj.id)
    );
    setRiskQuestionsFilter([...filteredRisk]);
    setUsersFilter([...globalUsers]);
  }

  const renderStateBadges = (obj) => {
    const states = [];
    const selectedState =
      step05FormValues?.provider_other_licensed_state?.toString();

    // Add other licensed states
    if (obj?.LicenseInOtherStates?.includes(selectedState)) {
      states.push(selectedState);
    }

    // Check if the provider is a PsyPact member and add the PsyPact badge, excluding "MD Only" and no state selected cases
    if (obj?.isPsyPactMember && selectedState && selectedState !== 'MD Only') {
      states.push('PsyPact');
    }

    return states.map((state, index) => (
      <Box
        key={index}
        sx={{
          display: 'flex',
          alignItems: 'center',
          bgcolor: state === 'PsyPact' ? green[500] : purple[500], // Green for PsyPact, purple for others
          color: '#fff',
          padding: '2px 8px',
          borderRadius: '12px',
          marginLeft: '4px',
          fontSize: '0.75rem',
        }}
      >
        <VerifiedUserIcon sx={{ marginRight: '4px', fontSize: '1rem' }} />
        {state}
      </Box>
    ));
  };

  const handleSuicidalThoughtType = (filterObject) => {
    let tempRiskQuestionsFilter = riskQuestionsFilter;
    let objectToBePushed1 = { answer: 'Yes', id: 1 };
    let objectToBePushed2 = { answer: 'Yes', id: 4 };
    let objectToBePushed3 = { answer: 'Yes', id: 2 };
    let objectToBePushed4 = { answer: 'Yes', id: 3 };
    let objectToBePushed5 = { answer: 'Yes', id: 5 };
    let objectToBePushed6 = { answer: 'Yes', id: 6 };
    let objectToBePushed7 = { answer: 'Yes', id: 7 };

    if (filterObject?.includes('Passive')) {
      if (
        !tempRiskQuestionsFilter.some(
          (tempQuestionObj) =>
            tempQuestionObj.id === objectToBePushed1.id &&
            tempQuestionObj.name === objectToBePushed1.name
        )
      ) {
        tempRiskQuestionsFilter.push(objectToBePushed1);
      }
      setRiskQuestionsFilter([...tempRiskQuestionsFilter]);
    } else {
      let filtered = tempRiskQuestionsFilter?.filter(
        (tempRiskQuestionObj) => tempRiskQuestionObj.id !== objectToBePushed1.id
      );
      setRiskQuestionsFilter([...filtered]);
    }
    if (filterObject?.includes('Less Than 6 Months Ago')) {
      if (
        !tempRiskQuestionsFilter.some(
          (tempQuestionObj) =>
            tempQuestionObj.id === objectToBePushed2.id &&
            tempQuestionObj.name === objectToBePushed2.name
        )
      ) {
        tempRiskQuestionsFilter.push(objectToBePushed2);
      }
      setRiskQuestionsFilter([...tempRiskQuestionsFilter]);
    } else {
      let filtered = tempRiskQuestionsFilter.filter(
        (tempRiskQuestionObj) => tempRiskQuestionObj.id !== objectToBePushed2.id
      );
      setRiskQuestionsFilter([...filtered]);
    }
    if (filterObject?.includes('Virtual Only')) {
      if (
        !tempRiskQuestionsFilter.some(
          (tempQuestionObj) =>
            tempQuestionObj.id === objectToBePushed4.id &&
            tempQuestionObj.name === objectToBePushed4.name
        )
      ) {
        tempRiskQuestionsFilter.push(objectToBePushed4);
      } else {
        let filteredSecondCase = tempRiskQuestionsFilter.filter(
          (tempRiskQObject) => tempRiskQObject.id === objectToBePushed4.id
        );
        tempRiskQuestionsFilter = [...filteredSecondCase];
      }
      setRiskQuestionsFilter([...tempRiskQuestionsFilter]);
    } else {
      let filtered = tempRiskQuestionsFilter.filter(
        (tempRiskQuestionObj) => tempRiskQuestionObj.id !== objectToBePushed4.id
      );
      setRiskQuestionsFilter([...filtered]);
    }

    if (filterObject?.includes('In-Person')) {
      if (
        !tempRiskQuestionsFilter.some(
          (tempQuestionObj) =>
            tempQuestionObj.id === objectToBePushed3.id &&
            tempQuestionObj.name === objectToBePushed3.name
        )
      ) {
        tempRiskQuestionsFilter.push(objectToBePushed3);
        tempRiskQuestionsFilter.push(objectToBePushed4);
      }
      setRiskQuestionsFilter([...tempRiskQuestionsFilter]);
    } else {
      let filtered = tempRiskQuestionsFilter?.filter(
        (tempRiskQuestionObj) =>
          tempRiskQuestionObj.id !== objectToBePushed3.id ||
          tempRiskQuestionObj.id !== objectToBePushed4.id
      );
      setRiskQuestionsFilter([...filtered]);
    }

    if (filterObject?.includes('harmvirtual')) {
      if (
        !tempRiskQuestionsFilter.some(
          (tempQuestionObj) =>
            tempQuestionObj.id === objectToBePushed6.id &&
            tempQuestionObj.name === objectToBePushed6.name
        )
      ) {
        tempRiskQuestionsFilter.push(objectToBePushed6);
      } else {
        let filteredSecondCase = tempRiskQuestionsFilter.filter(
          (tempRiskQObject) => tempRiskQObject.id === objectToBePushed6.id
        );
        tempRiskQuestionsFilter = [...filteredSecondCase];
      }
      setRiskQuestionsFilter([...tempRiskQuestionsFilter]);
    } else {
      let filtered = tempRiskQuestionsFilter.filter(
        (tempRiskQuestionObj) => tempRiskQuestionObj.id !== objectToBePushed6.id
      );
      setRiskQuestionsFilter([...filtered]);
    }

    if (filterObject?.includes('harminperson')) {
      if (
        !tempRiskQuestionsFilter.some(
          (tempQuestionObj) =>
            tempQuestionObj.id === objectToBePushed5.id &&
            tempQuestionObj.name === objectToBePushed5.name
        )
      ) {
        tempRiskQuestionsFilter.push(objectToBePushed5);
        tempRiskQuestionsFilter.push(objectToBePushed6);
      }
      setRiskQuestionsFilter([...tempRiskQuestionsFilter]);
    } else {
      let filtered = tempRiskQuestionsFilter.filter(
        (tempRiskQuestionObj) =>
          tempRiskQuestionObj.id !== objectToBePushed5.id ||
          tempRiskQuestionObj.id !== objectToBePushed6.id
      );
      setRiskQuestionsFilter([...filtered]);
    }

    if (filterObject?.includes('psyhistory')) {
      if (
        !tempRiskQuestionsFilter.some(
          (tempQuestionObj) =>
            tempQuestionObj.id === objectToBePushed7.id &&
            tempQuestionObj.name === objectToBePushed7.name
        )
      ) {
        tempRiskQuestionsFilter.push(objectToBePushed7);
      }
      setRiskQuestionsFilter([...tempRiskQuestionsFilter]);
    } else {
      let filtered = tempRiskQuestionsFilter.filter(
        (tempRiskQuestionObj) => tempRiskQuestionObj.id !== objectToBePushed7.id
      );
      setRiskQuestionsFilter([...filtered]);
    }
  };

  useEffect(() => {
    compareUsersFilterForRiskQuestions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [riskQuestionsFilter, isManual, trigger]);

  React.useEffect(() => {
    async function getProviderDetaiFromAPI() {
      const updatedUsersFilter = await Promise.all(
        usersFilter.map(async (obj, index) => {
          const response = await providerService.getProviderDetail(
            obj.id,
            obj.email
          );

          return {
            ...obj,
            isPsyPactMember:
              response?.data?.provider?.psyPactDetails?.isPsyPactMember ===
              'yes',
            LicenseInOtherStates:
              response?.data?.provider?.licensed_in_other_states?.split(','),
          };
        })
      );

      setUsersFilter(updatedUsersFilter);
    }

    getProviderDetaiFromAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerPsyPactFilter]);

  const [, setAllAnswered] = useState(false);
  const handleAllQuestions = (value) => {
    setAllAnswered(value);
  };

  const [, setSettingValues] = useState([]);
  const handleSettingChange = (values) => {
    setSettingValues(values);
  };

  return (
    <React.Fragment>
      <div className="mainContainer">
        <Row>
          <Col xs={12}>
            <Box>
              <div>
                {entryTypeFormValuesValid(entryTypeFormValues) && (
                  <>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography>Client's Age</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <SchedulingStep01
                          handlePopulationChange={handlePopulationChange}
                          localFormValues={localFormValues}
                          formValues={formValues}
                          underEighteenForm={setUnderEighteenForm}
                          setUnderEighteenForm={setUnderEighteenForm}
                          setFormValues={setFormValues}
                          setOpenCancellation={setOpenCancellation}
                          entryTypeFormValues={entryTypeFormValues}
                          step01FormValues={step01FormValues}
                          setStep01FormValues={setStep01FormValues}
                          sectionsToShow={['ageRange']}
                        />
                      </AccordionDetails>
                    </Accordion>

                    {shouldShowSegmentOrNot('Service Info') && (
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel2a-content"
                          id="panel2a-header"
                        >
                          <Typography>Service Info</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <SchedulingStep03
                            entryTypeFormValues={entryTypeFormValues}
                            setStep03FormValues={setStep03FormValues}
                            step03FormValues={step03FormValues}
                            serviceTesting={serviceTesting}
                            setShouldShowStep03B={setShouldShowStep03B}
                            setServiceTesting={setServiceTesting}
                            showTextarea={false}
                            setSpecialConsiderationSelected={
                              setSpecialConsiderationSelected
                            }
                            sectionsToShow={[
                              'serviceType',
                              'insurance',
                              // , 'formHelperText'
                              'therapySpecializations',
                              'specialConsiderations',
                              'services',
                              'modalitySpecialization',
                              'serviceType',
                            ]}
                          />
                        </AccordionDetails>
                      </Accordion>
                    )}

                    {shouldShowSegmentOrNot(
                      'Appropriateness for Remote Sessions'
                    ) &&
                      shouldShowStep03B && (
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                          >
                            <Typography>Risk Questions</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <SchedulingStep03B
                              suicidalThoughts={suicidalThoughts}
                              selfHarm={selfHarm}
                              setSuicidalThoughts={setSuicidalThoughts}
                              setSelfHarm={setSelfHarm}
                              serviceGroup={step03FormValues.serviceGroup}
                              psychiatricHospitalizationHistory={
                                psychiatricHospitalizationHistory
                              }
                              setPsychiatricHospitalizationHistory={
                                setPsychiatricHospitalizationHistory
                              }
                              serviceType={step03FormValues.serviceType} // Pass the serviceType prop
                              handleSuicidalThoughtType={
                                handleSuicidalThoughtType
                              }
                              clearRiskFilter={clearRiskFilter}
                              handleAllQuestions={handleAllQuestions}
                              handleSettingChange={handleSettingChange}
                              riskQuestionDB={riskQuestionDB}
                              setRiskQuestionDB={setRiskQuestionDB}
                            />
                          </AccordionDetails>
                        </Accordion>
                      )}

                    {shouldShowSegmentOrNot('Provider Profile Filter') && (
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel2a-content"
                          id="panel2a-header"
                        >
                          <Typography>Provider's Profile Filters</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <SchedulingStep05
                            step05FormValues={step05FormValues}
                            setStep05FormValues={setStep05FormValues}
                          />
                        </AccordionDetails>
                      </Accordion>
                    )}
                  </>
                )}
              </div>
            </Box>
          </Col>
        </Row>

        {entryTypeFormValuesValid(entryTypeFormValues) && (
          <>
            <div
              style={{
                fontweight: '600',
                fontSize: '25px',
                marginTop: '20px',
                marginBottom: '20px',
              }}
            >
              Providers
            </div>

            {totalAvailableSlots === 0 && usersFilter.length === 0 && (
              <Typography>
                Unfortunately, there are no available providers{' '}
                {isManual
                  ? 'that meet your search criteria'
                  : 'with any openings '}
                . Please either expand your search
                {isManual
                  ? ' by changing your filters.'
                  : 'to include a wider date range or expand other filters.'}{' '}
                If you still can't find an opening you can search our referral
                list for providers outside of PsychCare by clicking{' '}
                <Link
                  href="https://sites.google.com/psychcaremd.com/home/referrals_2/all"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  here
                </Link>
              </Typography>
            )}

            <Popover
              anchorEl={popoverState.anchorEl}
              open={popoverState.open}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              {popoverState.text}
            </Popover>

            <Row xs="12">
              <Col xs="12">
                <Row xs="12">
                  {usersFilter.map((obj, index) => {
                    return obj.slots.length > 0 || isManual ? (
                      <Col
                        xs={12}
                        sm={6}
                        md={4}
                        lg={2}
                        style={{ margin: '10px' }}
                        key={index}
                      >
                        <div>
                          <Accordion defaultExpanded={false}>
                            <AccordionSummary
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                            >
                              <Row
                                style={{
                                  marginTop: '10px',
                                  paddingLeft: '10px',
                                  paddingRight: '10px',
                                }}
                              >
                                <div
                                  style={{
                                    position: 'absolute',
                                    top: '0px',
                                    left: '0px',
                                    display: 'flex',
                                  }}
                                >
                                  {renderStateBadges(obj)}
                                </div>
                                <Col
                                  xs="12"
                                  className="d-flex justify-content-center align-items-center"
                                >
                                  <img
                                    style={{
                                      width: '150px', // Adjust the size as needed
                                      height: '150px', // Adjust the size as needed
                                      paddingLeft: '3px',
                                      paddingRight: '3px',
                                      objectFit: 'cover', // Ensures the aspect ratio of the image is maintained
                                    }}
                                    src={
                                      obj.imageUrl ||
                                      'https://img.freepik.com/free-vector/doctor-character-background_1270-84.jpg'
                                    }
                                    className="card-img-top"
                                    alt=""
                                    onError={(e) => {
                                      e.target.onerror = null; // Prevents infinite loop in case the default image fails to load
                                      e.target.src =
                                        'https://img.freepik.com/free-vector/doctor-character-background_1270-84.jpg';
                                    }}
                                  />
                                </Col>
                                <Col
                                  xs="12"
                                  className="d-flex justify-content-center align-items-center"
                                  style={{
                                    marginTop: '10px',
                                    paddingLeft: '10px',
                                    paddingRight: '10px',
                                  }}
                                >
                                  <div
                                    style={{ fontSize: 'calc(10px + 0.3vw)' }}
                                  >
                                    {obj.username}
                                  </div>
                                </Col>
                                <Col
                                  xs="12"
                                  className="d-flex justify-content-center align-items-center"
                                >
                                  <AppropAlertButton
                                    provider={obj}
                                    getProviderYesMaybeText={
                                      getProviderYesMaybeText
                                    }
                                  />
                                </Col>
                              </Row>
                            </AccordionSummary>
                          </Accordion>
                        </div>
                      </Col>
                    ) : (
                      <></>
                    );
                  })}
                </Row>
              </Col>
            </Row>
          </>
        )}
      </div>
    </React.Fragment>
  );
}
