import React, { useState, useEffect, useMemo, memo } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { userService, providerService } from '../../services';

const ProviderPracticeInfoTable = ({
  showActiveProviders,
  setShowActiveProviders,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [providers, setProviders] = useState([]);
  const [locations, setLocations] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);

  function compareProviderFullName(a, b) {
    // Safely split the provider_name, default to empty string if provider_name is missing
    const lastNameA = a.provider_name
      ? a.provider_name.split(' ')[1] || ''
      : '';
    const lastNameB = b.provider_name
      ? b.provider_name.split(' ')[1] || ''
      : '';

    return lastNameA.localeCompare(lastNameB);
  }

  useEffect(() => {
    const status = showActiveProviders ? 'active' : 'inactive';

    providerService
      .getProviders(status)
      .then((response) => {
        // Filter out any providers with a null provider_name right after fetching
        const fetchedProviders =
          response?.data?.providers.filter(
            (provider) => provider.provider_name != null
          ) ?? [];

        return userService.getAllUsers().then((usersResponse) => {
          const users = usersResponse?.data?.users ?? [];

          // Enrich providers with user details including imageUrl
          const providersWithImages = fetchedProviders.map((provider) => {
            const user = users.find((user) => user.id === provider.id);
            return {
              ...provider,
              imageUrl:
                user?.imageUrl ||
                'https://img.freepik.com/free-vector/doctor-character-background_1270-84.jpg',
            };
          });

          // Now proceed with fetching additional details for each provider and updating state
          // Convert each provider detail fetching into a promise
          const fetchDetailsPromises = providersWithImages.map((provider) =>
            getProviderDetail(provider.id, provider)
              .then((detail) => ({ ...provider, ...detail })) // Merge provider with its details
              .catch((error) => {
                console.error(
                  'Failed to fetch details for provider:',
                  provider.id,
                  error
                );
                return provider; // Return provider as is in case of error
              })
          );

          Promise.all(fetchDetailsPromises).then((completedProviders) => {
            // Further filter out any enriched providers if needed
            const validProviders = completedProviders.filter(
              (provider) => provider.provider_name != null
            );
            setLocations(validProviders); // Update locations with all fetched and valid details
            setProviders(validProviders);
          });
        });
      })
      .catch((error) => {
        const errorMessage =
          error.response?.data?.message || error.message || error.toString();
        enqueueSnackbar(errorMessage, {
          variant: 'error',
          timeout: 3000,
        });
      });
  }, [showActiveProviders, enqueueSnackbar]); // Include alert if used within the effect

  // Adjust getProviderDetail to return details instead of setting state
  const getProviderDetail = async (id, provider) => {
    try {
      const response = await providerService.getTherapyConfig(id);
      const therapyLocations = response?.data?.therapy?.locations || '';
      const testingLocations = response?.data?.testing?.locations || '';
      const allLocations = [...testingLocations, ...therapyLocations].filter(
        Boolean
      );
      const uniqueLocations = [...new Set(allLocations)].join(',');

      return {
        location: uniqueLocations || ' ',
        last_attestation: response?.data?.last_attestation,
        last_roster_submission: response?.data?.last_roster_submission,
      };
    } catch (error) {
      console.error('Error fetching provider details:', error);
      // Return some default/fallback detail structure in case of error
      return {
        location: ' ',
        last_attestation: null,
        last_roster_submission: null,
      };
    }
  };

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset the page to 0 when changing the rows per page
  };

  // ...

  const displayedProviders = useMemo(() => {
    // Sort providers just before rendering
    return locations
      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      .sort(compareProviderFullName);
  }, [locations, page, rowsPerPage]);

  const tableHeaderCellStyle1 = {
    borderBottom: '0.5px solid black',
    fontWeight: '900',
    position: 'sticky',
    top: '0px',
    zIndex: 100,
    backgroundColor: '#F5F5F5',
  };

  const formatLink = (url) => {
    // Checks if URL and inserts a zero-width space after ".me/"
    return url.replace(/(.me\/)/g, '$1&#8203;');
  };

  const tableCellStyle1 = {
    borderBottom: '0.1px solid black',
    whiteSpace: 'normal', // Ensures text wraps in the cell
    wordWrap: 'break-word', // Allows long words to be broken and wrapped to the next line
    overflowWrap: 'break-word', // Ensures overflow text is handled properly
    maxWidth: '150px', // Adjust based on your layout needs
    padding: '10px', // Optional, for better cell content spacing
  };

  return (
    <div>
      <TableContainer
        component={Paper}
        id="child"
        style={{ maxHeight: 'calc(100vh - 200px)', overflowY: 'auto' }}
      >
        <Table
          sx={{ minWidth: 650 }}
          size="medium"
          aria-label="Providers_table"
        >
          <TableHead>
            <TableRow>
              <TableCell style={tableHeaderCellStyle1}>Full Name</TableCell>
              <TableCell style={tableHeaderCellStyle1}>Location</TableCell>
              <TableCell style={tableHeaderCellStyle1}>Start Date</TableCell>
              {!showActiveProviders && (
                <TableCell style={tableHeaderCellStyle1}>End Date</TableCell>
              )}
              <TableCell style={tableHeaderCellStyle1}>
                Admin Handling Account
              </TableCell>
              <TableCell style={tableHeaderCellStyle1}>Doxy/Meet</TableCell>

              <TableCell style={tableHeaderCellStyle1}>
                HIPAA WorkForce
              </TableCell>
              <TableCell style={tableHeaderCellStyle1}>
                Hours Per Week
              </TableCell>
              <TableCell style={tableHeaderCellStyle1}>Password</TableCell>
              <TableCell style={tableHeaderCellStyle1}>Schedule</TableCell>
              <TableCell style={tableHeaderCellStyle1}>LLC Name</TableCell>
              <TableCell style={tableHeaderCellStyle1}>LLC Address</TableCell>
              <TableCell style={tableHeaderCellStyle1}>SDAT Number</TableCell>
              <TableCell style={tableHeaderCellStyle1}>Tax ID</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {displayedProviders?.map((row, index) => (
              <TableRow
                key={index}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell style={tableCellStyle1}>
                  {row?.provider_name}
                </TableCell>
                <TableCell style={tableCellStyle1}>
                  {row?.location.split(',').map((loc, index, arr) => (
                    <React.Fragment key={index}>
                      {loc.trim()}
                      {index !== arr.length - 1 && <br />}
                    </React.Fragment>
                  ))}
                </TableCell>
                <TableCell style={tableCellStyle1}>
                  {' '}
                  {row?.date_start}{' '}
                </TableCell>
                {!showActiveProviders && (
                  <TableCell style={tableCellStyle1}>
                    {' '}
                    {row?.date_end}{' '}
                  </TableCell>
                )}

                <TableCell style={tableCellStyle1}>
                  {row?.admin_handling_name}
                </TableCell>

                <TableCell style={tableCellStyle1}>
                  {row?.static_doxy_link ? (
                    <a
                      href={row.static_doxy_link}
                      target="_blank"
                      rel="noopener noreferrer"
                      dangerouslySetInnerHTML={{
                        __html: formatLink(row.static_doxy_link),
                      }}
                    />
                  ) : (
                    'Uses Google Meet'
                  )}
                </TableCell>

                <TableCell style={tableCellStyle1}>
                  {row.hipaa != null // Checks if row.hipaa is not null
                    ? Number(row.hipaa) === 1 // Converts to number and uses strict equality
                      ? 'True'
                      : 'False'
                    : ''}
                </TableCell>

                <TableCell style={tableCellStyle1}>
                  {' '}
                  {row?.hrs_p_week}{' '}
                </TableCell>
                <TableCell style={tableCellStyle1}> {row?.pw} </TableCell>
                <TableCell style={tableCellStyle1}> {row?.schedule} </TableCell>
                <TableCell style={tableCellStyle1}> {row?.llc_name} </TableCell>
                <TableCell style={tableCellStyle1}>
                  {' '}
                  {row?.llc_address}{' '}
                </TableCell>
                <TableCell style={tableCellStyle1}> {row?.sdat_num} </TableCell>
                <TableCell style={tableCellStyle1}> {row?.tax_id} </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        component="div"
        count={providers.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage="Rows per page:"
      />
    </div>
  );
};
export default memo(ProviderPracticeInfoTable);
