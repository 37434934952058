import React, { useEffect, useState, memo } from 'react';
import { testingAlertMapping, therapyAlertMapping } from '../../common/utility';
import Grid2 from '@mui/material/Grid2';
import Autocomplete from '@mui/material/Autocomplete';
import { saveFormData } from '../../actions/scheduling';
import { useSelector, useDispatch } from 'react-redux';
import FormHelperText from '@mui/material/FormHelperText';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import LinkIcon from '@mui/icons-material/Link';
import Popover from '@mui/material/Popover';
import RenderPopoverContent from '../UI/RenderPopoverContent'; // adjust the path as needed
import '../../styles/SchedulingStep03.css';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormLabel from '@mui/material/FormLabel';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import definitionService from '../../services/definition.service';

const serviceOptions = [
  { title: 'Academic Underachievement' },
  { title: 'ADHD (Therapy)' },
  { title: 'ADHD (Coaching)' },
  { title: 'Addiction' },
  { title: 'Adjustments' },
  { title: 'Adoption' },
  { title: 'Alcohol Abuse' },
  { title: "Alzheimer's" },
  { title: 'Anger Management' },
  { title: 'Animal Support - Writing Certification Letter' },
  { title: 'Antisocial Personality' },
  { title: 'Anxiety or Fears' },
  { title: 'Aspergers Syndrome' },
  { title: 'Autism' },
  { title: 'Behavioral Issues' },
  { title: 'Bipolar Disorder' },
  { title: 'Body Dysmorphic Disorder' },
  { title: 'Borderline Personality' },
  { title: 'Bullying (being bullied)' },
  { title: 'Bullying (being the bully)' },
  { title: 'Career Counseling' },
  { title: 'Caregiver Stress' },
  { title: 'Child or Adolescent' },
  { title: 'Chronic Impulsivity' },
  { title: 'Chronic Pain or Illness' },
  { title: 'Chronic Relapse' },
  { title: 'Coping Skills' },
  { title: 'Depression' },
  { title: 'Developmental Disorders' },
  { title: 'Dissociative Identity (DID)' },
  { title: 'Divorce' },
  { title: 'Domestic Abuse' },
  { title: 'Domestic Violence (Current)' },
  { title: 'Domestic Violence (Past)' },
  { title: 'Drug Abuse (as primary issue)' },
  { title: 'Dual Diagnosis' },
  {
    title:
      'Eating Disorder: Anorexia/Bulimia (Mild-Moderate), as #1 Therapy Issue',
  },
  {
    title:
      'Eating Disorder: Anorexia/Bulimia (Moderate-Severe), as #1 Therapy Issue',
  },
  { title: 'Eating Disorder: Anorexia/Bulimia, as #2 Therapy Issue' },
  { title: 'Eating Disorder: Binge Eating Disorder' },
  { title: 'Entitlement' },
  { title: 'Family Conflict' },
  { title: 'Gambling' },
  { title: 'Hoarding' },
  { title: 'Incest - Perpetrator' },
  { title: 'Incest - Victim' },
  { title: 'Infidelity - Perpetrator' },
  { title: 'Infidelity - Victim' },
  { title: 'Infertility' },
  { title: 'Internet Addiction' },
  { title: 'Learning Disability' },
  {
    title: 'LGBTQIAA+ (issues; identity development, coping with oppression)',
  },
  { title: 'Loss or Grief' },
  { title: 'Medical Detox' },
  { title: 'Mental Retardation (Treatment)' },
  { title: 'Mens Issues' },
  { title: 'Narcissistic Personality Disorder' },
  { title: 'Obesity (Presenting Issue)' },
  { title: 'OCD' },
  { title: 'Oppositional Defiance' },
  { title: 'Parenting' },
  { title: 'Peer Relationships' },
  { title: 'Post Partum Depression' },
  { title: 'Pregnancy / Prenatal' },
  { title: 'Racial Identity' },
  { title: 'Relationship Issues (General)' },
  { title: 'Relationships - Open Relationships' },
  { title: 'School Issues' },
  { title: 'Self Esteem' },
  { title: 'Sex Therapy' },
  { title: 'Sexual Abuse (Presenting Issue)' },
  { title: 'Sexual Abuse in Military (MST)' },
  { title: 'Sexual Addiction' },
  { title: 'Sexuality - BDSM' },
  { title: 'Sleep or Insomnia' },
  { title: 'Sociopathy' },
  { title: 'Substance Abuse' },
  { title: 'Teen Violence' },
  { title: 'Transgender/Sex Surgery - Writing Letter of Recommendation' },
  { title: 'Trauma and PTSD - Childhood' },
  { title: 'Trauma and PTSD - General' },
  { title: 'Traumatic Brain Injury' },
  { title: 'Trichotillomania' },
  { title: 'Video Game Addiction' },
  { title: 'Weight Loss' },
  { title: 'Womens Issues' },
];
const psychologicalTestingOptions = [
  { title: '504 accommodations' },
  { title: 'ADHD Adult Diagnostic Evaluation' },
  { title: 'ADHD Child Comprehensive Evaluation' },
  { title: 'ADHD/Autism Child Comprehensive Combined Evaluation' },
  { title: 'Anxiety' },
  { title: 'Autism Spectrum Disorder - Under 18 Evaluation' },
  { title: 'Autism Spectrum Disorder - Adult Evaluation' },
  { title: 'Bariatric Surgical Psychological Evaluations' },
  // { title: 'Comprehensive Evaluation' },
  { title: 'Child Psycho-Educational Evaluation' },
  { title: 'Cosmetic Surgery Evaluations' },
  { title: 'Depression' },
  { title: 'Developmental delays and early intervention' },
  { title: 'Differential Adult Diagnosis Evaluation' },
  { title: 'Evaluation for Accommodations on High Stakes Testing' },
  { title: 'Giftedness' },
  { title: 'Intellectual disabilities' },
  { title: 'Kindergarten readiness and early entry into kindergarten' },
  { title: 'Neuropsychological Testing ' },
  { title: 'Nonverbal learning disabilities' },
  { title: 'Oppositional defiance and conduct disorders' },
  { title: 'Reading' },
  { title: 'Writing' },
  { title: 'Math Disability' },
  { title: 'Special education services' },
  { title: 'Spinal Stimulator Pre Surgery Evaluations' },
  { title: 'Transgender Evaluations' },
  { title: 'Transplant / Bone Marrow Transplant Pre-Surgical' },
  { title: 'Weaknesses in executive functioning' },
  { title: 'Weaknesses in memory and learning' },
];
const forensicTestingOptions = [
  { title: 'Adoption Evaluations' },
  { title: 'Adult Competency' },
  { title: 'Child Abuse' },
  { title: 'Child Custody Evaluations' },
  { title: 'Criminal Mitigation' },
  { title: 'Criminal Responsibility' },
  { title: 'Expert Witness Testimony and Evaluations' },
  { title: 'Fitness for Duty Evaluation' },
  { title: 'Immigration Evaluation: Disability Waiver' },
  { title: 'Immigration Evaluation: Asylum Cases' },
  { title: 'Immigration Evaluation: Extreme or Exceptional Hardship' },
  { title: 'Immigration Evaluation: Violence Against Women Act' },
  { title: 'Juvenile Competency' },
  { title: 'Law Enforcement/Weapon Screening' },
  { title: 'Medical Records Review' },
  { title: 'Parenting Capacity Evaluations' },
  { title: 'Risk Assessments- Violence/Sex' },
  { title: 'Sentencing Evaluations' },
  { title: 'Sex Crimes' },
  { title: 'White Collar Crime' },
];
const therapyModalities = [
  { title: 'Acceptance and Commitment Therapy (ACT)' },
  { title: 'ACT Informed Therapy' },
  { title: 'Adlerian' },
  { title: 'Applied Behavioral Analysis' },
  { title: 'Art Therapy' },
  { title: 'Attachment-based' },
  { title: 'Christian Counseling' },
  { title: 'Coaching' },
  { title: 'Cognitive Behavioral Therapy (CBT)' },
  { title: 'DBT' },
  { title: 'EMDR' },
  { title: 'Eclectic' },
  { title: 'Emotionally Focused Therapy (EFT)' },
  { title: 'Existential' },
  { title: 'Experiential Therapy' },
  { title: 'Expressive Arts' },
  { title: 'Exposure and Response Prevention (ERP)' },
  { title: 'Feminist' },
  { title: 'Gestalt' },
  { title: 'Gottman Method' },
  { title: 'Humanistic' },
  { title: 'Hypnotherapy' },
  { title: 'Imago' },
  { title: 'Integrative' },
  { title: 'Internal Family Systems (IFS)' },
  { title: 'Interpersonal' },
  { title: 'Jungian' },
  { title: 'Mindfulness-based (MBCT)' },
  { title: 'Motivational Interviewing' },
  { title: 'Narrative' },
  { title: 'Neuro-Linguistic' },
  { title: 'Neurofeedback' },
  { title: 'Parent-Child Interaction Therapy (PCIT)' },
  { title: 'Person-Centered' },
  { title: 'Play Therapy' },
  { title: 'Positive Psychology' },
  { title: 'Prolonged Exposure Therapy' },
  { title: 'Psychoanalytic' },
  { title: 'Psychodynamic' },
  { title: 'Rational Emotive Behavior Therapy (REBT)' },
  { title: 'Relational' },
  { title: 'Sand Play' },
  { title: 'Solution Focused Brief (SFBT)' },
  { title: 'Somatic' },
  { title: 'Structural Family Therapy' },
];
const therapySpecialties = [
  { title: 'Life Coaching' },
  { title: 'DBT Therapy - Primarily Use DBT in Therapy' },
  { title: 'DBT Therapy - Occasionally use DBT Skills when appropriate' },
  { title: 'Feminist Therapy' },
  { title: 'Premarital Counseling' },
];

const specialConsiderations = [
  { title: 'Deaf or Hard of Hearing' },
  { title: 'Working with Mental Health Professionals' },
  { title: 'Working with Orthodox Jews' },
];

const hospitalSurgeonMapping = {
  GBMC: [
    'Dr. Elizabeth Dovac',
    'Dr. Timothy Friesen',
    'Dr. Gustavo Bello Vincentelli',
  ],
  'George Washington University Hospital': [
    'Dr. Joseph Afram',
    'Dr. Hope Jackson',
    'Dr Juliet Lee',
    'Dr. Paul Lin',
    'Dr. Khashayar Vaziri',
  ],
  'Holy Cross Health': [
    'Dr. Tuesday Cooke',
    'Dr. Barry Greene',
    'Dr. Joseph Greene',
  ],
  'Johns Hopkins Bayview Medical Center': [
    'Dr. Gina Adrales',
    'Dr. Alisa Coker',
    'Dr. Thomas Magnuson',
    'Dr. Hein Tan Nguyen',
    'Dr. Michael Schweitzer',
  ],
  'MedStar Franklin Square': ['Dr. Alain Abdo', 'Dr. Christopher You'],
  'MedStar Montgomery County': ['Dr. John Brebbia', 'Dr. Fred Finelli'],
  'Mercy Hospital': ['Dr. Kudleep Singh'],
  'St. Agnes': [
    'Dr. Andrew Averbach',
    'Dr. Isam Hamdallah',
    'Dr. Catriona Swift',
  ],
  'University of MD Medical Center': [
    'Dr. Mark Kligman',
    'Dr. Natalia Kubicki',
  ],
};

const SchedulingStep03 = (props) => {
  const dispatch = useDispatch();

  const onChangeServiceType = (event, values) => {
    const selectedValue = event.target.value;
    setPreferredSetting();
    setFollowUpPreferredSettingAnswer(); // making settings to null to clear
    let serviceGroup = 'Therapy';
    if (
      selectedValue === 'Psychological Testing' ||
      selectedValue === 'Forensic Testing'
    ) {
      serviceGroup = 'Testing';
      props.setServiceTesting(true);
      props.setShouldShowStep03B(false);
    } else {
      props.setServiceTesting(false);
      props.setShouldShowStep03B(!!selectedValue);
    }
    const propsClone = { ...props.step03FormValues };
    propsClone.serviceType = selectedValue;
    propsClone.serviceGroup = serviceGroup;
    props.setStep03FormValues(propsClone);
    checkForALert(serviceGroup, propsClone.Insurance);
    const isFamiliesSelected = selectedValue === 'Families';
    setShowDrMcLeansBehavioralTherapyQuestion(isFamiliesSelected);
    // The below code is for Redux
    state.serviceType = event.target.value;
    state.serviceGroup = serviceGroup;
    state.showDrMcLeansBehavioralTherapyQuestion = isFamiliesSelected;
    dispatch(saveFormData(state));
  };
  const state = useSelector((state) => state.scheduling);
  const [popoverSelectedInsurance, setPopoverSelectedInsurance] =
    React.useState('');
  const [insuranceTypeCheck, setInsuranceTypeCheck] = React.useState(true);
  // const [exceptionChecked, setExceptionChecked] = useState(false);
  const [nameChecked, setNameChecked] = useState(
    props?.step03FormValues?.nameChecked
      ? props?.step03FormValues?.nameChecked
      : false
  );
  const [schedulingChecked, setSchedulingChecked] = useState(
    props?.step03FormValues?.schedulingChecked
      ? props?.step03FormValues?.schedulingChecked
      : false
  );
  const [selectedInsurance, setSelectedInsurance] = React.useState(
    props?.step03FormValues?.Insurance
  );
  const [selectedTheoreticalInsurance, setSelectedTheoreticalInsurance] =
    React.useState(false);
  const [textareaVisible] = useState(props.showTextarea);
  const [reasonForService, setReasonForService] = useState(
    props?.nonFilteredData?.reasonForService
  ); // new useState
  const [additionalInsurance, setAdditionalInsurance] = useState(
    props?.step03FormValues?.additionalInsurance
  ); // new useState
  // const [exceptionText, setExceptionText] = useState(); // new useState
  const [pronounDetails, setPronounDetails] = useState(
    props?.step03FormValues?.pronounDetails
  ); // new useState
  const [unusualNote, setUnusalNote] = useState(
    props?.step03FormValues?.unusalNote
  ); // new useState
  const [selectedSurgeon, setSelectedSurgeon] = useState(
    props?.step03FormValues?.selectedSurgeon
  ); // new useState
  const [selectedHospital, setSelectedHospital] = useState(
    props?.step03FormValues?.selectedHospital
  ); // new useState
  const [notScheduled, setNotScheduled] = useState(
    props?.step03FormValues?.notScheduled !== null
      ? props?.step03FormValues?.notScheduled
      : false
  ); // new useState
  const [medicationsTaken, setMedicationsTaken] = useState(
    props?.step03FormValues?.medicationsTaken
  ); // new useState
  const [isMedicationTaken, setIsMedicationTaken] = useState(
    props?.step03FormValues?.isMedicationTaken
  ); // new useState
  const [nutritionDate, setNutritionDate] = useState(
    props?.step03FormValues?.nutritionDate
  ); // new useState
  const [surgeonName, setSurgeonName] = useState(
    props?.step03FormValues?.surgeonName
  ); // new useState
  const [hospitalName, setHospitalName] = useState(); // new usestate

  const [
    showDrMcLeansBehavioralTherapyQuestion,
    setShowDrMcLeansBehavioralTherapyQuestion,
  ] = useState(state.showDrMcLeansBehavioralTherapyQuestion || false);

  const [
    drMcLeansBehavioralTherapyAnswer,
    setDrMcLeansBehavioralTherapyAnswer,
  ] = useState(state.drMcLeansBehavioralTherapyAnswer || '');

  const [preferredSetting, setPreferredSetting] = useState(
    props?.nonFilteredData?.preferredSetting
  ); // New state for preferred setting and comment to push
  const [followUpPreferredSettingAnswer, setFollowUpPreferredSettingAnswer] =
    useState(props?.nonFilteredData?.followUpPreferredSetting); // Renamed state for follow-up question
  const [alertOpen, setAlertOpen] = useState(false); // State for dialog visibility
  const [selectedServices, setSelectedServices] = useState(
    props?.step03FormValues?.services || []
  ); // services array
  const selectedServicesTitles =
    props?.step03FormValues?.services?.map((val) => val?.title?.trim()) || [];

  // The below definition in therapy Issues
  const [definitions, setDefinitions] = useState({});

  useEffect(() => {
    const fetchDefinitions = async () => {
      try {
        const response = await definitionService.getAllDefinitions();
        const definitionsData = response.data.reduce(
          (acc, cur) => ({
            ...acc,
            [cur.term]: cur,
          }),
          {}
        );
        setDefinitions(definitionsData);
      } catch (error) {
        console.error('Failed to fetch definitions:', error);
      }
    };

    fetchDefinitions();
  }, []);

  useEffect(() => {
    if (
      (reasonForService ||
        (!reasonForService &&
          props?.entryTypeFormValues?.entryType === 'existing')) &&
      (!selectedServicesTitles.includes(
        'Bariatric Surgical Psychological Evaluations'
      ) ||
        (selectedServicesTitles.includes(
          'Bariatric Surgical Psychological Evaluations'
        ) &&
          props?.entryTypeFormValues?.entryType !== 'new' &&
          !selectedHospital &&
          !selectedSurgeon &&
          !notScheduled &&
          !isMedicationTaken) ||
        (selectedHospital &&
          selectedSurgeon &&
          ((!notScheduled && nutritionDate) ||
            (notScheduled && !nutritionDate)) &&
          ((!isMedicationTaken && medicationsTaken) ||
            (isMedicationTaken && !medicationsTaken)))) &&
      ((nameChecked && pronounDetails) || (!nameChecked && !pronounDetails)) &&
      ((schedulingChecked && unusualNote) ||
        (!schedulingChecked && !unusualNote)) &&
      ((selectedHospital === 'Other' && hospitalName) ||
        (selectedHospital !== 'Other' && !hospitalName)) &&
      ((selectedSurgeon === 'Other' && surgeonName) ||
        (selectedSurgeon !== 'Other' && !surgeonName)) &&
      ((props?.step03FormValues?.serviceGroup === 'Therapy' &&
        preferredSetting &&
        preferredSetting === 'In-Person' &&
        !followUpPreferredSettingAnswer) ||
        (props?.step03FormValues?.serviceGroup === 'Therapy' &&
          preferredSetting &&
          preferredSetting === 'Virtual' &&
          followUpPreferredSettingAnswer) ||
        (props?.step03FormValues?.serviceGroup !== 'Therapy' &&
          !preferredSetting &&
          !followUpPreferredSettingAnswer) ||
        (props?.step03FormValues?.serviceGroup === 'Therapy' &&
          (props?.entryTypeFormValues?.existingClientOptions ===
            'Therapy Client Coming to Office' ||
            props?.entryTypeFormValues?.existingClientOptions ===
              'Testing Client Coming to Office') &&
          !preferredSetting &&
          !followUpPreferredSettingAnswer)) &&
      (((selectedInsurance === 'Aetna EAP' ||
        selectedInsurance === 'CIGNA EAP' ||
        selectedTheoreticalInsurance === 'Aetna EAP' ||
        selectedTheoreticalInsurance === 'CIGNA EAP') &&
        additionalInsurance) ||
        (selectedInsurance !== 'Aetna EAP' &&
          selectedInsurance !== 'CIGNA EAP' &&
          selectedTheoreticalInsurance !== 'Aetna EAP' &&
          selectedTheoreticalInsurance !== 'CIGNA EAP' &&
          !additionalInsurance))
    ) {
      // eslint-disable-next-line no-unused-expressions
      props.setNonFilteredAnswered?.(true);
    } else {
      // eslint-disable-next-line no-unused-expressions
      props.setNonFilteredAnswered?.(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    reasonForService,
    selectedInsurance,
    selectedServicesTitles,
    hospitalName,
    additionalInsurance,
    schedulingChecked,
    pronounDetails,
    nameChecked,
    unusualNote,
    selectedHospital,
    selectedSurgeon,
    medicationsTaken,
    isMedicationTaken,
    notScheduled,
    nutritionDate,
    preferredSetting,
    followUpPreferredSettingAnswer,
  ]);

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    props.setNonFilteredData?.({
      reasonForService: reasonForService,
      additionalInsurance: additionalInsurance,
      pronounNotes: pronounDetails,
      schedulingNotes: unusualNote,
      hospitalComingFrom:
        selectedHospital === 'Other' ? hospitalName : selectedHospital,
      surgeonName: selectedSurgeon === 'Other' ? surgeonName : selectedSurgeon,
      NutritionDate: nutritionDate,
      medicationsTaken: medicationsTaken,
      preferredSetting: preferredSetting,
      followUpPreferredSetting: followUpPreferredSettingAnswer,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    reasonForService,
    additionalInsurance,
    pronounDetails,
    unusualNote,
    selectedHospital,
    selectedHospital,
    nutritionDate,
    medicationsTaken,
    surgeonName,
    hospitalName,
    preferredSetting,
    followUpPreferredSettingAnswer,
  ]);

  useEffect(() => {
    if (
      props?.step03FormValues?.serviceGroup?.includes('Testing') &&
      selectedServicesTitles.includes(
        'Bariatric Surgical Psychological Evaluations'
      ) &&
      props?.entryTypeFormValues?.entryType !== 'existing'
    ) {
      setReasonForService('Bariatric Pysch Screening');
    } else {
      setReasonForService(props?.nonFilteredData?.reasonForService || '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedServices]);

  // State to manage the Popover
  const [anchorEl, setAnchorEl] = React.useState(null);

  // Function to handle opening the Popover
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // Function to handle closing the Popover
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const handleChange = (event) => {
    const newAnswer = event.target.value; // 'yes' or 'no'
    setDrMcLeansBehavioralTherapyAnswer(newAnswer); // Update local state
    props.onDrMcLeansBehavioralTherapyAnswer(newAnswer === 'yes'); // Inform parent component
    state.drMcLeansBehavioralTherapyAnswer = newAnswer;
    state.showSchedulingStep03C = newAnswer === 'yes';
    dispatch(saveFormData(state));
  };

  const onChangeTheoreticalInsurance = (event) => {
    const selectedValue = event.target.value;

    // Update popoverSelectedInsurance state
    setPopoverSelectedInsurance(selectedValue);

    const propsClone = { ...props.step03FormValues };
    // Update the state variable for theoretical insurance
    setSelectedTheoreticalInsurance(selectedValue);

    // As this is theoretical insurance, we're updating Insurance_t instead of Insurance
    props.setStep03FormValues({
      ...propsClone,
      Insurance_t: selectedValue,
      type: insuranceTypeCheck,
    });

    // Check for Alert
    checkForALert(props.step03FormValues.serviceGroup, selectedValue);

    // Update the selected Theoretical Insurance
    setSelectedTheoreticalInsurance(selectedValue);
  };

  const onChangePracticalInsurance = (event) => {
    const selectedValue = event.target.value;
    // Update popoverSelectedInsurance state
    setPopoverSelectedInsurance(selectedValue);
    const propsClone = { ...props.step03FormValues };
    // Update the state variable
    setSelectedInsurance(selectedValue);

    props.setStep03FormValues({
      ...propsClone,
      Insurance: selectedValue,
      type: insuranceTypeCheck,
    });

    // Check for Alert
    checkForALert(props.step03FormValues.serviceGroup, selectedValue);

    // Update the selected Insurance
    setSelectedInsurance(selectedValue);
    setAdditionalInsurance();
    state.Insurance = selectedValue;
    state.type = insuranceTypeCheck;
    dispatch(saveFormData(state));
  };

  const checkForALert = (serviceGroup, selectedValue) => {
    if (serviceGroup && selectedValue) {
      if (serviceGroup === 'Therapy') {
        const alertObj = therapyAlertMapping.find((obj) => {
          return obj.insurances === selectedValue;
        });
        if (alertObj.alert !== '') {
          alert(alertObj.alert);
        }
      } else {
        const alertObj = testingAlertMapping.find((obj) => {
          return obj.insurances === selectedValue;
        });
        if (alertObj.alert !== '') {
          alert(alertObj.alert);
        }
      }
    }
  };

  const handleFollowUpAnswerChange = (e) => {
    const answer = e.target.value;
    setFollowUpPreferredSettingAnswer(answer);
    if (answer === 'No') {
      setAlertOpen(true);
    }
    state.followUpPreferredSetting = e.target.value;
    dispatch(saveFormData(state));
  };

  const onChangeSpecialConsiderations = (event) => {
    const selectedValue = event.target.value;
    props.setSpecialConsiderationSelected(selectedValue);
  };

  const onChangeService = (event, values) => {
    const propsClone = { ...props.step03FormValues };
    propsClone.services = values;
    setSelectedServices([...values]);
    props.setStep03FormValues(propsClone);
    state.services = [...values];
    dispatch(saveFormData(state));
  };

  const onChangeTherapyModalities = (event, values) => {
    const propsClone = { ...props.step03FormValues };
    propsClone.therapyModalities = values;
    props.setStep03FormValues(propsClone);
  };

  const onChangeTherapySpecialties = (event, values) => {
    const propsClone = { ...props.step03FormValues };
    propsClone.therapySpecialties = event.target.value;
    props.setStep03FormValues(propsClone);
  };

  // Calculate the list of surgeons for the selected hospital
  const surgeonsForSelectedHospital =
    hospitalSurgeonMapping[selectedHospital] || [];

  const onChangeModalitySpecialization = (event, values) => {
    const selectedValue = event.target.value;
    const propsClone = { ...props.step03FormValues };
    propsClone.therapyModalitySpecialization = selectedValue;
    props.setStep03FormValues(propsClone);
  };

  const isServiceInfoPartRequiredOrNot = () => {
    const { entryType, existingClientOptions } = props.entryTypeFormValues;
    if (entryType === 'new') {
      return true;
    }

    if (entryType === 'cancellation') {
      return true;
    }

    if (
      entryType === 'existing' &&
      (existingClientOptions === 'Testing Client Coming to Office' ||
        existingClientOptions === 'Therapy Client Coming to Office') &&
      props.step03FormValues.serviceType === 'Psychological Testing'
    ) {
      return true;
    }
  };

  useEffect(() => {
    if (props.step03FormValues.serviceGroup === 'Testing') {
      if (!insuranceTypeCheck) {
        setInsuranceTypeCheck(true); // Switch to Practical
        setSelectedTheoreticalInsurance(null); // Make the selected theoretical insurance null
      }
    } else if (props.step03FormValues.serviceGroup === 'Therapy') {
      // The switch is unlocked and can move back to theoretical
    }
  }, [props.step03FormValues.serviceGroup, insuranceTypeCheck]);

  return (
    <React.Fragment>
      <div className="mainContainer">
        <Grid2 container spacing={3} style={{ marginTop: '-115px' }}>
          {props.sectionsToShow &&
            props.sectionsToShow.includes('serviceType') && (
              <Grid2
                size={{ xs: 12, sm: 6, md: 4, lg: 4, xl: 4 }}
                style={{ marginTop: '-28px' }}
              >
                <FormControl>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Typography variant="body1">Service Type</Typography>
                    <Tooltip title="After you select a Service Type an additional dropdown menu called 'Service(s)' will show that will allow you to specify the specific area within the service type you are searching">
                      <IconButton aria-label="help">
                        <HelpOutlineIcon />
                      </IconButton>
                    </Tooltip>
                  </Box>

                  <Select
                    error={props.step03FormValues.serviceType ? false : true}
                    native
                    id="grouped-native-select"
                    label="Grouping"
                    value={props?.step03FormValues?.serviceType || ''}
                    onChange={onChangeServiceType}
                  >
                    <option aria-label="None" value="" />
                    <optgroup label="Therapy">
                      <option value={'Individuals'}>Individuals</option>
                      <option value={'Couples'}>Couples</option>
                      <option value={'Groups'}>Groups</option>
                      <option value={'Families'}>Families</option>
                      <option value={'Couples/Families Using Insurance'}>
                        Couples/Families Using Insurance
                      </option>
                    </optgroup>
                    <optgroup label="Testing">
                      <option value={'Psychological Testing'}>
                        Psychological Testing
                      </option>
                      <option value={'Forensic Testing'}>
                        Forensic Testing
                      </option>
                    </optgroup>
                  </Select>
                </FormControl>
              </Grid2>
            )}

          {showDrMcLeansBehavioralTherapyQuestion && (
            <Grid2 size={{ xs: 12, sm: 6, md: 2, lg: 2, xl: 2 }}>
              <Typography variant="subtitle1">
                Dr. McLean's Behavioral Therapy?
              </Typography>
              <RadioGroup
                row
                aria-label="dr-mcleans-behavioral-therapy"
                name="drMcLeansBehavioralTherapy"
                value={drMcLeansBehavioralTherapyAnswer}
                onChange={handleChange} // Use handleChange here
              >
                <FormControlLabel
                  value="yes"
                  control={
                    <Radio
                      style={{
                        color: drMcLeansBehavioralTherapyAnswer
                          ? 'black'
                          : 'red',
                      }}
                    />
                  }
                  label="Yes"
                />
                <FormControlLabel
                  value="no"
                  control={
                    <Radio
                      style={{
                        color: drMcLeansBehavioralTherapyAnswer
                          ? 'black'
                          : 'red',
                      }}
                    />
                  }
                  label="No"
                />
              </RadioGroup>
            </Grid2>
          )}

          {props.sectionsToShow &&
            props.sectionsToShow.includes('insurance') && (
              <Grid2 size={{ xs: 12, sm: 6, md: 6, lg: 6, xl: 6 }}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    position: 'relative',
                    marginTop: '-4px', // Combine marginTop here for consistency
                    top: '0',
                  }}
                  className="switch-wraper"
                >
                  <Grid2 container direction="column">
                    <Grid2
                      item
                      sx={{
                        marginTop: (theme) => theme.spacing(-4), // Default margin for larger screens
                        '@media (max-width: 600px)': {
                          marginTop: (theme) => theme.spacing(-1), // Adjusted margin for small screens
                        },
                      }}
                    >
                      <FormControlLabel
                        control={
                          <Switch
                            checked={insuranceTypeCheck}
                            onChange={(event) => {
                              if (
                                props.step03FormValues.serviceGroup !==
                                'Testing'
                              ) {
                                // Allow switch only when serviceGroup is not 'Testing'
                                setInsuranceTypeCheck(event.target.checked);
                                setSelectedInsurance(null);
                                setAdditionalInsurance();
                                setSelectedTheoreticalInsurance(null);
                                props.setStep03FormValues({
                                  ...props.step03FormValues,
                                  Insurance: '',
                                  Insurance_t: '',
                                  type: event.target.checked,
                                });
                              }
                            }}
                          />
                        }
                        label={
                          <Grid2 container alignItems="center">
                            <Grid2 item>
                              <Typography variant="body1">
                                {insuranceTypeCheck
                                  ? 'Practical'
                                  : 'Theoretical'}
                              </Typography>
                            </Grid2>
                            <Grid2 item>
                              <Tooltip title="Practical refers to the insurances that the provider is currently generally accepting, while Theoretical refers to insurances that the provider is officially in-network with ">
                                <IconButton aria-label="help" size="small">
                                  <HelpOutlineIcon fontSize="medium" />
                                </IconButton>
                              </Tooltip>
                            </Grid2>
                          </Grid2>
                        }
                      />
                    </Grid2>
                  </Grid2>
                </Box>

                <Grid2 item container>
                  <Grid2 size={{ xs: 12, sm: 10, md: 10, lg: 10, xl: 10 }}>
                    <FormControl>
                      {insuranceTypeCheck && (
                        <>
                          <InputLabel id="practical-select-label">
                            Insurance - Practical
                          </InputLabel>
                          <Select
                            labelId="practical-select-label"
                            id="practical-select"
                            value={
                              selectedTheoreticalInsurance ||
                              props.step03FormValues.Insurance ||
                              ''
                            }
                            error={
                              insuranceTypeCheck
                                ? props.step03FormValues.Insurance
                                  ? false
                                  : true
                                : props.step03FormValues.Insurance_
                                ? false
                                : true
                            }
                            label={
                              insuranceTypeCheck ? 'Insurance' : 'Insurance'
                            }
                            onChange={onChangePracticalInsurance}
                            ref={props.insuranceRef}
                          >
                            <MenuItem value={'Private Pay'}>
                              Private Pay
                            </MenuItem>
                            <MenuItem value={'Aetna'}>Aetna</MenuItem>
                            <MenuItem value={'Aetna EAP'}>Aetna EAP</MenuItem>
                            <MenuItem value={'Aetna Medicare Advantage'}>
                              Aetna Medicare Advantage
                            </MenuItem>
                            <MenuItem value={'BCBS'}>BCBS</MenuItem>
                            <MenuItem value={'CIGNA'}>CIGNA</MenuItem>
                            <MenuItem value={'CIGNA EAP'}>CIGNA EAP</MenuItem>
                            <MenuItem value={'Johns Hopkins EHP'}>
                              Johns Hopkins EHP
                            </MenuItem>
                            <MenuItem value={'Johns Hopkins USFHP'}>
                              Johns Hopkins USFHP
                            </MenuItem>
                            <MenuItem value={'Medical Assistance'}>
                              Medical Assistance
                            </MenuItem>
                            <MenuItem value={'Medicare'}>Medicare</MenuItem>
                            <MenuItem
                              value={'Tricare Standard/Indeminity (NOT Prime)'}
                            >
                              Tricare Standard/Indeminity (NOT Prime)
                            </MenuItem>
                          </Select>

                          {selectedInsurance === 'Private Pay' && (
                            <FormControl
                              component="fieldset"
                              style={{ marginTop: '10px', width: '100%' }}
                            >
                              <FormLabel component="legend">
                                Payment Amount
                              </FormLabel>
                              <RadioGroup
                                aria-label="payment-method"
                                name="paymentMethod"
                                value={props.selectedPrivatePayAmount}
                                onChange={(e) =>
                                  props.onPrivatePayAmountChange(e.target.value)
                                }
                                row // This prop aligns radio buttons in a row
                                style={{
                                  flexDirection: 'row',
                                  justifyContent: 'flex-start',
                                }} // Ensures that items are in a row and aligned to the start
                              >
                                <FormControlLabel
                                  value="Full Pay"
                                  control={<Radio />}
                                  label="Full Pay"
                                  style={{ marginRight: '20px' }}
                                />
                                <FormControlLabel
                                  value="Sliding Scale"
                                  control={<Radio />}
                                  label="Sliding Scale"
                                  style={{ marginRight: '20px' }}
                                />
                                <FormControlLabel
                                  value="ProBono"
                                  control={<Radio />}
                                  label="Pro Bono"
                                />
                              </RadioGroup>
                            </FormControl>
                          )}

                          {textareaVisible &&
                            selectedInsurance !== 'Private Pay' && (
                              <FormHelperText>
                                Hover over{' '}
                                <Tooltip
                                  title={
                                    <div
                                      style={{
                                        border: '1px solid #ccc',
                                        padding: '10px',
                                      }}
                                    >
                                      <table>
                                        <thead>
                                          <tr>
                                            <th
                                              style={{
                                                textAlign: 'center',
                                                paddingRight: '5px',
                                                borderRight: '1px solid #ccc',
                                              }}
                                            >
                                              Accepted
                                            </th>
                                            <th
                                              style={{
                                                textAlign: 'center',
                                                paddingLeft: '5px',
                                              }}
                                            >
                                              Not Accepted
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr>
                                            <td
                                              style={{
                                                textAlign: 'center',
                                                paddingRight: '5px',
                                                borderRight: '1px solid #ccc',
                                              }}
                                            >
                                              Allegiance (Cigna begins w/ 77 )
                                            </td>
                                            <td
                                              style={{
                                                textAlign: 'center',
                                                paddingLeft: '5px',
                                              }}
                                            >
                                              Comp Psych
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              style={{
                                                textAlign: 'center',
                                                paddingRight: '5px',
                                                borderRight: '1px solid #ccc',
                                              }}
                                            >
                                              Carefirst Administrators (BCBS)
                                            </td>
                                            <td
                                              style={{
                                                textAlign: 'center',
                                                paddingLeft: '5px',
                                              }}
                                            >
                                              Kaiser
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              style={{
                                                textAlign: 'center',
                                                paddingRight: '5px',
                                                borderRight: '1px solid #ccc',
                                              }}
                                            >
                                              Evernorth (Cigna)
                                            </td>
                                            <td
                                              style={{
                                                textAlign: 'center',
                                                paddingLeft: '5px',
                                              }}
                                            >
                                              LHI
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              style={{
                                                textAlign: 'center',
                                                paddingRight: '5px',
                                                borderRight: '1px solid #ccc',
                                              }}
                                            >
                                              Meritan (Typically Aetna)
                                            </td>
                                            <td
                                              style={{
                                                textAlign: 'center',
                                                paddingLeft: '5px',
                                              }}
                                            >
                                              United
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              style={{
                                                textAlign: 'center',
                                                paddingRight: '5px',
                                                borderRight: '1px solid #ccc',
                                              }}
                                            >
                                              Trustmark (Typically Cigna))
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              style={{
                                                textAlign: 'center',
                                                paddingRight: '5px',
                                                borderRight: '1px solid #ccc',
                                              }}
                                            >
                                              Web TPA (Aetna or Cigna))
                                            </td>
                                          </tr>
                                          {/* You can add more rows as needed */}
                                        </tbody>
                                      </table>
                                    </div>
                                  }
                                >
                                  <span
                                    style={{
                                      textDecoration: 'underline',
                                      color: 'blue',
                                      cursor: 'pointer',
                                    }}
                                  >
                                    here
                                  </span>
                                </Tooltip>{' '}
                                for a list of insurances not listed in the
                                dropdowns to see whether the group generally
                                accepts that insurance. Hover over{' '}
                                <Tooltip
                                  title={
                                    <div
                                      style={{
                                        border: '1px solid #ccc',
                                        padding: '10px',
                                      }}
                                    >
                                      <div>
                                        <table
                                          style={{ border: '1px solid #ccc' }}
                                        >
                                          <thead>
                                            <tr>
                                              <th
                                                style={{ textAlign: 'center' }}
                                              >
                                                Medicaid MCO's
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            <tr>
                                              <td
                                                style={{
                                                  textAlign: 'center',
                                                  padding: '5px',
                                                  border: '1px solid #ccc',
                                                }}
                                              >
                                                Aetna Better Health
                                              </td>
                                            </tr>
                                            <tr>
                                              <td
                                                style={{
                                                  textAlign: 'center',
                                                  padding: '5px',
                                                  border: '1px solid #ccc',
                                                }}
                                              >
                                                BCBS Community Health Plan
                                                Maryland
                                              </td>
                                            </tr>
                                            <tr>
                                              <td
                                                style={{
                                                  textAlign: 'center',
                                                  padding: '5px',
                                                  border: '1px solid #ccc',
                                                }}
                                              >
                                                Jai Medical Systems
                                              </td>
                                            </tr>
                                            <tr>
                                              <td
                                                style={{
                                                  textAlign: 'center',
                                                  padding: '5px',
                                                  border: '1px solid #ccc',
                                                }}
                                              >
                                                Kaiser Permanente
                                              </td>
                                            </tr>
                                            <tr>
                                              <td
                                                style={{
                                                  textAlign: 'center',
                                                  padding: '5px',
                                                  border: '1px solid #ccc',
                                                }}
                                              >
                                                Maryland Physicians Care
                                              </td>
                                            </tr>
                                            <tr>
                                              <td
                                                style={{
                                                  textAlign: 'center',
                                                  padding: '5px',
                                                  border: '1px solid #ccc',
                                                }}
                                              >
                                                MedStar Family Choice
                                              </td>
                                            </tr>
                                            <tr>
                                              <td
                                                style={{
                                                  textAlign: 'center',
                                                  padding: '5px',
                                                  border: '1px solid #ccc',
                                                }}
                                              >
                                                Meritan (Typically Aetna)
                                              </td>
                                            </tr>
                                            <tr>
                                              <td
                                                style={{
                                                  textAlign: 'center',
                                                  padding: '5px',
                                                  border: '1px solid #ccc',
                                                }}
                                              >
                                                Priority Partners
                                              </td>
                                            </tr>
                                            <tr>
                                              <td
                                                style={{
                                                  textAlign: 'center',
                                                  padding: '5px',
                                                  border: '1px solid #ccc',
                                                }}
                                              >
                                                United Healthcare Community Plan
                                              </td>
                                            </tr>
                                            <tr>
                                              <td
                                                style={{
                                                  textAlign: 'center',
                                                  padding: '5px',
                                                  border: '1px solid #ccc',
                                                }}
                                              >
                                                Wellpoint Maryland
                                              </td>
                                            </tr>
                                            {/* You can add more rows as needed */}
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  }
                                >
                                  <span
                                    style={{
                                      textDecoration: 'underline',
                                      color: 'blue',
                                      cursor: 'pointer',
                                    }}
                                  >
                                    here
                                  </span>
                                </Tooltip>{' '}
                                for a list of Medicaid MCO's. For WebTPA or
                                Trustmark, you will need to inquire whether the
                                insurance carrier is Aetna or Cigna. Click{' '}
                                <a
                                  href="https://docs.google.com/spreadsheets/d/13TX4qngbtbuNtcQMRMXLsgbZ3F-53E62lPaRg77dpmw/edit?usp=sharing"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  here
                                </a>{' '}
                                for some tips.
                              </FormHelperText>
                            )}
                        </>
                      )}
                      {!insuranceTypeCheck &&
                        props.step03FormValues.serviceGroup !== 'Testing' && (
                          <>
                            <InputLabel id="theoretical-select-label">
                              Insurance - Theoretical
                            </InputLabel>
                            <Select
                              labelId="theoretical-select-label"
                              id="theoretical-select"
                              value={
                                selectedInsurance ||
                                props.step03FormValues.Insurance_t
                              }
                              error={
                                props.step03FormValues.Insurance_t
                                  ? false
                                  : true
                              }
                              label="Insurance - Theoretical"
                              onChange={onChangeTheoreticalInsurance}
                            >
                              <MenuItem value={'Private Pay'}>
                                Private Pay
                              </MenuItem>
                              <MenuItem value={'Aetna'}>Aetna</MenuItem>
                              <MenuItem value={'Aetna EAP'}>Aetna EAP</MenuItem>
                              <MenuItem value={'Aetna Medicare Advantage'}>
                                Aetna Medicare Advantage
                              </MenuItem>
                              <MenuItem value={'BCBS'}>BCBS</MenuItem>
                              <MenuItem value={'CIGNA'}>CIGNA</MenuItem>
                              <MenuItem value={'CIGNA EAP'}>CIGNA EAP</MenuItem>
                              <MenuItem value={'Johns Hopkins EHP'}>
                                Johns Hopkins EHP
                              </MenuItem>
                              <MenuItem value={'Johns Hopkins USFHP'}>
                                Johns Hopkins USFHP
                              </MenuItem>
                              <MenuItem value={'Medical Assistance'}>
                                Medical Assistance
                              </MenuItem>
                              <MenuItem value={'Medicare'}>Medicare</MenuItem>
                              <MenuItem
                                value={
                                  'Tricare Standard/Indeminity (NOT Prime)'
                                }
                              >
                                Tricare Standard/Indeminity (NOT Prime)
                              </MenuItem>
                            </Select>
                            {textareaVisible && (
                              <FormHelperText>
                                Hover over{' '}
                                <Tooltip
                                  title={
                                    <div
                                      style={{
                                        border: '1px solid #ccc',
                                        padding: '10px',
                                      }}
                                    >
                                      <table>
                                        <thead>
                                          <tr>
                                            <th
                                              style={{
                                                textAlign: 'center',
                                                paddingRight: '5px',
                                                borderRight: '1px solid #ccc',
                                              }}
                                            >
                                              Accepted
                                            </th>
                                            <th
                                              style={{
                                                textAlign: 'center',
                                                paddingLeft: '5px',
                                              }}
                                            >
                                              Not Accepted
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr>
                                            <td
                                              style={{
                                                textAlign: 'center',
                                                paddingRight: '5px',
                                                borderRight: '1px solid #ccc',
                                              }}
                                            >
                                              Allegiance (Cigna begins w/ 77 )
                                            </td>
                                            <td
                                              style={{
                                                textAlign: 'center',
                                                paddingLeft: '5px',
                                              }}
                                            >
                                              Comp Psych
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              style={{
                                                textAlign: 'center',
                                                paddingRight: '5px',
                                                borderRight: '1px solid #ccc',
                                              }}
                                            >
                                              Carefirst Administrators (BCBS)
                                            </td>
                                            <td
                                              style={{
                                                textAlign: 'center',
                                                paddingLeft: '5px',
                                              }}
                                            >
                                              Kaiser
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              style={{
                                                textAlign: 'center',
                                                paddingRight: '5px',
                                                borderRight: '1px solid #ccc',
                                              }}
                                            >
                                              Evernorth (Cigna)
                                            </td>
                                            <td
                                              style={{
                                                textAlign: 'center',
                                                paddingLeft: '5px',
                                              }}
                                            >
                                              LHI
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              style={{
                                                textAlign: 'center',
                                                paddingRight: '5px',
                                                borderRight: '1px solid #ccc',
                                              }}
                                            >
                                              Meritan (Typically Aetna)
                                            </td>
                                            <td
                                              style={{
                                                textAlign: 'center',
                                                paddingLeft: '5px',
                                              }}
                                            >
                                              United
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              style={{
                                                textAlign: 'center',
                                                paddingRight: '5px',
                                                borderRight: '1px solid #ccc',
                                              }}
                                            >
                                              Trustmark (Typically Cigna))
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              style={{
                                                textAlign: 'center',
                                                paddingRight: '5px',
                                                borderRight: '1px solid #ccc',
                                              }}
                                            >
                                              Web TPA (Aetna or Cigna))
                                            </td>
                                          </tr>
                                          {/* You can add more rows as needed */}
                                        </tbody>
                                      </table>
                                    </div>
                                  }
                                >
                                  <span
                                    style={{
                                      textDecoration: 'underline',
                                      color: 'blue',
                                      cursor: 'pointer',
                                    }}
                                  >
                                    here
                                  </span>
                                </Tooltip>{' '}
                                for a list of insurances not listed in the
                                dropdowns to see whether the group generally
                                accepts that insurance. Hover over{' '}
                                <Tooltip
                                  title={
                                    <div
                                      style={{
                                        border: '1px solid #ccc',
                                        padding: '10px',
                                      }}
                                    >
                                      <div>
                                        <table
                                          style={{ border: '1px solid #ccc' }}
                                        >
                                          <thead>
                                            <tr>
                                              <th
                                                style={{ textAlign: 'center' }}
                                              >
                                                Medicaid MCO's
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            <tr>
                                              <td
                                                style={{
                                                  textAlign: 'center',
                                                  padding: '5px',
                                                  border: '1px solid #ccc',
                                                }}
                                              >
                                                Aetna Better Health
                                              </td>
                                            </tr>
                                            <tr>
                                              <td
                                                style={{
                                                  textAlign: 'center',
                                                  padding: '5px',
                                                  border: '1px solid #ccc',
                                                }}
                                              >
                                                BCBS Community Health Plan
                                                Maryland
                                              </td>
                                            </tr>
                                            <tr>
                                              <td
                                                style={{
                                                  textAlign: 'center',
                                                  padding: '5px',
                                                  border: '1px solid #ccc',
                                                }}
                                              >
                                                Jai Medical Systems
                                              </td>
                                            </tr>
                                            <tr>
                                              <td
                                                style={{
                                                  textAlign: 'center',
                                                  padding: '5px',
                                                  border: '1px solid #ccc',
                                                }}
                                              >
                                                Kaiser Permanente
                                              </td>
                                            </tr>
                                            <tr>
                                              <td
                                                style={{
                                                  textAlign: 'center',
                                                  padding: '5px',
                                                  border: '1px solid #ccc',
                                                }}
                                              >
                                                Maryland Physicians Care
                                              </td>
                                            </tr>
                                            <tr>
                                              <td
                                                style={{
                                                  textAlign: 'center',
                                                  padding: '5px',
                                                  border: '1px solid #ccc',
                                                }}
                                              >
                                                MedStar Family Choice
                                              </td>
                                            </tr>
                                            <tr>
                                              <td
                                                style={{
                                                  textAlign: 'center',
                                                  padding: '5px',
                                                  border: '1px solid #ccc',
                                                }}
                                              >
                                                Meritan (Typically Aetna)
                                              </td>
                                            </tr>
                                            <tr>
                                              <td
                                                style={{
                                                  textAlign: 'center',
                                                  padding: '5px',
                                                  border: '1px solid #ccc',
                                                }}
                                              >
                                                Priority Partners
                                              </td>
                                            </tr>
                                            <tr>
                                              <td
                                                style={{
                                                  textAlign: 'center',
                                                  padding: '5px',
                                                  border: '1px solid #ccc',
                                                }}
                                              >
                                                United Healthcare Community Plan
                                              </td>
                                            </tr>
                                            <tr>
                                              <td
                                                style={{
                                                  textAlign: 'center',
                                                  padding: '5px',
                                                  border: '1px solid #ccc',
                                                }}
                                              >
                                                Wellpoint Maryland
                                              </td>
                                            </tr>
                                            {/* You can add more rows as needed */}
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  }
                                >
                                  <span
                                    style={{
                                      textDecoration: 'underline',
                                      color: 'blue',
                                      cursor: 'pointer',
                                    }}
                                  >
                                    here
                                  </span>
                                </Tooltip>{' '}
                                for a list of Medicaid MCO's. For WebTPA or
                                Trustmark, you will need to inquire whether the
                                insurance carrier is Aetna or Cigna. Click{' '}
                                <a
                                  href="https://docs.google.com/spreadsheets/d/13TX4qngbtbuNtcQMRMXLsgbZ3F-53E62lPaRg77dpmw/edit?usp=sharing"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  here
                                </a>{' '}
                                for some tips.
                              </FormHelperText>
                            )}
                          </>
                        )}
                    </FormControl>
                  </Grid2>

                  {textareaVisible && (
                    <Grid2 size={{ xs: 12, sm: 6, md: 4, lg: 2, xl: 2 }}>
                      {popoverSelectedInsurance && ( // Conditionally render based on selection
                        <IconButton onClick={handlePopoverOpen}>
                          <LinkIcon style={{ color: 'blue' }} />{' '}
                          {/* Set color to blue */}
                        </IconButton>
                      )}
                      <Popover
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handlePopoverClose}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'center',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'center',
                        }}
                      >
                        <Typography>
                          <RenderPopoverContent
                            popoverSelectedInsurance={popoverSelectedInsurance}
                          />
                          {/* You can also add a clickable link if you want */}
                        </Typography>
                      </Popover>
                    </Grid2>
                  )}
                </Grid2>
              </Grid2>
            )}

          {textareaVisible &&
            (selectedInsurance === 'Aetna EAP' ||
              selectedInsurance === 'CIGNA EAP' ||
              selectedTheoreticalInsurance === 'Aetna EAP' ||
              selectedTheoreticalInsurance === 'CIGNA EAP') && (
              <Grid2 size={{ xs: 12 }}>
                <FormControl>
                  <TextField
                    label="Additional EAP Insurance Information e.g. insurance that will be in effect after EAP sessions expire"
                    variant="outlined"
                    sx={
                      !additionalInsurance
                        ? {
                            '& input': {
                              borderColor: 'red', // Change this color to your desired outline color
                            },
                            '& fieldset': {
                              borderColor: 'red', // Same color as input borderColor
                            },
                          }
                        : {}
                    }
                    value={additionalInsurance}
                    onChange={(e) => {
                      setAdditionalInsurance(e.target.value);
                      state.additionalInsurance = e.target.value;
                      dispatch(saveFormData(state));
                    }}
                  />
                </FormControl>
              </Grid2>
            )}

          <Grid2 container spacing={3}>
            {props.sectionsToShow.includes('services') &&
              props.step03FormValues.serviceGroup && (
                <React.Fragment>
                  <Grid2 size={{ xs: 12, sm: 6, md: 4, lg: 4, xl: 4 }}>
                    <Autocomplete
                      multiple
                      fullwidth
                      id="tags-outlined"
                      options={
                        props.step03FormValues.serviceGroup === 'Therapy'
                          ? serviceOptions
                          : props.step03FormValues.serviceType ===
                            'Psychological Testing'
                          ? psychologicalTestingOptions
                          : forensicTestingOptions
                      }
                      getOptionLabel={(option) => option.title}
                      value={selectedServices}
                      onChange={onChangeService}
                      filterSelectedOptions
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option.title}
                          {definitions[option.title] &&
                            definitions[option.title].definition !== '' && (
                              <Tooltip
                                title={
                                  <>
                                    <Typography color="inherit">
                                      {definitions[option.title].definition}
                                    </Typography>
                                    {definitions[option.title].example &&
                                      definitions[
                                        option.title
                                      ].example.trim() !== '' && (
                                        <Typography
                                          color="inherit"
                                          style={{ marginTop: '4px' }}
                                        >
                                          Example:{' '}
                                          {definitions[option.title].example}
                                        </Typography>
                                      )}
                                  </>
                                }
                              >
                                <IconButton aria-label="help" size="small">
                                  <HelpOutlineIcon fontSize="small" />
                                </IconButton>
                              </Tooltip>
                            )}
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="Service(s)"
                          error={
                            isServiceInfoPartRequiredOrNot() &&
                            (props.step03FormValues.services &&
                            props.step03FormValues.services.length > 0
                              ? false
                              : true)
                          }
                        />
                      )}
                    />
                  </Grid2>

                  {textareaVisible &&
                    selectedServicesTitles.includes(
                      'Bariatric Surgical Psychological Evaluations'
                    ) &&
                    props?.entryTypeFormValues?.entryType === 'new' && (
                      <React.Fragment>
                        <Grid2 size={{ xs: 12, sm: 6, md: 6, lg: 6, xl: 6 }}>
                          <FormControl
                            variant="outlined"
                            required
                            style={{
                              border: selectedHospital
                                ? '1px solid black'
                                : '1px solid red', // Dynamic border color
                              borderRadius: 4,
                              width: '100%',
                            }}
                          >
                            <InputLabel id="hospital-label">
                              Hospital Coming From
                            </InputLabel>
                            <Select
                              labelId="hospital-label"
                              id="hospital-dropdown"
                              native
                              value={selectedHospital}
                              onChange={(e) => {
                                setSelectedHospital(e.target.value);
                                setHospitalName(null);
                                state.selectedHospital = e.target.value;
                                dispatch(saveFormData(state));
                              }}
                              variant="outlined"
                              style={{ border: 'none' }} // Ensures that the internal border does not appear
                            >
                              <option aria-label="None" value="" />
                              <optgroup label="Hospitals">
                                <option value={'GBMC'}>GBMC</option>
                                <option
                                  value={
                                    'George Washington University Hospital'
                                  }
                                >
                                  George Washington University Hospital
                                </option>
                                <option value={'Holy Cross Health'}>
                                  Holy Cross Health
                                </option>
                                <option
                                  value={'Johns Hopkins Bayview Medical Center'}
                                >
                                  Johns Hopkins Bayview Medical Center
                                </option>
                                <option value={'MedStar Franklin Square'}>
                                  MedStar Franklin Square
                                </option>
                                <option value={'MedStar Montgomery County'}>
                                  MedStar Montgomery County
                                </option>
                                <option value={'Mercy Hospital'}>
                                  Mercy Hospital
                                </option>
                                <option value={'St. Agnes'}>St. Agnes</option>
                                <option
                                  value={'University of MD Medical Center'}
                                >
                                  University of MD Medical Center
                                </option>
                                <option value={'Other'}>Other</option>
                              </optgroup>
                            </Select>
                          </FormControl>
                        </Grid2>
                      </React.Fragment>
                    )}
                </React.Fragment>
              )}

            {selectedHospital === 'Other' && (
              <React.Fragment>
                <Grid2 size={{ xs: 12, sm: 6, md: 4, lg: 4, xl: 4 }}>
                  <Grid2 container spacing={3}>
                    <Grid2 size={{ xs: 9, sm: 6, md: 4, lg: 4, xl: 4 }}>
                      <TextField
                        id="hospital-input-other-name"
                        label="Hospital Name"
                        variant="outlined"
                        multiline
                        fullWidth
                        required
                        sx={
                          !hospitalName
                            ? {
                                '& input': {
                                  borderColor: 'red', // Change this color to your desired outline color
                                },
                                '& fieldset': {
                                  borderColor: 'red', // Same color as input borderColor
                                },
                              }
                            : {}
                        }
                        value={hospitalName}
                        onChange={(e) => setHospitalName(e.target.value)}
                      />
                    </Grid2>
                  </Grid2>
                </Grid2>
              </React.Fragment>
            )}

            {textareaVisible &&
              selectedServicesTitles.includes(
                'Bariatric Surgical Psychological Evaluations'
              ) &&
              props?.entryTypeFormValues?.entryType === 'new' && (
                <React.Fragment>
                  <Grid2 size={{ xs: 12, sm: 6, md: 4, lg: 4, xl: 4 }}>
                    <FormControl
                      variant="outlined"
                      required
                      style={{
                        border: selectedSurgeon
                          ? '1px solid black'
                          : '1px solid red', // Dynamic border color
                        borderRadius: 4,
                        width: '100%',
                      }}
                    >
                      <InputLabel id="surgeon-label">Surgeon's Name</InputLabel>
                      <Select
                        labelId="surgeon-label"
                        id="surgeon-dropdown"
                        native
                        value={selectedSurgeon}
                        onChange={(e) => {
                          setSelectedSurgeon(e.target.value);
                          setSurgeonName(null);
                          state.surgeonName = null;
                          state.selectedSurgeon = e.target.value;
                          dispatch(saveFormData(state));
                        }}
                        variant="outlined"
                        style={{ border: 'none' }} // Ensures that the internal border does not appear
                      >
                        <option aria-label="None" value="" />
                        <optgroup label="Surgeons">
                          {surgeonsForSelectedHospital.map((surgeon) => (
                            <option key={surgeon} value={surgeon}>
                              {surgeon}
                            </option>
                          ))}
                          <option value={'Other'}>Other</option>
                        </optgroup>
                      </Select>
                    </FormControl>
                  </Grid2>

                  {selectedSurgeon === 'Other' && (
                    <React.Fragment>
                      <Grid2 size={{ xs: 12, sm: 6, md: 4, lg: 4, xl: 4 }}>
                        <Grid2 container spacing={3}>
                          <Grid2 size={{ xs: 9, sm: 9, md: 4, lg: 4, xl: 4 }}>
                            <TextField
                              id="surgeon-input-other-name"
                              label="Surgeon Name"
                              variant="outlined"
                              multiline
                              fullWidth
                              required
                              sx={
                                !surgeonName
                                  ? {
                                      '& input': {
                                        borderColor: 'red', // Change this color to your desired outline color
                                      },
                                      '& fieldset': {
                                        borderColor: 'red', // Same color as input borderColor
                                      },
                                    }
                                  : {}
                              }
                              value={surgeonName}
                              onChange={(e) => {
                                setSurgeonName(e.target.value);
                                state.surgeonName = e.target.value;
                                dispatch(saveFormData(state));
                              }}
                            />
                          </Grid2>
                        </Grid2>
                      </Grid2>
                    </React.Fragment>
                  )}

                  <Grid2 size={{ xs: 6, sm: 6, md: 4, lg: 4, xl: 4 }}>
                    <Grid2 container spacing={4}>
                      {!notScheduled && (
                        <Grid2 size={{ xs: 9 }}>
                          <FormControl
                            variant="outlined"
                            required
                            style={{
                              border: nutritionDate
                                ? '1px solid black'
                                : '1px solid red',
                              borderRadius: 4,
                              width: '100%',
                            }}
                          >
                            <TextField
                              id="date-picker"
                              label="Date of Nutrition Appt"
                              type="date"
                              variant="outlined"
                              componentsProps={{
                                label: {
                                  shrink: true,
                                },
                              }}
                              value={nutritionDate}
                              placeholder="MM/DD/YYYY"
                              onChange={(e) => {
                                setNutritionDate(e.target.value);
                                state.nutritionDate = e.target.value;
                                dispatch(saveFormData(state));
                              }}
                              required
                            />
                          </FormControl>
                        </Grid2>
                      )}
                      <Grid2 size={{ xs: 3, sm: 3, md: 3, lg: 3, xl: 3 }}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              checked={notScheduled}
                              onChange={() => {
                                let notScheduledClone = notScheduled;
                                setNotScheduled(() => !notScheduled);
                                setNutritionDate(null);
                                state.nutritionDate = null;
                                state.notScheduled = () => !notScheduledClone;
                                dispatch(saveFormData(state));
                              }}
                            />
                          }
                          label="Not Scheduled"
                        />
                      </Grid2>
                    </Grid2>
                  </Grid2>
                  {/* Medications Taken */}
                  <Grid2 size={{ xs: 12 }}>
                    <Grid2 container spacing={3}>
                      {!isMedicationTaken && (
                        <Grid2 size={{ xs: 9 }}>
                          <TextField
                            id="medications-input"
                            label="Medications Taken"
                            variant="outlined"
                            multiline
                            fullWidth
                            required
                            sx={
                              !medicationsTaken
                                ? {
                                    '& input': {
                                      borderColor: 'red', // Change this color to your desired outline color
                                    },
                                    '& fieldset': {
                                      borderColor: 'red', // Same color as input borderColor
                                    },
                                  }
                                : {}
                            }
                            value={medicationsTaken}
                            onChange={(e) => {
                              setMedicationsTaken(e.target.value);
                              state.medicationsTaken = e.target.value;
                              dispatch(saveFormData(state));
                            }}
                          />
                        </Grid2>
                      )}
                      <Grid2 size={{ xs: 3, sm: 3, md: 3, lg: 3, xl: 3 }}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              checked={isMedicationTaken}
                              onChange={() => {
                                let isMedicationTakenClone;
                                setIsMedicationTaken(() => !isMedicationTaken);
                                setMedicationsTaken(null);
                                state.medicationsTaken = null;
                                state.isMedicationTaken = () =>
                                  !isMedicationTakenClone;
                                dispatch(saveFormData(state));
                              }}
                            />
                          }
                          label="None"
                        />
                      </Grid2>
                    </Grid2>
                  </Grid2>
                </React.Fragment>
              )}

            {props.sectionsToShow.includes('services') &&
              props.step03FormValues.serviceGroup === 'Therapy' && (
                <Grid2 size={{ xs: 12, sm: 6, md: 4, lg: 4, xl: 4 }}>
                  <FormControl>
                    <Autocomplete
                      multiple
                      fullwidth
                      id="tags-outlined"
                      options={therapyModalities}
                      onChange={onChangeTherapyModalities}
                      getOptionLabel={(option) => option.title}
                      // defaultValue={[top100Films[13]]}
                      filterSelectedOptions
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="Therapy Modalities"
                          // placeholder="Favorites"
                        />
                      )}
                    />
                  </FormControl>
                </Grid2>
              )}

            {props.sectionsToShow.includes('modalitySpecialization') &&
              props.step03FormValues.serviceGroup === 'Therapy' && (
                <Grid2
                  size={{ xs: 12, sm: 6, md: 4, lg: 4, xl: 4 }}
                  style={{ marginTop: '0px' }}
                >
                  <FormControl
                    style={{ border: '1px solid #D3D3D3', borderRadius: '1px' }}
                  >
                    <InputLabel id="demo-simple-select-label">
                      Provider's Modality Specialization
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      onChange={onChangeModalitySpecialization}
                      label="Provider's Modality Specialization"
                      defaultValue="Primary OR Borrow & Integrate"
                    >
                      <MenuItem value="Primary OR Borrow & Integrate">
                        Primary OR Borrow & Integrate
                      </MenuItem>
                      <MenuItem value="Primary Only">Primary Only</MenuItem>
                    </Select>
                  </FormControl>
                </Grid2>
              )}
            {props.sectionsToShow.includes('therapySpecializations') &&
              props.step03FormValues.serviceGroup === 'Therapy' && (
                <>
                  <Grid2 size={{ xs: 12, sm: 6, md: 4, lg: 4, xl: 4 }}>
                    <FormControl
                      style={{
                        border: '1px solid #D3D3D3',
                        borderRadius: '1px',
                      }}
                    >
                      <InputLabel id="demo-simple-select-label">
                        Specialized Therapies
                      </InputLabel>
                      <Select
                        error={
                          props.step03FormValues.serviceType ? false : true
                        }
                        defaultValue=""
                        id="grouped-native-select"
                        label="Grouping"
                        onChange={onChangeTherapySpecialties}
                      >
                        <MenuItem value="All" />
                        {therapySpecialties?.map((therapySpeciality, index) => {
                          return (
                            <MenuItem
                              value={therapySpeciality.title}
                              key={index}
                            >
                              {therapySpeciality.title}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid2>
                </>
              )}

            {props.sectionsToShow.includes('specialConsiderations') &&
              props.step03FormValues.serviceGroup === 'Therapy' && (
                <Grid2 size={{ xs: 12, sm: 6, md: 4, lg: 4, xl: 4 }}>
                  <FormControl
                    style={{ border: '1px solid #D3D3D3', borderRadius: '1px' }}
                  >
                    <InputLabel id="demo-simple-select-label">
                      Special Considerations
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Special Considerations"
                      onChange={onChangeSpecialConsiderations}
                    >
                      <MenuItem value="All" />
                      {specialConsiderations?.map(
                        (specialConsideration, index) => {
                          return (
                            <MenuItem
                              value={specialConsideration.title}
                              key={index}
                            >
                              {specialConsideration.title}
                            </MenuItem>
                          );
                        }
                      )}
                    </Select>
                  </FormControl>
                </Grid2>
              )}

            {textareaVisible &&
              props.entryTypeFormValues.entryType !== 'existing' &&
              (!props?.step03FormValues?.serviceGroup?.includes('Testing') ||
                !selectedServicesTitles.includes(
                  'Bariatric Surgical Psychological Evaluations'
                )) && (
                <Grid2 size={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}>
                  {' '}
                  {/*Hidding it by making display none. Adding a default value to it.*/}
                  <InputLabel
                    htmlFor="reason-textarea"
                    style={{ color: '#333' }}
                  >
                    Reason for Seeking Out Services
                  </InputLabel>
                  <TextareaAutosize
                    id="reason-textarea"
                    value={reasonForService || ''}
                    style={{
                      width: '100%',
                      padding: '8px',
                      border:
                        '1px solid ' + (reasonForService ? 'black' : 'red'), // Set border color based on text presence
                      borderRadius: '4px',
                    }}
                    onChange={(e) => {
                      setReasonForService(e.target.value);
                      state.reasonForService = e.target.value;
                      dispatch(saveFormData(state));
                    }} // Use state to track text
                    // ... other handlers
                  />
                </Grid2>
              )}

            {/* Radio buttons for Preferred Setting */}
            {textareaVisible &&
              props?.entryTypeFormValues?.existingClientOptions !==
                'Therapy Client Coming to Office' &&
              props?.entryTypeFormValues?.existingClientOptions !==
                'Testing Client Coming to Office' &&
              props?.step03FormValues?.serviceGroup === 'Therapy' && (
                <Grid2 size={{ xs: 4 }}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend" style={{ color: '#000' }}>
                      Preferred Setting
                    </FormLabel>
                    <div className="radio-container">
                      <RadioGroup
                        aria-label="preferred-setting"
                        name="preferred-setting"
                        value={preferredSetting}
                        onChange={(e) => {
                          setPreferredSetting(e.target.value);
                          setFollowUpPreferredSettingAnswer(null);
                          state.preferredSetting = e.target.value;
                          state.followUpPreferredSetting = null;
                          dispatch(saveFormData(state));
                        }}
                      >
                        <FormControlLabel
                          value="In-Person"
                          control={<Radio />}
                          label="In-Person"
                        />
                        <FormControlLabel
                          value="Virtual"
                          control={<Radio />}
                          label="Virtual"
                        />
                      </RadioGroup>
                    </div>
                    <style>
                      {`
            .radio-container .MuiRadio-root {
             color: ${preferredSetting ? 'inherit' : 'red'};
            }
        `}
                    </style>
                  </FormControl>
                </Grid2>
              )}

            {/* Follow-up question if Virtual is selected */}
            {preferredSetting === 'Virtual' && textareaVisible && (
              <Grid2
                size={{ xs: 6, sm: 6, md: 6, lg: 6, xl: 6 }}
                style={{ marginLeft: '150px' }} // Adjusts the component to move 20px to the right
              >
                <FormControl component="fieldset">
                  <FormLabel component="legend" style={{ color: '#000' }}>
                    Do you have a private, quiet place AND if it were overheard,
                    would you feel safe?
                  </FormLabel>
                  <div className="preferred-setting-container">
                    <RadioGroup
                      aria-label="follow-up-preferred-setting"
                      name="follow-up-preferred-setting"
                      value={followUpPreferredSettingAnswer}
                      onChange={handleFollowUpAnswerChange} // Custom handler for change event
                    >
                      <FormControlLabel
                        value="Yes"
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="No"
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                    <style>
                      {`
                 .preferred-setting-container .MuiRadio-root {
                     color: ${
                       followUpPreferredSettingAnswer ? 'inherit' : 'red'
                     };
                    }
                  `}
                    </style>
                  </div>
                </FormControl>
              </Grid2>
            )}

            {/* Dialog for displaying the alert message */}
            <Dialog
              open={alertOpen}
              onClose={() => setAlertOpen(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                Important Notice
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  This client cannot be scheduled for a virtual session. Please
                  try to find an in-person session or refer them out to a
                  provider who can see clients in-person.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setAlertOpen(false)} color="primary">
                  Okay
                </Button>
              </DialogActions>
            </Dialog>

            {textareaVisible && (
              <Grid2 container spacing={1}>
                <Grid2
                  size={{ xs: 12 }}
                  style={{
                    marginBottom: nameChecked ? '-5px' : '-40px',
                    marginLeft: '30px',
                  }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={nameChecked}
                        onChange={(e) => {
                          setNameChecked(e.target.checked);
                          setPronounDetails(null);
                          state.nameChecked = e.target.checked;
                          state.pronounDetails = null;
                          dispatch(saveFormData(state));
                        }}
                      />
                    }
                    label="Name / Pronoun Notes"
                  />
                  {nameChecked && (
                    <React.Fragment>
                      <InputLabel
                        htmlFor="name-textarea"
                        style={{ color: '#333' }}
                      >
                        Name/Pronoun Details
                      </InputLabel>
                      <TextareaAutosize
                        id="name-textarea"
                        required
                        style={{
                          width: '100%',
                          padding: '8px',
                          border: pronounDetails
                            ? '1px solid black'
                            : '1px solid red', // Dynamic border color
                          borderRadius: '4px',
                        }}
                        value={pronounDetails}
                        onChange={(e) => {
                          setPronounDetails(e.target.value);
                          state.pronounDetails = e.target.value;
                          dispatch(saveFormData(state));
                        }}
                      />
                    </React.Fragment>
                  )}
                </Grid2>
                <Grid2
                  size={{ xs: 12 }}
                  style={{
                    marginBottom: nameChecked ? '-5px' : '-40px',
                    marginLeft: '30px',
                  }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={schedulingChecked}
                        onChange={(e) => {
                          setSchedulingChecked(e.target.checked);
                          setUnusalNote(null);
                          state.schedulingChecked = e.target.checked;
                          state.unusalNote = null;
                          dispatch(saveFormData(state));
                        }}
                      />
                    }
                    label="Unusual Scheduling Notes"
                  />
                  {schedulingChecked && (
                    <React.Fragment>
                      <InputLabel
                        htmlFor="scheduling-textarea"
                        style={{ color: '#333' }}
                      >
                        Scheduling Notes
                      </InputLabel>
                      <TextareaAutosize
                        id="scheduling-textarea"
                        required
                        style={{
                          width: '100%',
                          padding: '8px',
                          border: unusualNote
                            ? '1px solid black'
                            : '1px solid red', // Dynamic border color
                          borderRadius: '4px',
                        }}
                        value={unusualNote}
                        onChange={(e) => {
                          setUnusalNote(e.target.value);
                          state.unusalNote = e.target.value;
                          dispatch(saveFormData(state));
                        }}
                      />
                    </React.Fragment>
                  )}
                </Grid2>
              </Grid2>
            )}
          </Grid2>
        </Grid2>
      </div>
    </React.Fragment>
  );
};

export default memo(SchedulingStep03);
