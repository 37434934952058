import React, { useState, useEffect, useCallback, memo } from 'react';
import {
  createTheme,
  ThemeProvider,
  Typography,
  ListItem,
  ListItemText,
  Tooltip,
  useMediaQuery,
  useTheme,
  DialogTitle,
  Dialog,
  DialogContent,
  Button,
  DialogActions,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import ScheduleIcon from '@mui/icons-material/Schedule';
import moment from 'moment';
import '../styles/PersonalInfo.css';
import { clearLoading, setLoading } from '../actions/loading';
import { setNotificationCount } from '../actions/notification';
import {
  isAdmin,
  isCareCoordinator,
  isDoctor,
  isAdminOrCareCoordinator,
} from '../common/utility';
import { socket } from '../common/sockets';
import {
  notificationService,
  clientService,
  complianceService,
  providerService,
} from '../services';

const therapyGroups = {
  locations: ['locations'],
  Population: ['populationValue', 'subPopulations'],
  'Therapy Type': ['Individuals', 'Couples', 'Groups', 'Families'],
  'Special Considerations': [
    'Deaf or Hard of Hearing',
    'Working with Mental Health Professionals',
    'Working with Orthodox Jews',
  ],
  'Specialized Therapies': [
    'Life Coaching',
    'ADHD Therapy/Coaching',
    'Parenting Therapy',
    'Mediation',
    'CBT Only Therapy',
    'DBT Therapy - DBT Certified Therapist',
    'DBT Therapy - Primarily Use DBT in Therapy',
    'DBT Therapy - Occasionally use DBT Skills when appropriate',
    'Feminist Therapy',
    'Premarital Counseling',
  ],
  'Therapy Issues': [
    'Academic Underachievement',
    'ADHD (Therapy)',
    'ADHD (Coaching)',
    'Addiction',
    'Adjustments',
    'Adoption',
    'Alcohol Abuse',
    "Alzheimer's",
    'Anger Management',
    'Animal Support - Writing Certification Letter',
    'Antisocial Personality ',
    'Anxiety or Fears',
    'Aspergers Syndrome',
    'Autism',
    'Behavioral Issues',
    'Bipolar Disorder',
    'Body Dysmorphic Disorder',
    'Borderline Personality',
    'Bullying (being bullied)',
    'Bullying (being the bully)',
    'Caregiver Stress',
    'Career Counseling',
    'Child or Adolescent',
    'Chronic Impulsivity',
    'Chronic Pain or Illness',
    'Chronic Relapse',
    'Coping Skills',
    'Depression',
    'Developmental Disorders',
    'Dissociative Identity (DID)',
    'Divorce',
    'Domestic Abuse',
    'Domestic Violence (Current)',
    'Domestic Violence (Past)',
    'Drug Abuse (as primary issue)',
    'Dual Diagnosis',
    'Eating Disorder: Anorexia/Bulimia (Mild-Moderate), as #1 Therapy Issue',
    'Eating Disorder: Anorexia/Bulimia (Moderate-Severe), as #1 Therapy Issue',
    'Eating Disorder: Anorexia/Bulimia, as #2 Therapy Issue',
    'Eating Disorder: Binge Eating Disorder',
    'Entitlement',
    'Family Conflict',
    'Gambling',
    'Incest - Perpetrator',
    'Incest - Victim',
    'Infidelity - Perpetrator',
    'Infidelity - Victim',
    'Infertility',
    'Internet Addiction',
    'Learning Disability',
    'LGBTQIAA+ (issues; identity development, coping with oppression)',
    'Loss or Grief',
    'Medical Detox',
    'Mens Issues',
    'Mental Retardation (Treatment)',
    'Narcissistic Personality Disorder',
    'Obesity (Presenting Issue)',
    'OCD',
    'Oppositional Defiance',
    'Parenting',
    'Peer Relationships',
    'Post Partum Depression',
    'Pregnancy / Prenatal',
    'Racial Identity',
    'Relationship Issues (General)',
    'Relationships - Open Relationships',
    'School Issues',
    'Self Esteem',
    'Sex Therapy',
    'Sexual Abuse (Presenting Issue)',
    'Sexual Abuse in Military (MST)',
    'Sexual Addiction',
    'Sexuality - BDSM',
    'Sleep or Insomnia',
    'Sociopathy',
    'Substance Abuse',
    'Teen Violence',
    'Transgender/Sex Surgery - Writing Letter of Recommendation',
    'Trauma and PTSD - Childhood',
    'Trauma and PTSD - General',
    'Traumatic Brain Injury',
    'Trichotillomania',
    'Video Game Addiction',
    'Weight Loss',
    'Womens Issues',
  ],
  topIssues: ['issue1', 'issue2', 'issue3'],

  'Therapy Modalities': [
    'Acceptance and Commitment Therapy (ACT)',
    'ACT Informed Therapy',
    'Adlerian',
    'Applied Behavioral Analysis',
    'Art Therapy',
    'Attachment-based',
    'Christian Counseling',
    'Coaching',
    'Cognitive Behavioral Therapy (CBT)',
    'DBT',
    'EMDR',
    'Eclectic',
    'Emotionally Focused Therapy (EFT)',
    'Existential',
    'Experiential Therapy',
    'Expressive Arts',
    'Exposure and Response Prevention (ERP)',
    'Feminist',
    'Gestalt',
    'Gottman Method',
    'Humanistic',
    'Hypnotherapy',
    'Imago',
    'Integrative',
    'Internal Family Systems (IFS)',
    'Interpersonal',
    'Jungian',
    'Mindfulness-based (MBCT)',
    'Motivational Interviewing',
    'Narrative',
    'Neuro-Linguistic',
    'Neurofeedback',
    'Parent-Child Interaction Therapy (PCIT)',
    'Person-Centered',
    'Play Therapy',
    'Positive Psychology',
    'Prolonged Exposure Therapy',
    'Psychoanalytic',
    'Psychodynamic',
    'Rational Emotive Behavior Therapy (REBT)',
    'Relational',
    'Sand Play',
    'Solution Focused Brief (SFBT)',
    'Somatic',
    'Structural Family Therapy',
  ],
  'Risk Questions': ['riskQuestions'],
};

const testingGroups = {
  Locations: ['locations'],
  Population: ['populationValue'],
  'Areas of Assessment': [
    'ADHD Adult Diagnostic Evaluation',
    'ADHD Child Comprehensive Evaluation',
    'ADHD/Autism Child Comprehensive Combined Evaluation',
    'Anxiety',
    'Autism Spectrum Disorder - Under 18 Evaluation',
    'Autism Spectrum Disorder - Adult Evaluation',
    'Bariatric Surgical Psychological Evaluations',
    'Child Psycho-Educational Evaluation',
    'Comprehensive Evaluation',
    'Cosmetic Surgery Evaluations',
    'Depression',
    'Differential Adult Diagnosis Evaluation',
    'Developmental delays and early intervention',
    'Evaluation for Accommodations on High Stakes Testing',
    'Giftedness',
    'Intellectual disabilities',
    'Kindergarten readiness and early entry into kindergarten',
    'Neuropsychological Testing ',
    'Nonverbal learning disabilities',
    'Oppositional defiance and conduct disorders',
    'Reading',
    'Writing',
    'Math Disability',
    'Special education services',
    'Spinal Stimulator Pre Surgery Evaluations',
    'Transgender Evaluations',
    'Transplant / Bone Marrow Transplant Pre-Surgical',
    'Weaknesses in executive functioning',
    'Weaknesses in memory and learning',
  ],

  'Forensic Services': [
    'Adoption Evaluations',
    'Adult Competency',
    'Child Abuse',
    'Child Custody Evaluations',
    'Criminal Mitigation',
    'Criminal Responsibility',
    'Expert Witness Testimony and Evaluations',
    'Fitness for Duty Evaluation',
    'Immigration Evaluation:  Disability Waiver',
    'Immigration Evaluation: Asylum Cases',
    'Immigration Evaluation: Extreme or Exceptional Hardship',
    'Immigration Evaluation: Violence Against Women Act',
    'Juvenile Competency',
    'Law Enforcement/Weapon Screening',
    'Medical Records Review',
    'Parenting Capacity Evaluations',
    'Risk Assessments- Violence/Sex',
    'Sentencing Evaluations',
    'Sex Crimes',
    'White Collar Crime',
  ],
};

const PersonalInfo = () => {
  const [, setContent] = useState('');
  const [error, setError] = useState(false);
  const { user: currentUser } = useSelector((state) => state.auth);
  const [show, setShow] = useState(false);
  const [therapy, setTherapy] = useState({});
  const [testing, setTesting] = useState({});
  const [personalInfo, setPersonalInfo] = useState({});
  const [lastAttestation, setLastAttestation] = useState(null);
  const [lastRosterSubmission, setLastRosterSubmission] = useState(null);
  const [showDipSubmission, setShowDipSubmission] = useState(false);
  const [marylandLicenseExpiration, setMarylandLicenseExpiration] =
    useState(null);
  const [psyPactLicenseExpiration, setPsyPactLicenseExpiration] =
    useState(null);
  const [outOfStateLicenses, setOutOfStateLicenses] = useState([]);
  const [liabilityInsuranceExpiration, setLiabilityInsuranceExpiration] =
    useState(null);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const dispatch = useDispatch();
  const tooltipTheme = createTheme({
    components: {
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            fontSize: '1.1em', // Customize your tooltip font size
          },
        },
      },
    },
  });

  const keyTransform = (key) => {
    let obj = {
      populationValue: 'Population Range',
    };

    if (obj[key]) {
      return obj[key];
    }

    return key
      .split('_')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  const getArrayOrObjectDisplay = (key, value) => {
    // if the value is an array, join its elements with commas
    if (Array.isArray(value)) {
      return value.join(', ');
    }
    // if the value is an object, stringify it (you may want to customize this)
    else if (typeof value === 'object') {
      return JSON.stringify(value, null, 2);
    }
    // otherwise, just return the value as is
    else {
      return value;
    }
  };

  const confirmContent = (content) => {
    let providerOrder = [
      'provider_name',
      'provider_email',
      'gender',
      'degrees_and_license',
      'licensed_in_other_states',
    ];

    let mergedTherapyContent = {
      ...content.therapy,
      ...content.therapy?.top_issues,
    };

    let therapyKeys = Object.keys(mergedTherapyContent);
    let testingKeys = Object.keys(content.testing || {});
    let providerKeys = providerOrder.filter((key) =>
      content.personalInfo.hasOwnProperty(key)
    );

    therapyKeys.sort();
    testingKeys.sort();

    const getContentRow = ({ key, value, parentObj }) => {
      const reasonKey = `${key}_reason`;
      let displayValue = getArrayOrObjectDisplay(key, value);

      // Special case for risk questions
      if (key === 'riskQuestions' && Array.isArray(value)) {
        return value.map((questionObj, idx) => (
          <ListItem key={`${key}-${idx}`}>
            <ListItemText
              primary={
                <Typography variant="subtitle2">{`Risk Question ${idx + 1}: ${
                  questionObj.question
                }`}</Typography>
              }
              secondary={questionObj.answer}
            />
          </ListItem>
        ));
      }

      if (
        Array.isArray(value) &&
        value[0] &&
        typeof value[0] === 'object' &&
        value[0].hasOwnProperty('text') &&
        Array.isArray(value[0].value)
      ) {
        return value.map((subPop, idx) => (
          <ListItem key={`${key}-${idx}`}>
            <ListItemText
              primary={
                <Typography variant="subtitle2">{`${keyTransform(key)} ${
                  idx + 1
                }`}</Typography>
              }
              secondary={`Low: ${subPop.value[0]}, High: ${subPop.value[1]} : ${subPop.text}`}
            />
          </ListItem>
        ));
      } else if (!displayValue || displayValue.length === 0) {
        return '';
      } else if (value.hasOwnProperty(reasonKey)) {
        const reasonValue = String(value[reasonKey]);
        displayValue += ` (${reasonValue.replace(/^"(.*)"$/, '$1')})`;
      } else if (key === 'top_issues') {
        const issues = Object.values(value).filter((issue) => !!issue);
        if (issues.length > 0) {
          displayValue = issues.join(', ');
        } else {
          displayValue = 'None';
        }
      } else if (parentObj.hasOwnProperty(reasonKey)) {
        const reasonValue = String(parentObj[reasonKey]);
        displayValue += ` (${reasonValue.replace(/^"(.*)"$/, '$1')})`;
      }

      return (
        <ListItem key={`${key}`}>
          <ListItemText
            primary={
              <Typography variant="subtitle2">{keyTransform(key)}</Typography>
            }
            secondary={displayValue}
          />
        </ListItem>
      );
    };

    const mapKeysToContentRow = (keys, contentPart) => {
      return keys
        .map(function (key) {
          if (contentPart[key]) {
            return (
              <div className="col-sm-4">
                {getContentRow({
                  key: key,
                  value: contentPart[key],
                  parentObj: contentPart,
                })}
              </div>
            );
          }
          return null;
        })
        .filter(Boolean); // Filter out null values
    };

    return (
      <div style={{ maxWidth: 850 }}>
        <p>
          <h6>
            Please thoroughly review the information listed below. This process
            is of utmost importance as the details displayed will be directly
            used in scheduling your appointments with clients. Please pay
            careful attention to each item and ensure that they are not only
            accurate but also up-to-date.
            <br /> <br /> If, upon review, you find any areas that require
            updating, please go the Update Provider Config page by selecting the
            "Cancel" option on the bottom of this page and then selecting the
            "Update Provider Config" from the side menu. If there are items
            which you do not have the access to alter, please reach out to
            either Victoria or Levi for assistance.
            <br /> <br /> Your diligence in this matter aids us in maintaining
            the highest standards of service to our clients and is greatly
            appreciated.
            <br /> <br /> By selecting 'OK' to the information below, you are
            attesting to the accuracy and currentness of all the details below.
          </h6>
        </p>
        <h5>Provider Details</h5>
        <div className="row">
          {mapKeysToContentRow(providerKeys, content.personalInfo)}
        </div>
        <hr />
        <h5>Therapy Details</h5>
        {(() => {
          // Merge top_issues into therapy
          const mergedTherapyContent = {
            ...content.therapy,
            ...content.therapy?.topIssues,
          };

          // Get keys for merged therapy content
          const mergedTherapyKeys = Object.keys(mergedTherapyContent);

          return Object.keys(therapyGroups).map(function (groupName, index) {
            // Filter keys for this group
            let groupKeys = therapyGroups[groupName].filter((key) =>
              mergedTherapyKeys.includes(key)
            );

            return (
              <div key={groupName + index}>
                <h6>{groupName}</h6>
                <div className="row">
                  {mapKeysToContentRow(groupKeys, mergedTherapyContent)}
                </div>
              </div>
            );
          });
        })()}
        <hr />
        <h5>Testing Details</h5>
        {Object.keys(testingGroups).map(function (groupName, index) {
          // Filter keys for this group
          let groupKeys = testingGroups[groupName].filter((key) =>
            testingKeys.includes(key)
          );

          return (
            <div key={groupName + index}>
              <h6>{groupName}</h6>
              <div className="row">
                {mapKeysToContentRow(groupKeys, content.testing)}
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const getAttestationDueDaysText = () => {
    if (!lastAttestation) {
      return 'Your attestation is pending.';
    }
    let m = moment.utc(lastAttestation).add(91, 'days');
    let n = moment.utc(new Date());
    let diffDays = m.diff(n, 'days');

    if (diffDays > 0) {
      return `${diffDays} days until next provider profile attestation`;
    } else {
      return `Your profile attestation is overdue`;
    }
  };

  const getRosterDueDaysText = () => {
    if (!lastRosterSubmission) {
      return 'Your roster submission is pending.';
    }
    let m = moment.utc(lastRosterSubmission).add(30, 'days');
    let n = moment.utc(new Date());
    let diffDays = m.diff(n, 'days');

    if (diffDays > 0) {
      return `${diffDays} days until next roster submission`;
    } else {
      return `Your profile roster submission is overdue`;
    }
  };

  // Below is just for demo for UI
  const getLicenseDueDaysText = () => {
    if (!marylandLicenseExpiration) {
      return 'Fetching Maryland license expiration date...';
    }

    let expirationMoment = moment.utc(marylandLicenseExpiration);
    let today = moment.utc(new Date());
    let diffDays = expirationMoment.diff(today, 'days');

    if (diffDays > 0) {
      return `${diffDays} days until your Maryland license expires.`;
    } else if (diffDays === 0) {
      return 'Your Maryland license expires today!';
    } else {
      return 'Your Maryland license is overdue for renewal.';
    }
  };

  const checkRosterButtonStatus = () => {
    // Check if lastRosterSubmission is not available and disable the button
    if (!lastRosterSubmission) {
      return true; // Disable button because there's no last roster submission
    }

    // If lastRosterSubmission is available, calculate the days difference to determine button status
    let m = moment.utc(lastRosterSubmission).add(30, 'days');
    let n = moment.utc(new Date());
    let diffDays = m.diff(n, 'days');

    // Disable the button if it is not time yet to submit the next roster
    return diffDays > 0;
  };

  const getLastAttestationText = () => {
    if (!lastAttestation) {
      return 'Last attested: N/A';
    }
    return `Last attested ${moment
      .utc(lastAttestation)
      .format('MMM DD, yyyy')}`;
  };

  const getLastRosterText = () => {
    if (!lastRosterSubmission) {
      return 'Last Roster Submission: N/A';
    }
    return `Last Roster submission ${moment
      .utc(lastRosterSubmission)
      .format('MMM DD, yyyy')}`;
  };

  const showLoading = useCallback(() => {
    dispatch(setLoading());
  }, [dispatch]);
  const hideLoading = useCallback(() => {
    dispatch(clearLoading());
  }, [dispatch]);

  const updateNotificationCount = () => {
    notificationService.getNotificationsCount().then((resp) => {
      dispatch(setNotificationCount(resp.data.data.count));
    });
  };

  useEffect(() => {
    updateNotificationCount();
    socket.connect();
    // initialize socket
    socket.on('provider_config_updated_notification', (data) => {
      if (data.user.id === currentUser.id) {
        return;
      }
      if (
        isDoctor(currentUser) ||
        isCareCoordinator(currentUser) ||
        isAdmin(currentUser)
      ) {
        updateNotificationCount();
      }
    });
    return () => {
      socket.removeAllListeners();
      socket.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // The below condition checks if it was admin or careCordinator then skip
    if (isAdminOrCareCoordinator(currentUser)) {
      return; // Skip useEffect if the user is an admin or care coordinator
    }

    providerService
      .getTherapyConfig(currentUser?.id)
      .then((response) => {
        setTherapy(response.data.therapy);
        setTesting(response.data.testing);
        let lastAttestation = response.data.last_attestation;
        let lastRosterSubmission = response.data.last_roster_submission;

        if (!lastRosterSubmission) {
          setLastRosterSubmission(null);
        } else {
          setLastRosterSubmission(new Date(lastRosterSubmission));
        }

        if (!lastAttestation) {
          setLastAttestation(null);
        } else {
          setLastAttestation(new Date(lastAttestation));
        }
      })
      .catch((error) => {
        setError(true);
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setContent(message);
      });
    providerService
      .getProviderDetail(currentUser.id, currentUser.email)
      .then((resp) => {
        setPersonalInfo(resp.data.provider);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser.email, currentUser.id]);

  useEffect(() => {
    if (isAdminOrCareCoordinator(currentUser)) {
      return; // Skip useEffect if the user is an admin or care coordinator
    }
    clientService
      .getDIPStatus({ username: currentUser.username })
      .then((response) => {
        if (response?.data?.clients?.length > 0) {
          setShowDipSubmission(true);
        }
      })
      .catch((error) => {
        console.log('error', error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser.username, currentUser.email]);

  useEffect(() => {
    if (isAdminOrCareCoordinator(currentUser)) {
      return; // Skip useEffect if the user is an admin or care coordinator
    }
    const providerId = currentUser.id; // Assuming 'currentUser.id' is defined and holds the correct provider ID

    // Fetch the Maryland license expiration date
    complianceService
      .getMarylandLicenseExpiration(providerId)
      .then((response) => {
        if (response.data.expirationDate) {
          setMarylandLicenseExpiration(new Date(response.data.expirationDate));
        }
      })
      .catch((error) => {
        console.error(
          'Failed to fetch Maryland license expiration date:',
          error
        );
      });

    // Fetch the out-of-state license expiration dates
    complianceService
      .getOutofStateLicenseExpiration(providerId)
      .then((response) => {
        // Assuming response data is structured as { expirations: [{ state: 'FL', expirationDate: 'date' }, ...] }
        setOutOfStateLicenses(response.data.expirations);
      })
      .catch((error) => {
        console.error(
          'Failed to fetch out-of-state license expiration dates:',
          error
        );
      });

    complianceService
      .getPsyPactLicenseExpiration(providerId)
      .then((response) => {
        console.log('PsyPact License Expiration Data:', response.data); // Check the response structure
        setPsyPactLicenseExpiration(new Date(response.data.expirationDate)); // Adjust based on actual data structure
      })
      .catch((error) => {
        console.error(
          'Failed to fetch PsyPact license expiration date:',
          error
        );
      });

    complianceService
      .getCurrentLiabilityInsuranceExpiration(providerId)
      .then((response) => {
        console.log('Liability Insurance Expiration Data:', response.data);
        setLiabilityInsuranceExpiration(new Date(response.data.expirationDate));
      })
      .catch((error) => {
        console.error(
          'Failed to fetch liability insurance expiration date:',
          error
        );
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Empty dependency array to run only once on component mount

  const handleAttestationConfirm = async () => {
    showLoading();
    try {
      await complianceService.updateLastAttestation(currentUser.id);
      setLastAttestation(new Date());
    } catch (e) {
      setError(true);
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      setContent(message);
    } finally {
      hideLoading();
      setShow(false);
    }
  };

  const shouldShowMDLicenseReminder = () => {
    if (!marylandLicenseExpiration) {
      return false;
    }

    const expirationMoment = moment.utc(marylandLicenseExpiration);
    const today = moment.utc();
    const diffDays = expirationMoment.diff(today, 'days');

    return diffDays <= 46;
  };

  const shouldShowOutOfStateLicenseReminder = () => {
    const today = moment.utc();
    return outOfStateLicenses.some((license) => {
      const expirationMoment = moment.utc(license.expirationDate);
      return expirationMoment.diff(today, 'days') <= 46;
    });
  };

  const shouldShowPsyPactLicenseReminder = () => {
    if (!psyPactLicenseExpiration) {
      return false; // No expiration date available, so no reminder
    }

    const expirationMoment = moment.utc(psyPactLicenseExpiration);
    const today = moment.utc();
    const diffDays = expirationMoment.diff(today, 'days');

    return diffDays <= 46; // Show reminder if expiration is within 46 days
  };

  const shouldShowLiabilityInsuranceReminder = () => {
    if (!liabilityInsuranceExpiration) {
      return false;
    }

    const expirationMoment = moment.utc(liabilityInsuranceExpiration);
    const today = moment.utc();
    const diffDays = expirationMoment.diff(today, 'days');

    return diffDays <= 31; // Show reminder if expiration is within 46 days
  };

  // Determine if the button should be disabled
  const shouldDisableAttestButton = () => {
    if (!lastAttestation) {
      return true; // Disables the button if lastAttestation is null or undefined
    }

    // If lastAttestation is available, calculate the days difference to determine button status
    let m = moment.utc(lastAttestation).add(91, 'days');
    let n = moment.utc(new Date());
    let diffDays = m.diff(n, 'days');

    // Disable the button if it is not time yet to submit the next roster
    return diffDays > 0;
  };

  const handleRosterSubmission = () => {
    localStorage.setItem('activeTab', 1); // setting this to get open the roster tab
    window.location = '/clients'; // moving towards the client
  };

  const handleDIPSubmission = () => {
    localStorage.setItem('activeTab', 2); // setting this to get open the roster tab
    window.location = '/clients'; // moving towards the client
  };

  const handleUpdateLicenseMalpracticeClick = (optionSelected) => {
    localStorage.setItem('activeTab', 'professional_info'); // Set to open "Professional Info" tab
    localStorage.setItem('optionSelected', optionSelected); // set the selected option
    window.location = '/user-profiles/provider_config'; // Navigate to the provider_config page
  };

  return (
    <>
      <div style={{ marginTop: '110px', marginRight: '-110px' }}>
        {isDoctor(currentUser) && (
          <div>
            {/* Existing section for attestation reminder */}

            <div
              className="clearfix responsive-border-box"
              style={{
                border: '1px solid #000',
                borderRadius: '5px',
                padding: '10px',
                marginTop: '10px',
                maxWidth: '28vw',
                marginLeft: 'auto',
                backgroundColor: '#f0f0f0',
              }}
            >
              <div className="row align-items-center">
                <div className="col-md-1">
                  <ScheduleIcon />
                </div>
                <div className="col-md-7">
                  <div
                    className="heading3-black"
                    style={{ fontWeight: 'normal', letterSpacing: '0' }}
                  >
                    {getAttestationDueDaysText()} <br />
                    {getLastAttestationText()}
                  </div>
                </div>
                <div className="col-md-4 text-right d-flex align-items-center justify-content-end">
                  <ThemeProvider theme={tooltipTheme}>
                    <Tooltip
                      title={
                        shouldDisableAttestButton()
                          ? 'The button is not enabled and will be enabled and clickable when the next attestation is due.'
                          : ''
                      }
                      placement="top"
                    >
                      <button
                        className="btn-style"
                        disabled={shouldDisableAttestButton()} // Disables the button based on the presence of last attestation
                        onClick={() => setShow(true)}
                        id="Review___Attest"
                        style={{
                          backgroundColor: shouldDisableAttestButton()
                            ? '#CCCCCC'
                            : '#990066', // Grey when disabled, original color when enabled
                          color: '#FFFFFF',
                          borderRadius: '0',
                          padding: '5px 10px',
                          fontSize: '14px',
                          lineHeight: '1.5',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        Review & Attest
                      </button>
                    </Tooltip>
                  </ThemeProvider>
                </div>
              </div>
            </div>

            <div
              className="clearfix responsive-border-box"
              style={{
                border: '1px solid #000',
                borderRadius: '5px',
                padding: '10px',
                marginTop: '10px',
                maxWidth: '28vw',
                marginLeft: 'auto',
                backgroundColor: '#f0f0f0',
              }}
            >
              <div className="row align-items-center">
                <div className="col-md-1">
                  <ScheduleIcon />
                </div>
                <div className="col-md-7">
                  <div
                    className="heading3-black"
                    style={{ fontWeight: 'normal', letterSpacing: '0' }}
                  >
                    {getRosterDueDaysText()} <br />
                    {getLastRosterText()}
                    <br />
                  </div>
                </div>

                <div className="col-md-4 text-right d-flex align-items-center justify-content-end">
                  <ThemeProvider theme={tooltipTheme}>
                    <Tooltip
                      title={
                        shouldDisableAttestButton()
                          ? 'The button is not enabled and will be enabled and clickable when the next attestation is due.'
                          : ''
                      }
                      placement="top"
                    >
                      <span>
                        <button
                          className="btn-style"
                          disabled={checkRosterButtonStatus()}
                          onClick={() => handleRosterSubmission()}
                          style={{
                            backgroundColor: checkRosterButtonStatus()
                              ? '#CCCCCC'
                              : '#990066', // faded when checkRosterButtonStatus() is true
                            color: '#FFFFFF',
                            borderRadius: '0',
                            padding: '5px 10px',
                            fontSize: '14px',
                            lineHeight: '1.5',
                          }}
                        >
                          Complete Monthly Rosters
                        </button>
                      </span>
                    </Tooltip>
                  </ThemeProvider>
                </div>
              </div>
            </div>

            {/* Below is just for UI Purposes */}

            {shouldShowMDLicenseReminder() && (
              <div
                className="clearfix responsive-border-box"
                style={{
                  border: '1px solid #000',
                  borderRadius: '5px',
                  padding: '10px',
                  marginTop: '10px',
                  maxWidth: '28vw',
                  marginLeft: 'auto',
                  backgroundColor: '#f0f0f0',
                }}
              >
                <div className="row align-items-center">
                  <div className="col-md-1">
                    <ScheduleIcon />
                  </div>
                  <div className="col-md-7">
                    <div
                      className="heading3-black"
                      style={{ fontWeight: 'normal', letterSpacing: '0' }}
                    >
                      {getLicenseDueDaysText()} <br />
                      Upcoming Expiration:{' '}
                      {marylandLicenseExpiration
                        ? moment
                            .utc(marylandLicenseExpiration)
                            .format('MMM DD, yyyy')
                        : 'N/A'}
                    </div>
                  </div>
                  <div className="col-md-4 text-right d-flex align-items-center justify-content-end">
                    <button
                      className="btn-style"
                      onClick={() =>
                        // passing maryland in the below Don't remove or change anything
                        handleUpdateLicenseMalpracticeClick('MDLicense')
                      }
                      style={{
                        backgroundColor: '#990066',
                        color: '#FFFFFF',
                        borderRadius: '0',
                        padding: '5px 10px',
                        fontSize: '14px',
                        lineHeight: '1.5',
                      }}
                    >
                      Update Maryland License
                    </button>
                  </div>
                </div>
              </div>
            )}

            {shouldShowOutOfStateLicenseReminder() && (
              <div
                className="clearfix responsive-border-box"
                style={{
                  border: '1px solid #000',
                  borderRadius: '5px',
                  padding: '10px',
                  marginTop: '10px',
                  maxWidth: '28vw',
                  marginLeft: 'auto',
                  backgroundColor: '#f0f0f0',
                }}
              >
                <div className="row align-items-center">
                  <div className="col-md-1">
                    <ScheduleIcon />
                  </div>
                  <div className="col-md-7">
                    <div
                      className="heading3-black"
                      style={{ fontWeight: 'normal', letterSpacing: '0' }}
                    >
                      Your state license(s) expire soon for the state(s) of:{' '}
                      {outOfStateLicenses
                        .map((license) => license.state)
                        .join(', ')}
                      <br />
                      Upcoming Expirations:{' '}
                      {outOfStateLicenses
                        .map(
                          (license) =>
                            `${license.state}: ${moment
                              .utc(license.expirationDate)
                              .format('MMM DD, YYYY')}`
                        )
                        .join(', ')}
                    </div>
                  </div>
                  <div className="col-md-4 text-right d-flex align-items-center justify-content-end">
                    <button
                      className="btn-style"
                      onClick={() =>
                        // passing OutOfStateLicenses in the below Don't remove or change anything
                        handleUpdateLicenseMalpracticeClick(
                          'outOfStateLicensure'
                        )
                      }
                      style={{
                        backgroundColor: '#990066',
                        color: '#FFFFFF',
                        borderRadius: '0',
                        padding: '5px 10px',
                        fontSize: '14px',
                        lineHeight: '1.5',
                      }}
                    >
                      Update Out-of-State Licenses
                    </button>
                  </div>
                </div>
              </div>
            )}

            {shouldShowPsyPactLicenseReminder() && (
              <div
                className="clearfix responsive-border-box"
                style={{
                  border: '1px solid #000',
                  borderRadius: '5px',
                  padding: '10px',
                  marginTop: '10px',
                  maxWidth: '28vw',
                  marginLeft: 'auto',
                  backgroundColor: '#f0f0f0',
                }}
              >
                <div className="row align-items-center">
                  <div className="col-md-1">
                    <ScheduleIcon />
                  </div>
                  <div className="col-md-7">
                    <div
                      className="heading3-black"
                      style={{ fontWeight: 'normal', letterSpacing: '0' }}
                    >
                      Your PsyPact license expires soon: <br />
                      Upcoming Expiration:{' '}
                      {psyPactLicenseExpiration
                        ? moment
                            .utc(psyPactLicenseExpiration)
                            .format('MMM DD, YYYY')
                        : 'N/A'}
                    </div>
                  </div>
                  <div className="col-md-4 text-right d-flex align-items-center justify-content-end">
                    <button
                      className="btn-style"
                      onClick={() =>
                        // passing PsyPactLicense in the below Don't remove or change anything
                        handleUpdateLicenseMalpracticeClick('PsyPact')
                      }
                      style={{
                        backgroundColor: '#990066',
                        color: '#FFFFFF',
                        borderRadius: '0',
                        padding: '5px 10px',
                        fontSize: '14px',
                        lineHeight: '1.5',
                      }}
                    >
                      Update PsyPact License
                    </button>
                  </div>
                </div>
              </div>
            )}

            {shouldShowLiabilityInsuranceReminder() && (
              <div
                className="clearfix responsive-border-box"
                style={{
                  border: '1px solid #000',
                  borderRadius: '5px',
                  padding: '10px',
                  marginTop: '10px',
                  maxWidth: '28vw',
                  marginLeft: 'auto',
                  backgroundColor: '#f0f0f0',
                }}
              >
                <div className="row align-items-center">
                  <div className="col-md-1">
                    <ScheduleIcon />
                  </div>
                  <div className="col-md-7">
                    <div
                      className="heading3-black"
                      style={{ fontWeight: 'normal', letterSpacing: '0' }}
                    >
                      Your liability insurance expires soon: <br />
                      Upcoming Expiration:{' '}
                      {liabilityInsuranceExpiration
                        ? moment
                            .utc(liabilityInsuranceExpiration)
                            .format('MMM DD, YYYY')
                        : 'N/A'}
                    </div>
                  </div>
                  <div className="col-md-4 text-right d-flex align-items-center justify-content-end">
                    <button
                      className="btn-style"
                      onClick={() =>
                        // passing LiabilityInsurnace in the below Don't remove or change anything
                        handleUpdateLicenseMalpracticeClick('liability')
                      }
                      style={{
                        backgroundColor: '#990066',
                        color: '#FFFFFF',
                        borderRadius: '0',
                        padding: '5px 10px',
                        fontSize: '14px',
                        lineHeight: '1.5',
                      }}
                    >
                      Update Liability Insurance
                    </button>
                  </div>
                </div>
              </div>
            )}

            {/* Above is just for UI Purposes */}

            {showDipSubmission && (
              <div
                className="clearfix responsive-border-box"
                style={{
                  border: '1px solid #000',
                  borderRadius: '5px',
                  padding: '10px',
                  marginTop: '10px',
                  maxWidth: '28vw',
                  marginLeft: 'auto',
                  backgroundColor: '#f0f0f0',
                }}
              >
                <div className="row align-items-center">
                  <div className="col-md-1">
                    <ScheduleIcon />
                  </div>
                  <div className="col-md-7">
                    <div
                      className="heading3-black"
                      style={{ fontWeight: 'normal', letterSpacing: '0' }}
                    >
                      Your DIP Submission is due <br />
                      <br />
                    </div>
                  </div>
                  <div className="col-md-4 text-right d-flex align-items-center justify-content-end">
                    <button
                      className="btn-style"
                      onClick={() => handleDIPSubmission()}
                      style={{
                        backgroundColor: '#990066',
                        color: '#FFFFFF',
                        borderRadius: '0',
                        padding: '5px 10px',
                        fontSize: '14px',
                        lineHeight: '1.5',
                      }}
                    >
                      Complete DIP Submission
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>

      {/* Profile card section */}
      <div>
        <div className="row">
          <div className="col-md-4 animated fadeIn">
            <div
              style={{
                position: 'absolute',
                marginLeft: isMobile ? '0px' : '-700px',
              }}
              className="card responsive-card"
            >
              <div className="card-body">
                <div className="avatar">
                  <img
                    src={
                      currentUser?.imageUrl ||
                      'https://img.freepik.com/free-vector/doctor-character-background_1270-84.jpg'
                    }
                    // This is bootStrap class
                    className="card-img-top"
                    alt=""
                    onError={(e) => {
                      e.target.onerror = null; // Prevents infinite loop in case the default image fails to load
                      e.target.src =
                        'https://img.freepik.com/free-vector/doctor-character-background_1270-84.jpg';
                    }}
                  />
                </div>
                <h5 className="card-title">
                  {currentUser &&
                    `Welcome, ${currentUser.username.split(' ')[0]}.`}
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Attestation Dialog */}
      <Dialog
        open={show}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Attestation'}</DialogTitle>
        <DialogContent className="DialogContent">
          <Typography variant="body1">
            {confirmContent({ therapy, testing, personalInfo })}
          </Typography>
        </DialogContent>
        <DialogActions className="DialogActions">
          <Button onClick={() => handleAttestationConfirm()}>Ok</Button>
          <Button onClick={() => setShow(false)}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default memo(PersonalInfo);
