import React, { useState } from 'react';
import {
  Tabs,
  Tab,
  TableContainer,
  Switch,
  FormControlLabel,
} from '@mui/material';
import AdminGeneralTable from './AdminGeneralTable';
import AdminPracticeInfoTable from './AdminPracticeInfoTable';

const TabLabelWithDescription = ({ title, description }) => (
  <div style={{ textAlign: 'center' }}>
    <div style={{ fontSize: '1rem' }}>{title}</div>{' '}
    {/* Larger font size for title */}
    <div className="tabDescription">{description}</div>{' '}
    {/* Smaller font size for description */}
  </div>
);

export default function Admin() {
  const [showActiveAdmins, setShowActiveAdmins] = useState(true);
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    localStorage.removeItem('activeTab');

    setActiveTab(newValue);

    // Assuming the sidebar is only meant to be visible on the first tab (index 0)
    if (newValue !== 0) {
      // Hide the sidebar and remove the class when switching away from the first tab
    }
  };

  return (
    <div>
      <div
        className="tabs-container"
        style={{ position: 'sticky', top: 60, zIndex: 1000 }}
      >
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          aria-label="client tabs"
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          style={{
            paddingTop: '10px',
            background: '#F5F5F5',
          }}
        >
          <Tab
            label={
              <TabLabelWithDescription
                title="Personal Info"
                description="This includes Name, Email, DOB, etc"
              />
            }
            className={activeTab === 0 ? 'customTabSelected' : ''}
            classes={{ root: 'customTabRoot' }}
          />

          <Tab
            label={
              <TabLabelWithDescription
                title="Admin Practice Info "
                description="HIPAA Workforce, LLC Info, etc"
              />
            }
            className={activeTab === 4 ? 'customTabSelected' : ''}
            classes={{ root: 'customTabRoot' }}
          />
        </Tabs>
      </div>

      <TableContainer>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            margin: '10px',
          }}
        >
          <FormControlLabel
            control={
              <Switch
                checked={showActiveAdmins}
                onChange={() => {
                  setShowActiveAdmins(!showActiveAdmins);
                  console.log(
                    'Switch toggled, showActiveAdmins:',
                    !showActiveAdmins
                  );
                }}
                name="showActiveAdmins"
              />
            }
            label={showActiveAdmins ? 'Active Admins' : 'Inactive Admins'}
          />
        </div>
      </TableContainer>

      {activeTab === 0 && (
        <AdminGeneralTable showActiveAdmins={showActiveAdmins} />
      )}
      {activeTab === 1 && (
        <AdminPracticeInfoTable showActiveAdmins={showActiveAdmins} />
      )}
    </div>
  );
}
