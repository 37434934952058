import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip, Button, IconButton } from '@mui/material';
import { logout } from '../../actions/auth';
import { setDIPBadgeNumbers } from '../../actions/clients';
import { SET_MESSAGE } from '../../actions/types';
import {
  ArrowDownward,
  ArrowUpward,
  FileCopy as FileCopyIcon,
  HelpOutline as HelpOutlineIcon,
} from '@mui/icons-material';
import moment from 'moment';
import { isAdmin } from '../../common/utility';
import { clientService, providerService } from '../../services';
import '../../styles/DIP.css';

const sampleNote =
  "Client has not been responsive since ____. A discharge letter was mailed to client on ____ (Discharge letter is in document section of chart) Client has not contacted this writer to date and consequently client's chart is being changed to inactive status.";

const isDatePassedTenDays = (selectedDate) => {
  if (!selectedDate) {
    return true;
  }
  const currentDate = new Date();
  const selectedDateObj = new Date(selectedDate);
  const timeDifference = currentDate - selectedDateObj;
  const daysDifference = timeDifference / (1000 * 60 * 60 * 24);
  return daysDifference >= 10;
};

export default function DIP() {
  const dispatch = useDispatch();
  const [sortColumn, setSortColumn] = useState('Provider');
  const [radioSelections, setRadioSelections] = useState({});
  const [sortDirection, setSortDirection] = useState('asc');
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(false);
  const [providers, setProviders] = useState([]);
  const [expandedRows] = useState({});
  const [selectedProvider, setSelectedProvider] = useState({});
  const [displayedClients, setDisplayedClients] = useState(clients);
  const [selectedStatus] = useState(null);
  const [confirmationModalOpen] = useState(false);
  const [confirmedSelections, setConfirmedSelections] = useState({});
  const [isAllConfirmedModalVisible, setIsAllConfirmedModalVisible] =
    useState(false);
  const [specificConfirmationModalOpen, setSpecificConfirmationModalOpen] =
    useState(false);
  const [successMessage] = useState('');
  const [modalMessageType, setModalMessageType] = useState('');
  const [, setBlackBadgeNumber] = useState(0);
  const [, setRedBadgeNumber] = useState(0);
  const [dateSelections, setDateSelections] = useState({});

  const [users] = React.useState([]);
  const [, setSelectedUser] = React.useState();
  const [, setSelectedUserDetail] = React.useState();
  const [selectedClientId, setSelectedClientId] = useState(null);
  const [specificRadioSelection, setSpecificRadioSelection] = useState();
  const [isCopied, setIsCopied] = useState(false);
  const [successMessageTrigger, setSuccessMessageTrigger] = useState(false);
  const [clientProviderId, setClientProviderId] = useState();
  // The above state contains the providerId for a client. This state has been made to cater Admin adding Date

  const { user: currentUser } = useSelector((state) => state.auth);

  const sortClients = (clients) => {
    if (!Array.isArray(clients)) return [];

    return [...clients].sort((a, b) => {
      let primaryValA, primaryValB, secondaryValA, secondaryValB;

      switch (sortColumn) {
        case 'Provider':
          primaryValA = a.PROVIDER.split(' ')[a.PROVIDER.split(' ').length - 2]; // Sorting by last name
          primaryValB = b.PROVIDER.split(' ')[b.PROVIDER.split(' ').length - 2];
          secondaryValA = `${a.LASTNAME} ${a.FIRSTNAME}`;
          secondaryValB = `${b.LASTNAME} ${b.FIRSTNAME}`;
          break;
        case 'Name':
          primaryValA = a.LASTNAME;
          primaryValB = b.LASTNAME;
          secondaryValA = a.FIRSTNAME;
          secondaryValB = b.FIRSTNAME;
          break;
        case 'Status':
          primaryValA = a.STATUS;
          primaryValB = b.STATUS;
          break;
        case 'Therapist':
          primaryValA = a.PROVIDER.split(' ')[a.PROVIDER.split(' ').length - 2];
          primaryValB = b.PROVIDER.split(' ')[b.PROVIDER.split(' ').length - 2];
          break;
        default:
          return 0;
      }

      if (primaryValA < primaryValB) return sortDirection === 'asc' ? -1 : 1;
      if (primaryValA > primaryValB) return sortDirection === 'asc' ? 1 : -1;

      // Secondary sort logic (for Provider and Name)
      if (secondaryValA < secondaryValB) return -1;
      if (secondaryValA > secondaryValB) return 1;

      return 0;
    });
  };

  const calculateBadgeNumbers = () => {
    const totalRows = clients?.length; // This is the number for the black badge.
    const twoWeeksAgo = moment().subtract(10, 'days');
    const rowsOverTwoWeeks = clients?.filter((client) => {
      const letterMailedDate = moment.utc(client.AWOL_DATE_MAILED);
      return letterMailedDate.isSameOrBefore(twoWeeksAgo, 'day');
    }).length; // This is the number for the red badge.

    setBlackBadgeNumber(totalRows);
    setRedBadgeNumber(rowsOverTwoWeeks);

    dispatch(setDIPBadgeNumbers(totalRows, rowsOverTwoWeeks));
  };

  const handleRadioChange = (clientId, selection, providerId) => {
    setSpecificRadioSelection(selection);
    setRadioSelections({
      [clientId]: selection,
    });
    setSelectedClientId(clientId); // Keep track of the selected client ID

    // Open the specific confirmation modal for these columns
    setSpecificConfirmationModalOpen(true);
    setModalMessageType(selection);
    setClientProviderId(providerId);
  };

  const handleProviderChange = (event) => {
    const providerId = Number(event.target.value); // Convert to Number if your IDs are numerical
    const provider = providers.find((p) => p.id === providerId);
    setSelectedProvider(provider || { id: 1001 });
  };

  const handleDateChange = (dataObj, newDate) => {
    setDateSelections({
      [dataObj.tamrn]: newDate,
    });
    setClientProviderId(dataObj.providerId);
  };

  const handleConfirmSubmit = async () => {
    setConfirmedSelections((prev) => ({ ...prev, [selectedClientId]: true }));
    setSpecificConfirmationModalOpen(false);
    setSelectedClientId(null);
    if (
      specificRadioSelection !== 'remained_awol' &&
      specificRadioSelection !== 'returned'
    ) {
      await handleAddAdminNameToClient({
        MRN: Object.keys(dateSelections)[0],
        dateAdded: Object.values(dateSelections)[0],
        adminName: currentUser.username,
        providerId:
          selectedProvider.id !== 1001 ? selectedProvider.id : clientProviderId, // passing providerID to backend
      });
    } else if (specificRadioSelection === 'remained_awol') {
      await clientService.addClientToStillAwol({
        MRN: Object.keys(radioSelections)[0], //MRN
        providerId:
          selectedProvider.id !== 1001 ? selectedProvider.id : clientProviderId, // passing providerID to backend
      });
    } else if (specificRadioSelection === 'returned') {
      await clientService.addClientToReturned({
        MRN: Object.keys(radioSelections)[0], //MRN
        providerId:
          selectedProvider.id !== 1001 ? selectedProvider.id : clientProviderId, // passing providerID to backend
      });
    }
    handleCombinedSubmit(); //Calling it at the end
  };

  const handleCancelConfirmation = () => {
    setRadioSelections((prev) => ({ ...prev, [selectedClientId]: '' }));
    setSpecificConfirmationModalOpen(false);
    setSelectedClientId(null);
  };

  const handleAddAdminNameToClient = async (updatedBody) => {
    await clientService.addAdminNameToClient(updatedBody);
  };

  useEffect(() => {
    if (Object.keys(dateSelections).length !== 0) {
      setSpecificConfirmationModalOpen(true);
    }
  }, [dateSelections]);

  useEffect(() => {
    if (isAdmin(currentUser)) {
      if (selectedProvider.id === 1001) {
        setLoading(true); // Start loading only after 'ALL' is selected
        clientService
          .getDIPAllClients() // Method assumed to fetch all clients for admin
          .then((response) => {
            const activeClients = response?.data?.clients;
            setClients(activeClients);
            setDisplayedClients(activeClients);
          })
          .catch((error) => {
            console.error('There was an error fetching all clients:', error);
            // here have to make user logout of the application
            dispatch({
              type: SET_MESSAGE,
              payload:
                'You have been logged out something went wrong. Please click on login button to login back!',
            });
            dispatch(logout(currentUser));
          })
          .finally(() => {
            setLoading(false); // Stop loading whether there was an error or not
          });
      } else if (selectedProvider.id) {
        setLoading(true); // Start loading only after a specific provider is selected
        clientService
          .getClientsByProvider(selectedProvider.id)
          .then((response) => {
            const activeAwolClients = response?.data?.clients?.filter(
              (client) =>
                client?.STATUS === 'Active' && client?.AWOL_CLIENT === 1
            );
            setClients(activeAwolClients);
            setDisplayedClients(activeAwolClients);
          })
          .catch((error) => {
            console.error(
              'There was an error fetching the clients for the selected provider:',
              error
            );
            // here have to make user logout of the application
            dispatch({
              type: SET_MESSAGE,
              payload:
                'You have been logged out something went wrong. Please click on login button to login back!',
            });
            dispatch(logout(currentUser));
          })
          .finally(() => {
            setLoading(false);
          });
      }
      // Note: No else clause to setLoading(true) here, as we don't want to show loading when nothing is selected
    } else {
      // If a provider is logged in, show loading immediately since their clients are being fetched
      setLoading(true);
      clientService
        .getMyClients() // Adjust according to your method names
        .then((response) => {
          const activeClients = response.data.clients.filter(
            (client) => client.AWOL_CLIENT && client.STATUS === 'Active'
          );
          setClients(activeClients);
          setDisplayedClients(activeClients);
        })
        .catch((error) => {
          console.error('There was an error fetching my clients:', error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProvider, successMessageTrigger, currentUser]);

  useEffect(() => {
    // Initialize confirmed selections when displayedClients changes
    const initialConfirmedSelections = displayedClients?.reduce(
      (acc, client) => {
        acc[client.ta_mrn] = false; // Assuming ta_mrn is your unique identifier
        return acc;
      },
      {}
    );

    setConfirmedSelections(initialConfirmedSelections);
  }, [displayedClients]);

  useEffect(() => {
    providerService.getProviders().then(
      (response) => {
        let providers = response?.data?.providers ?? [];
        // Extract last name by finding the word before the comma
        if (providers?.length > 0) {
          providers.sort((a, b) => {
            const lastNameA = (a?.provider_name || '')
              .split(', ')[0]
              .split(' ')
              .slice(-1)[0]
              .toLowerCase();

            const lastNameB = (b?.provider_name || '')
              .split(', ')[0]
              .split(' ')
              .slice(-1)[0]
              .toLowerCase();

            return lastNameA.localeCompare(lastNameB);
          });
        }
        setProviders(providers);
      },
      (error) => {
        // Handle any errors here
        console.error('Error fetching providers:', error);
      }
    );
  }, []);

  useEffect(() => {
    if (selectedProvider?.id !== 1001) {
      if (isAdmin(currentUser) && selectedProvider?.id) {
        setLoading(true);
        setClients([]); // Clear out the currently displayed clients
        setDisplayedClients([]); // Clear out the currently displayed clients

        clientService
          .getClientsByProvider(selectedProvider.id)
          .then((response) => {
            const activeAwolClients = response?.data?.clients?.filter(
              (client) =>
                client?.STATUS === 'Active' && client?.AWOL_CLIENT === 1
            );
            setClients(activeAwolClients);
            setDisplayedClients(activeAwolClients);
          })
          .catch((error) => {
            console.error(
              'There was an error fetching the clients for the selected provider:',
              error
            );
            // here have to make user logout of the application
            dispatch({
              type: SET_MESSAGE,
              payload:
                'You have been logged out something went wrong. Please click on login button to login back!',
            });
            dispatch(logout(currentUser));
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        // Clear the client list if no provider is selected or if the user is not an admin
        setClients([]);
        setDisplayedClients([]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser, selectedProvider]);

  useEffect(() => {
    calculateBadgeNumbers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clients]);

  useEffect(() => {
    if (users.length > 1) {
      // not a doctor. so return
      return;
    }
    if (currentUser.roles.indexOf('ROLE_DOCTOR') > -1) {
      onTagsChange(null, users[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users]);
  const onTagsChange = async (event, values) => {
    let enObj = users.find((obj) => {
      return obj?.id === values?.id;
    });
    setSelectedUser(enObj);
    if (enObj?.id) {
      const providerDetail = await providerService.getProviderDetail(
        enObj.id,
        enObj.email
      );
      if (providerDetail && providerDetail.data) {
        providerDetail.data.provider.username = enObj.username;
        setSelectedUserDetail(providerDetail?.data?.provider);
      }
    }
  };

  useEffect(() => {
    if (!isAdmin(currentUser)) {
      setSelectedProvider({
        ...currentUser,
        provider_name: currentUser.username,
      });
    }
  }, [currentUser]);

  const toggleSort = (column) => {
    if (sortColumn === column) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortDirection('asc');
    }
  };

  const handleCombinedSubmit = async () => {
    try {
      // Prepare data for updating new columns in the database.
      // This involves mapping the current selections to a format suitable for the backend API.
      const selectedClient = displayedClients.find(
        (displayedClient) => displayedClient.ta_mrn
      );
      const clientUpdates = Object.entries(radioSelections).map(
        ([clientId, selection]) => {
          return {
            clientId: clientId,
            providerId:
              selectedProvider.id !== 1001
                ? selectedProvider.id
                : clientProviderId,
            establishedClient: selection === 'established',
            futureClient: selection === 'future',
            awolClient: selection === 'awol',
            neverShowedClient: selection === 'never_showed',
            agreedTerminationClient: selection === 'agreed_termination',
            remainedAwolClient: selection === 'remained_awol',
            returnedClient: selection === 'returned',
            letterMailedOn:
              dateSelections[clientId] ||
              selectedClient.AWOL_DATE_MAILED ||
              null,
            changeStatusToInactive: selection === 'agreed_termination',
            // Additional statuses can be added here as needed.
          };
        }
      );

      // Send the updates to the backend using the clientService.
      console.log('Before sending updates to backend');
      await clientService.updateClientColumns(clientUpdates);
      console.log('After sending updates to backend');

      // After successful submission, close the modal and show a success message.
      setIsAllConfirmedModalVisible(false); // This will close the modal

      setSuccessMessageTrigger(
        (successMessageTrigger) => successMessageTrigger + 1
      ); // Setting true for the above success message trigger so that success message can be displayed
    } catch (error) {
      console.log('Error in handleCombinedSubmit:', error);
      // Handle any errors that occur during the submission process.
      // This could include displaying error messages to the user.
    } finally {
      console.log('Completed handleCombinedSubmit');
      // Any final cleanup can be performed here.
    }
  };

  return (
    <div>
      {isAdmin(currentUser) && (
        <select
          value={selectedProvider?.id || ''}
          onChange={handleProviderChange}
        >
          <option value="">Please Select</option>
          <option key={1001} value={1001}>
            ALL
          </option>

          {providers
            .filter(
              (provider) =>
                provider.provider_name && provider.provider_name.trim() !== ''
            )
            .map((provider) => (
              <option key={provider.id} value={provider.id}>
                {provider.provider_name}
              </option>
            ))}
        </select>
      )}

      {loading ? (
        <p>Loading...</p>
      ) : (
        <>
          <div className="mobile-dip-list">
            {/* This will be toggled based on screen size */}
            {sortClients(displayedClients).map((client) => (
              <div
                key={client.ta_mrn + client.provider_id}
                className={
                  confirmedSelections[client.ta_mrn] ||
                  ['remained_awol', 'returned'].includes(
                    radioSelections[client.ta_mrn]
                  )
                    ? 'highlighted-row'
                    : ''
                }
              >
                <div className="dip-client-name">
                  {`${client.FIRSTNAME} ${client.LASTNAME}`}
                </div>

                {expandedRows[client.ta_mrn] && (
                  <div className="dip-client-details">
                    {/* Conditional rendering for Provider name */}
                    {isAdmin(currentUser) && (
                      <div className="dip-client-provider">
                        <strong>Provider:</strong> {client.PROVIDER}
                      </div>
                    )}
                    <div className="centered-content">
                      <strong>Letter Mailed On:</strong>
                      <input
                        type="date"
                        className="date-picker-input"
                        value={
                          dateSelections[client.ta_mrn]
                            ? new Date(dateSelections[client.ta_mrn])
                                .toISOString()
                                .split('T')[0]
                            : ''
                        }
                        onChange={(e) =>
                          handleDateChange(
                            {
                              tamrn: client.ta_mrn,
                              providerId: client.provider_id,
                            },
                            e.target.value
                          )
                        }
                        disabled={!isAdmin(currentUser)} // Assuming only admin can edit
                      />
                    </div>
                    <div className="centered-content">
                      <strong>Admin:</strong> {client.AdminMailed}
                    </div>
                    <div
                      className="centered-content"
                      onClick={() => {
                        if (
                          !client.AWOL_DATE_MAILED ||
                          !isDatePassedTenDays(client.AWOL_DATE_MAILED)
                        ) {
                          alert(
                            'This button is disabled until the date has been entered by admin for "Letter Mailed On..." and 10 days have passed since that date.'
                          );
                        }
                      }}
                    >
                      <input
                        type="radio"
                        name={`client-selection-${client.ta_mrn}`}
                        checked={
                          radioSelections[client.ta_mrn] === 'remained_awol'
                        }
                        disabled={
                          !client.AWOL_DATE_MAILED ||
                          !isDatePassedTenDays(client.AWOL_DATE_MAILED)
                        }
                        onChange={() =>
                          handleRadioChange(
                            client.ta_mrn,
                            'remained_awol',
                            client.provider_id
                          )
                        }
                      />{' '}
                      Still AWOL
                    </div>
                    <div className="centered-radio">
                      <strong>Client Returned:</strong>
                      <input
                        type="checkbox"
                        checked={radioSelections[client.ta_mrn] === 'returned'}
                        onChange={() =>
                          handleRadioChange(
                            client.ta_mrn,
                            'returned',
                            client.provider_id
                          )
                        }
                      />
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>

          <table border="1">
            <thead>
              <tr>
                <th onClick={() => toggleSort('Name')}>
                  {/* Removed the outer div wrapping the content */}
                  Name
                  <ArrowUpward
                    style={{
                      color:
                        sortColumn === 'Name' && sortDirection === 'asc'
                          ? 'black'
                          : 'lightgray',
                    }}
                  />
                  <ArrowDownward
                    style={{
                      color:
                        sortColumn === 'Name' && sortDirection === 'desc'
                          ? 'black'
                          : 'lightgray',
                    }}
                  />
                </th>

                {/* Conditional Column: Provider */}
                {isAdmin(currentUser) && selectedProvider?.id === 1001 && (
                  <th>Provider</th>
                )}

                <th>
                  {/* Removed the outer div wrapping the content */}
                  Letter Mailed On...
                  <Tooltip
                    title="Your Client Still Remained AWOL"
                    sx={{ '.MuiTooltip-tooltip': { fontSize: '1.1em' } }} // Apply styling directly
                  >
                    <IconButton aria-label="help">
                      <HelpOutlineIcon />
                    </IconButton>
                  </Tooltip>
                </th>

                <th>Admin</th>

                <th>
                  {/* Removed the outer div wrapping the content */}
                  Still AWOL
                  <Tooltip title="Your Client Still Remained AWOL">
                    <IconButton aria-label="help">
                      <HelpOutlineIcon />
                    </IconButton>
                  </Tooltip>
                </th>

                <th>
                  {/* Removed the outer div wrapping the content */}
                  Client Returned
                  <Tooltip title="Has Your Client Has Returned">
                    <IconButton aria-label="help">
                      <HelpOutlineIcon />
                    </IconButton>
                  </Tooltip>
                </th>
              </tr>
            </thead>

            <tbody>
              {sortClients(displayedClients).map((client) => (
                <tr
                  key={client.ta_mrn}
                  className={
                    confirmedSelections[client.ta_mrn] ||
                    ['remained_awol', 'returned'].includes(
                      radioSelections[client.ta_mrn]
                    )
                      ? 'highlighted-row'
                      : ''
                  }
                >
                  <td style={{ fontSize: '1.2em' }}>
                    {`${client.FIRSTNAME} ${client.LASTNAME}`}
                  </td>

                  {/* Conditional rendering for the Provider data */}
                  {isAdmin(currentUser) && selectedProvider?.id === 1001 && (
                    <td style={{ fontSize: '1.2em' }}>{client.PROVIDER}</td>
                  )}

                  <td className="centered-radio">
                    <span>
                      <input
                        type="date"
                        value={
                          dateSelections[client.ta_mrn]
                            ? new Date(dateSelections[client.ta_mrn])
                                .toISOString()
                                .split('T')[0]
                            : client.AWOL_DATE_MAILED
                            ? new Date(client.AWOL_DATE_MAILED)
                                .toISOString()
                                .split('T')[0]
                            : ''
                        }
                        className="date-picker-input"
                        disabled={
                          !isDatePassedTenDays(client.AWOL_DATE_MAILED) ||
                          !isAdmin(currentUser)
                        }
                        onChange={(e) =>
                          handleDateChange(
                            {
                              tamrn: client.ta_mrn,
                              providerId: client.provider_id,
                            },
                            e.target.value
                          )
                        }
                      />
                    </span>
                  </td>

                  <td style={{ fontSize: '1.2em' }}>{client.AdminMailed}</td>

                  <td className="centered-radio">
                    <Tooltip
                      title={
                        !client.AWOL_DATE_MAILED ||
                        !isDatePassedTenDays(client.AWOL_DATE_MAILED)
                          ? 'This button is disabled until the date has been entered by admin for Letter Mailed On... and 10 days have passed since that date.'
                          : ''
                      }
                    >
                      <span>
                        <input
                          type="radio"
                          checked={
                            radioSelections[client.ta_mrn] ===
                              'remained_awol' &&
                            client.AWOL_DATE_MAILED &&
                            isDatePassedTenDays(client.AWOL_DATE_MAILED)
                          }
                          disabled={
                            !client.AWOL_DATE_MAILED ||
                            !isDatePassedTenDays(client.AWOL_DATE_MAILED)
                          }
                          onChange={() =>
                            handleRadioChange(
                              client.ta_mrn,
                              'remained_awol',
                              client.provider_id
                            )
                          }
                        />
                      </span>
                    </Tooltip>
                  </td>

                  <td className="centered-radio">
                    <input
                      type="radio"
                      checked={radioSelections[client.ta_mrn] === 'returned'}
                      onChange={() =>
                        handleRadioChange(
                          client.ta_mrn,
                          'returned',
                          client.provider_id
                        )
                      }
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}

      {confirmationModalOpen && (
        <div className="confirmation-modal-backdrop">
          <div className="confirmation-modal">
            <p>
              In order to make this client "{selectedStatus}" please confirm
              that you have done the following:
            </p>
            <ul>
              <li>Written a closing summary in TA as a quick note</li>
              <li>Closed the status in TA to {selectedStatus}</li>
            </ul>
            <button onClick={handleConfirmSubmit}>
              I ACKNOWLEDGE I COMPLETED THESE STEPS
            </button>
            <button onClick={handleCancelConfirmation}>Cancel</button>
          </div>
        </div>
      )}

      <ConfirmationModal
        isVisible={specificConfirmationModalOpen}
        onConfirm={handleConfirmSubmit}
        onCancel={handleCancelConfirmation}
        modalMessageType={modalMessageType}
      />

      <AllConfirmedModal
        isVisible={isAllConfirmedModalVisible}
        onSubmit={handleCombinedSubmit}
      />

      {successMessage && (
        <div className="success-message">{successMessage}</div>
      )}
    </div>
  );

  function ConfirmationModal({
    isVisible,
    onConfirm,
    onCancel,
    modalMessageType,
  }) {
    if (!isVisible) return null;

    // This function determines the message to display based on the modalMessageType
    const getMessage = () => {
      switch (modalMessageType) {
        case 'never_showed':
          return <p>Confirm That You Have Made This Client InActive in TA?</p>;
        case 'agreed_termination':
          return (
            <div>
              <p>Confirm That</p>
              <ul>
                <li>You Have Written A Termination Summary in TA Notes?</li>
                <li>You Have Made This Client InActive in TA?</li>
              </ul>
            </div>
          );

        case 'remained_awol':
          const handleCopyToClipboard = () => {
            navigator.clipboard
              .writeText(sampleNote)
              .then(() => {
                setIsCopied(true);
                setTimeout(() => {
                  setIsCopied(false);
                }, 2000);
              })
              .catch((error) => {
                console.error('Failed to copy text: ', error);
              });
          };

          return (
            <div>
              <p
                style={{
                  fontWeight: 'bold',
                  textAlign: 'center',
                  fontSize: '1.2em',
                }}
              >
                Confirm all of the following:
              </p>
              <div style={{ height: '1em' }}></div>
              <ul>
                <li className="list-item">1. Your client remained AWOL</li>
                <li>2. You have made the client Inactive in TA</li>
                <li>
                  3. You have written a "quick note" in TA about the client
                  remaining AWOL.
                  <span
                    className="hover-text"
                    title={sampleNote} // Added title attribute for hover effect
                  >
                    Hover over here
                  </span>{' '}
                  to view a sample quick note that you can use.
                </li>
              </ul>
              <Button
                className="copy-button"
                variant="outlined"
                color="primary"
                onClick={handleCopyToClipboard}
                startIcon={<FileCopyIcon style={{ marginRight: 8 }} />} // Add margin to the icon
                sx={{
                  marginTop: '16px',
                  display: 'block',
                  margin: 'auto',
                  padding: '10px 20px', // Adjust padding for better spacing
                }}
              >
                {isCopied ? 'Copied!' : 'Copy Sample Quick Note'}
              </Button>
            </div>
          );

        default:
          return <p>Are you sure you want to confirm this selection?</p>;
      }
    };

    return (
      <div className="modal">
        <div className="modal-content">
          {getMessage()}
          <div className="modal-button-container">
            <button onClick={onConfirm} className="modal-button submit-button">
              Confirm
            </button>
            <button onClick={onCancel} className="modal-button cancel-button">
              Cancel
            </button>
          </div>
        </div>
      </div>
    );
  }
  function AllConfirmedModal({ isVisible, onSubmit }) {
    if (!isVisible) return null;

    return (
      <div className="confirmation-modal-backdrop">
        <div className="confirmation-modal">
          <p>
            All clients have been accounted for. Click the "Submit" button below
            to finalize and confirm your Active Rosters for this month.
          </p>
          <div className="modal-button-container">
            <button onClick={onSubmit} className="modal-button submit-button">
              Submit
            </button>
            <button
              onClick={() => setIsAllConfirmedModalVisible(false)}
              className="modal-button cancel-button"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    );
  }
}
