/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useState, useRef } from 'react';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { Col, Row } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  ArrowDownward as ArrowDownwardIcon,
  ArrowUpward as ArrowUpwardIcon,
  ExpandMore as ExpandMoreIcon,
  HelpOutline as HelpOutlineIcon,
} from '@mui/icons-material';
import * as MUIv6 from '@mui/material';
import '../../../src/App.css';
import { clearLoading, setLoading } from '../../actions/loading';
import { clearFormData, saveFormData } from '../../actions/scheduling';
import EventBus from '../../common/EventBus';
import {
  durations,
  entryTypeFormValuesValid,
  extractEmailAddress,
  isValidEmail,
  parseDockHealthPatientDetails,
  yesNoOptions,
  getDocsDue,
  compareSlotDateWithToday,
  isSpecialScenarioMet,
  filterDocuments,
  sortDocuments,
  replaceIndexWithZero,
  getActiveProviders,
} from '../../common/utility';
import ServiceDocuments from '../../services/docs.service';
import appointmentService from '../../services/appointment.service';
import dockService from '../../services/dock.service';
import providerService from '../../services/provider.service';
import userService from '../../services/user.service';
import carecoordinatorService from '../../services/carecoordinator.service';
import AddSchedulingModal from '../Modals/AddSchedulingModal/AddSchedulingModal';
import ApptSummaryModal from '../Modals/ApptSummaryModal/ApptSummaryModal';
import InQuirySchedulingModal from '../Modals/InQuirySchedulingModal/InQuirySchedulingModal';
import EntryTypeForm from '../EntryTypeForm';
import ProviderCard from '../Provider/ProviderCard';
import ServiceFeeDisplayComponent from '../UI/ServiceFeeDisplayComponent';
import {
  SchedulingStep01,
  SchedulingStep03,
  SchedulingStep03B,
  SchedulingStep03C,
  SchedulingStep04,
  SchedulingStep05,
  SchedulingStep06,
} from './';
import TopBanner from '../UI/TopBanner';
import IconKeyBox from '../UI/IconKeyBox';
import SnackBarSchedulingAlert from '../UI/SnackBarSchedulingAlert';
import CustomAlert from '../UI/CustomAlert';

const officeLocations = ['Silver Spring', 'Baltimore', 'Columbia'];

function getLocationAbbreviation(location) {
  if (!location) {
    return 'Remote';
  }
  switch (location) {
    case 'Baltimore':
      return 'Bal';
    case 'Silver Spring':
      return 'SS';
    case 'Columbia':
      return 'Col';
    case 'Remote':
      return 'Remote';
    default:
      return null;
  }
}

// The below function check the slot age which is more than 21 days old
const getOldestSlotAge = (provider) => {
  if (!provider?.slots || provider.slots.length === 0) return 0;

  const openSlots = provider.slots.filter((slot) => slot.type !== 'Hold');

  if (openSlots.length === 0) return 0;

  const oldestSlot = openSlots.reduce((oldest, slot) => {
    const slotAge = moment().diff(moment(slot.createdAt), 'days');
    return slotAge > oldest ? slotAge : oldest;
  }, 0);

  return oldestSlot;
};

export default function Scheduling() {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const state = useSelector((state) => state.scheduling);
  const [ccEmailCheck, setCcEmailCheck] = React.useState(false);
  const [isManual, setIsManual] = React.useState(false);
  const [showBanner, setShowBanner] = useState(false);
  const [entryTypeFormValues, setEntryTypeFormValues] = React.useState({
    entryType: 'new',
  });
  const [step01FormValues, setStep01FormValues] = React.useState({
    first_name: state?.firstName,
    last_name: state?.lastName,
    date: state?.date ? new Date(state?.date) : null,
    email: state?.email,
    phone: state?.phone,
  });
  const [underEighteenForm, setUnderEighteenForm] = React.useState(false);
  const [underEighteenFormResponse, setUnderEighteenFormResponse] =
    React.useState(null);

  const [step03FormValues, setStep03FormValues] = React.useState({
    therapyModalitySpecialization: 'Primary OR Borrow & Integrate',
    serviceType: state?.serviceType || null,
    serviceGroup: state?.serviceGroup || null,
    Insurance: state?.Insurance || null,
    services: state?.services || null,
    nameChecked: state?.nameChecked || null,
    pronounDetails: state?.pronounDetails || null,
    schedulingChecked: state?.schedulingChecked || null,
    unusalNote: state?.unusalNote || null,
    selectedHospital: state?.selectedHospital || null,
    surgeonName: state?.surgeonName || null,
    selectedSurgeon: state?.selectedSurgeon || null,
    nutritionDate: state?.nutritionDate || null,
    notScheduled: state?.notScheduled || null,
    medicationsTaken: state?.medicationsTaken || null,
    isMedicationTaken: state?.isMedicationTaken || null,
    additionalInsurance: state?.additionalInsurance || null,
  });
  const [userSessionDetails, setUserSessionDetails] = useState({
    isProBono: false,
    isAdoptions: false,
    isTurning18: false,
    isAdultADHD: false,
    isAdultAutism: false,
    isBariatricHopkins: false,
    isBariatricNOTHopkins: false,
    isCouplesInsurance: false,
    isCouplesPP: false,
    isFamiliesInsurance: false,
    isFamiliesPP: false,
    // Add other relevant session details as needed
  });
  const [selectedPrivatePayAmount, setSelectedPrivatePayAmount] =
    useState('Full Pay');
  const [step03BFormValues, setStep03BFormValues] = React.useState({
    suicidalThoughtsHistory: state?.suicidalThoughtsHistory,
    suicidalThoughtsType: state?.suicidalThoughtsType,
    suicidalThoughtsTime: state?.suicidalThoughtsTime,
    suicidalThoughtsTherapy: state?.suicidalThoughtsTherapy,
    selfHarmHistory: state?.selfHarmHistory,
    psycChange: state?.psychiatricHospitalizationHistory,
  });

  const serviceTypeRef = useRef(null);
  const insuranceRef = useRef(null);
  const settingRef = useRef(null);
  const locationRef = useRef(null);
  const licenseRef = useRef(null);
  const accordionRef1 = useRef(null); // Ref for the first accordion
  const accordionRef3 = useRef(null); // Ref for the second accordion
  const accordionRef5 = useRef(null); // Ref for the second accordion
  const accordionRef6 = useRef(null); // Ref for the second accordion
  const providerRef = useRef(null);

  useEffect(() => {
    if (state.selfHarm === 'In-Person Only') {
      setStep06FormValues({
        ...step06FormValues,
        range: '4 weeks',
        setting: ['Any Setting', 'In-Person Only'],
      });
    } else if (state.selfHarm === '') {
      setStep06FormValues({
        ...step06FormValues,
        range: '4 weeks',
        setting: ['Any Setting', 'Remote Only'],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step03BFormValues]);

  const [totalSortForBar, setTotalSotsForBar] = useState(0);
  const [step04FormValues, setStep04FormValues] = React.useState({
    aboutUsInfo: state?.aboutUsInfo || '',
    selectedInsurance: state?.selectedInsurance || '', // Initialize selectedInsurance

    selectedExternalTherapist: state?.selectedExternalTherapist || '',
    otherInsurance: state?.otherInsurance || '',
    otherExternalTherapist: state?.otherExternalTherapist || '',
  });

  const [step05FormValues, setStep05FormValues] = React.useState({});

  const [step06FormValues, setStep06FormValues] = React.useState({});
  const selectedServicesTitles =
    step03FormValues.services?.map((service) => service.title.trim()) || [];
  const [exceptionChecked, setExceptionChecked] = useState(false);
  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [age, setAge] = React.useState(null);
  const [reasonValue, setReasonValue] = React.useState('');
  const [serviceTesting, setServiceTesting] = React.useState(false);
  const [localFormValues, setLocalFormValues] = useState({
    ageLow: 18,
    ageHigh: 64,
  });
  const [users, setUsers] = React.useState([]);
  // thesetUsersFilter does not yield results
  const [usersFilter, setUsersFilter] = React.useState([]);
  const [totalNumOfAppoinntment, setTotalNumberOfAppointment] = useState(0);
  const [totalNumOfHolds, setTotalNumberOfHolds] = useState(0);
  const [priorityFlag, setPriorityFlag] = useState(false);
  const [showCustomAlert, setShowCustomAlert] = useState(false);
  const [showSchedulingStep03C, setShowSchedulingStep03C] = useState(
    state.showSchedulingStep03C || false
  );
  const [feeDetails, setFeeDetails] = useState({}); // State for fee details
  const [availableSlots, setAvailableSlots] = React.useState([]);
  const [availableGlobalSlots, setGlobalAvailableSlots] = React.useState([]);
  const [providersOptions, setProvidersOptions] = React.useState([]);
  const [selectedProviders, setSelectedProviders] = React.useState([]);
  const [providers, setProviders] = React.useState([]);
  const [globalUsers, setGlobalUsers] = useState(state.globalUsers || []);
  const [selectedProvidersNonScheduling, setSelectedProvidersNonScheduling] =
    React.useState([]);
  const [selectedDuration, setSelectedDuration] = React.useState([]);
  const [taskDropdownOptions, setTaskDropdownOptions] = useState([]);
  const [selectedStartDate, setSelectedStartDate] = useState();
  const [selectedEndDate, setSelectedEndDate] = useState();
  const [selectedProviderLicense, setSelectedProviderLicense] = useState(null); // Add this state
  const [isCardClickable, setIsCardClickable] = useState(false); // Add this state
  const [popoverState] = useState({
    open: false,
    text: null,
    anchorEl: null,
  });

  const [docFilters, setDocFilters] = useState({
    serviceGroup: '',
    serviceType: '',
    clientType: '',
    age: age,
    licenseStatus: '',
    specialScenario: '',
  });

  const [documents, setDocuments] = useState([]);

  const [selectedApptType, setSelectedApptType] = React.useState([]);
  const [taMrn, setTaMrn] = React.useState();
  const [selectedResult, setSelectedResult] = React.useState([]);
  const [selectedReason, setSelectedReason] = React.useState([]);
  const [dockHealthTasks, setDockHealthTasks] = React.useState([]);

  const [selectedOfficeLocation, setSelectedOfficeLocation] = React.useState(
    []
  );

  const [selectedSort, setSelectedSort] = React.useState('priorityAge');
  const [open, setOpen] = React.useState(false);
  const [apptDetailsOpen, setApptDetailsOpen] = useState(false);
  const [sortOrder, setSortOrder] = useState('Descending');
  const [frequency, setFrequency] = useState('all');
  const [exceptionText, setExceptionText] = useState(); // new useState
  const [selectedDate, setSelectedDate] = useState(null);
  const [, setOpenCancellation] = React.useState(false);
  const [sepcializedTherapyFilter, setSpecializedTherapyFilter] =
    React.useState(false);
  const [openLogNonScheduling, setOpenLogNonScheduling] = React.useState(false);
  const [selectedAvailableSlot, setSelectedAvailableSlot] = useState('');
  const [selectedSlotsForAppt, setSelectedSlotForAppt] = useState(undefined);
  const [activeProviders, setActiveProviders] = useState([]);
  const [totalAvailableSlots, setTotalAvailableSlots] = useState(0);
  const [formValues, setFormValues] = useState({ ageLow: 18, ageHigh: 64 });

  const [functionExecuted, setFunctionExecuted] = useState(false);

  const [expanded, setExpanded] = React.useState(true);
  const [shouldShowStep03B, setShouldShowStep03B] = useState(
    state?.serviceGroup != null && !state?.serviceGroup?.includes('Testing')
      ? true
      : false
  );
  const [suicidalThoughts, setSuicidalThoughts] = useState(yesNoOptions[0]);
  const [selfHarm, setSelfHarm] = useState(state?.selfHarm || yesNoOptions[0]);
  const [
    psychiatricHospitalizationHistory,
    setPsychiatricHospitalizationHistory,
  ] = useState(state?.psychiatricHospitalizationHistory || yesNoOptions[0]);
  const [riskQuestionsFilter, setRiskQuestionsFilter] = useState([]);
  const [trigger, setTrigger] = useState(false);
  const [allAnswered, setAllAnswered] = useState(false);
  const [specialConsiderationSelected, setSpecialConsiderationSelected] =
    useState(null);
  const [serviceType, setServiceType] = useState(state?.serviceGroup || '');
  const [nonFilteredAnswered, setNonFilteredAnswered] = useState(false);
  const [selectedProvider, setSelectedProvider] = useState();
  const [nonFilteredData, setNonFilteredData] = useState({
    reasonForService: state?.reasonForService || null,
    preferredSetting: state?.preferredSetting || null,
    followUpPreferredSetting: state?.followUpPreferredSetting || null,
  });
  const [riskQuestionDB, setRiskQuestionDB] = useState({
    'History of Suicidal Thoughts': '',
    'Suicidal Thoughts Details': '',
    'Time of Last Suicidal Thought': '',
    'What Type of Therapy Setting Are You Looking For?': '',
    'History of Self-Harm Within The Last 6 Months': '',
    'What Type of Setting for Therapy Are You Looking For?': '',
    'History of Psychiatric Hospitalization Within Last 6 Months': '',
  });
  const [careCoordinator, setCareCoordinator] = useState();
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [selectedDockHealthPatient, setSelectedDockHeathPatient] = useState();
  const [selectedDockHealthTaskId, setSelectedDockHealthTaskId] = useState(
    state?.dockHealthTaskId || null
  );
  const [mcLeanConditions, setMcLeanConditions] = useState(false); // This new state has been introduced to track developmental condition
  // The below State for managing activeCareCoordinators
  const [activeCareCoordinators, setActiveCareCoordinators] = useState();
  // The below State for maintaining SelectedCareCoordinator
  const [selectedCareCoordinator, setSelectedCareCoordinator] = useState();
  /*
  The below State for maintaning allUsers
  (It will fetch all the users from the database) and set the state
  */
  const [allUsers, setAllUsers] = useState();

  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setAlertOpen(false);
  };

  const handleToggle1 = (event) => {
    setExpanded(event.target.checked);
  };

  const handleUpdateFeeDetails = (newFeeDetails) => {
    setFeeDetails(newFeeDetails); // Update state with new fee details
  };

  // The handleUpdateSelectedProvider function will be passed down to ServiceFeeDisplayComponent
  const handleUpdateSelectedProvider = (license) => {
    setSelectedProviderLicense(license);
  };

  const toggleCardClickable = () => {
    setIsCardClickable((prev) => !prev);
  };

  const handlePopulationChange = (event, newValue) => {
    setLocalFormValues({ ageLow: newValue[0], ageHigh: newValue[1] });

    let filterProvider = [];
    for (const provider of providers) {
      if (provider && provider.therapy && provider.therapy.populationValue) {
        if (
          localFormValues.ageHigh <= provider.therapy.populationValue[1] &&
          localFormValues.ageLow >= provider.therapy.populationValue[0]
        ) {
          // Ensure provider has a slots property and it's an array
          provider.slots = provider.slots || [];
          filterProvider.push(provider);
        }
      }
    }

    let scannedUsers = [];
    for (let i = 0; i < usersFilter.length; i++) {
      if (
        filterProvider.find(
          (obj) => obj.provider_email === usersFilter[i].email
        )
      ) {
        scannedUsers.push(usersFilter[i]);
      }
    }
    let data = filterSlotsForSingle(availableSlots, scannedUsers);
    setUsersFilter([...data]);
  };

  const handlePrivatePayAmountChange = (newValue) => {
    setSelectedPrivatePayAmount(newValue);
    // You can also call any other logic here that needs to happen when this changes
  };

  const showLoading = useCallback(() => {
    dispatch(setLoading());
  }, [dispatch]);

  const hideLoading = useCallback(() => {
    dispatch(clearLoading());
  }, [dispatch]);

  useEffect(() => {
    // Update isProBono based on selectedPrivatePayAmount
    const isProBono = selectedPrivatePayAmount === 'ProBono';

    // Determine if the Adoptions, PP Couples & Families scenario is met
    const isAdoptionsPPCouplesAndFamilies =
      (step03FormValues.serviceType === 'Forensic Testing' &&
        selectedServicesTitles.includes('Adoption Evaluations')) ||
      (step03FormValues.serviceType === 'Couples' &&
        step03FormValues.Insurance === 'Private Pay');

    const isAdoptionsCouplesAndFamilies =
      (step03FormValues.serviceType === 'Forensic Testing' &&
        selectedServicesTitles.includes('Adoption Evaluations')) ||
      step03FormValues.serviceType === 'Couples';

    const isAdoptions =
      step03FormValues.serviceType === 'Forensic Testing' &&
      selectedServicesTitles.includes('Adoption Evaluations');

    const isTurning18 =
      entryTypeFormValues.existingClientOptions === 'Turning 18 years-Old';

    const isAdultADHD =
      step03FormValues.serviceType === 'Psychological Testing' &&
      selectedServicesTitles.includes('ADHD Adult Diagnostic Evaluation');

    const isAdultADHDAndAutism =
      step03FormValues.serviceType === 'Psychological Testing' &&
      (selectedServicesTitles.includes('ADHD Adult Diagnostic Evaluation') ||
        selectedServicesTitles.includes(
          'Autism Spectrum Disorder - Adult Evaluation'
        ));

    const isAdultAutism =
      step03FormValues.serviceType === 'Psychological Testing' &&
      selectedServicesTitles.includes(
        'Autism Spectrum Disorder - Adult Evaluation'
      );

    const isBariatric =
      step03FormValues.serviceType === 'Psychological Testing' &&
      selectedServicesTitles.includes(
        'Bariatric Surgical Psychological Evaluations'
      );

    const isBariatricHopkins =
      step03FormValues.serviceType === 'Psychological Testing' &&
      selectedServicesTitles.includes(
        'Bariatric Surgical Psychological Evaluations'
      ) &&
      step03FormValues.selectedHospital ===
        'Johns Hopkins Bayview Medical Center';

    const isBariatricNOTHopkins =
      step03FormValues.serviceType === 'Psychological Testing' &&
      selectedServicesTitles.includes(
        'Bariatric Surgical Psychological Evaluations'
      ) &&
      step03FormValues.selectedHospital !==
        'Johns Hopkins Bayview Medical Center';

    // For Couples - PP and Couples - Insurance
    const isCouplesPP =
      step03FormValues.serviceType === 'Couples' &&
      step03FormValues.Insurance === 'Private Pay';

    const isCouplesInsurance =
      step03FormValues.serviceType === 'Couples' &&
      step03FormValues.Insurance !== 'Private Pay'; // Assuming all non-PP is Insurance

    // For Families - PP and Families - Insurance
    const isFamiliesPP =
      step03FormValues.serviceType === 'Families' &&
      step03FormValues.Insurance === 'Private Pay';

    const isFamiliesInsurance =
      step03FormValues.serviceType === 'Families' &&
      step03FormValues.Insurance !== 'Private Pay'; // Assuming all non-PP is Insurance

    // Update userSessionDetails with both conditions
    setUserSessionDetails((prevState) => ({
      ...prevState,
      isProBono: isProBono,
      isAdoptionsPPCouplesAndFamilies: isAdoptionsPPCouplesAndFamilies,
      isAdoptionsCouplesAndFamilies: isAdoptionsCouplesAndFamilies,
      isAdoptions: isAdoptions,
      isTurning18: isTurning18,
      isAdultADHD: isAdultADHD,
      isAdultADHDAndAutism: isAdultADHDAndAutism,
      isAdultAutism: isAdultAutism,
      isBariatric: isBariatric,
      isBariatricHopkins: isBariatricHopkins,
      isBariatricNOTHopkins: isBariatricNOTHopkins,
      isCouplesInsurance: isCouplesInsurance,
      isCouplesPP: isCouplesPP,
      isFamiliesInsurance: isFamiliesInsurance,
      isFamiliesPP: isFamiliesPP,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedPrivatePayAmount,
    step03FormValues.serviceType,
    step03FormValues.services,
    step03FormValues.Insurance,
    step03FormValues.selectedHospital,
    entryTypeFormValues.existingClientOptions,
  ]);

  useEffect(() => {
    const hasVisitedBefore = localStorage.getItem('visitedBefore');
    if (hasVisitedBefore && state && Object.keys(state).length > 2) {
      setShowBanner(true);
    }
    localStorage.setItem('visitedBefore', 'true');
  }, [state]);

  useEffect(() => {
    dockService
      .getIncompleteDockHealthTasks()
      .then((response) => {
        // Make sure you're setting the state with the 'tasks' array from the server response.
        const tasks = (response && response.data && response.data.tasks) || [];
        setTaskDropdownOptions(tasks);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    if (step01FormValues.date) {
      if (step01FormValues.date?.toString() === 'Invalid Date') {
        setAge(null);
        step01FormValues.date = null;
        return;
      }
      let curDate = new Date();
      let ageMs = curDate.getTime() - new Date(step01FormValues.date.getTime());
      const ageYear = Math.floor(ageMs / (1000 * 60 * 60 * 24 * 365));
      setAge(ageYear);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step01FormValues.date]);

  useEffect(() => {
    if (formValues.populationValue) {
      const [newAgeLow, newAgeHigh] = formValues.populationValue;
      setFormValues((prevValues) => ({
        ...prevValues,
        ageLow: newAgeLow,
        ageHigh: newAgeHigh,
      }));
    }
  }, [formValues.populationValue]);

  const handleChangeDuration = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedDuration(typeof value === 'string' ? value.split(',') : value);
  };
  const handleSelectedEndDate = (event) => {
    setSelectedEndDate(event.target.value);
    let selectedAvailableSlotClone = { ...selectedAvailableSlot };
    selectedAvailableSlotClone.end_date_time = event.target.value;
    setSelectedAvailableSlot(selectedAvailableSlotClone);
    setSelectedSlotForAppt({ ...selectedAvailableSlot });
  };

  useEffect(() => {
    if (formValues.populationValue) {
      const [newAgeLow, newAgeHigh] = formValues.populationValue;
      setFormValues((prevValues) => ({
        ...prevValues,
        ageLow: newAgeLow,
        ageHigh: newAgeHigh,
      }));
    }
  }, [formValues.populationValue]);

  useEffect(() => {
    if (!selectedStartDate) {
      return;
    }
    if (selectedDuration.length > 0) {
      const time = moment(selectedStartDate, 'YYYY-MM-DDTHH:mm')
        .add(selectedDuration, 'm')
        .toDate();
      setSelectedEndDate(moment(time).format('YYYY-MM-DDTHH:mm'));

      let selectedAvailableSlotClone = { ...selectedAvailableSlot };
      selectedAvailableSlotClone.end_date_time =
        moment(time).format('YYYY-MM-DDTHH:mm');

      setSelectedAvailableSlot(selectedAvailableSlotClone);
      setSelectedSlotForAppt(selectedAvailableSlotClone);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDuration, selectedStartDate]);

  useEffect(() => {
    showLoading();

    async function fetchProviders() {
      await providerService.getAllProviders().then(
        (response) => {
          let users = response?.data?.users ?? [];
          setProvidersOptions(users);
          let providers = users
            .filter((user) => user.roleId === 5) // filtering provider from users
            .sort((user1, user2) => {
              const name1 = user1?.username?.split(' ')[1] ?? '';
              const name2 = user2?.username?.split(' ')[1] ?? '';
              return name1.localeCompare(name2);
            });

          setUsers(providers);
          setUsersFilter([]);
        },
        (error) => {
          const _content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          console.log(_content);

          if (error.response && error.response.status === 401) {
            EventBus.dispatch('logout');
          }
        }
      );

      await providerService.getProviders().then((response) => {
        let providers = response?.data?.providers ?? [];
        setActiveProviders(providers);
      });
    }

    async function getActiveCareCoordinators() {
      const response = await carecoordinatorService.getCareCoordinators();
      setActiveCareCoordinators(response.data);
    }

    async function getAllUsers() {
      const response = await userService.getAllUsers();
      setAllUsers(response?.data?.users);
    }

    // Call both functions in parallel and wait for both to finish before hiding the loading spinner
    Promise.all([
      fetchProviders(),
      getActiveCareCoordinators(),
      getAllUsers(),
    ]).finally(() => hideLoading());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (users.length > 0) {
      if (
        serviceTesting &&
        Object.keys(step06FormValues).length !== 0 &&
        step03FormValues.serviceGroup?.includes('Testing')
      ) {
        fetchProviders();

        !isManual ? setServiceType('Testing') : setServiceType('');
      } else if (
        serviceTesting === false &&
        step03FormValues.serviceGroup !== undefined &&
        !step03FormValues.serviceGroup?.includes('Testing')
      ) {
        fetchProviders();
        !isManual ? setServiceType('Therapy') : setServiceType('');
      } else if (
        serviceTesting &&
        step06FormValues.range !== '3 months' &&
        Object.keys(step06FormValues).length !== 0
      ) {
        !isManual ? setServiceType('Testing') : setServiceType('');
      } else {
        fetchProviders();
        if (isManual) {
          setServiceType('');
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    step03FormValues,
    step05FormValues,
    users,
    step06FormValues,
    age,
    specialConsiderationSelected,
    showSchedulingStep03C,
    mcLeanConditions,
  ]);

  useEffect(() => {
    if (step03FormValues.serviceType === 'Couples') {
      setCcEmailCheck(true);
    }
  }, [step03FormValues.serviceType]);

  const fetchProviders = async (filters) => {
    try {
      showLoading();
      appointmentService
        .getProviderAppts(step03FormValues, step05FormValues, step06FormValues)
        .then(
          async (response) => {
            let providers = response?.data?.providers ?? [];
            providers = getActiveProviders(providers); // filtering only active Providers
            const slots = response?.data?.availableSlots ?? [];
            let prioritySlots = [];
            let usersFilterLocal = users
              .filter((obj) => {
                const provider = providers.find((providerObj) => {
                  return obj.id === providerObj.id;
                });
                return provider;
              })
              .map((slotObj) => {
                const slotFilters = slots.filter((slotFilterObj) => {
                  return slotFilterObj.provider_id === slotObj.id;
                });
                return { ...slotObj, slots: slotFilters };
              })
              .sort((obj1, obj2) => {
                const name1 = obj1?.username?.split(' ')[1] ?? '';
                const name2 = obj2?.username?.split(' ')[1] ?? '';
                return name1.localeCompare(name2);
              });

            state.globalUsers = [...usersFilterLocal]; // This is we are saving to state for McLean case to be working
            dispatch(saveFormData(state));

            if (age != null) {
              usersFilterLocal = usersFilterLocal.map((ufl) => {
                let id = ufl.id;
                let provider = providers.find((it) => it.id === id);
                if (provider && provider.therapy) {
                  let populationValues = provider.therapy.populationValue;
                  ufl['ageLow'] = populationValues[0];
                  ufl['ageHigh'] = populationValues[1];
                  return ufl;
                }
                if (provider && provider.testing) {
                  let populationValues = provider.testing.populationValue;
                  ufl['ageLow'] = populationValues[0];
                  ufl['ageHigh'] = populationValues[1];
                  return ufl;
                }
                return null;
              });

              usersFilterLocal = usersFilterLocal.filter((it) => !!it);
              usersFilterLocal = usersFilterLocal.filter(
                (it) => it['ageLow'] <= age && it['ageHigh'] >= age
              );
              state.globalUsers = [...usersFilterLocal]; // This is we are saving to state for McLean case to be working
              dispatch(saveFormData(state));
            }
            if (slots.length > 0 && availableGlobalSlots.length <= 6) {
              setGlobalAvailableSlots([...slots]);
            }

            setAvailableSlots([...slots]);
            prioritySlots = [[...slots]];

            let filteredSlots;

            if (
              step06FormValues?.frequency?.toLowerCase() === 'weekly' ||
              step06FormValues?.frequency?.toLowerCase() === 'floater' ||
              step06FormValues?.frequency?.toLowerCase() === 'every_other_week'
            ) {
              filteredSlots = availableSlots.filter(
                (slotObj) =>
                  slotObj.frequency.toLowerCase() ===
                  step06FormValues.frequency.toLowerCase()
              );
              prioritySlots = [[...filteredSlots]];
              setAvailableSlots([...filteredSlots]);
              setFrequency(step06FormValues.frequency.toLowerCase());
            } else {
              setFrequency('all');
            }

            if (
              step06FormValues?.type?.toLowerCase() === 'hold' ||
              step06FormValues?.type?.toLowerCase() === 'open'
            ) {
              filteredSlots = availableGlobalSlots.filter(
                (slotObj) =>
                  slotObj.type.toLowerCase() ===
                  step06FormValues.type.toLowerCase()
              );
              setAvailableSlots([...filteredSlots]);
              prioritySlots = [[...filteredSlots]];
            } else {
              setAvailableSlots([...slots]);
              prioritySlots = [[...slots]];
            }

            const licenseArray = step05FormValues?.license?.map(
              (licenseObj) => licenseObj?.title
            );

            function checkArrayElementsExist(arr1, arr2) {
              arr1 = arr1 || [];
              arr2 = arr2 || [];
              setFunctionExecuted(true); // moved up here
              if (arr1?.length === 1) {
                return arr2?.includes(arr1[0]);
              } else {
                arr1 = arr1.sort();
                arr2 = arr2.sort();
                for (let i = 0; i < arr1?.length; i++) {
                  if (arr1[i] !== arr2[i]) {
                    return false;
                  }
                }
                return true;
              }
            }

            let licenseFilteredProvider = [];

            for (let i = 0; i < providers?.length; i++) {
              if (
                checkArrayElementsExist(
                  licenseArray,
                  providers[i]?.degrees_and_license
                )
              ) {
                licenseFilteredProvider.push(providers[i]);
              }
            }
            if (
              licenseFilteredProvider?.length > 0 ||
              functionExecuted === true
            ) {
              setProviders(licenseFilteredProvider);
            } else {
              setProviders(providers);
            }

            let scannedUsers = [];
            if (
              age != null ||
              (step06FormValues.setting && step06FormValues.setting.length > 0)
            ) {
              for (let i = 0; i < usersFilterLocal?.length; i++) {
                if (
                  licenseFilteredProvider?.find(
                    (obj) => obj?.provider_email === usersFilterLocal[i]?.email
                  )
                ) {
                  scannedUsers.push(usersFilterLocal[i]);
                }
              }
            } else {
              for (let i = 0; i < globalUsers?.length; i++) {
                if (
                  licenseFilteredProvider?.find(
                    (obj) => obj?.provider_email === globalUsers[i]?.email
                  )
                ) {
                  scannedUsers.push(globalUsers[i]);
                }
              }
            }
            if (scannedUsers?.length > 0 || functionExecuted === true) {
              let data = filterSlotsForSingle(prioritySlots, usersFilterLocal);
              setUsersFilter([...data]);
            } else {
              let data = filterSlotsForSingle(prioritySlots, usersFilterLocal);
              setUsersFilter(data);
              setGlobalUsers(data);
            }
            if (
              step03FormValues.therapySpecialties !== undefined &&
              step03FormValues.therapySpecialties !== 'All'
            ) {
              let filteredProvider = [];

              providers.map((provider) => {
                // eslint-disable-next-line array-callback-return
                return Object.keys(provider.therapy).find((key) => {
                  if (
                    key.toString().toLowerCase() ===
                    step03FormValues.therapySpecialties.toLowerCase()
                  ) {
                    if (
                      provider.therapy[key].toString().toLowerCase() === 'yes'
                    ) {
                      filteredProvider.push(provider);
                      return provider;
                    }
                  }
                });
              });
              let FilteredUsersT = [];
              if (sepcializedTherapyFilter) {
                if (age !== null) {
                  for (let i = 0; i < usersFilterLocal.length; i++) {
                    if (
                      filteredProvider?.find(
                        (obj) =>
                          obj?.provider_email === usersFilterLocal[i]?.email
                      )
                    ) {
                      FilteredUsersT.push(usersFilterLocal[i]);
                    }
                  }
                } else {
                  for (let i = 0; i < globalUsers.length; i++) {
                    if (
                      filteredProvider?.find(
                        (obj) => obj?.provider_email === globalUsers[i]?.email
                      )
                    ) {
                      FilteredUsersT.push(globalUsers[i]);
                    }
                  }
                }
              } else {
                for (let i = 0; i < usersFilter.length; i++) {
                  if (
                    filteredProvider?.find(
                      (obj) => obj?.provider_email === usersFilter[i]?.email
                    )
                  ) {
                    FilteredUsersT.push(usersFilter[i]);
                  }
                }
              }
              let data = filterSlotsForSingle(prioritySlots, FilteredUsersT);
              setUsersFilter([...data]);
              setSpecializedTherapyFilter(true);
            }

            /* The below filter is for special Consideration */
            if (specialConsiderationSelected) {
              let filteredProviders = [];
              for (let i = 0; i < providers.length; i++) {
                let therapyInfo = providers[i].therapy;

                // Check each special consideration condition
                if (
                  specialConsiderationSelected === 'Deaf or Hard of Hearing'
                ) {
                  if (
                    ['Yes', 'Yes, But...'].some((answer) =>
                      therapyInfo['Deaf or Hard of Hearing']?.includes(answer)
                    )
                  ) {
                    filteredProviders.push(providers[i]);
                  }
                } else if (
                  specialConsiderationSelected ===
                  'Working with Mental Health Professionals'
                ) {
                  if (
                    ['Yes', 'Yes, But...'].some((answer) =>
                      therapyInfo[
                        'Working with Mental Health Professionals'
                      ]?.includes(answer)
                    )
                  ) {
                    filteredProviders.push(providers[i]);
                  }
                } else if (
                  specialConsiderationSelected === 'Working with Orthodox Jews'
                ) {
                  if (
                    ['Yes', 'Yes, But...'].some((answer) =>
                      therapyInfo['Working with Orthodox Jews']?.includes(
                        answer
                      )
                    )
                  ) {
                    filteredProviders.push(providers[i]);
                  }
                }
              }

              let fileredUserConsideration = [];

              for (let i = 0; i < usersFilterLocal.length; i++) {
                if (
                  filteredProviders?.find(
                    (obj) => obj?.provider_email === usersFilterLocal[i]?.email
                  )
                ) {
                  fileredUserConsideration.push(usersFilterLocal[i]);
                }
              }
              filterSlotsForSingle(
                prioritySlots,
                fileredUserConsideration,
                (getAllFilterUser) => {
                  fileredUserConsideration = [...getAllFilterUser];
                }
              );
              setUsersFilter([...fileredUserConsideration]);
            }
            // The below don't gets fired when special consideration is there
            setTrigger(true);
            setPriorityFlag(true);
            checkMcleanCase();
          },
          (error) => {
            const _content =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();

            console.log('_content', _content);

            if (error.response && error.response.status === 401) {
              EventBus.dispatch('logout');
            }
          }
        )
        .finally(() => hideLoading());
    } catch (error) {
      // console.log('fetchProviders.error', error)
    }
  };

  const getProviderYesMaybeText = (userFilter) => {
    let provider = providers.find((it) => it.id === userFilter.id);
    let reasonText = [];
    if (provider) {
      let therapy = provider['therapy'];
      let services = (step03FormValues.services || []).map((it) => it.title);
      let serviceTypes = step03FormValues.serviceType;
      let serviceGroup = step03FormValues.serviceGroup;
      let therapySpecialties = step03FormValues.therapySpecialties;
      let specialConsideration = specialConsiderationSelected;

      if (therapy) {
        let keys = Object.keys(therapy);
        for (let i = 0; i < keys.length; i++) {
          let k = keys[i];
          if (k.endsWith('_reason')) {
            if (
              services?.indexOf(k.replace('_reason', '')) > -1 ||
              serviceGroup?.indexOf(k.replace('_reason', '')) > -1 ||
              therapySpecialties?.indexOf(k.replace('_reason', '')) > -1 ||
              specialConsideration?.indexOf(k.replace('_reason', '')) > -1 ||
              serviceTypes?.indexOf(k.replace('_reason', '')) > -1
            ) {
              let replaced = k.replace('_reason', '');
              if (therapy[replaced]?.indexOf('Yes, But...') > -1) {
                reasonText.push(`${replaced}: ${therapy[k]} <br>`);
              }
            }
          }
        }

        if (provider.therapy.subPopulations) {
          provider.therapy.subPopulations.forEach((subPopulation) => {
            const [low, high] = subPopulation.value;
            if (age >= low && age <= high) {
              reasonText.push(`Age Note: ${subPopulation.text} <br>`);
            }
          });
        }
      }
      let testing = provider['testing'];
      if (testing) {
        let keys = Object.keys(testing);
        for (let i = 0; i < keys.length; i++) {
          let k = keys[i];
          if (k.endsWith('_reason')) {
            if (
              services?.indexOf(k.replace('_reason', '')) > -1 ||
              serviceGroup?.indexOf(k.replace('_reason', '')) > -1 ||
              serviceTypes?.indexOf(k.replace('_reason', '')) > -1
            ) {
              let replaced = k.replace('_reason', '');
              if (therapy[replaced]?.indexOf('Yes, But...') > -1) {
                reasonText.push(`${replaced}: ${therapy[k]} <br>`);
              }
            }
          }
        }
      }
      let riskQuestions = provider['therapy']?.riskQuestions;
      if (riskQuestionsFilter?.length > 0) {
        if (riskQuestions?.length > 0) {
          let reasons = riskQuestions
            // eslint-disable-next-line array-callback-return
            .map((riskQuestion) => {
              if (riskQuestion.reason)
                if (riskQuestion.question.includes('suicidal')) {
                  return 'Risk Suicide: ' + riskQuestion.reason + '<br>';
                } else if (riskQuestion.question.includes('self-harming')) {
                  return 'Risk Self-Harm: ' + riskQuestion.reason + '<br>';
                } else {
                  return (
                    'Risk Psych Hospitalization: ' +
                    riskQuestion.reason +
                    '<br>'
                  );
                }
            })
            .filter((element) => element !== undefined);
          if (reasons?.length > 0) {
            reasons = reasons.join('\n');
            reasonText.push(reasons);
          }
        }
      }
    }
    let out = reasonText.join('\n');
    return { out: out, provider: provider };
  };
  // The below function is for the case of Mclean
  const getAutismTherapyObject = () => {
    if (showSchedulingStep03C) {
      return {
        McLeanBehavioralTherapy: true,
        developmentalCondition: mcLeanConditions === true ? 'yes' : 'no', // This is tracking developmental condition
        behaviorIssues: Object.fromEntries(
          Object.entries(state.behaviorIssues).filter(
            ([key, value]) => value === true
          )
        ), // only taking the positive values
        exclusions: Object.fromEntries(
          Object.entries(state.exclusions).filter(
            ([key, value]) => value === true
          )
        ), // only taking the positive values
      };
    }
  };

  const handleIsManualChange = (event) => {
    let value = event.target.value === 'Manual Entry';
    if (event.target.value === 'Manual Entry') {
      setExpanded(false);
    } else {
      setExpanded(true);
    }
    setIsManual(value);
  };
  useEffect(() => {
    if (isManual) {
      setStep06FormValues({});
      if (
        step03FormValues?.serviceType !== null ||
        step03FormValues?.serviceType !== ''
      ) {
        fetchProviders();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isManual]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedProviders(typeof value === 'string' ? value.split(',') : value);
  };

  const handleChangeNonScheduling = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedProvidersNonScheduling(
      typeof value === 'string' ? value.split(',') : value
    );
  };

  const handleChangeResult = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedResult(typeof value === 'string' ? value.split(',') : value);
  };

  const handleChangeReason = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedReason(typeof value === 'string' ? value.split(',') : value);
  };

  const handleChangeApptType = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedApptType(typeof value === 'string' ? value.split(',') : value);
  };
  const handleChangeOfficeLocation = (event) => {
    const {
      target: { value },
    } = event;
    let location = typeof value === 'string' ? value.split(',') : value;
    setSelectedOfficeLocation(location);
    let selectedAvailableSlotClone = { ...selectedAvailableSlot };
    selectedAvailableSlotClone.location = location;
    setSelectedAvailableSlot(selectedAvailableSlotClone);
  };

  const handleDockHealthPatientChange = (event) => {
    let selectedValue = event.target.value;

    let taskId = selectedValue?.id;

    selectedValue = selectedValue?.details;

    const parsedDockHealthPatient =
      parseDockHealthPatientDetails(selectedValue);
    setSelectedDockHeathPatient({
      ...parsedDockHealthPatient,
      email: extractEmailAddress(parsedDockHealthPatient.email),
    }); // setting the state to be passed

    // Here I am checking if date is undefined or not for pyshcology today clients
    const nextDay = parsedDockHealthPatient.date_of_birth
      ? moment(parsedDockHealthPatient.date_of_birth).toDate()
      : undefined;
    // Remember to use moment for synchronous time across the application

    setSelectedDockHealthTaskId(taskId);
    setStep01FormValues({
      ...step01FormValues,
      first_name: parsedDockHealthPatient.first_name,
      last_name: parsedDockHealthPatient.last_name,
      date: nextDay,
      email: extractEmailAddress(parsedDockHealthPatient.email),
      phone: parsedDockHealthPatient.phone,
    });

    state.firstName = parsedDockHealthPatient.first_name;
    state.lastName = parsedDockHealthPatient.last_name;
    state.date = parsedDockHealthPatient.date_of_birth;
    state.email = extractEmailAddress(parsedDockHealthPatient.email);
    state.phone = parsedDockHealthPatient.phone;
    state.dockHealthTaskId = taskId;

    dispatch(saveFormData(state));
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseOpenLogNonScheduling = () => {
    setOpenLogNonScheduling(true);
  };

  // Function to focus on the input fields
  const openAccordion = (ref) => {
    if (ref.current) {
      const button = ref.current.querySelector('div[role="button"]');
      if (button) {
        setTimeout(() => {
          button.click();
        }, 200);
      }
    }
  };

  const focusField = (fieldRef, accordionRef) => {
    if (accordionRef) {
      openAccordion(accordionRef);
    }

    if (fieldRef.current) {
      fieldRef.current.scrollIntoView({ behavior: 'smooth' });
      fieldRef.current.focus();

      const buttonDiv = fieldRef.current.querySelector('div[role="button"]');
      if (buttonDiv) {
        setTimeout(() => {
          const mousedownEvent = new MouseEvent('mousedown', {
            view: window,
            bubbles: true,
            cancelable: true,
          });
          const clickEvent = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true,
          });
          buttonDiv.dispatchEvent(mousedownEvent);
          buttonDiv.dispatchEvent(clickEvent);
        }, 200);
      } else {
        console.log('Button div not found'); // Log if the button div is not found
      }
    } else {
      console.log('Ref current is null'); // Log if the ref is null
    }
  };

  const handleOpen = (isManual, availableSlot) => {
    if (isManual) {
      setSelectedAvailableSlot({ isManual: true, ...availableSlot });
    } else {
      setSelectedAvailableSlot({ ...availableSlot });
    }
    setSelectedStartDate(availableSlot.start_date_time);
    setSelectedEndDate(availableSlot.end_date_time);
    setSelectedOfficeLocation([availableSlot.location]);

    if (compareSlotDateWithToday(availableSlot)) {
      setAlertMessage(
        'You cannot select appointment on the same date or after 4pm for tomorrow.'
      );
      setAlertOpen(true);
    } else {
      setOpen(true);
    }
  };

  const handleLogNonScheduling = (event) => {
    try {
      if (step03FormValues?.serviceType) {
        setOpenLogNonScheduling(true);
      } else {
        enqueueSnackbar(
          'Please fill in at least Service Type under Service Info section (and as much information known as possible)',
          {
            variant: 'error',
            timeout: 3000,
          }
        );
      }
    } catch (error) {
      // console.log('handleLogNonScheduling.error', error)
    }
  };

  useEffect(() => {
    if (step01FormValues.phone && step01FormValues.phone.length === 10) {
      showLoading();
      dockService
        .searchDockHealthTasks(step01FormValues.phone)
        .then(
          (response) => {
            // TODO: populate dock health section
            setDockHealthTasks(
              (response && response.data && response.data.tasks) || []
            );
          },
          (error) => {
            const _content =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();

            console.log('_content: ', _content);
            if (error.response && error.response.status === 401) {
              EventBus.dispatch('logout');
            }
          }
        )
        .finally(() => hideLoading());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step01FormValues.phone]);

  useEffect(() => {
    const { entryType, existingClientOptions } = entryTypeFormValues;
    if (entryType === 'existing' && existingClientOptions === 'Transfer') {
      setShouldShowStep03B(false);
      setIsManual(true);
    }
    if (
      entryType === 'existing' &&
      existingClientOptions === 'Therapy Client Coming to Office'
    ) {
      setIsManual(true);
    }
    if (
      entryType === 'existing' &&
      existingClientOptions === 'Testing Client Coming to Office'
    ) {
      setIsManual(true);
    }
    if (
      entryType === 'existing' &&
      existingClientOptions === 'Turning 18 years-Old'
    ) {
      setIsManual(true);
    }
    if (entryType === 'returning') {
      setIsManual(true);
    }
  }, [entryTypeFormValues]);

  const createSchedule = () => {
    /* 
    Below activeCareCoordinators are running find method
    */
    const careCoordinator = allUsers?.find((obj) => {
      return obj.username === selectedCareCoordinator;
    });
    setCareCoordinator({ ...careCoordinator });
    showLoading();
    appointmentService
      .createAppointment({
        step01FormValues: step01FormValues,
        step03FormValues: step03FormValues,
        step04FormValues: step04FormValues,
        step05FormValues: step05FormValues,
        entryTypeFormValues,
        selectedProviders: selectedProviders,
        selectedApptType: selectedApptType,
        selectedOfficeLocation: selectedOfficeLocation,
        taMrn: taMrn,
        isManual: isManual,
        cc_first_name: firstName,
        cc_last_name: lastName,
        cc_email: email,
        selectedAvailableSlot,
        care_coordinator: careCoordinator.id,
        non_filtered_data: JSON.stringify(nonFilteredData),
        riskQuestions:
          step03FormValues?.serviceGroup === 'Therapy'
            ? JSON.stringify(riskQuestionDB)
            : null,
        Sep_Div: underEighteenFormResponse,
        selectedDockHealthTaskId: selectedDockHealthTaskId,
        autism_therapy: JSON.stringify(getAutismTherapyObject()), // This key is for Maclean autism therapy
      })
      .then(
        async (response) => {
          if (response.data.message === 'Failure') {
            setOpen(false);
            setLoading(false);
            hideLoading();
            setShowCustomAlert(true);
          } else {
            setOpen(false);
            setLoading(false);
            hideLoading();
            setNonFilteredData({
              ...JSON.parse(response.data.appointments.non_filtered_data),
            });
            enqueueSnackbar('Appointment created', {
              variant: 'success',
              timeout: 3000,
            });
            await new Promise((resolve) => setTimeout(resolve, 2000));
            setApptDetailsOpen(true);
            dispatch(clearFormData());
          }
        },
        (error) => {
          setOpen(false);
          const _content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          console.log('_content', _content);

          if (error.response && error.response.status === 401) {
            EventBus.dispatch('logout');
          }
        }
      )
      .finally(() => {
        hideLoading();
      });
  };
  const createLogNonScheduling = () => {
    /* 
    Below activeCareCoordinators are running find method
    */
    const careCoordinator = allUsers?.find((obj) => {
      return obj.username === selectedCareCoordinator;
    });
    setCareCoordinator({ ...careCoordinator });
    const selectedProviderIds = selectedProvidersNonScheduling.map((it) => {
      return users.find((obj) => obj.username === it)?.id;
    });

    showLoading();

    appointmentService
      .createAppointment({
        step01FormValues: step01FormValues,
        step03FormValues: step03FormValues,
        step04FormValues: step04FormValues,
        step05FormValues: step05FormValues,
        entryTypeFormValues,
        selectedProviders,
        selectedReason: [
          reasonValue.length > 0
            ? selectedReason + `(${reasonValue})`
            : selectedReason,
        ],
        selectedResult,
        selectedProvidersNonScheduling: selectedProviderIds,
        is_non_scheduling: true,
        // selectedProviders: selectedProviders,
        selectedApptType: selectedApptType,
        selectedOfficeLocation: selectedOfficeLocation,
        taMrn: taMrn,
        isManual: isManual,
        cc_first_name: firstName,
        cc_last_name: lastName,
        cc_email: email,
        selectedAvailableSlot,
        care_coordinator: careCoordinator?.id,
      })
      .then(
        async (response) => {
          setOpen(false);
          setLoading(false);
          enqueueSnackbar('Appointment created', {
            variant: 'success',
            timeout: 3000,
          });
          setApptDetailsOpen(true);
          await new Promise((resolve) => setTimeout(resolve, 1000));
          dispatch(clearFormData());
          await new Promise((resolve) => setTimeout(resolve, 1000));
          window.location.href = '/scheduling';
        },
        (error) => {
          setOpen(false);
          const _content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          console.log('_content: ', _content);

          if (error.response.message && error.response.status === 504) {
            enqueueSnackbar(
              'The appointment was not immediately created but is likely in queue and to NOT create a second appointment but rather wait (e.g. 5 minutes) to see if it was created',
              {
                variant: 'error',
                timeout: 3000,
              }
            );
          }

          if (error.response && error.response.status === 401) {
            EventBus.dispatch('logout');
          }
        }
      )
      .finally(() => {
        hideLoading();
      });
  };

  function shouldShowSegmentOrNot(label) {
    const { entryType, existingClientOptions, cancellationOptions } =
      entryTypeFormValues;

    if (label === 'Service Info') {
      if (entryType === 'cancellation' && cancellationOptions === 'new') {
        return false;
      }
    }
    if (label === 'Referral') {
      if (entryType === 'existing' && existingClientOptions === 'Transfer') {
        return false;
      }
      if (
        entryType === 'existing' &&
        existingClientOptions === 'Testing Client Coming to Office'
      ) {
        return false;
      }
      if (
        entryType === 'existing' &&
        existingClientOptions === 'Therapy Client Coming to Office'
      ) {
        return false;
      }
      if (
        entryType === 'existing' &&
        existingClientOptions === 'Turning 18 years-Old'
      ) {
        return false;
      }
      if (entryType === 'returning') {
        return false;
      }
      if (entryType === 'cancellation' && cancellationOptions === 'new') {
        return false;
      }
    }
    if (label === 'Provider Profile Filter') {
      if (entryType === 'existing' && existingClientOptions === 'Transfer') {
        return false;
      }
      if (
        entryType === 'existing' &&
        existingClientOptions === 'Testing Client Coming to Office'
      ) {
        return false;
      }
      if (
        entryType === 'existing' &&
        existingClientOptions === 'Therapy Client Coming to Office'
      ) {
        return false;
      }
      if (
        entryType === 'existing' &&
        existingClientOptions === 'Turning 18 years-Old'
      ) {
        return false;
      }
      if (entryType === 'returning') {
        return false;
      }
      if (entryType === 'cancellation' && cancellationOptions === 'new') {
        return false;
      }
    }
    if (label === 'Cal Event Filters') {
      if (entryType === 'existing' && existingClientOptions === 'Transfer') {
        return false;
      }

      if (
        entryType === 'existing' &&
        existingClientOptions === 'Therapy Client Coming to Office'
      ) {
        return false;
      }
      if (
        entryType === 'existing' &&
        existingClientOptions === 'Turning 18 years-Old'
      ) {
        return false;
      }
      if (entryType === 'returning') {
        return true;
      }
      if (entryType === 'cancellation' && cancellationOptions === 'new') {
        return false;
      }
    }
    if (label === 'Appropriateness for Remote Sessions') {
      if (entryType === 'existing') {
        return false;
      }
    }
    return true;
  }
  // The user object here is being passed as optional for McLean case to update Status Bar
  const updateStatusBar = (user) => {
    let availableSlotsCount = 0;
    let totalAppointments = 0;
    let totalHold = 0;
    let totalSlots = 0;

    if (user) {
      totalHold += user?.slots?.filter((v, k) => v.type === 'Hold')?.length / 2;
      totalAppointments += user?.slots?.filter(
        (v, k) => v.type !== 'Hold'
      )?.length;
      availableSlotsCount += user?.slots?.length || 0;

      const idsOfSlot1 = new Set(
        user?.slots
          ?.filter((sep) => sep.type !== 'Hold')
          ?.filter((sep) => sep.frequency !== 'every_other_week')
          .map((e) => e.id)
      );
      const idsOfSlot2 = new Set(
        user?.slots
          ?.filter((sep) => sep.type !== 'Hold')
          ?.filter((sep) => sep.frequency === 'every_other_week')
          .map((e) => e.id)
      );
      totalSlots += idsOfSlot1?.size;
      totalSlots += idsOfSlot2?.size / 2;
    } else {
      usersFilter.forEach((user) => {
        totalHold +=
          user?.slots?.filter((v, k) => v.type === 'Hold')?.length / 2;
        totalAppointments += user?.slots?.filter(
          (v, k) => v.type !== 'Hold'
        )?.length;
        availableSlotsCount += user?.slots?.length || 0;

        const idsOfSlot1 = new Set(
          user?.slots
            ?.filter((sep) => sep.type !== 'Hold')
            ?.filter((sep) => sep.frequency !== 'every_other_week')
            .map((e) => e.id)
        );
        const idsOfSlot2 = new Set(
          user?.slots
            ?.filter((sep) => sep.type !== 'Hold')
            ?.filter((sep) => sep.frequency === 'every_other_week')
            .map((e) => e.id)
        );
        totalSlots += idsOfSlot1?.size;
        totalSlots += idsOfSlot2?.size / 2;
      });
    }

    setTotalSotsForBar(totalSlots);
    setTotalNumberOfAppointment(totalAppointments);
    setTotalNumberOfHolds(totalHold);
    setTotalAvailableSlots(availableSlotsCount);
  };

  useEffect(() => {
    updateStatusBar();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    usersFilter,
    availableSlots,
    step06FormValues,
    step03FormValues,
    step05FormValues,
  ]);

  function scheduleUsingValue(isManual) {
    if (isManual) {
      return 'Manual Entry';
    } else {
      return 'Calendar Integration';
    }
  }

  function isReferralShown() {
    const { entryType } = entryTypeFormValues;
    if (entryType === 'new') {
      return true;
    }

    if (entryType === 'cancellation') {
      return true;
    }

    return false;
  }

  function isServiceInfoShown() {
    const { entryType, existingClientOptions } = entryTypeFormValues;
    if (
      entryType === 'existing' &&
      existingClientOptions === 'Turning 18 years-Old'
    ) {
      return false;
    }

    return true;
  }

  function isServicesRequired() {
    const { entryType, existingClientOptions } = entryTypeFormValues;
    if (entryType === 'new ' || entryType === 'cancellation') {
      return true;
    }

    if (
      entryType === 'existing' &&
      (existingClientOptions === 'Testing Client Coming to Office' ||
        existingClientOptions === 'Therapy Client Coming to Office') &&
      step03FormValues.serviceType === 'Psychological Testing'
    ) {
      return true;
    }

    return false;
  }

  function getTherapyDocsDueSection(overRideDocDate = null) {
    const {
      entryType,
      existingClientOptions,
      transferQuestion,
      returningClientQ1,
    } = entryTypeFormValues;
    if (
      entryType === 'existing' &&
      existingClientOptions === 'Transfer' &&
      transferQuestion === 'Before July 2021'
    ) {
      return (
        <Row style={{ margin: 2 }}>
          <div style={{ textAlign: 'center', width: '100%' }}>
            <MUIv6.Tooltip
              title={
                <React.Fragment>
                  <div>Due Dates Calculations</div>
                  <div>Therapy: 2 Bus Days Prior to Appt </div>
                  <div>Testing +4 Wks Out: 3 Mondays Prior </div>
                  <div>
                    Testing 4 Wks or Less: 1/2 # of Bus Days Until Appt & Round
                    Down if Not Even #
                  </div>
                </React.Fragment>
              }
              arrow
            >
              <MUIv6.IconButton>
                <HelpOutlineIcon />
              </MUIv6.IconButton>
            </MUIv6.Tooltip>
            <span style={{ fontWeight: 'bold' }}>Docs Due:</span>{' '}
            {getDocsDue(
              selectedAvailableSlot?.start_date_time,
              step03FormValues,
              overRideDocDate
            )}
          </div>
        </Row>
      );
    }

    if (
      entryType === 'existing' &&
      existingClientOptions === 'Transfer' &&
      transferQuestion === 'After July 2021'
    ) {
      return '';
    }

    if (
      entryType === 'existing' &&
      (existingClientOptions === 'Testing Client Coming to Office' ||
        existingClientOptions === 'Therapy Client Coming to Office')
    ) {
      return '';
    }

    if (
      entryType === 'existing' &&
      (existingClientOptions === 'Testing Client Coming to Office' ||
        existingClientOptions === 'Therapy Client Coming to Office')
    ) {
      return (
        <Row style={{ margin: 2 }}>
          <div style={{ textAlign: 'center', width: '100%' }}>
            <span style={{ fontWeight: 'bold' }}>COVID Docs Due:</span>{' '}
            {getDocsDue(
              selectedAvailableSlot?.start_date_time,
              step03FormValues,
              overRideDocDate
            )}
          </div>
        </Row>
      );
    }

    if (entryType === 'returning' && returningClientQ1 === 'No') {
      return '';
    }

    return (
      <Row style={{ margin: 2 }}>
        <div style={{ textAlign: 'center', width: '100%' }}>
          <MUIv6.Tooltip
            title={
              <React.Fragment>
                <div>Due Dates Calculations</div>
                <div>Therapy: 2 Bus Days Prior to Appt </div>
                <div>Testing +4 Wks Out: 3 Mondays Prior </div>
                <div>
                  Testing 4 Wks or Less: 1/2 # of Bus Days Until Appt & Round
                  Down if Not Even #
                </div>
              </React.Fragment>
            }
            arrow
          >
            <MUIv6.IconButton>
              <HelpOutlineIcon />
            </MUIv6.IconButton>
          </MUIv6.Tooltip>
          <span style={{ fontWeight: 'bold' }}>Docs Due:</span>{' '}
          {getDocsDue(
            selectedAvailableSlot?.start_date_time,
            step03FormValues,
            overRideDocDate
          )}
        </div>
      </Row>
    );
  }

  function testingOrTherapyClientComingToOffice() {
    const { entryType, existingClientOptions } = entryTypeFormValues;
    if (
      entryType === 'existing' &&
      (existingClientOptions === 'Testing Client Coming to Office' ||
        existingClientOptions === 'Therapy Client Coming to Office')
    ) {
      return true;
    }
    return false;
  }

  function existingClientTurning18() {
    const { entryType, existingClientOptions } = entryTypeFormValues;
    if (
      entryType === 'existing' &&
      existingClientOptions === 'Turning 18 years-Old'
    ) {
      return true;
    }
    return false;
  }
  function clearRiskFilter(id) {
    // expecting the id to be clear from risk filter
    let riskFilters = riskQuestionsFilter;
    let filteredRisk = riskFilters.filter(
      (riskFilterObj) => !id.includes(riskFilterObj.id)
    );
    setRiskQuestionsFilter([...filteredRisk]);
    let data = filterSlotsForSingle(availableSlots, globalUsers);
    setUsersFilter([...data]);
  }
  const handleSuicidalThoughtType = (filterObject) => {
    // eslint-disable-next-line no-unused-vars
    let inAnyCondition = false;
    let tempRiskQuestionsFilter = riskQuestionsFilter;
    let objectToBePushed1 = { answer: 'Yes', id: 1, actualId: 1 };
    let objectToBePushed2 = { answer: 'Yes', id: 4, actualId: 4 };
    let objectToBePushed3 = { answer: 'Yes', id: 2, actualId: 2 };
    let objectToBePushed4 = { answer: 'Yes', id: 3, actualId: 3 };
    let objectToBePushed5 = { answer: 'Yes', id: 5, actualId: 2 };
    let objectToBePushed6 = { answer: 'Yes', id: 6, actualId: 6 };
    let objectToBePushed7 = { answer: 'Yes', id: 7, actualId: 7 };

    if (filterObject?.includes('Passive')) {
      if (
        !tempRiskQuestionsFilter.some(
          (tempQuestionObj) =>
            tempQuestionObj.id === objectToBePushed1.id &&
            tempQuestionObj.name === objectToBePushed1.name
        )
      ) {
        tempRiskQuestionsFilter.push(objectToBePushed1);
      }

      setRiskQuestionsFilter([...tempRiskQuestionsFilter]);
      inAnyCondition = true;
    } else {
      let filtered = tempRiskQuestionsFilter?.filter(
        (tempRiskQuestionObj) => tempRiskQuestionObj.id !== objectToBePushed1.id
      );
      setRiskQuestionsFilter([...filtered]);
    }
    if (filterObject?.includes('Less Than 6 Months Ago')) {
      if (
        !tempRiskQuestionsFilter.some(
          (tempQuestionObj) =>
            tempQuestionObj.id === objectToBePushed2.id &&
            tempQuestionObj.name === objectToBePushed2.name
        )
      ) {
        tempRiskQuestionsFilter.push(objectToBePushed2);
      }
      setRiskQuestionsFilter([...tempRiskQuestionsFilter]);
      inAnyCondition = true;
    } else {
      let filtered = tempRiskQuestionsFilter.filter(
        (tempRiskQuestionObj) => tempRiskQuestionObj.id !== objectToBePushed2.id
      );
      setRiskQuestionsFilter([...filtered]);
    }
    if (filterObject?.includes('Virtual Only')) {
      if (
        !tempRiskQuestionsFilter.some(
          (tempQuestionObj) =>
            tempQuestionObj.id === objectToBePushed4.id &&
            tempQuestionObj.name === objectToBePushed4.name
        )
      ) {
        tempRiskQuestionsFilter.push(objectToBePushed4);
        inAnyCondition = true;
      } else {
        let filteredSecondCase = tempRiskQuestionsFilter.filter(
          (tempRiskQObject) => tempRiskQObject.id === objectToBePushed4.id
        );
        tempRiskQuestionsFilter = [...filteredSecondCase];
      }
      setRiskQuestionsFilter([...tempRiskQuestionsFilter]);
    } else {
      let filtered = tempRiskQuestionsFilter.filter(
        (tempRiskQuestionObj) => tempRiskQuestionObj.id !== objectToBePushed4.id
      );
      setRiskQuestionsFilter([...filtered]);
    }

    if (filterObject?.includes('In-Person')) {
      if (
        !tempRiskQuestionsFilter.some(
          (tempQuestionObj) =>
            tempQuestionObj.id === objectToBePushed3.id &&
            tempQuestionObj.name === objectToBePushed3.name
        )
      ) {
        tempRiskQuestionsFilter.push(objectToBePushed3);
      }
      setRiskQuestionsFilter([...tempRiskQuestionsFilter]);
      inAnyCondition = true;
    } else {
      let filtered = tempRiskQuestionsFilter?.filter(
        (tempRiskQuestionObj) => tempRiskQuestionObj.id !== objectToBePushed3.id
      );
      setRiskQuestionsFilter([...filtered]);
    }

    if (filterObject?.includes('harmvirtual')) {
      if (
        !tempRiskQuestionsFilter.some(
          (tempQuestionObj) =>
            tempQuestionObj.id === objectToBePushed6.id &&
            tempQuestionObj.name === objectToBePushed6.name
        )
      ) {
        tempRiskQuestionsFilter.push(objectToBePushed6);
      }
      setRiskQuestionsFilter([...tempRiskQuestionsFilter]);
      inAnyCondition = true;
    } else {
      let filteredSecondCase = tempRiskQuestionsFilter?.filter(
        (tempRiskQObject) => tempRiskQObject.id !== objectToBePushed6.id
      );
      setRiskQuestionsFilter([...filteredSecondCase]);
    }

    if (filterObject?.includes('harminperson')) {
      if (
        !tempRiskQuestionsFilter.some(
          (tempQuestionObj) =>
            tempQuestionObj.id === objectToBePushed5.id &&
            tempQuestionObj.name === objectToBePushed5.name
        )
      ) {
        tempRiskQuestionsFilter.push(objectToBePushed5);
      }
      setRiskQuestionsFilter([...tempRiskQuestionsFilter]);
      inAnyCondition = true;
    } else {
      let filtered = tempRiskQuestionsFilter.filter(
        (tempRiskQuestionObj) => tempRiskQuestionObj.id !== objectToBePushed5.id
      );
      setRiskQuestionsFilter([...filtered]);
    }

    if (filterObject?.includes('psyhistory')) {
      if (
        !tempRiskQuestionsFilter.some(
          (tempQuestionObj) =>
            tempQuestionObj.id === objectToBePushed7.id &&
            tempQuestionObj.name === objectToBePushed7.name
        )
      ) {
        tempRiskQuestionsFilter.push(objectToBePushed7);
      }
      setRiskQuestionsFilter([...tempRiskQuestionsFilter]);
      inAnyCondition = true;
    } else {
      let filtered = tempRiskQuestionsFilter.filter(
        (tempRiskQuestionObj) => tempRiskQuestionObj.id !== objectToBePushed7.id
      );
      setRiskQuestionsFilter([...filtered]);
    }
  };
  function compareUsersFilterForRiskQuestions() {
    let filteredUsersWithSlots;
    if (!isManual) {
      filteredUsersWithSlots = usersFilter.filter(
        (usersFilterObject) => usersFilterObject.slots.length > 0
      );
    } else {
      filteredUsersWithSlots = globalUsers;
    }
    const providersTobeCompared = [];
    for (let i = 0; i < filteredUsersWithSlots.length; i++) {
      const foundProvider = providers.find(
        (providerObj) =>
          providerObj.provider_email === filteredUsersWithSlots[i].email
      );
      providersTobeCompared.push(foundProvider);
    }
    let finalProvidersTobeCompared = [];
    if (riskQuestionsFilter?.length > 0) {
      for (let i = 0; i < providersTobeCompared.length; i++) {
        let riskQuestionsForThisProvider =
          providersTobeCompared[i]?.therapy?.riskQuestions;
        let filteredRiskQuestionsForThisProvider =
          riskQuestionsForThisProvider?.filter(
            // eslint-disable-next-line array-callback-return
            (riskQuestionsForThisProviderObj) => {
              if (
                riskQuestionsFilter?.find(
                  (riskQuestionObj) =>
                    riskQuestionObj.id === riskQuestionsForThisProviderObj.id
                )
              ) {
                return riskQuestionsForThisProviderObj;
              }
            }
          );

        let Flag = true;
        for (let k = 0; k < riskQuestionsFilter?.length; k++) {
          if (
            filteredRiskQuestionsForThisProvider?.find(
              (filteredRiskQuestionsForThisProviderObj) => {
                return (
                  filteredRiskQuestionsForThisProviderObj.id ===
                    riskQuestionsFilter[k].id &&
                  filteredRiskQuestionsForThisProviderObj.answer.includes('Yes')
                );
              }
            )
          ) {
          } else {
            Flag = false;
          }
        }
        if (Flag) {
          finalProvidersTobeCompared.push(providersTobeCompared[i]);
        }
      }
      let finalFilteredUsers = [];
      for (let i = 0; i < filteredUsersWithSlots?.length; i++) {
        const foundProvider = finalProvidersTobeCompared?.find(
          (finalProviderObj) =>
            finalProviderObj.provider_email === filteredUsersWithSlots[i].email
        );
        if (foundProvider) {
          finalFilteredUsers.push(filteredUsersWithSlots[i]);
        }
      }
      let data = filterSlotsForSingle(availableSlots, finalFilteredUsers);
      setUsersFilter([...data]);
      setTrigger(false);
    }
  }

  useEffect(() => {
    compareUsersFilterForRiskQuestions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [riskQuestionsFilter, isManual, trigger]);

  const handleAllQuestions = (value) => {
    setAllAnswered(value);
  };
  const [settingValues, setSettingValues] = useState([]);
  const handleSettingChange = (values) => {
    setSettingValues(values);
  };

  const filterSlotsForSingle = (availableSlotsGot, usersFilter) => {
    let allUser = usersFilter;
    // eslint-disable-next-line no-unused-vars, array-callback-return
    var check = allUser?.map((obj, k) => {
      const filteredSlots = availableSlotsGot.filter((availableSlotObj) => {
        if (serviceType !== '') {
          return (
            availableSlotObj.provider_id === obj.id &&
            availableSlotObj.service === serviceType
          );
        } else {
          return availableSlotObj.provider_id === obj.id;
        }
      });
    });
    return allUser;
  };

  const onSlotClickHadler = async (availableSlot, obj, title = null) => {
    try {
      const providerDetails = await providerService.getProviderDetail(
        obj.id,
        obj.email
      );
      if (providerDetails && providerDetails.data) {
        // Example assuming degrees_and_license contains a field specifying the license status
        // Check if 'Fully Licensed' is one of the licenses
        const isFullyLicensed =
          providerDetails.data.provider.degrees_and_license.includes(
            'Fully Licensed'
          );

        const newFilters = {
          serviceGroup: availableSlot.service, // Assuming 'service' corresponds to 'serviceGroup'
          serviceType: step03FormValues.serviceType, // From step03FormValues
          clientType: entryTypeFormValues.entryType,
          age: age,
          licenseStatus: isFullyLicensed
            ? 'Fully Licensed'
            : 'Non-Fully Licensed', // Using conditional based on fetched data
          specialScenario: isSpecialScenarioMet(documents, userSessionDetails),
        };

        setDocFilters(newFilters); // Update filters which will trigger useEffect for fetching documents
        // Directly update the provider detail state in the parent component
        setSelectedProviderLicense(
          providerDetails.data.provider.degrees_and_license
        );
      }
    } catch (error) {
      console.error(
        'Failed to fetch provider details or slot information',
        error
      );
    }

    if (
      !(
        isServiceInfoShown() &&
        !(
          step03FormValues.serviceType &&
          !(isServicesRequired() && !step03FormValues.services) &&
          (step03FormValues.Insurance || step03FormValues.Insurance_t)
        )
      ) &&
      !(isReferralShown() && !step04FormValues.aboutUsInfo) &&
      // Check for Insurance List selection and enforce insurance selection
      !(
        step04FormValues.aboutUsInfo === 'Insurance List' &&
        !step04FormValues.selectedInsurance
      ) &&
      step01FormValues &&
      step01FormValues.first_name &&
      step01FormValues.last_name &&
      step01FormValues.phone &&
      isValidEmail(step01FormValues.email) &&
      (ccEmailCheck ? email && firstName && lastName : true) &&
      step01FormValues.date &&
      nonFilteredAnswered
    ) {
      if (
        shouldShowSegmentOrNot('Appropriateness for Remote Sessions') &&
        shouldShowStep03B
      ) {
        if (allAnswered && checkMcleanCaseForAllQuestions()) {
          // This allAnswered is for Risk questions to check if all the risk questions has been answered
          setSelectedProvider(title);
          setSelectedSlotForAppt({ ...availableSlot });
          handleOpen(false, {
            ...availableSlot,
            obj,
          });
        } else {
          enqueueSnackbar('Please fill all required fields', {
            variant: 'error',
            timeout: 3000,
          });
        }
      } else {
        setSelectedProvider(title);
        setSelectedSlotForAppt({ ...availableSlot });
        handleOpen(false, {
          ...availableSlot,
          obj,
        });
      }
    } else {
      enqueueSnackbar('Please fill all required fields', {
        variant: 'error',
        timeout: 3000,
      });
    }
  };

  const onWithoutCalenderClickHandler = async (obj, title = null) => {
    try {
      const providerDetails = await providerService.getProviderDetail(
        obj.id,
        obj.email
      );
      if (providerDetails && providerDetails.data) {
        // Example assuming degrees_and_license contains a field specifying the license status
        // Check if 'Fully Licensed' is one of the licenses
        const isFullyLicensed =
          providerDetails.data.provider.degrees_and_license.includes(
            'Fully Licensed'
          );

        const newFilters = {
          serviceGroup: step03FormValues.serviceGroup, // Assuming 'service' corresponds to 'serviceGroup'
          serviceType: step03FormValues.serviceType, // From step03FormValues
          clientType: entryTypeFormValues.entryType,
          age: age,
          licenseStatus: isFullyLicensed
            ? 'Fully Licensed'
            : 'Non-Fully Licensed', // Using conditional based on fetched data
          specialScenario: isSpecialScenarioMet(documents, userSessionDetails),
        };

        setDocFilters(newFilters); // Update filters which will trigger useEffect for fetching documents
        // Directly update the provider detail state in the parent component
        setSelectedProviderLicense(
          providerDetails.data.provider.degrees_and_license
        );
      }
    } catch (error) {
      console.error(
        'Failed to fetch provider details or slot information',
        error
      );
    }

    if (
      !(
        isServiceInfoShown() &&
        !(
          step03FormValues.serviceType &&
          !(
            isServicesRequired() &&
            !(step03FormValues.services && step03FormValues.services.length > 0)
          ) &&
          (step03FormValues.Insurance || step03FormValues.Insurance_t)
        )
      ) &&
      !(isReferralShown() && !step04FormValues.aboutUsInfo) &&
      !(
        step04FormValues.aboutUsInfo === 'Insurance List' &&
        !step04FormValues.selectedInsurance
      ) &&
      !(
        step04FormValues.selectedInsurance === 'Other' &&
        !step04FormValues.otherInsurance
      ) &&
      !(
        step04FormValues.aboutUsInfo === 'External Therapist' &&
        !step04FormValues.selectedExternalTherapist
      ) &&
      !(
        step04FormValues.selectedExternalTherapist === 'Other' &&
        !step04FormValues.otherExternalTherapist
      ) &&
      step01FormValues &&
      step01FormValues.first_name &&
      step01FormValues.last_name &&
      step01FormValues.phone &&
      underEighteenForm === true &&
      isValidEmail(step01FormValues.email) &&
      (ccEmailCheck ? email && firstName && lastName : true) &&
      step01FormValues.date &&
      nonFilteredAnswered
    ) {
      providerService
        .getProviderDetail(obj.id, obj.email)
        .then((providerDetails) => {
          if (providerDetails && providerDetails.data) {
            setSelectedProviderLicense(
              providerDetails.data.provider.degrees_and_license
            );
            if (checkMcleanCaseForAllQuestions()) {
              // This if case is for checking McleanCase for All the questions
              setSelectedProvider(title);
              setSelectedSlotForAppt({ ...selectedAvailableSlot });
              handleOpen(true, {
                obj,
                id: null,
                provider_availability_id: null,
                start_date_time: null,
                end_date_time: null,
                status: null,
                updated_by: null,
                reason: null,
                email_status: null,
                createdAt: null,
                updatedAt: null,
                provider_id: obj.id,
                slot_start_date_time: null,
                slot_end_date_time: null,
                frequency: null,
                location: null,
                note: null,
                color: null,
                provider_availabilities: null,
              });
            } else {
              enqueueSnackbar('Please fill all required fields ', {
                variant: 'error',
                timeout: 3000,
              });
            }
          } else {
            console.log(`No provider details found for ${obj.username}`);
          }
        })
        .catch((error) => {
          console.error(
            `Failed to fetch provider details for ${obj.username}`,
            error
          );
          enqueueSnackbar('Failed to fetch provider details', {
            variant: 'error',
            timeout: 3000,
          });
        }); // End of .catch() block
    } else {
      enqueueSnackbar('Please fill all required fields ', {
        variant: 'error',
        timeout: 3000,
      });
    }
  };

  const checkAndVerify = (user) => {
    // eslint-disable-next-line array-callback-return
    const filteredSlots = availableSlots.filter((availableSlotObj) => {
      if (serviceType !== '' && frequency !== 'all') {
        return (
          availableSlotObj.provider_id === user.id &&
          availableSlotObj.service === serviceType &&
          availableSlotObj.frequency.toLowerCase() === frequency.toLowerCase()
        );
      } else if (serviceType !== '') {
        return (
          availableSlotObj.provider_id === user.id &&
          availableSlotObj.service === serviceType
        );
      } else if (frequency !== 'all') {
        return (
          availableSlotObj.frequency.toLowerCase() ===
            frequency.toLowerCase() && availableSlotObj.provider_id === user.id
        );
      }
    });
    if (serviceType === '' && frequency === 'all') {
      return true;
    }
    return filteredSlots.length > 0 ? true : false;
  };

  const filterAvailableSlots = (user) => {
    let filteredSlots = availableSlots.filter((availableSlotObj) => {
      if (serviceType !== '' && frequency !== 'all') {
        return (
          availableSlotObj.provider_id === user.id &&
          availableSlotObj.service === serviceType &&
          availableSlotObj.frequency.toLowerCase() === frequency.toLowerCase()
        );
      } else if (serviceType !== '') {
        return (
          availableSlotObj.provider_id === user.id &&
          availableSlotObj.service === serviceType
        );
      } else if (frequency !== 'all') {
        return (
          availableSlotObj.frequency.toLowerCase() === frequency.toLowerCase()
        );
      } else {
        return availableSlotObj.provider_id === user.id;
      }
    });
    return filteredSlots;
  };

  const checkProviderCardRendered = () => {
    const providerCardElement = document.getElementById('providerCard');
    const firstErrorMessageElement =
      document.querySelector('#firstErrorMessage');

    if (providerCardElement || firstErrorMessageElement) {
      return true;
    }
    return false;
  };

  const hasOnlyHoldSlots = (provider) => {
    if (!provider?.slots || provider.slots.length === 0) return true;

    const hasOpenSlots = provider.slots.some((slot) => slot.type !== 'Hold');

    return !hasOpenSlots;
  };

  const sortHandler = (e, sortOrder, usersFilter) => {
    setSelectedSort(e);

    if (e === 'priorityAge') {
      let sortingDataState = [...usersFilter].sort((first, second) => {
        // 1. Check for Priority Flag
        const priorityA = getProviderYesMaybeText(first)?.provider?.therapy
          ?.providerPriorityScheduling
          ? 1
          : 0;
        const priorityB = getProviderYesMaybeText(second)?.provider?.therapy
          ?.providerPriorityScheduling
          ? 1
          : 0;

        if (priorityA !== priorityB) {
          return priorityA > priorityB ? -1 : 1;
        }

        // 2. Check for Slots 21+ Days Old
        const oldestSlotAgeA = getOldestSlotAge(first);
        const oldestSlotAgeB = getOldestSlotAge(second);

        const hasOldSlotA = oldestSlotAgeA >= 21 ? 1 : 0;
        const hasOldSlotB = oldestSlotAgeB >= 21 ? 1 : 0;

        if (hasOldSlotA !== hasOldSlotB) {
          return hasOldSlotA > hasOldSlotB ? -1 : 1;
        }

        // If both have old slots, compare by oldest slot age
        if (hasOldSlotA && hasOldSlotB) {
          if (oldestSlotAgeA !== oldestSlotAgeB) {
            return oldestSlotAgeA > oldestSlotAgeB ? -1 : 1; // Higher age first
          }
        }

        // 3. Providers with Only 'Hold' Slots Should Appear Last
        const hasOnlyHoldSlotsA = hasOnlyHoldSlots(first) ? 1 : 0;
        const hasOnlyHoldSlotsB = hasOnlyHoldSlots(second) ? 1 : 0;

        if (hasOnlyHoldSlotsA !== hasOnlyHoldSlotsB) {
          return hasOnlyHoldSlotsA > hasOnlyHoldSlotsB ? 1 : -1;
        }

        // 4. Compare by Last Name Alphabetically using your existing code
        let a = first.username?.split(',').join('').split(' ')[1],
          b = second.username?.split(',').join('').split(' ')[1];

        if (a < b) return -1;
        if (a > b) return 1;
        return 0;
      });

      setUsersFilter([...sortingDataState]);
      return [...sortingDataState];
    }

    if (e === 'priority') {
      let sortingDataState = [...usersFilter].sort((first, second) => {
        const priorityA = getProviderYesMaybeText(first)?.provider?.therapy
          ?.providerPriorityScheduling
          ? 1
          : 0;
        const priorityB = getProviderYesMaybeText(second)?.provider?.therapy
          ?.providerPriorityScheduling
          ? 1
          : 0;

        if (priorityA !== priorityB) {
          return priorityA > priorityB ? -1 : 1;
        }

        // New Modified Block: Tiebreaker for available slots 14+ days old
        const slotsA = first?.slots?.filter((slot) => {
          const slotDaysOld = moment().diff(moment(slot.createdAt), 'days');
          return (
            slotDaysOld >= 14 &&
            slot.type === 'Open' &&
            slot.service === 'Therapy'
          );
        });
        const slotsB = second?.slots?.filter((slot) => {
          const slotDaysOld = moment().diff(moment(slot.createdAt), 'days');
          return (
            slotDaysOld >= 14 &&
            slot.type === 'Open' &&
            slot.service === 'Therapy'
          );
        });

        const oldSlotsA = slotsA && slotsA.length > 0 ? 1 : 0;
        const oldSlotsB = slotsB && slotsB.length > 0 ? 1 : 0;
        if (oldSlotsA !== oldSlotsB) {
          return oldSlotsA > oldSlotsB ? -1 : 1;
        }

        const openTherapyA = first?.slots?.some(
          (slot) => slot.type === 'Open' && slot.service === 'Therapy'
        )
          ? 1
          : 0;
        const openTherapyB = second?.slots?.some(
          (slot) => slot.type === 'Open' && slot.service === 'Therapy'
        )
          ? 1
          : 0;

        if (openTherapyA !== openTherapyB) {
          return openTherapyA > openTherapyB ? -1 : 1;
        }

        const allTestingA = first?.slots?.every(
          (slot) => slot.service === 'Testing'
        )
          ? 1
          : 0;
        const allTestingB = second?.slots?.every(
          (slot) => slot.service === 'Testing'
        )
          ? 1
          : 0;

        if (allTestingA !== allTestingB) {
          return allTestingA > allTestingB ? 1 : -1;
        }

        const allHoldA = first?.slots?.every((slot) => slot.type === 'Hold')
          ? 1
          : 0;
        const allHoldB = second?.slots?.every((slot) => slot.type === 'Hold')
          ? 1
          : 0;

        if (allHoldA !== allHoldB) {
          return allHoldA > allHoldB ? 1 : -1;
        }

        // Final Tiebreaker for name
        let a = first.username?.split(',').join('').split(' ')[1],
          b = second.username?.split(',').join('').split(' ')[1];
        return a < b ? -1 : a > b ? 1 : 0;
      });

      setUsersFilter([...sortingDataState]);
      return [...sortingDataState];
    }
    let sortigDataState = [...usersFilter]?.sort(function (first, second) {
      let a = first.username?.split(',').join('').split(' ')[1],
        b = second.username?.split(',').join('').split(' ')[1];
      // eslint-disable-next-line eqeqeq
      if (e == 'name') {
        a = first.username?.split(',').join('').split(' ')[1];
        b = second.username?.split(',').join('').split(' ')[1];
        // eslint-disable-next-line eqeqeq
      } else if (e == 'slot') {
        let firstSlot = first?.slots
          // eslint-disable-next-line eqeqeq
          ?.filter((v, k) => v.type != 'Hold')
          ?.sort((a, b) => {
            let FirstDate = moment(a?.createdAt).valueOf();
            let SecondDate = moment(b?.createdAt).valueOf();
            return sortOrder === 'Ascending'
              ? SecondDate - FirstDate
              : FirstDate - SecondDate;
          });
        let secondSlot = second?.slots
          // eslint-disable-next-line eqeqeq
          ?.filter((v, k) => v.type != 'Hold')
          ?.sort((a, b) => {
            let FirstDate = moment(a?.createdAt).valueOf();
            let SecondDate = moment(b?.createdAt).valueOf();
            return sortOrder === 'Ascending'
              ? SecondDate - FirstDate
              : FirstDate - SecondDate;
          });
        b = firstSlot.length > 0 ? new Date(firstSlot[0]?.createdAt) : '';
        a = secondSlot.length > 0 ? new Date(secondSlot[0]?.createdAt) : '';
        // eslint-disable-next-line eqeqeq
      } else if (e == 'favorites' && step03FormValues?.services?.length > 0) {
        let firstValue = getProviderYesMaybeText(first)?.provider?.therapy
          ?.topIssues
          ? Object.values(
              getProviderYesMaybeText(first)?.provider?.therapy?.topIssues
            )
          : [];
        let secondValue = getProviderYesMaybeText(second)?.provider?.therapy
          ?.topIssues
          ? Object.values(
              getProviderYesMaybeText(second)?.provider?.therapy?.topIssues
            )
          : [];
        let FirstIndex =
          firstValue?.findIndex(
            (value) => value === step03FormValues?.services[0]?.title
          ) || 0;
        let secondIndex =
          secondValue?.findIndex(
            (value) => value === step03FormValues?.services[0]?.title
          ) || 0;

        b = FirstIndex > -1 ? 3 - FirstIndex : FirstIndex;
        a = secondIndex > -1 ? 3 - secondIndex : FirstIndex;
        // eslint-disable-next-line eqeqeq
      } else if (e == 'openings') {
        a =
          (first?.slots?.length > 0 &&
            Array.from(new Set(first?.slots?.map((obj) => obj.id)))
              .map((id) => first?.slots?.find((obj) => obj.id === id))
              // eslint-disable-next-line eqeqeq
              ?.filter((v) => v.type != 'Hold')?.length) ||
          0;
        b =
          (second?.slots?.length > 0 &&
            Array.from(new Set(second?.slots?.map((obj) => obj.id)))
              .map((id) => second?.slots?.find((obj) => obj.id === id))
              // eslint-disable-next-line eqeqeq
              ?.filter((v) => v.type != 'Hold')?.length) ||
          0;
      } else if (e === 'appt') {
        // start_date_time
        let firstSlot = first?.slots?.sort((a, b) => {
          let FirstDate = new Date(a.start_date_time);
          let SecondDate = new Date(b.start_date_time);

          if (FirstDate < SecondDate) {
            return sortOrder === 'Ascending' ? -1 : 1;
          }
          if (FirstDate > SecondDate) {
            return sortOrder === 'Ascending' ? 1 : -1;
          }
          return 0;
        });
        let secondSlot = second?.slots?.sort((a, b) => {
          let FirstDate = new Date(a.start_date_time);
          let SecondDate = new Date(b.start_date_time);

          if (FirstDate < SecondDate) {
            return sortOrder === 'Ascending' ? -1 : 1;
          }
          if (FirstDate > SecondDate) {
            return sortOrder === 'Ascending' ? 1 : -1;
          }
          return 0;
        });
        a = firstSlot.length > 0 ? new Date(firstSlot[0]?.start_date_time) : '';
        b =
          secondSlot.length > 0 ? new Date(secondSlot[0]?.start_date_time) : '';
      }
      if (a < b) {
        return sortOrder === 'Ascending' ? -1 : 1;
      }
      if (a > b) {
        return sortOrder === 'Ascending' ? 1 : -1;
      }
      return 0;
    });
    setUsersFilter([...sortigDataState]);
    return [...sortigDataState];
  };

  useEffect(() => {
    sortHandler(selectedSort, sortOrder, usersFilter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    totalNumOfAppoinntment,
    totalSortForBar,
    totalNumOfHolds,
    totalAvailableSlots,
    priorityFlag,
    globalUsers,
  ]);

  // The below function is getting called in the above useEffect
  const checkMcleanCase = () => {
    // First condition that checks if McLean has been selected
    if (showSchedulingStep03C) {
      setUsersFilter([
        ...state.globalUsers.filter(
          (userFilterObj) =>
            userFilterObj.email === process.env.REACT_APP_MCLEAN_CASE_EMAIL // getting the mail from .env file
        ),
      ]);
    }

    // All other checks for behaviorIssues and exlcusions
    if (
      state.developmentalCondition === 'no' ||
      (state.behaviorIssues && state.behaviorIssues.none) ||
      (state.exclusions && !state.exclusions.none)
    ) {
      setUsersFilter([]);
    }
  };

  const checkMcleanCaseForAllQuestions = () => {
    if (state.showSchedulingStep03C) {
      // parent condition that checks if McLean therapy was selected
      if (
        state.developmentalCondition &&
        state.behaviorIssues !== null &&
        state.behaviorIssues !== undefined &&
        Object.values(state.behaviorIssues).includes(true) &&
        state.exclusions !== null &&
        state.exclusions !== undefined &&
        Object.values(state.exclusions).includes(true)
      ) {
        // Child condition that verifies if McLean condition was selected then it should return true
        return true;
      } else {
        return false;
      }
    } else if (state.serviceType === 'Families') {
      if (
        state.drMcLeansBehavioralTherapyAnswer !== null &&
        state.drMcLeansBehavioralTherapyAnswer !== undefined
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  useEffect(() => {
    async function fetchDocuments() {
      try {
        const response = await ServiceDocuments.getAllDocuments(); // Fetch all documents
        const filteredDocuments = filterDocuments(
          response.data,
          docFilters, // The four Doc filters on the Documents table
          userSessionDetails
        );

        if (userSessionDetails.isProBono) {
          // This is ProBono case then replace the index meaning replacing index 0 with index 1
          setDocuments(replaceIndexWithZero(filteredDocuments));
        } else {
          setDocuments(sortDocuments(filteredDocuments));
        }
      } catch (error) {
        console.error('Error fetching documents:', error);
      }
    }
    fetchDocuments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [docFilters, userSessionDetails]); // Depend on userSessionDetails to refetch when it changes

  return (
    <div>
      <TopBanner showBanner={showBanner} setShowBanner={setShowBanner} />
      {showCustomAlert && (
        <CustomAlert setShowCustomAlert={setShowCustomAlert} />
      )}

      {/* Below is the Appointment Summary Modal */}
      <ApptSummaryModal
        open={apptDetailsOpen}
        onClose={() => {
          setApptDetailsOpen(false);
          window.location.reload();
        }}
        serviceGroup={step03FormValues.serviceGroup}
        services={step03FormValues.services}
        appointmentDetails={{
          name: `${step01FormValues.first_name} ${step01FormValues.last_name}`,
          phone: `${step01FormValues.phone}`,
          email: `${step01FormValues.email}`,
          insurance: `${step03FormValues.Insurance}`,
          provider: `${selectedProvider}`,
          appointmentType: `${selectedApptType}`,
          selectedTime: selectedSlotsForAppt,
          nonFilteredData: nonFilteredData,
          careCoordinator: careCoordinator,
        }}
        docsDue={getDocsDue(
          selectedAvailableSlot?.start_date_time,
          step03FormValues,
          selectedDate // overRideDocDate
        )} // This is the docsDueDate to be shown ApptSummaryModal
        documents={documents}
        feeDetails={feeDetails}
        age={age}
        step03FormValues={step03FormValues}
        step05FormValues={step05FormValues} // Assuming this is an additional field impacting fees
        step06FormValues={step06FormValues}
        updateFeeDetails={handleUpdateFeeDetails}
        selectedPrivatePayAmount={selectedPrivatePayAmount}
        serviceTypeRef={serviceTypeRef}
        insuranceRef={insuranceRef}
        settingRef={settingRef}
        locationRef={locationRef}
        licenseRef={licenseRef}
        focusField={focusField}
        accordionRef1={accordionRef1}
        accordionRef3={accordionRef3}
        accordionRef5={accordionRef5}
        accordionRef6={accordionRef6}
        providerRef={providerRef}
        selectedProviderLicense={selectedProviderLicense} // Pass the selected license
        toggleCardClickable={toggleCardClickable} // Pass the toggle function
        isCardClickable={isCardClickable} // Pass the clickable state
      />
      <div className="mainContainer">
        <MUIv6.FormControl
          style={{ width: '70%', marginTop: '-35px', marginLeft: '30px' }}
        >
          <MUIv6.InputLabel id="demo-simple-select-label">
            New Client Inquiries - DockHealth
          </MUIv6.InputLabel>
          <MUIv6.Select
            labelId="demo-simple-select-label"
            value={selectedDockHealthPatient}
            onChange={(event) => handleDockHealthPatientChange(event)}
          >
            {taskDropdownOptions &&
              taskDropdownOptions.map((task, idx) => (
                <MUIv6.MenuItem key={task.identifier + idx} value={task}>
                  {task.description}
                </MUIv6.MenuItem>
              ))}
          </MUIv6.Select>
        </MUIv6.FormControl>
        <Row xs="12">
          <Col xs="9">
            <MUIv6.Box style={{ marginTop: '-75px', marginLeft: '30px' }}>
              <div>
                <EntryTypeForm
                  entryTypeFormValues={entryTypeFormValues}
                  setEntryTypeFormValues={setEntryTypeFormValues}
                />
                {entryTypeFormValuesValid(entryTypeFormValues) && (
                  <>
                    <MUIv6.Accordion>
                      <MUIv6.AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <MUIv6.Typography>
                          Client's Personal Info
                        </MUIv6.Typography>
                      </MUIv6.AccordionSummary>
                      <MUIv6.AccordionDetails>
                        <SchedulingStep01
                          selectedDockHealthPatient={selectedDockHealthPatient}
                          handlePopulationChange={handlePopulationChange}
                          localFormValues={localFormValues}
                          formValues={formValues}
                          setFormValues={setFormValues}
                          setOpenCancellation={setOpenCancellation}
                          entryTypeFormValues={entryTypeFormValues}
                          step01FormValues={step01FormValues}
                          setUnderEighteenFormResponse={
                            setUnderEighteenFormResponse
                          }
                          underEighteenForm={setUnderEighteenForm}
                          setUnderEighteenForm={setUnderEighteenForm}
                          setStep01FormValues={setStep01FormValues}
                          age={age}
                          userSessionDetails={userSessionDetails}
                          sectionsToShow={[
                            'firstName',
                            'lastName',
                            'dob',
                            'email',
                            'phone',
                            // 'ageRange',
                            'divSep',
                            'divSepModal',
                            'cancellButton',
                          ]}
                        />
                      </MUIv6.AccordionDetails>
                    </MUIv6.Accordion>

                    {shouldShowSegmentOrNot('Service Info') && (
                      <MUIv6.Accordion>
                        <MUIv6.AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel2a-content"
                          id="panel2a-header"
                        >
                          <MUIv6.Typography>Service Info</MUIv6.Typography>
                        </MUIv6.AccordionSummary>
                        <MUIv6.AccordionDetails>
                          <SchedulingStep03
                            insuranceRef={insuranceRef}
                            setNonFilteredData={setNonFilteredData}
                            setNonFilteredAnswered={setNonFilteredAnswered}
                            entryTypeFormValues={entryTypeFormValues}
                            setStep03FormValues={setStep03FormValues}
                            nonFilteredData={nonFilteredData}
                            step03FormValues={step03FormValues}
                            showTextarea={true}
                            onDrMcLeansBehavioralTherapyAnswer={
                              setShowSchedulingStep03C
                            }
                            setShouldShowStep03B={setShouldShowStep03B}
                            selectedPrivatePayAmount={selectedPrivatePayAmount}
                            onPrivatePayAmountChange={
                              handlePrivatePayAmountChange
                            }
                            setServiceTesting={setServiceTesting}
                            setSpecialConsiderationSelected={
                              setSpecialConsiderationSelected
                            }
                            sectionsToShow={[
                              'serviceType',
                              'insurance',
                              'formHelperText',
                              'specialConsiderations',
                              'services',
                              'modality',
                              'modalitySpecialization',
                              'therapySpecializations',
                            ]}
                          />
                        </MUIv6.AccordionDetails>
                      </MUIv6.Accordion>
                    )}
                    {showSchedulingStep03C && (
                      <MUIv6.Accordion>
                        <MUIv6.AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel3a-content"
                          id="panel3a-header"
                        >
                          <MUIv6.Typography>
                            Screening Questions for Dr McLean's Behavioral
                            Therapy
                          </MUIv6.Typography>
                        </MUIv6.AccordionSummary>
                        <MUIv6.AccordionDetails>
                          <SchedulingStep03C
                            setMcLeanConditions={setMcLeanConditions}
                          />
                        </MUIv6.AccordionDetails>
                      </MUIv6.Accordion>
                    )}

                    {shouldShowSegmentOrNot(
                      'Appropriateness for Remote Sessions'
                    ) &&
                      shouldShowStep03B && (
                        <MUIv6.Accordion>
                          <MUIv6.AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                          >
                            <MUIv6.Typography>Risk Questions</MUIv6.Typography>
                          </MUIv6.AccordionSummary>
                          <MUIv6.AccordionDetails>
                            <SchedulingStep03B
                              step03BFormValues={step03BFormValues}
                              suicidalThoughts={suicidalThoughts}
                              selfHarm={selfHarm}
                              setSuicidalThoughts={setSuicidalThoughts}
                              setSelfHarm={setSelfHarm}
                              serviceGroup={step03FormValues.serviceGroup}
                              psychiatricHospitalizationHistory={
                                psychiatricHospitalizationHistory
                              }
                              setPsychiatricHospitalizationHistory={
                                setPsychiatricHospitalizationHistory
                              }
                              serviceType={step03FormValues.serviceType} // Pass the serviceType prop
                              handleSuicidalThoughtType={
                                handleSuicidalThoughtType
                              }
                              clearRiskFilter={clearRiskFilter}
                              riskQuestionsFilter={riskQuestionsFilter}
                              handleAllQuestions={handleAllQuestions}
                              handleSettingChange={handleSettingChange}
                              riskQuestionDB={riskQuestionDB}
                              setRiskQuestionDB={setRiskQuestionDB}
                            />
                          </MUIv6.AccordionDetails>
                        </MUIv6.Accordion>
                      )}

                    {shouldShowSegmentOrNot('Referral') && (
                      <MUIv6.Accordion>
                        <MUIv6.AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel2a-content"
                          id="panel2a-header"
                        >
                          <MUIv6.Typography>Referral</MUIv6.Typography>
                        </MUIv6.AccordionSummary>
                        <MUIv6.AccordionDetails>
                          <SchedulingStep04
                            entryTypeFormValues={entryTypeFormValues}
                            step04FormValues={step04FormValues}
                            setStep04FormValues={setStep04FormValues}
                          />
                        </MUIv6.AccordionDetails>
                      </MUIv6.Accordion>
                    )}

                    {shouldShowSegmentOrNot('Provider Profile Filter') && (
                      <MUIv6.Accordion>
                        <MUIv6.AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel2a-content"
                          id="panel2a-header"
                        >
                          <MUIv6.Typography>
                            Provider's Profile Filters
                          </MUIv6.Typography>
                        </MUIv6.AccordionSummary>
                        <MUIv6.AccordionDetails>
                          <SchedulingStep05
                            licenseRef={licenseRef}
                            step05FormValues={step05FormValues}
                            setStep05FormValues={setStep05FormValues}
                          />
                        </MUIv6.AccordionDetails>
                      </MUIv6.Accordion>
                    )}

                    {!isManual &&
                      shouldShowSegmentOrNot('Cal Event Filters') && (
                        <MUIv6.Accordion ref={accordionRef6}>
                          <MUIv6.AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                          >
                            <MUIv6.Typography>
                              Cal Event Filters (Day/Time, Location, Range,
                              Type, Frequency)
                            </MUIv6.Typography>
                          </MUIv6.AccordionSummary>
                          <MUIv6.AccordionDetails>
                            <SchedulingStep06
                              settingRef={settingRef}
                              locationRef={locationRef}
                              manualEntry={isManual}
                              step06FormValues={step06FormValues}
                              setStep06FormValues={setStep06FormValues}
                              serviceTesting={serviceTesting}
                              settingValues={settingValues}
                            />
                          </MUIv6.AccordionDetails>
                        </MUIv6.Accordion>
                      )}
                  </>
                )}
              </div>
            </MUIv6.Box>
          </Col>
          <Col xs="3">
            <MUIv6.Button
              style={{ left: '15%', marginBottom: '5px' }}
              variant="contained"
              onClick={async (event) => {
                handleLogNonScheduling(event);
              }}
            >
              Log Non-Scheduling
            </MUIv6.Button>
            <div
              style={{
                borderStyle: 'solid',
                borderWidth: '1px',
                borderColor: 'black',
                height: '200px',
                padding: '20px',
                marginBottom: '20px',
              }}
            >
              <div>
                <h6>Dock Health</h6>
              </div>
              {dockHealthTasks.map((task, index) => (
                <div className="row" key={index}>
                  <div className="col s12 m5 l4">
                    <a
                      href={`https://app.dock.health/#/core/tasks/${task.taskList.id}/${task.status}/${task.id}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span>{task.description}</span>
                    </a>
                  </div>
                </div>
              ))}
            </div>

            <div
              style={{
                borderStyle: 'solid',
                borderColor: 'black',
                borderWidth: '1px',
                padding: '20px',
                marginBottom: '20px',
              }}
            >
              <ServiceFeeDisplayComponent
                step03FormValues={step03FormValues}
                step05FormValues={step05FormValues} // Assuming this is an additional field impacting fees
                step06FormValues={step06FormValues}
                updateFeeDetails={handleUpdateFeeDetails}
                age={age}
                selectedPrivatePayAmount={selectedPrivatePayAmount}
                insuranceRef={insuranceRef}
                settingRef={settingRef}
                locationRef={locationRef}
                licenseRef={licenseRef}
                focusField={focusField}
                accordionRef1={accordionRef1}
                accordionRef3={accordionRef3}
                accordionRef5={accordionRef5}
                accordionRef6={accordionRef6}
                providerRef={providerRef}
                selectedProviderLicense={selectedProviderLicense} // Pass the selected license
                toggleCardClickable={toggleCardClickable} // Pass the toggle function
                isCardClickable={isCardClickable} // Pass the clickable state
              />
            </div>
          </Col>
        </Row>

        {entryTypeFormValuesValid(entryTypeFormValues) && (
          <>
            <Row xs="12" style={{ padding: '20px' }}>
              <div>Send the scheduling email to additional people (as CC)</div>
              <MUIv6.Switch
                checked={ccEmailCheck}
                onChange={(event) => {
                  setCcEmailCheck(event.target.checked);
                }}
              />
            </Row>
            {ccEmailCheck && (
              <Row style={{ padding: '20px' }}>
                <div>
                  <div>
                    The clients name and email will be shown in the CC
                    recipients email
                  </div>
                  <MUIv6.Grid container spacing={3}>
                    <MUIv6.Grid item xs={4}>
                      <MUIv6.TextField
                        required
                        // error={props.step01FormValues?.first_name ? false : true}
                        autoComplete="new-password"
                        fullWidth
                        variant="outlined"
                        name="first_name"
                        label="First Name"
                        defaultValue={firstName}
                        onChange={(event) => {
                          setFirstName(event.target.value);
                        }}
                      />
                    </MUIv6.Grid>

                    <MUIv6.Grid item xs={4}>
                      <MUIv6.TextField
                        required
                        // error={props.step01FormValues?.last_name ? false : true}
                        autoComplete="new-password"
                        fullWidth
                        variant="outlined"
                        name="last_name"
                        label="Last Name"
                        onChange={(event) => {
                          setLastName(event.target.value);
                        }}
                        defaultValue={lastName}
                      />
                    </MUIv6.Grid>

                    <MUIv6.Grid item xs={4}>
                      <MUIv6.TextField
                        required
                        // error={props.step01FormValues?.email ? false : true}
                        fullWidth
                        variant="outlined"
                        name="email"
                        label="Email"
                        autoComplete="email"
                        onChange={(event) => {
                          setEmail(event.target.value);
                        }}
                        defaultValue={email}
                      />
                    </MUIv6.Grid>
                  </MUIv6.Grid>
                </div>
              </Row>
            )}
            <Row xs="12">
              <Col xs="2">Schedule Using:</Col>
              <Col>
                <MUIv6.RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={scheduleUsingValue(isManual)}
                  onChange={handleIsManualChange}
                >
                  <MUIv6.FormControlLabel
                    value="Calendar Integration"
                    control={<MUIv6.Radio />}
                    label="Calendar Integration"
                  />
                  <MUIv6.FormControlLabel
                    value="Manual Entry"
                    control={<MUIv6.Radio />}
                    label="Manual Entry"
                  />
                </MUIv6.RadioGroup>
              </Col>
            </Row>
            <div
              style={{
                fontweight: '600',
                fontSize: '25px',
                marginTop: '20px',
                marginBottom: '20px',
              }}
            >
              Providers
            </div>

            <MUIv6.AppBar
              position="static"
              style={{ height: '30px', backgroundColor: 'purple' }}
            >
              <MUIv6.Toolbar
                style={{
                  minHeight: '20px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <MUIv6.Typography
                  variant="body2"
                  style={{ flexGrow: 1, textAlign: 'center', fontSize: '20px' }}
                >
                  Practice Wide: {totalSortForBar} Slots |{' '}
                  {totalNumOfAppoinntment} Appts | {totalNumOfHolds} Holds
                </MUIv6.Typography>
              </MUIv6.Toolbar>
            </MUIv6.AppBar>

            {!isManual && (
              <MUIv6.Box
                sx={{
                  minWidth: 100,
                  maxWidth: '100%',
                  marginBottom: '1em',
                  marginLeft: '30px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start', // Align items to the start
                }}
              >
                <MUIv6.Box
                  display="flex"
                  alignItems="center"
                  sx={{
                    maxWidth: '300px', // Adjust as needed
                    flexGrow: 2, // Increase this to move IconKeyBox to the left
                  }}
                >
                  <MUIv6.FormControl
                    fullWidth
                    sx={{
                      width: '300px', // or another suitable value
                      border: '1px solid gray',
                      borderRadius: '4px',
                      textAlign: 'center',
                    }}
                  >
                    <MUIv6.Select
                      value={selectedSort}
                      defaultValue="priorityAge" // Change from "priority" to "priorityAge"
                      inputProps={{
                        name: 'sort',
                      }}
                      onChange={(e) =>
                        sortHandler(e.target.value, sortOrder, usersFilter)
                      }
                    >
                      <MUIv6.MenuItem value="name">
                        ABC Last Name
                      </MUIv6.MenuItem>
                      <MUIv6.MenuItem value="slot">Age of Slot</MUIv6.MenuItem>
                      <MUIv6.MenuItem value="favorites">
                        Favorites
                      </MUIv6.MenuItem>
                      <MUIv6.MenuItem value="openings">
                        Number of Openings
                      </MUIv6.MenuItem>
                      <MUIv6.MenuItem value="priority">Priority</MUIv6.MenuItem>
                      <MUIv6.MenuItem value="priorityAge">
                        Priority + Age of Slot
                      </MUIv6.MenuItem>
                      <MUIv6.MenuItem value="appt">Soonest Appt</MUIv6.MenuItem>
                    </MUIv6.Select>
                  </MUIv6.FormControl>

                  <MUIv6.IconButton
                    onClick={() => {
                      let sortOrderVar =
                        sortOrder === 'Ascending' ? 'Descending' : 'Ascending';

                      sortHandler(selectedSort, sortOrderVar, usersFilter);
                      setSortOrder(sortOrderVar);
                    }}
                  >
                    {sortOrder === 'Ascending' ? (
                      <ArrowUpwardIcon />
                    ) : (
                      <ArrowDownwardIcon />
                    )}
                  </MUIv6.IconButton>

                  <MUIv6.Tooltip
                    title={
                      <React.Fragment>
                        <div>Priority Sort is default and it sorts by</div>
                        <div>1. Priority Provider</div>
                        <div>
                          2. Providers with available slots 14+ days old.
                        </div>
                        <div>
                          3. Providers that are not in 1st Two Categories with
                          Openings for Therapy
                        </div>
                        <div>4. Providers that Only have Holds</div>
                        <div>5. Providers with slots for Testing Only</div>
                      </React.Fragment>
                    }
                    arrow
                  >
                    <MUIv6.IconButton>
                      <HelpOutlineIcon />
                    </MUIv6.IconButton>
                  </MUIv6.Tooltip>
                </MUIv6.Box>

                <MUIv6.Box sx={{ marginLeft: '50px', width: '100%' }}>
                  <IconKeyBox />
                </MUIv6.Box>
              </MUIv6.Box>
            )}

            {!isManual && (
              <MUIv6.FormControlLabel
                control={
                  <MUIv6.Switch checked={expanded} onChange={handleToggle1} />
                }
                label={expanded ? 'Expanded View' : 'Slim View'}
              />
            )}

            {selectedServicesTitles.includes('Comprehensive Evaluation') && (
              <MUIv6.Typography>
                <br />
                <b>Comprehensive Evaluations</b> - If there are no available
                times that work for a prospective client - click{' '}
                <MUIv6.Link
                  href="https://form.jotform.com/233245137211142"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <b>here</b>
                </MUIv6.Link>{' '}
                in order to complete the{' '}
                <b>Comprehensive Evaluation Initial Inquiry Form</b>
                <br />
                <br /> {/* Two line breaks for an extra line space */}
              </MUIv6.Typography>
            )}

            {selectedServicesTitles.includes('Adoption Evaluations') && (
              <MUIv6.Typography>
                <br />
                <b>Adoption Evaluations</b> - If there are no available times
                that work for a prospective client - click{' '}
                <MUIv6.Link
                  href="https://hipaa.jotform.com/form/210253903688054"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <b>here</b>
                </MUIv6.Link>{' '}
                in order to complete the{' '}
                <b>Adoption Evaluation Initial Inquiry Form</b>
                <br />
                <br /> {/* Two line breaks for an extra line space */}
              </MUIv6.Typography>
            )}

            {(totalAvailableSlots === 0 ||
              // eslint-disable-next-line array-callback-return
              usersFilter.map((obj) => {
                availableSlots.filter((availableSlotObj) => {
                  return availableSlotObj.provider_id === obj.id;
                });
              }).length === 0 ||
              usersFilter.length === 0) && (
              <MUIv6.Typography id="firstErrorMessage">
                Unfortunately, there are no available providers{' '}
                {isManual
                  ? 'that meet your search criteria'
                  : 'with any openings '}
                . Please either expand your search
                {isManual
                  ? ' by changing your filters.'
                  : ' to include a wider date range or expand other filters.'}{' '}
                If you still can't find an opening you can search our referral
                list for providers outside of PsychCare by clicking{' '}
                <MUIv6.Link
                  href="https://sites.google.com/psychcaremd.com/home/referrals_2/all"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  here
                </MUIv6.Link>
              </MUIv6.Typography>
            )}

            {!checkProviderCardRendered() && (
              <MUIv6.Typography>
                Unfortunately, there are no available providers{' '}
                {isManual
                  ? 'that meet your search criteria'
                  : 'with any openings '}
                . Please either expand your search
                {isManual
                  ? ' by changing your filters.'
                  : ' to include a wider date range or expand other filters.'}{' '}
                If you still can't find an opening you can search our referral
                list for providers outside of PsychCare by clicking{' '}
                <MUIv6.Link
                  href="https://sites.google.com/psychcaremd.com/home/referrals_2/all"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  here
                </MUIv6.Link>
              </MUIv6.Typography>
            )}

            <MUIv6.Popover
              anchorEl={popoverState.anchorEl}
              open={popoverState.open}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              {popoverState.text}
            </MUIv6.Popover>

            <Row xs="12">
              <Col xs="12">
                <Row className="grid-container">
                  {usersFilter.map((user, index) => {
                    const provider = providersOptions.find(
                      (provider) =>
                        provider.provider_email === user.provider_email
                    );
                    if (!provider) {
                      return null;
                    }
                    // eslint-disable-next-line no-lone-blocks
                    {
                      // filterSlots(availableSlots, user);
                    }
                    let providerSlots = availableSlots.filter(
                      (slot) => slot.provider_id === provider.id
                    );

                    const uniqueLocationDetailsArray = providerSlots.reduce(
                      (uniqueArray, slot) => {
                        const isAnySetting = slot.setting === 'Any Setting';
                        const isRemoteOnly = slot.setting === 'Remote Only';
                        const isDuplicate = uniqueArray.some(
                          (locationDetails) =>
                            (isAnySetting &&
                              locationDetails.location === slot.location) ||
                            (isRemoteOnly &&
                              locationDetails.location === 'Remote')
                        );
                        if (!isDuplicate) {
                          uniqueArray.push({
                            location: slot.location,
                            isRemote: isRemoteOnly,
                            isAnySetting: isAnySetting,
                          });
                        }
                        return uniqueArray;
                      },
                      []
                    );

                    return (
                      (user?.slots?.length > 0 || isManual) &&
                      checkAndVerify(user) && (
                        <div
                          key={user.id}
                          id="providerCard"
                          className="wrapperProvider" // Don't remove classname check css file
                        >
                          <div ref={providerRef} id="providers-section"></div>

                          <ProviderCard
                            step03FormValues={step03FormValues}
                            step05FormValues={step05FormValues}
                            onWithoutCalenderClickHandler={
                              onWithoutCalenderClickHandler
                            }
                            entryTypeFormValues={entryTypeFormValues}
                            isManual={isManual}
                            onSlotClickHadler={onSlotClickHadler}
                            expandedOfToggle={expanded}
                            key={provider.id}
                            title={user.username}
                            image={
                              user.imageUrl ||
                              'https://img.freepik.com/free-vector/doctor-character-background_1270-84.jpg'
                            }
                            getProviderYesMaybeText={getProviderYesMaybeText}
                            obj={user}
                            locationAbbreviation={getLocationAbbreviation(
                              availableSlots.location
                            )}
                            availableSlots={filterAvailableSlots(user)}
                            uniqueLocationDetails={uniqueLocationDetailsArray}
                            provider={provider}
                            providerSlots={providerSlots}
                            getProviderDetail={
                              providerService.getProviderDetail
                            } // passing getProviderDetail function
                            setSelectedProvider={handleUpdateSelectedProvider} // Pass the function to update the selected provider
                            isCardClickable={isCardClickable} // Pass the clickable state
                          />
                        </div>
                      )
                    );
                  })}
                </Row>
              </Col>
            </Row>
          </>
        )}
      </div>

      {/* The below is AddScheduling Modal that gets appeared for making the appointment*/}
      <AddSchedulingModal
        open={open}
        handleClose={handleClose}
        selectedAvailableSlot={selectedAvailableSlot}
        existingClientTurning18={existingClientTurning18}
        selectedDuration={selectedDuration}
        handleChangeDuration={handleChangeDuration}
        durations={durations}
        selectedStartDate={selectedStartDate}
        setSelectedStartDate={setSelectedStartDate}
        testingOrTherapyClientComingToOffice={
          testingOrTherapyClientComingToOffice
        }
        getTherapyDocsDueSection={getTherapyDocsDueSection}
        selectedApptType={selectedApptType}
        selectedEndDate={selectedEndDate}
        handleSelectedEndDate={handleSelectedEndDate}
        setSelectedAvailableSlot={setSelectedAvailableSlot}
        selectedOfficeLocation={selectedOfficeLocation}
        selectedProviders={selectedProviders}
        handleChange={handleChange}
        handleChangeApptType={handleChangeApptType}
        activeCareCoordinators={activeCareCoordinators}
        handleChangeOfficeLocation={handleChangeOfficeLocation}
        officeLocations={officeLocations}
        step01FormValues={step01FormValues}
        isValidEmail={isValidEmail}
        exceptionText={exceptionText}
        setExceptionText={setExceptionText}
        setTaMrn={setTaMrn}
        createSchedule={createSchedule}
        exceptionChecked={exceptionChecked}
        setExceptionChecked={setExceptionChecked}
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
        taMrn={taMrn}
        setOpen={setOpen}
        nonFilteredData={nonFilteredData}
        setNonFilteredData={setNonFilteredData}
        userSessionDetails={userSessionDetails}
        selectedCareCoordinator={selectedCareCoordinator}
        setSelectedCareCoordinator={setSelectedCareCoordinator}
        feeDetails={feeDetails}
        age={age}
        documents={documents}
        entryTypeFormValues={entryTypeFormValues}
        step03FormValues={step03FormValues}
        step05FormValues={step05FormValues} // Assuming this is an additional field impacting fees
        step06FormValues={step06FormValues}
        updateFeeDetails={handleUpdateFeeDetails}
        selectedPrivatePayAmount={selectedPrivatePayAmount}
        serviceTypeRef={serviceTypeRef}
        insuranceRef={insuranceRef}
        settingRef={settingRef}
        locationRef={locationRef}
        licenseRef={licenseRef}
        focusField={focusField}
        accordionRef1={accordionRef1}
        accordionRef3={accordionRef3}
        accordionRef5={accordionRef5}
        accordionRef6={accordionRef6}
        providerRef={providerRef}
        selectedProviderLicense={selectedProviderLicense} // Pass the selected license
        toggleCardClickable={toggleCardClickable} // Pass the toggle function
        isCardClickable={isCardClickable}
      />

      {/* The below is Inquiry Scheduling Modal */}
      <InQuirySchedulingModal
        openLogNonScheduling={openLogNonScheduling}
        handleCloseOpenLogNonScheduling={handleCloseOpenLogNonScheduling}
        handleChangeResult={handleChangeResult}
        handleChange={handleChange}
        providersOptions={providersOptions}
        selectedResult={selectedResult}
        selectedReason={selectedReason}
        handleChangeReason={handleChangeReason}
        createLogNonScheduling={createLogNonScheduling}
        reasonValue={reasonValue}
        setReasonValue={setReasonValue}
        selectedCareCoordinator={selectedCareCoordinator}
        setSelectedCareCoordinator={setSelectedCareCoordinator}
        selectedProvidersNonScheduling={selectedProvidersNonScheduling}
        handleChangeNonScheduling={handleChangeNonScheduling}
        activeProviders={activeProviders}
        setOpenLogNonScheduling={setOpenLogNonScheduling}
        taskDropdownOptions={taskDropdownOptions}
        activeCareCoordinators={activeCareCoordinators}
      />

      <SnackBarSchedulingAlert
        alertOpen={alertOpen}
        alertMessage={alertMessage}
        handleAlertClose={handleAlertClose}
      />
    </div>
  );
}
