import React, { useState, useEffect, useCallback, memo } from 'react';
import {
  Modal,
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  IconButton,
  Chip,
} from '@mui/material';
import { HelpOutline as HelpOutlineIcon } from '@mui/icons-material';
import { cptInsuranceService } from '../../services';
import '../../styles/FeeDisplay.css';

// Sticky header style
const stickyHeaderStyle = {
  position: 'sticky',
  top: 0,
  backgroundColor: '#fff',
  zIndex: 10, // Higher than the body content
};

// Modal content style
const modalStyle = {
  position: 'absolute',
  marginTop: '1vh',
  top: '40%',
  left: '50%',
  transform: 'translate(-50%, -45%)',
  width: 800,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  outline: 'none',
  overflowY: 'auto',
  maxHeight: '95vh',
};

const serviceTypeMappings = {
  All: [
    'Individuals',
    'Couples',
    'Family',
    'Group',
    'Psychological Testing',
    'Forensic Testing',
  ],
};
const specificCPTCodesForFilterNote = ['96130', '96131', '96136', '96137'];

const serviceTitleToCPTCodes = {
  'Autism Spectrum Disorder - Under 18 Evaluation': ['Autist'],
  'Autism Spectrum Disorder - Adult Evaluation': ['AdAutsm'],
  'Adoption Evaluations': ['Adopval'],
  'ADHD Adult Diagnostic Evaluation ': ['ADHD'],
  'Bariatric Surgical Psychological Evaluations': ['Bar'],
  'Differential Adult Diagnosis Evaluation': ['Dde'],
  'Comprehensive Evaluation': ['Comp'],
  'ADHD Child Comprehensive Evaluation': ['ChADHD'],
  'Child Psycho-Educational Evaluation': ['ChPsyEd'],
  'ADHD/Autism Child Comprehensive Combined Evaluation': ['ChADHDAut'],
  default: ['90791', '96130', '96131', '96136', '96137'],
};

const specificTestingServiceList = [
  'ADHD Diagnostic Evaluation', // Replace with actual service names
  'Bariatric Surgical Psychological Evaluations',
  'Comprehensive Evaluation',
  'Autism Spectrum Disorder - Adult Evaluation',
  'Differential Diagnosis Evaluation',
];

function ServiceFeeDisplayComponent({
  step03FormValues,
  step05FormValues,
  step06FormValues,
  age,
  updateFeeDetails,
  selectedPrivatePayAmount,
  insuranceRef,
  settingRef,
  locationRef,
  licenseRef,
  focusField,
  accordionRef1,
  accordionRef3,
  accordionRef5,
  accordionRef6,
  providerRef,
  selectedProviderLicense, // Receive the selected license
  toggleCardClickable, // Receive the function to toggle card clickable state
  isCardClickable, // Receive the clickable state
}) {
  const [selectedCPTCodes, setSelectedCPTCodes] = useState([]);
  const { Insurance, Insurance_t, serviceType, serviceGroup, services } =
    step03FormValues;
  const { license } = step05FormValues;
  const [modalOpen, setModalOpen] = useState(false);
  const isTesting =
    serviceType === 'Psychological Testing' ||
    serviceType === 'Forensic Testing';
  const handleOpenModal = () => setModalOpen(true);
  const handleCloseModal = () => setModalOpen(false);
  const [feeDetails, setFeeDetails] = useState({});
  const areSelectionsMade =
    serviceType &&
    (serviceGroup ||
      (Insurance && serviceType) ||
      (Insurance_t && serviceType));

  const [selectedSetting] = useState(
    step06FormValues.setting && step06FormValues.setting.length > 0
      ? step06FormValues.setting[0]
      : ''
  );

  const [selectedLocations, setSelectedLocations] = useState(
    step06FormValues.location && step06FormValues.location.length > 0
      ? step06FormValues.location.map((loc) => ({ title: loc.title }))
      : []
  );

  const isForensicService = serviceType === 'Forensic Testing';
  const isAdoptionEvaluation =
    Array.isArray(services) &&
    services.some((service) => service.title.includes('Adoption Evaluations'));
  const shouldShowHelpIcon =
    areSelectionsMade &&
    !(
      (serviceType === 'Psychological Testing' ||
        serviceType === 'Forensic Testing') &&
      (!services || services.length === 0)
    );

  const isChildAutismUnder18 =
    Array.isArray(services) &&
    services.some((service) =>
      service.title.includes('Autism Spectrum Disorder - Under 18 Evaluation')
    );

  const isChildADHDUnder18 =
    Array.isArray(services) &&
    services.some((service) =>
      service.title.includes('ADHD Child Comprehensive Evaluation')
    );

  const isChildPsycEd =
    Array.isArray(services) &&
    services.some((service) =>
      service.title.includes('Child Psycho-Educational Evaluation')
    );

  const isChildCombADHDAutismUnder18 =
    Array.isArray(services) &&
    services.some((service) =>
      service.title.includes(
        'ADHD/Autism Child Comprehensive Combined Evaluation'
      )
    );

  const [cptFeeRanges, setCptFeeRanges] = useState({
    90791: { min: null, max: null },
    90837: { min: null, max: null },
    90834: { min: null, max: null },
    90846: { min: null, max: null },
    90847: { min: null, max: null },
    90853: { min: null, max: null },
    96130: { min: null, max: null },
    96131: { min: null, max: null },
    96136: { min: null, max: null },
    96137: { min: null, max: null },
    Autist: { min: null, max: null },
    Adopval: { min: null, max: null },
    Bar: { min: null, max: null },
    ADHD: { min: null, max: null },
    ChADHD: { min: null, max: null },
    ChADHDAut: { min: null, max: null },
    ForenHr: { min: null, max: null },
    TestimHr: { min: null, max: null },
    ForenHlfDy: { min: null, max: null },
    ForenFulDy: { min: null, max: null },
  });

  const getCPTCodesForServiceType = useCallback(
    (serviceType, Insurance, Insurance_t) => {
      const effectiveInsurance = Insurance || Insurance_t; // Use either Insurance or Insurance_t

      const baseCPTCodes = {
        Individuals: [
          '90791',
          // Adjusting CPT codes based on Insurance or lack thereof
          effectiveInsurance === 'Private Pay' ||
          effectiveInsurance === 'Medical Assistance'
            ? '90834'
            : !effectiveInsurance && serviceGroup === 'Therapy'
            ? '90834'
            : '90837',
        ],
        Couples: ['90791', '90847'],
        Families: ['90791', '90847'],
        Groups: ['90853'],
        'Psychological Testing': [
          '90791',
          '96130',
          '96131',
          '96136',
          '96137',
          'Autist',
          'Bar',
          'ADHD',
          'AdAutsm',
          'ChADHD',
          'ChADHDAut',
        ],
        'Forensic Testing': [
          'Adopval',
          'ForenHr',
          'TestimHr',
          'ForenHlfDy',
          'ForenFulDy',
        ],
      };

      return baseCPTCodes[serviceType] || [];
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const hasDifferentMinMax = (cptFeeRanges, codes) =>
    codes.every(
      (code) =>
        cptFeeRanges[code] && cptFeeRanges[code].min !== cptFeeRanges[code].max
    );

  const shouldShowNote =
    serviceGroup === 'Therapy'
      ? cptFeeRanges['90791'] &&
        cptFeeRanges['90791'].min !== cptFeeRanges['90791'].max
      : serviceType === 'Psychological Testing' &&
        cptFeeRanges['90791'] &&
        cptFeeRanges['90791'].min !== cptFeeRanges['90791'].max &&
        hasDifferentMinMax(cptFeeRanges, specificCPTCodesForFilterNote);

  const fetchBillingRates = async () => {
    try {
      const response = await cptInsuranceService.getAllBillingRates();
      const effectiveInsurance = Insurance || Insurance_t; // Use either Insurance or Insurance_t

      // Filter rates that are active and match the selected insurance (if Insurance or Insurance_t is not null)
      const filteredRates = response.data.filter(
        (rate) =>
          rate.end_date === null &&
          (!effectiveInsurance || rate.insurance === effectiveInsurance)
      );

      return filteredRates; // Return filtered data
    } catch (error) {
      // console.error('Error fetching billing rates:', error);
      throw error; // Rethrowing the error to handle it in the calling function
    }
  };

  const getCPTCodesForServices = useCallback(
    (services = [], Insurance, Insurance_t) => {
      const effectiveInsurance = Insurance || Insurance_t; // Use either Insurance or Insurance_t

      // Check if services is correctly formatted as an array and has items
      if (!Array.isArray(services) || services.length === 0) {
        return []; // Return empty array if no services or not an array
      }

      // Additional check for proper structure of each service item
      if (services.some((service) => typeof service.title !== 'string')) {
        return []; // Return empty if any service entry is improperly formatted
      }

      // Filter out only the highlighted services from the array
      const cptCodes = services.flatMap((service) => {
        if (
          effectiveInsurance === 'Private Pay' &&
          serviceTitleToCPTCodes[service.title]
        ) {
          return serviceTitleToCPTCodes[service.title];
        }
        /* The below condition has been temporarily commented by Shamoon don't remove it 
        This comment is a fix for that Service Group Therapy and private pay should not change
        the fee values
        */
        // else if (
        //   effectiveInsurance === 'Private Pay' &&
        //   !serviceTitleToCPTCodes[service.title]
        // ) {
        //   return serviceTitleToCPTCodes['default'];
        // }

        return serviceTitleToCPTCodes[service.title] || [];
      });

      return [...new Set(cptCodes)]; // Return unique CPT codes to avoid duplicates
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const getLicenseLevel = () => {
    if (selectedProviderLicense) {
      const hasDoctoral = selectedProviderLicense.includes('Doctoral Level');
      const hasFullyLicensed =
        selectedProviderLicense.includes('Fully Licensed');
      if (hasDoctoral && hasFullyLicensed) {
        return 'Doctoral';
      }
      return 'Masters';
    }
    if (!Array.isArray(license) || license.length === 0) {
      return 'Any'; // Default to 'Any' when license array is empty
    }
    const hasDoctoral = license.some((item) => item.title === 'Doctoral Level');
    const hasFullyLicensed = license.some(
      (item) => item.title === 'Fully Licensed'
    );
    const hasAny = license.some((item) => item.title === 'Any');

    if (hasDoctoral && hasFullyLicensed && !hasAny) {
      return 'Doctoral';
    }
    if (hasAny) {
      return 'Any';
    }
    return 'Masters'; // Default to Masters if no specific 'Any' or 'Doctoral and Fully Licensed' condition is met
  };

  const shouldDisplayFees = () => {
    const isSpecialTesting =
      serviceType === 'Psychological Testing' ||
      serviceType === 'Forensic Testing';
    const hasSelectedServices = services && services.length > 0;
    return (
      !isSpecialTesting ||
      (isSpecialTesting && hasSelectedServices) ||
      isTesting
    );
  };

  const isRateRelevant = (
    rate,
    Insurance,
    Insurance_t,
    serviceGroup,
    serviceType,
    selectedLocations,
    selectedSetting,
    service
  ) => {
    const effectiveInsurance = Insurance || Insurance_t; // Use either Insurance or Insurance_t
    const insuranceMatches = effectiveInsurance
      ? rate.insurance === effectiveInsurance
      : false; // Only include rates for the selected insurance, exclude all if none selected
    const serviceGroupMatches =
      !serviceGroup || rate.service_group === serviceGroup;

    // Check if service selection is relevant based on service group
    const matchesService =
      serviceGroup === 'Therapy'
        ? true
        : service
        ? rate.service === service
        : true;

    const expandedServiceTypes = serviceTypeMappings[rate.service_type] || [
      rate.service_type,
    ];
    const serviceTypeSubtypeMatches =
      !services || services.includes(rate.service_type_subtype);
    const serviceTypeMatches =
      !serviceType || expandedServiceTypes.includes(serviceType);

    const rateLocations = rate.office_location
      ?.split(',')
      ?.map((loc) => loc.trim());

    // Adjust for "Remote Only" setting
    const effectiveLocations = selectedSetting?.includes('Remote')
      ? [...selectedLocations, 'Silver Spring']
      : selectedLocations;

    const locationMatches =
      !rate.office_location ||
      effectiveLocations.length === 0 ||
      rateLocations.some((loc) => effectiveLocations.includes(loc));

    return (
      insuranceMatches &&
      serviceGroupMatches &&
      matchesService &&
      serviceTypeMatches &&
      locationMatches &&
      serviceTypeSubtypeMatches
    );
  };

  const calculateFeeRanges = (billingRates, selectedCPTCodes) => {
    const licenseLevel = getLicenseLevel();

    const feeDetailsInit = {};
    selectedCPTCodes.forEach((code) => {
      feeDetailsInit[code] = { min: Infinity, max: -Infinity, details: [] };
    });

    billingRates.forEach((rate) => {
      if (!selectedCPTCodes.includes(rate.cpt_code)) return;

      const amounts = {
        masters:
          rate.masters_amount !== null ? parseFloat(rate.masters_amount) : null,
        doctoral:
          rate.doctoral_amount !== null
            ? parseFloat(rate.doctoral_amount)
            : null,
        doctoral_low:
          rate.doctoral_low_range !== null
            ? parseFloat(rate.doctoral_low_range)
            : null,
        doctoral_high:
          rate.doctoral_high_range !== null
            ? parseFloat(rate.doctoral_high_range)
            : null,
      };

      if (licenseLevel === 'Doctoral') {
        if (amounts.doctoral_low !== null && amounts.doctoral_high !== null) {
          feeDetailsInit[rate.cpt_code].min = Math.min(
            feeDetailsInit[rate.cpt_code].min,
            amounts.doctoral_low
          );
          feeDetailsInit[rate.cpt_code].max = Math.max(
            feeDetailsInit[rate.cpt_code].max,
            amounts.doctoral_high
          );
        } else if (amounts.doctoral !== null) {
          feeDetailsInit[rate.cpt_code].min = Math.min(
            feeDetailsInit[rate.cpt_code].min,
            amounts.doctoral
          );
          feeDetailsInit[rate.cpt_code].max = Math.max(
            feeDetailsInit[rate.cpt_code].max,
            amounts.doctoral
          );
        }
      } else if (licenseLevel === 'Any') {
        if (amounts.masters !== null) {
          feeDetailsInit[rate.cpt_code].min = Math.min(
            feeDetailsInit[rate.cpt_code].min,
            amounts.masters
          );
          feeDetailsInit[rate.cpt_code].max = Math.max(
            feeDetailsInit[rate.cpt_code].max,
            amounts.masters
          );
        }
        if (amounts.doctoral_low !== null && amounts.doctoral_high !== null) {
          feeDetailsInit[rate.cpt_code].min = Math.min(
            feeDetailsInit[rate.cpt_code].min,
            amounts.doctoral_low
          );
          feeDetailsInit[rate.cpt_code].max = Math.max(
            feeDetailsInit[rate.cpt_code].max,
            amounts.doctoral_high
          );
        } else if (amounts.doctoral !== null) {
          feeDetailsInit[rate.cpt_code].min = Math.min(
            feeDetailsInit[rate.cpt_code].min,
            amounts.doctoral
          );
          feeDetailsInit[rate.cpt_code].max = Math.max(
            feeDetailsInit[rate.cpt_code].max,
            amounts.doctoral
          );
        }
      } else {
        if (amounts.masters !== null) {
          feeDetailsInit[rate.cpt_code].min = Math.min(
            feeDetailsInit[rate.cpt_code].min,
            amounts.masters
          );
          feeDetailsInit[rate.cpt_code].max = Math.max(
            feeDetailsInit[rate.cpt_code].max,
            amounts.masters
          );
        }
      }

      feeDetailsInit[rate.cpt_code].details.push({
        cptCode: rate.cpt_code,
        insurance: rate.insurance || '',
        location: rate.office_location || '',
        mastersAmount: rate.masters_amount !== null ? rate.masters_amount : '',
        doctoralAmount:
          rate.doctoral_amount !== null ? rate.doctoral_amount : '',
        mastersRange:
          rate.masters_low_range !== null && rate.masters_high_range !== null
            ? `${rate.masters_low_range}-${rate.masters_high_range}`
            : '',
        doctoralRange:
          rate.doctoral_low_range !== null && rate.doctoral_high_range !== null
            ? `${rate.doctoral_low_range}-${rate.doctoral_high_range}`
            : '',
        selectedAmount:
          amounts.doctoral !== null
            ? amounts.doctoral.toFixed(2)
            : amounts.masters !== null
            ? amounts.masters.toFixed(2)
            : 'N/A',
        notes: rate.notes || '',
      });
    });

    Object.keys(feeDetailsInit).forEach((code) => {
      feeDetailsInit[code].min = isFinite(feeDetailsInit[code].min)
        ? feeDetailsInit[code].min
        : null;
      feeDetailsInit[code].max = isFinite(feeDetailsInit[code].max)
        ? feeDetailsInit[code].max
        : null;
    });

    setFeeDetails(feeDetailsInit);
    return feeDetailsInit;
  };

  const calculateAverageLow = (feeDetails) => {
    const diagnosticFee = feeDetails['90791'] ? feeDetails['90791'].max : 0; // Use the doctoral rate for Testing
    const evaluationServicesFee =
      (feeDetails['96130'] ? feeDetails['96130'].min : 0) +
      (feeDetails['96131'] ? feeDetails['96131'].min * 3 : 0);
    const testAdministrationFee =
      (feeDetails['96136'] ? feeDetails['96136'].min : 0) +
      (feeDetails['96137'] ? feeDetails['96137'].min * 3 : 0);
    const averageLow =
      diagnosticFee + evaluationServicesFee + testAdministrationFee;

    return isNaN(averageLow) ? 0 : averageLow;
  };

  const calculateAverageHigh = (feeDetails) => {
    const diagnosticFee = feeDetails['90791'] ? feeDetails['90791'].max : 0; // Use the doctoral rate for Testing
    const evaluationServicesFee =
      (feeDetails['96130'] ? feeDetails['96130'].max : 0) +
      (feeDetails['96131'] ? feeDetails['96131'].max * 5 : 0);
    const testAdministrationFee =
      (feeDetails['96136'] ? feeDetails['96136'].max : 0) +
      (feeDetails['96137'] ? feeDetails['96137'].max * 7 : 0);
    const averageHigh =
      diagnosticFee + evaluationServicesFee + testAdministrationFee;
    return isNaN(averageHigh) ? 0 : averageHigh;
  };

  const calculateBariatricLow = (feeDetails) => {
    const diagnosticFee = feeDetails['90791'] ? feeDetails['90791'].max : 0;
    const evaluationServicesFee =
      (feeDetails['96130'] ? feeDetails['96130'].min : 0) +
      (feeDetails['96131'] ? feeDetails['96131'].min * 1 : 0);
    const testAdministrationFee =
      (feeDetails['96136'] ? feeDetails['96136'].min : 0) +
      (feeDetails['96137'] ? feeDetails['96137'].min * 3 : 0);
    const bariatricLow =
      diagnosticFee + evaluationServicesFee + testAdministrationFee;
    return isNaN(bariatricLow) ? 0 : bariatricLow;
  };

  const calculateBariatricHigh = (feeDetails) => {
    const diagnosticFee = feeDetails['90791'] ? feeDetails['90791'].max : 0;
    const evaluationServicesFee =
      (feeDetails['96130'] ? feeDetails['96130'].max : 0) +
      (feeDetails['96131'] ? feeDetails['96131'].max * 2 : 0);
    const testAdministrationFee =
      (feeDetails['96136'] ? feeDetails['96136'].max : 0) +
      (feeDetails['96137'] ? feeDetails['96137'].max * 5 : 0);
    const bariatricHigh =
      diagnosticFee + evaluationServicesFee + testAdministrationFee;
    return isNaN(bariatricHigh) ? 0 : bariatricHigh;
  };

  const calculateAdultAutismLow = (feeDetails) => {
    const diagnosticFee = feeDetails['90791'] ? feeDetails['90791'].max : 0;
    const evaluationServicesFee =
      (feeDetails['96130'] ? feeDetails['96130'].min : 0) +
      (feeDetails['96131'] ? feeDetails['96131'].min * 3 : 0);
    const testAdministrationFee =
      (feeDetails['96136'] ? feeDetails['96136'].min : 0) +
      (feeDetails['96137'] ? feeDetails['96137'].min * 5 : 0);
    const adultAutismLow =
      diagnosticFee + evaluationServicesFee + testAdministrationFee;
    return isNaN(adultAutismLow) ? 0 : adultAutismLow;
  };

  const calculateAdultAutismHigh = (feeDetails) => {
    const diagnosticFee = feeDetails['90791'] ? feeDetails['90791'].max : 0;
    const evaluationServicesFee =
      (feeDetails['96130'] ? feeDetails['96130'].max : 0) +
      (feeDetails['96131'] ? feeDetails['96131'].max * 5 : 0);
    const testAdministrationFee =
      (feeDetails['96136'] ? feeDetails['96136'].max : 0) +
      (feeDetails['96137'] ? feeDetails['96137'].max * 7 : 0);
    const adultAutismHigh =
      diagnosticFee + evaluationServicesFee + testAdministrationFee;
    return isNaN(adultAutismHigh) ? 0 : adultAutismHigh;
  };

  const calculateDifferentialLow = (feeDetails) => {
    const diagnosticFee = feeDetails['90791'] ? feeDetails['90791'].max : 0;
    const evaluationServicesFee =
      (feeDetails['96130'] ? feeDetails['96130'].min : 0) +
      (feeDetails['96131'] ? feeDetails['96131'].min * 3 : 0);
    const testAdministrationFee =
      (feeDetails['96136'] ? feeDetails['96136'].min : 0) +
      (feeDetails['96137'] ? feeDetails['96137'].min * 5 : 0);
    const differentialLow =
      diagnosticFee + evaluationServicesFee + testAdministrationFee;
    return isNaN(differentialLow) ? 0 : differentialLow;
  };

  const calculateDifferentialHigh = (feeDetails) => {
    const diagnosticFee = feeDetails['90791'] ? feeDetails['90791'].max : 0;
    const evaluationServicesFee =
      (feeDetails['96130'] ? feeDetails['96130'].max : 0) +
      (feeDetails['96131'] ? feeDetails['96131'].max * 5 : 0);
    const testAdministrationFee =
      (feeDetails['96136'] ? feeDetails['96136'].max : 0) +
      (feeDetails['96137'] ? feeDetails['96137'].max * 7 : 0);
    const differentialHigh =
      diagnosticFee + evaluationServicesFee + testAdministrationFee;
    return isNaN(differentialHigh) ? 0 : differentialHigh;
  };

  const calculateComprehensiveLow = (feeDetails) => {
    const diagnosticFee = feeDetails['90791'] ? feeDetails['90791'].max : 0;
    const evaluationServicesFee =
      (feeDetails['96130'] ? feeDetails['96130'].min : 0) +
      (feeDetails['96131'] ? feeDetails['96131'].min * 5 : 0);
    const testAdministrationFee =
      (feeDetails['96136'] ? feeDetails['96136'].min : 0) +
      (feeDetails['96137'] ? feeDetails['96137'].min * 3 : 0);
    const comprehensiveLow =
      diagnosticFee + evaluationServicesFee + testAdministrationFee;
    return isNaN(comprehensiveLow) ? 0 : comprehensiveLow;
  };

  const calculateComprehensiveHigh = (feeDetails) => {
    const diagnosticFee = feeDetails['90791'] ? feeDetails['90791'].max : 0;
    const evaluationServicesFee =
      (feeDetails['96130'] ? feeDetails['96130'].max : 0) +
      (feeDetails['96131'] ? feeDetails['96131'].max * 5 : 0);
    const testAdministrationFee =
      (feeDetails['96136'] ? feeDetails['96136'].max : 0) +
      (feeDetails['96137'] ? feeDetails['96137'].max * 9 : 0);
    const comprehensiveHigh =
      diagnosticFee + evaluationServicesFee + testAdministrationFee;

    return isNaN(comprehensiveHigh) ? 0 : comprehensiveHigh;
  };

  const calculateChildAutismLow = (feeDetails) => {
    const autismDetails = feeDetails['Autist']?.details?.[0];

    if (autismDetails && autismDetails.doctoralRange) {
      const lowRange = parseFloat(autismDetails.doctoralRange.split('-')[0]);
      return lowRange;
    } else {
      return 0;
    }
  };

  const calculateChildAutismHigh = (feeDetails) => {
    const autismDetails = feeDetails['Autist']?.details?.[0];

    if (autismDetails && autismDetails.doctoralRange) {
      const highRange = parseFloat(autismDetails.doctoralRange.split('-')[1]);
      return highRange;
    } else {
      return 0;
    }
  };

  const calculateChildADHDFee = (feeDetails) => {
    const chADHDDetails = feeDetails['ChADHD'];

    if (chADHDDetails && chADHDDetails.min) {
      return parseFloat(chADHDDetails.min);
    } else {
      return 0;
    }
  };

  const calculateChildADHDAutFee = (feeDetails) => {
    const chADHDAutDetails = feeDetails['ChADHDAut'];

    if (chADHDAutDetails && chADHDAutDetails.min) {
      return parseFloat(chADHDAutDetails.min);
    } else {
      return 0;
    }
  };

  const calculateChildPsychEdFee = (feeDetails) => {
    const chPsyEdDetails = feeDetails['ChPsyEd'];

    if (chPsyEdDetails && chPsyEdDetails.min) {
      return parseFloat(chPsyEdDetails.min);
    } else {
      return 0;
    }
  };

  const calculateAdditionalFees = (baseFee, calculationType) => {
    switch (calculationType) {
      case 'Testing Administration':
        return baseFee * 2; // Example calculation, adjust as needed
      default:
        return baseFee;
    }
  };

  const calculateCombinedFee = (feeDetails, cptCode1, cptCode2) => {
    const range1 = feeDetails[cptCode1];
    const range2 = feeDetails[cptCode2];

    if (
      !range1 ||
      !range2 ||
      range1.min === 'TBD' ||
      range1.max === 'TBD' ||
      range2.min === 'TBD' ||
      range2.max === 'TBD'
    ) {
      return { min: 'TBD', max: 'TBD' };
    }

    const minFee = range1.min + range2.min;
    const maxFee = range1.max + range2.max;

    return { min: minFee, max: maxFee };
  };

  const displayFeeRange = (cptCode, calculationType = null) => {
    const effectiveInsurance = Insurance || Insurance_t; // Use either Insurance or Insurance_t
    const isSpecificTestingService =
      Array.isArray(services) &&
      services.some((service) =>
        specificTestingServiceList.includes(service.title)
      );
    const isInsuranceNotPrivatePay = effectiveInsurance !== 'Private Pay';
    const shouldCalculateSpecialFees =
      isSpecificTestingService &&
      isInsuranceNotPrivatePay &&
      serviceType === 'Psychological Testing';
    const serviceDetails = feeDetails[cptCode] || {
      details: [],
      min: null,
      max: null,
    }; // Provide default values

    if (!serviceDetails) {
      const result = { displayText: 'N/A', notesText: '' };
      return result;
    }

    if (shouldCalculateSpecialFees && calculationType) {
      const isADHDService = services.some((service) =>
        service.title.includes('ADHD Diagnostic Evaluation')
      );
      const isBariatricService = services.some((service) =>
        service.title.includes('Bariatric Surgical Psychological Evaluations')
      );
      const isAdultAutismService = services.some((service) =>
        service.title.includes('Autism Spectrum Disorder - Adult Evaluation')
      );
      const isDifferentialService = services.some((service) =>
        service.title.includes('Differential Diagnosis Evaluation')
      );
      const isComprehensiveService = services.some((service) =>
        service.title.includes('Comprehensive Evaluation')
      );

      if (isADHDService) {
        let calculatedFee;
        switch (calculationType) {
          case 'Average Low':
            calculatedFee = calculateAverageLow(feeDetails);
            const resultADHDLow = {
              displayText: `$${calculatedFee.toFixed(2)}`,
              notesText: '',
            };
            return resultADHDLow;
          case 'Average High':
            calculatedFee = calculateAverageHigh(feeDetails);
            const resultADHDHigh = {
              displayText: `$${calculatedFee.toFixed(2)}`,
              notesText: '',
            };
            return resultADHDHigh;
          default:
            break;
        }
      } else if (isBariatricService) {
        let calculatedFee;
        switch (calculationType) {
          case 'Average Low':
            calculatedFee = calculateBariatricLow(feeDetails);
            const resultBariatricLow = {
              displayText: `$${calculatedFee.toFixed(2)}`,
              notesText: '',
            };
            return resultBariatricLow;
          case 'Average High':
            calculatedFee = calculateBariatricHigh(feeDetails);
            const resultBariatricHigh = {
              displayText: `$${calculatedFee.toFixed(2)}`,
              notesText: '',
            };
            return resultBariatricHigh;
          default:
            break;
        }
      } else if (isAdultAutismService) {
        let calculatedFee;
        switch (calculationType) {
          case 'Average Low':
            calculatedFee = calculateAdultAutismLow(feeDetails);
            const resultAdultAutismLow = {
              displayText: `$${calculatedFee.toFixed(2)}`,
              notesText: '',
            };
            return resultAdultAutismLow;
          case 'Average High':
            calculatedFee = calculateAdultAutismHigh(feeDetails);
            const resultAdultAutismHigh = {
              displayText: `$${calculatedFee.toFixed(2)}`,
              notesText: '',
            };
            return resultAdultAutismHigh;
          default:
            break;
        }
      } else if (isDifferentialService) {
        let calculatedFee;
        switch (calculationType) {
          case 'Average Low':
            calculatedFee = calculateDifferentialLow(feeDetails);
            const resultDifferentialLow = {
              displayText: `$${calculatedFee.toFixed(2)}`,
              notesText: '',
            };
            return resultDifferentialLow;
          case 'Average High':
            calculatedFee = calculateDifferentialHigh(feeDetails);
            const resultDifferentialHigh = {
              displayText: `$${calculatedFee.toFixed(2)}`,
              notesText: '',
            };
            return resultDifferentialHigh;
          default:
            break;
        }
      } else if (isComprehensiveService) {
        let calculatedFee;
        switch (calculationType) {
          case 'Average Low':
            calculatedFee = calculateComprehensiveLow(feeDetails);
            const resultComprehensiveLow = {
              displayText: `$${calculatedFee.toFixed(2)}`,
              notesText: '',
            };
            return resultComprehensiveLow;
          case 'Average High':
            calculatedFee = calculateComprehensiveHigh(feeDetails);
            const resultComprehensiveHigh = {
              displayText: `$${calculatedFee.toFixed(2)}`,
              notesText: '',
            };
            return resultComprehensiveHigh;
          default:
            break;
        }
      } else {
        const resultDefault = {
          displayText: '',
          notesText: '',
        };
        return resultDefault;
      }
    } else if (
      isChildAutismUnder18 ||
      isChildADHDUnder18 ||
      isChildCombADHDAutismUnder18 ||
      isChildPsycEd
    ) {
      if (effectiveInsurance !== 'Private Pay') {
        const resultPrivatePay = {
          displayText:
            'This Service is Only Covered By Private Pay and Not Insurance',
          notesText: '',
        };
        return resultPrivatePay;
      }

      let displayText;
      let notesText = '';

      if (isChildAutismUnder18) {
        const childAutismLow = calculateChildAutismLow(feeDetails);
        const childAutismHigh = calculateChildAutismHigh(feeDetails);

        if (age === null || age === undefined) {
          displayText = (
            <span style={{ color: 'orange', fontWeight: 'bold' }}>
              {`$${childAutismLow.toFixed(2)} - $${childAutismHigh.toFixed(2)}`}
            </span>
          );
        } else if (age <= 4) {
          displayText = (
            <span style={{ color: 'green', fontWeight: 'bold' }}>
              {`$${childAutismLow.toFixed(2)}`}
            </span>
          );
        } else {
          displayText = (
            <span style={{ color: 'green', fontWeight: 'bold' }}>
              {`$${childAutismHigh.toFixed(2)}`}
            </span>
          );
        }
        notesText = serviceDetails.details[0]?.notes ? (
          <p style={{ color: 'blue' }}>{serviceDetails.details[0].notes}</p>
        ) : (
          ''
        );

        const resultChildAutism = { displayText, notesText };
        return resultChildAutism;
      } else if (isChildADHDUnder18) {
        const childADHDFee = calculateChildADHDFee(feeDetails);

        displayText = (
          <span style={{ color: 'green', fontWeight: 'bold' }}>
            {`$${childADHDFee.toFixed(2)}`}
          </span>
        );
        notesText = serviceDetails.details[0]?.notes ? (
          <p style={{ color: 'blue' }}>{serviceDetails.details[0].notes}</p>
        ) : (
          ''
        );

        const resultChildADHD = { displayText, notesText };
        return resultChildADHD;
      } else if (isChildPsycEd) {
        const childPsyEdFee = calculateChildPsychEdFee(feeDetails);

        displayText = (
          <span style={{ color: 'green', fontWeight: 'bold' }}>
            {`$${childPsyEdFee.toFixed(2)}`}
          </span>
        );
        notesText = serviceDetails.details[0]?.notes ? (
          <p style={{ color: 'blue' }}>{serviceDetails.details[0].notes}</p>
        ) : (
          ''
        );

        const resultChildPsycEd = { displayText, notesText };
        return resultChildPsycEd;
      } else if (isChildCombADHDAutismUnder18) {
        const childCombADHDAutismFee = calculateChildADHDAutFee(feeDetails);

        displayText = (
          <span style={{ color: 'green', fontWeight: 'bold' }}>
            {`$${childCombADHDAutismFee.toFixed(2)}`}
          </span>
        );
        notesText = serviceDetails.details[0]?.notes ? (
          <p style={{ color: 'blue' }}>{serviceDetails.details[0].notes}</p>
        ) : (
          ''
        );

        const resultChildCombADHDAutism = { displayText, notesText };
        return resultChildCombADHDAutism;
      }
    }

    const range = feeDetails[cptCode];

    if (!range || range.min === 'TBD' || range.max === 'TBD') {
      const resultTBD = { displayText: 'TBD', notesText: '' };
      return resultTBD;
    }

    let baseMinFee = range.min;
    let baseMaxFee = range.max;

    // Exclude master's level rates for testing services
    if (serviceGroup === 'Testing') {
      const doctoralAmounts = range.details
        .map((detail) => parseFloat(detail.doctoralAmount))
        .filter((amount) => !isNaN(amount));
      if (doctoralAmounts.length > 0) {
        baseMinFee = Math.min(...doctoralAmounts);
        baseMaxFee = Math.max(...doctoralAmounts);
      }
    }

    if (calculationType) {
      switch (calculationType) {
        case 'Testing Administration':
          baseMinFee = baseMinFee * 2;
          baseMaxFee = baseMaxFee * 2;
          break;
        case 'Combined':
          const combinedFees = calculateCombinedFee(
            feeDetails,
            '96136',
            '96137'
          );
          baseMinFee = combinedFees.min;
          baseMaxFee = combinedFees.max;
          break;
        default:
          baseMinFee = calculateAdditionalFees(baseMinFee, calculationType);
          baseMaxFee = calculateAdditionalFees(baseMaxFee, calculationType);
      }
    }

    // Ensure baseMinFee and baseMaxFee are valid numbers
    baseMinFee = isNaN(baseMinFee) ? 0 : baseMinFee;
    baseMaxFee = isNaN(baseMaxFee) ? 0 : baseMaxFee;

    let displayText;
    let notesText =
      range.details.length > 0 && range.details[0].notes ? (
        <p style={{ color: 'blue' }}>{range.details[0].notes}</p>
      ) : (
        ''
      );

    if (effectiveInsurance === 'Private Pay') {
      switch (selectedPrivatePayAmount) {
        case 'ProBono':
          displayText = (
            <span style={{ color: 'green', fontWeight: 'bold' }}>$0</span>
          );
          break;
        case 'Sliding Scale':
          displayText =
            typeof baseMaxFee === 'number' ? (
              <span style={{ color: 'orange', fontWeight: 'bold' }}>
                $1 - ${baseMaxFee.toFixed(2)}
              </span>
            ) : (
              <span style={{ color: 'orange', fontWeight: 'bold' }}>
                $1 - N/A
              </span>
            );
          break;
        case 'Full Pay':
          displayText =
            baseMinFee === baseMaxFee ? (
              typeof baseMinFee === 'number' ? (
                <span style={{ color: 'green', fontWeight: 'bold' }}>
                  ${baseMinFee.toFixed(2)}
                </span>
              ) : (
                'N/A'
              )
            ) : typeof baseMinFee === 'number' &&
              typeof baseMaxFee === 'number' ? (
              <span style={{ color: 'orange', fontWeight: 'bold' }}>
                ${baseMinFee.toFixed(2)} - ${baseMaxFee.toFixed(2)}
              </span>
            ) : (
              'N/A'
            );
          break;
        default:
          displayText = (
            <span
              style={{
                color: baseMinFee === baseMaxFee ? 'green' : 'orange',
                fontWeight: 'bold',
              }}
            >
              {typeof baseMinFee === 'number'
                ? `$${baseMinFee.toFixed(2)}`
                : 'N/A'}
              {baseMinFee === baseMaxFee
                ? ''
                : ` - ${
                    typeof baseMaxFee === 'number'
                      ? `$${baseMaxFee.toFixed(2)}`
                      : 'N/A'
                  }`}
            </span>
          );
      }
    } else {
      displayText =
        typeof baseMinFee === 'number' && baseMinFee === baseMaxFee ? (
          <span style={{ color: 'green', fontWeight: 'bold' }}>
            ${baseMinFee.toFixed(2)}
          </span>
        ) : typeof baseMinFee === 'number' && typeof baseMaxFee === 'number' ? (
          <span style={{ color: 'orange', fontWeight: 'bold' }}>
            ${baseMinFee.toFixed(2)} - ${baseMaxFee.toFixed(2)}
          </span>
        ) : (
          'N/A'
        );
    }

    const resultFinal = { displayText, notesText };
    return resultFinal;
  };

  const handleButtonClick = () => {
    toggleCardClickable();
    if (providerRef.current) {
      providerRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  let feeNoteText = '';

  if (
    (!Insurance && !Insurance_t) ||
    (((Array.isArray(Insurance) && Insurance.length < 1) ||
      (Array.isArray(Insurance_t) && Insurance_t.length < 1)) &&
      serviceType !== 'Forensic Testing')
  ) {
    feeNoteText =
      'If insurance is used, the costs listed are the allowed amount and not what the client is necessarily responsible for, e.g., Copay, Deductible, Coinsurance.';
  } else if (
    (Insurance && Insurance !== 'Private Pay') ||
    (Insurance_t &&
      Insurance_t !== 'Private Pay' &&
      serviceType !== 'Forensic Testing')
  ) {
    feeNoteText =
      'The costs listed above are the allowed amount and not what the client is necessarily responsible for, e.g., Copay, Deductible, Coinsurance.';
  }

  const updateCptFeeRanges = (feeRanges) => {
    setCptFeeRanges(
      Object.keys(feeRanges).reduce((acc, code) => {
        if (feeRanges[code].min !== Infinity && feeRanges[code].min !== null) {
          acc[code] = {
            min: feeRanges[code].min,
            max: feeRanges[code].max,
          };
        } else {
          acc[code] = { min: null, max: null };
        }
        return acc;
      }, {})
    );
  };

  useEffect(() => {
    const fetchFeeData = async () => {
      try {
        const billingRates = await fetchBillingRates();

        const relevantRates = billingRates?.filter((rate) =>
          isRateRelevant(
            rate,
            Insurance || Insurance_t,
            serviceGroup,
            serviceType,
            selectedLocations,
            selectedSetting,
            services
          )
        );

        const feeRanges = calculateFeeRanges(
          relevantRates,
          selectedCPTCodes,
          getLicenseLevel(),
          age
        );

        updateCptFeeRanges(feeRanges);
        updateFeeDetails(feeRanges); // Assuming updateFeeDetails similarly expects an object
      } catch (error) {
        console.error('Failed to fetch or process fee data:', error);
      }
    };

    if (areSelectionsMade && selectedCPTCodes.length > 0) {
      fetchFeeData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    Insurance,
    Insurance_t,
    serviceGroup,
    serviceType,
    selectedLocations,
    selectedSetting,
    services,
    selectedCPTCodes,
    // age,
    selectedProviderLicense,
  ]); // Added Insurance_t and selectedProviderLicense to the dependency array

  useEffect(() => {
    const updatedCPTCodes = getCPTCodesForServiceType(
      serviceType,
      Insurance || Insurance_t
    );
    const serviceSpecificCodes = services
      ? getCPTCodesForServices(services, Insurance || Insurance_t)
      : [];
    const combinedCPTCodes = [
      ...new Set([...updatedCPTCodes, ...serviceSpecificCodes]),
    ];

    // Avoid re-render loop by comparing previous and new CPT codes
    if (JSON.stringify(selectedCPTCodes) !== JSON.stringify(combinedCPTCodes)) {
      setSelectedCPTCodes(combinedCPTCodes);
    }
  }, [
    serviceType,
    Insurance,
    Insurance_t,
    services,
    selectedCPTCodes,
    getCPTCodesForServices,
    getCPTCodesForServiceType,
  ]);

  useEffect(() => {
    fetchBillingRates().then((newRates) => {
      // Assuming calculateFeeRanges uses newRates to calculate and returns an object mapping CPT codes to their ranges
      const newFeeRanges = calculateFeeRanges(newRates, selectedCPTCodes);
      updateCptFeeRanges(newFeeRanges); // This function should update cptFeeRanges state
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedCPTCodes,
    services,
    serviceType,
    license,
    selectedProviderLicense,
  ]);

  useEffect(() => {
    const updatedCPTCodes = getCPTCodesForServiceType(
      serviceType,
      Insurance || Insurance_t
    );
    const serviceSpecificCodes = services
      ? getCPTCodesForServices(services, Insurance || Insurance_t)
      : [];
    let combinedCPTCodes;
    if (serviceSpecificCodes.length > 0) {
      combinedCPTCodes = [...new Set(serviceSpecificCodes)];
    } else {
      combinedCPTCodes = [...new Set(updatedCPTCodes)];
    }
    setSelectedCPTCodes(combinedCPTCodes);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceType, Insurance, Insurance_t, services]);

  useEffect(() => {
    // This useEffect handles the dependency on setting changes, particularly for "Remote Only"
    const currentSettings = step06FormValues.setting || [];
    const currentLocations = step06FormValues.location || [];

    if (currentSettings.includes('Remote Only')) {
      setSelectedLocations(['Silver Spring']);
    } else if (
      currentLocations.length > 0 &&
      !currentLocations.some((loc) => loc.title === 'Any')
    ) {
      setSelectedLocations(currentLocations.map((loc) => loc.title));
    } else {
      setSelectedLocations(['Baltimore', 'Columbia', 'Silver Spring']);
    }
  }, [step06FormValues.setting, step06FormValues.location]);

  return (
    <div style={{ textAlign: 'center', maxWidth: '600px', margin: 'auto' }}>
      <h3 style={{ marginBottom: '20px' }}>
        Fees
        {shouldShowHelpIcon && feeNoteText && (
          <Tooltip
            title={feeNoteText}
            sx={{
              '& .MuiTooltip-tooltip': {
                fontSize: '1.0em', // Adjust the size as needed
              },
            }}
          >
            <IconButton aria-label="help">
              <HelpOutlineIcon />
            </IconButton>
          </Tooltip>
        )}
      </h3>
      {!areSelectionsMade ? (
        <p style={{ fontSize: 'medium' }}>
          To begin viewing fees start with selecting the service type (e.g.
          Individuals, Couples, or Families)
        </p>
      ) : (
        <>
          {(serviceType === 'Psychological Testing' ||
            serviceType === 'Forensic Testing') &&
          (!services || services.length === 0) ? (
            <p>
              Please select a specific service to view fees for Psychological
              Testing or Forensic Testing.
            </p>
          ) : (
            <>
              {shouldDisplayFees() && (
                <>
                  <div>
                    {isTesting && services && services.length > 0 && (
                      <div style={{ textAlign: 'center' }}>
                        <div
                          style={{ fontSize: 'larger', marginBottom: '20px' }}
                        >
                          {serviceType === 'Psychological Testing'
                            ? 'Testing Fees'
                            : 'Forensic Fees'}
                        </div>

                        {(Insurance === 'Private Pay' ||
                          Insurance_t === 'Private Pay') &&
                        services.some((service) =>
                          specificTestingServiceList.includes(service.title)
                        ) ? (
                          services.map(
                            (service) =>
                              specificTestingServiceList.includes(
                                service.title
                              ) && (
                                <div key={service.title}>
                                  {serviceTitleToCPTCodes[service.title].map(
                                    (cptCode) => (
                                      <div
                                        key={cptCode}
                                        style={{ fontSize: 'larger' }}
                                      >
                                        {displayFeeRange(cptCode).displayText}
                                        <div>
                                          {displayFeeRange(cptCode).notesText}
                                        </div>
                                      </div>
                                    )
                                  )}
                                </div>
                              )
                          )
                        ) : (
                          <>
                            {services.some(
                              (service) =>
                                service.title ===
                                'Autism Spectrum Disorder - Under 18 Evaluation'
                            ) ? (
                              (Insurance !== 'Private Pay' &&
                                Insurance_t !== 'Private Pay') ||
                              Insurance === null ||
                              Insurance === '' ? (
                                <div style={{ fontSize: 'larger' }}>
                                  This Service is Only Covered By Private Pay
                                  and Not Insurance
                                </div>
                              ) : (
                                <div style={{ fontSize: 'larger' }}>
                                  {displayFeeRange('Autist').displayText}
                                  <div>
                                    {displayFeeRange('Autist').notesText}
                                  </div>
                                  {isChildAutismUnder18 &&
                                    (age === null || age === undefined) && (
                                      <Chip
                                        label="Age"
                                        clickable
                                        onClick={() =>
                                          focusField(null, accordionRef1)
                                        }
                                        style={{ margin: '5px' }}
                                        color="primary"
                                      />
                                    )}
                                </div>
                              )
                            ) : services.some(
                                (service) =>
                                  service.title ===
                                  'Child Psycho-Educational Evaluation'
                              ) ? (
                              (Insurance !== 'Private Pay' &&
                                Insurance_t !== 'Private Pay') ||
                              Insurance === null ||
                              Insurance === '' ? (
                                <div style={{ fontSize: 'larger' }}>
                                  This Service is Only Covered By Private Pay
                                  and Not Insurance
                                </div>
                              ) : (
                                <div style={{ fontSize: 'larger' }}>
                                  {displayFeeRange('ChPsyEd').displayText}
                                  <div>
                                    {displayFeeRange('ChPsyEd').notesText}
                                  </div>
                                </div>
                              )
                            ) : services.some(
                                (service) =>
                                  service.title ===
                                  'ADHD/Autism Child Comprehensive Combined Evaluation'
                              ) ? (
                              (Insurance !== 'Private Pay' &&
                                Insurance_t !== 'Private Pay') ||
                              Insurance === null ||
                              Insurance === '' ? (
                                <div style={{ fontSize: 'larger' }}>
                                  This Service is Only Covered By Private Pay
                                  and Not Insurance
                                </div>
                              ) : (
                                <div style={{ fontSize: 'larger' }}>
                                  {displayFeeRange('ChADHDAut').displayText}
                                  <div>
                                    {displayFeeRange('ChADHDAut').notesText}
                                  </div>
                                </div>
                              )
                            ) : isAdoptionEvaluation ? (
                              <div style={{ fontSize: 'larger' }}>
                                {displayFeeRange('Adopval').displayText}
                                <div>
                                  {displayFeeRange('Adopval').notesText}
                                </div>
                              </div>
                            ) : isForensicService ? (
                              (Insurance !== 'Private Pay' &&
                                Insurance_t !== 'Private Pay') ||
                              Insurance === null ||
                              Insurance === '' ? (
                                <p>
                                  Forensic Services is not covered by Insurances
                                  - Please select "Private Pay"
                                </p>
                              ) : (
                                <div
                                  style={{
                                    display: 'grid',
                                    gridTemplateColumns: 'repeat(4, 1fr)',
                                    gap: '2px',
                                    textAlign: 'center',
                                  }}
                                >
                                  <div>
                                    <div style={{ fontSize: 'larger' }}>
                                      Forensic Testing
                                    </div>
                                    <div style={{ fontSize: 'larger' }}>
                                      {displayFeeRange('ForenHr').displayText}
                                    </div>
                                    {displayFeeRange('ForenHr').notesText}
                                  </div>
                                  <div>
                                    <div style={{ fontSize: 'larger' }}>
                                      Testimony Per Hour
                                    </div>
                                    <div style={{ fontSize: 'larger' }}>
                                      {displayFeeRange('TestimHr').displayText}
                                    </div>
                                  </div>
                                  <div>
                                    <div style={{ fontSize: 'larger' }}>
                                      Testimony 1/2 Day
                                    </div>
                                    <div style={{ fontSize: 'larger' }}>
                                      {
                                        displayFeeRange('ForenHlfDy')
                                          .displayText
                                      }
                                    </div>
                                  </div>
                                  <div>
                                    <div style={{ fontSize: 'larger' }}>
                                      Testimony Full Day
                                    </div>
                                    <div style={{ fontSize: 'larger' }}>
                                      {
                                        displayFeeRange('ForenFulDy')
                                          .displayText
                                      }
                                    </div>
                                  </div>
                                </div>
                              )
                            ) : (
                              <div
                                style={{
                                  display: 'grid',
                                  gridTemplateColumns: 'repeat(3, 1fr)',
                                  gap: '20px',
                                  textAlign: 'center',
                                }}
                              >
                                <div>
                                  <div style={{ fontSize: 'larger' }}>
                                    Diagnostic Interview
                                  </div>
                                  <div style={{ fontSize: 'larger' }}>
                                    {displayFeeRange('90791').displayText}
                                  </div>
                                  {displayFeeRange('90791').notesText}
                                </div>
                                <div>
                                  <div style={{ fontSize: 'larger' }}>
                                    Testing Service
                                  </div>
                                  <div style={{ fontSize: 'larger' }}>
                                    {displayFeeRange('96130').displayText} -
                                    (1st Hour) /{' '}
                                    {displayFeeRange('96131').displayText} -
                                    (Subsequent Hours)
                                  </div>
                                  {displayFeeRange('96130').notesText}
                                  {displayFeeRange('96131').notesText}
                                </div>
                                <div>
                                  <div style={{ fontSize: 'larger' }}>
                                    Assessment Service
                                  </div>
                                  <div style={{ fontSize: 'larger' }}>
                                    {
                                      displayFeeRange('96136', 'Combined')
                                        .displayText
                                    }{' '}
                                    - (1st Hour) /{' '}
                                    {
                                      displayFeeRange(
                                        '96137',
                                        'Testing Administration'
                                      ).displayText
                                    }{' '}
                                    - (Subsequent Hours)
                                  </div>
                                  {displayFeeRange('96136').notesText}
                                  {displayFeeRange('96137').notesText}
                                </div>
                              </div>
                            )}
                            {services.some((service) =>
                              specificTestingServiceList.includes(service.title)
                            ) && (
                              <div
                                style={{
                                  display: 'grid',
                                  gridTemplateColumns: 'repeat(2, 1fr)',
                                  gap: '20px',
                                  marginTop: '20px',
                                  textAlign: 'center',
                                }}
                              >
                                <div>
                                  <div style={{ fontSize: 'larger' }}>
                                    Average Low
                                  </div>
                                  <div style={{ fontSize: 'larger' }}>
                                    {
                                      displayFeeRange(
                                        'Average Low',
                                        'Average Low'
                                      ).displayText
                                    }
                                  </div>
                                  <div>
                                    {
                                      displayFeeRange(
                                        'Average Low',
                                        'Average Low'
                                      ).notesText
                                    }
                                  </div>
                                </div>
                                <div>
                                  <div style={{ fontSize: 'larger' }}>
                                    Average High
                                  </div>
                                  <div style={{ fontSize: 'larger' }}>
                                    {
                                      displayFeeRange(
                                        'Average High',
                                        'Average High'
                                      ).displayText
                                    }
                                  </div>
                                  <div>
                                    {
                                      displayFeeRange(
                                        'Average High',
                                        'Average High'
                                      ).notesText
                                    }
                                  </div>
                                </div>
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    )}
                  </div>

                  {!isTesting && (
                    <div>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-around',
                          marginBottom: '20px',
                        }}
                      >
                        {selectedCPTCodes.map((code, index) => (
                          <div key={index} style={{ textAlign: 'center' }}>
                            <div style={{ fontSize: 'larger' }}>
                              {step03FormValues.serviceType === 'Groups' ? (
                                'Sessions'
                              ) : index === 0 ? (
                                'First Session'
                              ) : (
                                <span style={{ marginLeft: '20px' }}>
                                  Subsequent Sessions
                                </span>
                              )}
                            </div>
                            <div style={{ fontSize: 'larger' }}>
                              {displayFeeRange(code).displayText}
                            </div>
                            {displayFeeRange(code).notesText}
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                  {!(
                    isChildAutismUnder18 ||
                    isChildADHDUnder18 ||
                    isChildCombADHDAutismUnder18
                  ) &&
                    ((Insurance !== 'Private Pay' &&
                      Insurance_t !== 'Private Pay') ||
                      Insurance === null ||
                      Insurance === '') &&
                    !(
                      isForensicService &&
                      ((Insurance !== 'Private Pay' &&
                        Insurance_t !== 'Private Pay') ||
                        Insurance === null ||
                        Insurance === '')
                    ) && (
                      <>
                        <div
                          style={{
                            fontSize: 'medium',
                            color: 'blue',
                            marginTop: '10px',
                            marginBottom: '10px',
                          }}
                        >
                          Rates dynamically calculated based on current filters
                        </div>
                      </>
                    )}

                  {(!(
                    isChildAutismUnder18 ||
                    isChildADHDUnder18 ||
                    isChildCombADHDAutismUnder18
                  ) ||
                    ((isChildAutismUnder18 ||
                      isChildADHDUnder18 ||
                      isChildCombADHDAutismUnder18) &&
                      (Insurance === 'Private Pay' ||
                        Insurance_t === 'Private Pay'))) &&
                    !(
                      isForensicService &&
                      ((Insurance !== 'Private Pay' &&
                        Insurance_t !== 'Private Pay') ||
                        Insurance === null ||
                        Insurance === '')
                    ) &&
                    shouldShowNote && (
                      <div>
                        <div
                          style={{
                            fontSize: 'medium',
                            marginBottom: '10px',
                            marginTop: '10px',
                          }}
                        >
                          <br />

                          {!step03FormValues.Insurance &&
                            !step03FormValues.Insurance_t && (
                              <Chip
                                label="Insurance"
                                clickable
                                onClick={() =>
                                  focusField(insuranceRef, accordionRef3)
                                }
                                style={{ margin: '5px' }}
                                color="primary"
                              />
                            )}

                          {isChildAutismUnder18 &&
                            (age === null || age === undefined) && (
                              <>
                                <Chip
                                  label="Age"
                                  onClick={() =>
                                    focusField(null, accordionRef1)
                                  }
                                  style={{ color: 'blue' }}
                                />
                                <span>,</span>
                              </>
                            )}

                          <Chip
                            label={
                              isCardClickable
                                ? 'Turn off Provider Click'
                                : 'Select Provider'
                            }
                            clickable
                            onClick={handleButtonClick}
                            style={{ margin: '5px' }}
                            color="primary"
                          />
                          {selectedProviderLicense && (
                            <div style={{ marginTop: '10px' }}>
                              Selected Provider License:{' '}
                              {selectedProviderLicense}
                            </div>
                          )}

                          {!step05FormValues.license &&
                            serviceGroup !== 'Testing' && (
                              <Chip
                                label="License Type"
                                clickable
                                onClick={() =>
                                  focusField(licenseRef, accordionRef5)
                                }
                                style={{ margin: '5px' }}
                                color="primary"
                              />
                            )}

                          {!step06FormValues.setting && (
                            <Chip
                              label="Setting"
                              clickable
                              onClick={() =>
                                focusField(settingRef, accordionRef6)
                              }
                              style={{ margin: '5px' }}
                              color="primary"
                            />
                          )}

                          {step06FormValues.setting !== 'Remote Only' &&
                            (!step06FormValues.location ||
                              step06FormValues.location === 'Any' ||
                              (Array.isArray(step06FormValues.location) &&
                                step06FormValues.location.includes(
                                  'Baltimore'
                                ) &&
                                step06FormValues.location.includes(
                                  'Columbia'
                                ) &&
                                step06FormValues.location.includes(
                                  'Silver Spring'
                                ))) && (
                              <Chip
                                label="Location"
                                clickable
                                onClick={() =>
                                  focusField(locationRef, accordionRef6)
                                }
                                style={{ margin: '5px' }}
                                color="primary"
                              />
                            )}
                        </div>
                        <Button onClick={handleOpenModal}>
                          <b>Show More Details </b>
                        </Button>
                        <Modal
                          open={modalOpen}
                          onClose={handleCloseModal}
                          aria-labelledby="modal-modal-title"
                          aria-describedby="modal-modal-description"
                          style={{ overflowY: 'auto' }}
                        >
                          <Box sx={modalStyle}>
                            <Table stickyHeader>
                              <TableHead>
                                <TableRow style={stickyHeaderStyle}>
                                  <TableCell>CPT Code</TableCell>
                                  <TableCell>Insurance</TableCell>
                                  <TableCell>Location</TableCell>
                                  {serviceGroup === 'Testing' ? (
                                    <TableCell>Doctoral Level Fee</TableCell>
                                  ) : (
                                    <>
                                      <TableCell>Masters Level Fee</TableCell>
                                      <TableCell>Doctoral Level Fee</TableCell>
                                    </>
                                  )}
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {Object.keys(feeDetails).map((code) =>
                                  feeDetails[code].details.map(
                                    (detail, index) => {
                                      if (serviceGroup === 'Testing') {
                                        const doctoralAmounts =
                                          detail.doctoralAmount
                                            .split(',')
                                            .filter(
                                              (amount) =>
                                                !isNaN(parseFloat(amount))
                                            );
                                        if (doctoralAmounts.length > 0) {
                                          return (
                                            <TableRow key={index}>
                                              <TableCell>
                                                {detail.cptCode}
                                              </TableCell>
                                              <TableCell>
                                                {detail.insurance}
                                              </TableCell>
                                              <TableCell>
                                                {detail.location}
                                              </TableCell>
                                              <TableCell>
                                                {detail.doctoralAmount}
                                              </TableCell>{' '}
                                              {/* Only Doctoral Level Fee */}
                                            </TableRow>
                                          );
                                        }
                                      } else {
                                        return (
                                          <TableRow key={index}>
                                            <TableCell>
                                              {detail.cptCode}
                                            </TableCell>
                                            <TableCell>
                                              {detail.insurance}
                                            </TableCell>
                                            <TableCell>
                                              {detail.location}
                                            </TableCell>
                                            <TableCell>
                                              {detail.mastersAmount}
                                            </TableCell>{' '}
                                            {/* Masters Level Fee */}
                                            <TableCell>
                                              {detail.doctoralAmount}
                                            </TableCell>{' '}
                                            {/* Doctoral Level Fee */}
                                          </TableRow>
                                        );
                                      }
                                      return null;
                                    }
                                  )
                                )}
                              </TableBody>
                            </Table>
                          </Box>
                        </Modal>
                      </div>
                    )}
                </>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
}

export default memo(ServiceFeeDisplayComponent);
