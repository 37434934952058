import React from 'react';
import {
  Box,
  FormControl,
  Tabs,
  Tab,
  Select,
  MenuItem,
  useMediaQuery,
  useTheme,
} from '@mui/material';

const ResponsiveTabs = ({ tabs, activeTab, onChange, tabStyles }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleTabChange = (event, newValue) => {
    onChange(event, newValue); // Pass both event and newValue to the parent function
  };

  const handleSelectChange = (event) => {
    onChange(event, event.target.value); // Pass event and selected value for Select dropdown
  };

  // If the screen size is small, render a dropdown instead of tabs
  if (isSmallScreen) {
    return (
      <Box sx={{ minWidth: 120, maxWidth: '70%' }}>
        <FormControl fullWidth style={{ marginLeft: '10px', maxWidth: '30%' }}>
          <Select
            value={activeTab}
            onChange={handleSelectChange} // Handle select change
            inputProps={{
              name: 'tab',
              id: 'tab-select',
              style: { fontSize: '1.5rem' },
            }}
            MenuProps={{
              getContentAnchorEl: null,
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
              PaperProps: {
                style: {
                  width: '100%',
                  maxHeight: '200px',
                  overflow: 'visible',
                },
              },
            }}
          >
            {tabs.map((tab, index) => (
              <MenuItem key={tab.index} value={index} sx={{ fontSize: '1rem' }}>
                {tab.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    );
  } else {
    // Render Tabs for larger screens
    return (
      <Tabs
        value={activeTab}
        onChange={handleTabChange} // Handle tab change
        centered
        aria-label="responsive tabs"
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
        style={{
          paddingTop: '1px',
          paddingBottom: '1px',
          background: '#F5F5F5',
          display: 'flex',
          justifyContent: 'center',
          marginTop: '-4px',
        }}
      >
        {tabs.map((tab, index) => (
          <Tab
            key={tab.index}
            label={tab.label}
            style={tab.style}
            className={activeTab === index ? 'customTabSelected' : ''}
            classes={{ root: 'customTabRoot' }}
          />
        ))}
      </Tabs>
    );
  }
};

export default ResponsiveTabs;
