import { Paper, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import StackBarCharts from '../../../components/UI/StackBarChat';

function InquiriesAndResult(props) {
  const {
    secondData,
    data: fGData,
    isMobile,
    capitalize,
    CustomTooltipForReferredOut,
    reasonColor,
  } = props;
  const [data, setData] = useState();
  const convertLabelForMobile = (label) => {
    if (label?.toLowerCase() === 'testing') {
      return 'Pt';
    } else if (label?.toLowerCase() === 'therapy') {
      return 'Th';
    } else {
      return '?';
    }
  };
  const findTotal = (arr, type, key) => {
    if (arr?.length > 0) {
      return arr?.find((v, k) => v?.service_group === type)?.[key] || 0;
    } else {
      return 0;
    }
  };
  useEffect(() => {
    if (fGData?.length > 0) {
      let sumOfInquires = fGData?.reduce(
        (acc, cur) => {
          return {
            ...acc,
            value1: parseInt(acc?.value1) + parseInt(cur?.live_calls || 0),
            value2:
              parseInt(acc?.value2) + parseInt(cur?.online_inquiries || 0),
          };
        },
        {
          name: 'Total',
          labelForValue1: 'live Calls',
          labelForValue2: 'Online Inquiries',
          labelForValue3: 'Total Inquiries',
          value1: 0,
          value2: 0,
        }
      );
      setData([
        {
          ...sumOfInquires,
          customValue3:
            (sumOfInquires?.value1 || 0) + (sumOfInquires?.value2 || 0),
        },
        ...fGData?.sort((a, b) => b.total_inquiries - a.total_inquiries),
      ]);
    }
  }, [fGData]);
  return (
    <React.Fragment>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div>
          <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
            Conversion Rate (Appt/Inquiry)
          </Typography>
          <div
            style={{
              display: 'flex',
              flexDirection: isMobile ? 'column' : 'row',
            }}
          >
            <div style={{ width: isMobile ? '100%' : '250px' }}>
              <Paper sx={{ p: 2, mt: 1, mx: isMobile ? 4 : 1 }}>
                <p>All</p>
                <Typography variant="h5">
                  {(
                    (((secondData?.length > 0 &&
                      secondData?.reduce((acc, cur) => {
                        return acc + (cur?.total || 0);
                      }, 0)) ||
                      0) /
                      ((data?.length > 0 &&
                        data?.reduce((acc, cur) => {
                          return acc + (cur?.total_inquiries || 0);
                        }, 0)) ||
                        1)) *
                    100
                  )?.toFixed(2)}
                  %
                </Typography>
              </Paper>
            </div>
            <div style={{ width: isMobile ? '100%' : '250px' }}>
              <Paper sx={{ p: 2, mt: 1, mx: isMobile ? 4 : 1 }}>
                <p>Therapy</p>
                <Typography variant="h5">
                  {(
                    ((findTotal(secondData, 'Therapy', 'total') || 0) /
                      (findTotal(data, 'Therapy', 'total_inquiries') || 1)) *
                      100 || 0
                  )?.toFixed(2)}
                  %
                </Typography>
              </Paper>
            </div>
            <div style={{ width: isMobile ? '100%' : '250px' }}>
              <Paper sx={{ p: 2, mt: 1, mx: isMobile ? 4 : 1 }}>
                <p>Testing</p>
                <Typography variant="h5">
                  {(
                    ((findTotal(secondData, 'Testing', 'total') || 0) /
                      (findTotal(data, 'Testing', 'total_inquiries') || 1)) *
                      100 || 0
                  )?.toFixed(2)}
                  %
                </Typography>
              </Paper>
            </div>
          </div>
        </div>
      </div>
      <Paper
        style={{
          width:
            data?.length > 6
              ? ((data?.length || 0) * 200)?.toString()?.concat('px')
              : '100%',
        }}
      >
        <>
          <div
            style={{
              height: '500px',
              width: '100%',
              marginTop: isMobile ? '50px' : '40px', // Adjust this value as needed
            }}
          >
            <StackBarCharts
              data={
                data?.length > 0 &&
                data?.map((v, k) => {
                  if (v?.name === 'Total') {
                    return { ...v };
                  } else
                    return {
                      ...v,
                      name: isMobile
                        ? convertLabelForMobile(v?.service_group)
                        : `(${capitalize(v?.service_group || 'Unknown')})`,
                      value1: v?.live_calls,
                      value2: v?.online_inquiries,
                      customValue3: v?.total_inquiries,
                      labelForValue1: 'live Calls',
                      labelForValue2: 'Online Inquiries',
                      labelForValue3: 'Total Inquiries',
                    };
                })
              } // Pass the sorted data here
              // showXaxis={!isMobile}
              CustomTooltip={
                <CustomTooltipForReferredOut
                  reasonOptions={[
                    'live Calls',
                    'Online Inquiries',
                    'Total Inquiries',
                  ]}
                />
              }
              isTick={window.innerWidth >= 768}
            />
          </div>
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              width: '100%',
              justifyContent: !isMobile ? 'center' : '',
            }}
          >
            {['Live Calls', 'Online Inquiries']?.map((v, k) => (
              <div style={{ display: 'flex' }} key={k}>
                <div>{v}</div>
                <div
                  style={{
                    backgroundColor: reasonColor[k],
                    height: '10px',
                    marginTop: '7px',
                    width: '20px',
                    marginLeft: '10px',
                    marginRight: '10px',
                  }}
                ></div>
              </div>
            ))}
          </div>
        </>
      </Paper>
      <Paper
        style={{
          width:
            data?.length > 6
              ? ((data?.length || 0) * 200)?.toString()?.concat('px')
              : '100%',
        }}
      >
        <>
          <Typography
            variant="h5"
            gutterBottom
            sx={{
              marginTop: '10px',
              marginBottom: '10px',
              mt: 2, // Adds a top margin on mobile
            }}
          >
            Existing, New And Returning Patient Appointments
          </Typography>
          <div
            style={{
              height: '500px',
              width: '100%',
              marginTop: isMobile ? '50px' : '40px', // Adjust this value as needed
            }}
          >
            <StackBarCharts
              data={
                secondData?.length > 0 &&
                secondData?.map((v, k) => ({
                  ...v,
                  name: isMobile
                    ? convertLabelForMobile(v?.service_group)
                    : `(${capitalize(v?.service_group)})`,
                  value1: v?.['Existing Patient'],
                  value2: v?.['New Patient'],
                  value3: v?.['Returning Patient'],
                  labelForValue1: 'Existing Patient',
                  labelForValue2: 'New Patient',
                  labelForValue3: 'Returning Patient',
                }))
              } // Pass the sorted data here
              // showXaxis={!isMobile}
              CustomTooltip={
                <CustomTooltipForReferredOut
                  reasonOptions={[
                    'Existing Patient',
                    'New Patient',
                    'Returning Patient',
                  ]}
                />
              }
              isTick={window.innerWidth >= 768}
            />
          </div>
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              width: '100%',
              justifyContent: !isMobile ? 'center' : '',
            }}
          >
            {['Existing Patient', 'New Patient', 'Returning Patient']?.map(
              (v, k) => (
                <div style={{ display: 'flex' }} key={k}>
                  <div>{v}</div>
                  <div
                    style={{
                      backgroundColor: reasonColor[k],
                      height: '10px',
                      marginTop: '7px',
                      width: '20px',
                      marginLeft: '10px',
                      marginRight: '10px',
                    }}
                  ></div>
                </div>
              )
            )}
          </div>
        </>
      </Paper>
    </React.Fragment>
  );
}

export default InquiriesAndResult;