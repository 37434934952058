import React, { useEffect, useState, memo } from 'react';
import { Tabs, Tab, Button } from '@mui/material';
import {
  PersonSearch as PersonSearchIcon,
  PublishedWithChanges as PublishedWithChangesIcon,
  Clear as ClearIcon,
} from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import { userService, driveService } from '../../services';
import FilesMapping from './FilesMapping';
import DialogComponent from '../Dialogs';
import { clearLoading, setLoading } from '../../actions/loading';
import Input from '../Input/Input';
import Select from '../Select';
import TextArea from '../TextArea';
import RadioButton from '../Buttons/RadioButton/index';

let defaultCols = [
  { key: 'fileName', label: 'File Name', colsSpan: 'col-span-5' },
  {
    key: 'createdAt',
    label: 'Uploaded Date',
    colsSpan: 'col-span-5',
    getData: (c) => {
      return moment(c?.createdAt).format('MM-DD-YYYY');
    },
  },
];

function FilesOnDrive({ selectedUser, selectedUserDetail }) {
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [cols, setCols] = useState([...defaultCols]);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [openFileUploadDialog, setOpenFileUploadDialog] = useState(false);
  const [value, setValue] = React.useState('1');
  const [childTabForCurrent, setChildTabForCurrent] = useState([]);
  const [currentFolderType, setCurrentFolderType] = useState('');
  const [childTabForExpired, setChildTabForExpired] = useState([]);
  const [childValue, setChildValue] = React.useState('1');
  const [seelctedFileId, setSelectedFileId] = useState('');
  let [listArr, setListArr] = useState([
    {
      type: 'diploma',
      fileStartingName: 'DIPLOMA',
      diploma: true,
      title: 'Diploma Proof',
      subTitle: 'Diploma Card',
      icon: <PersonSearchIcon />,
      folderID: '',
      imagePreview: '',
      data: { graduating_school: '', graduating_date: '', other: '' },
    },
    {
      type: 'cv',
      fileStartingName: 'CV',
      cv: true,
      title: 'CV',
      subTitle: 'CV Card',
      icon: <PersonSearchIcon />,
      folderID: '',
      imagePreview: '',
    },
    {
      type: 'license',
      license: true,
      fileStartingName: 'LICENSE',
      title: 'License Proof',
      subTitle: 'License Document',
      icon: <PersonSearchIcon />,
      folderID: '',
      imagePreview: '',
      data: {
        state: 'Maryland', // Set 'Maryland' as the default value
        received_date: '',
        expiry_date: '',
        is_member: 'false',
        member_joining_date: '',
        member_expiry_date: '',
      },
    },
    {
      type: 'liability',
      fileStartingName: 'LIABILITY',
      liability: true,
      title: 'Liability',
      subTitle: 'Liability Card',
      icon: <PersonSearchIcon />,
      folderID: '',
      imagePreview: '',
      data: { insurance: '', expiry_date: '', other: '' },
    },
    {
      type: '1099',
      fileStartingName: '1099',
      1099: true,
      title: '1099',
      subTitle: '1099 Card',
      icon: <PersonSearchIcon />,
      folderID: '',
      imagePreview: '',
    },
  ]);
  const [parentTabs, setParentTab] = useState();

  useEffect(() => {
    if (currentFolderType) {
      getDataFromDriveFolder(currentFolderType);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFolderType, selectedUserDetail]);

  useEffect(() => {
    dispatch(setLoading());
    userService
      .getDriveDetail({ provider_id: selectedUser.id, type: 'tab' })
      .then(async (res) => {
        if (res?.data?.drive_details) {
          dispatch(clearLoading());
          let {
            current_cv_id,
            current_diploma,
            expired_diploma,
            current_liability,
            current_license_document,
            current_1099,
          } = res?.data?.drive_details;
          let lArray = [...listArr];
          if (current_diploma || expired_diploma) {
            lArray[0].folderID = current_diploma || expired_diploma || '';
          }
          if (current_cv_id) {
            lArray[1].folderID = current_cv_id;
          }
          if (current_liability) {
            lArray[3].folderID = current_liability;
          }
          if (current_license_document) {
            lArray[2].folderID = current_license_document;
          }
          if (current_1099) {
            lArray[4].folderID = current_1099;
          }
          setValue(
            res?.data?.ParentTab?.length > 0 && res?.data?.ParentTab[0].value
          );
          setParentTab(res?.data?.ParentTab);
          setListArr([...lArray]);
          setChildTabForCurrent(res?.data?.SubTab?.current);
          setChildTabForExpired(res?.data?.SubTab?.expired);
          if (res?.data?.SubTab?.current?.length > 0) {
            setCurrentFolderType(res?.data?.SubTab?.current[0]?.value);
            setChildValue(res?.data?.SubTab?.current[0]?.value);
          } else if (res?.data?.SubTab?.expired?.length > 0) {
            setCurrentFolderType(res?.data?.SubTab?.expired[0]?.value);
            setChildValue(res?.data?.SubTab?.expired[0]?.value);
          }
        } else dispatch(clearLoading());
      })
      .catch(() => {
        dispatch(clearLoading());
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const moveToExpiredHandler = (type, id) => {
    dispatch(setLoading());
    driveService
      .moveToExpire({
        type: type,
        file_id: id,
        provider_id: selectedUserDetail?.id,
      })
      .then((res) => {
        dispatch(clearLoading());
        if (res?.data?.msg) {
          setTimeout(() => getDataFromDriveFolder(currentFolderType), 200);
          setConfirmDelete(false);
          enqueueSnackbar('File Moved SuccessFully', {
            variant: 'success',
            timeout: 3000,
          });
        }
      })
      .catch(() => {
        dispatch(clearLoading());
      });
  };
  const getDataFromDriveFolder = (folderType, type) => {
    if (folderType && folderType === currentFolderType) {
      if (currentFolderType === 'cv') {
        setCols([...defaultCols]);
      } else if (currentFolderType === 'liability') {
        setCols([
          { key: 'fileName', label: 'File Name', colsSpan: 'col-span-4' },
          { key: 'insurance', label: 'Insurance', colsSpan: 'col-span-2' },
          {
            key: 'expiry_date',
            label: 'Expire Date',
            colsSpan: 'col-span-2',
            getData: (c) => {
              return moment(c?.expiry_date).format('MM-DD-YYYY');
            },
          },
          {
            key: 'createdAt',
            label: 'Uploaded Date',
            colsSpan: 'col-span-2',
            getData: (c) => {
              return moment(c?.createdAt).format('MM-DD-YYYY');
            },
          },
        ]);
      } else if (currentFolderType === 'diploma') {
        setCols([
          { key: 'fileName', label: 'File Name', colsSpan: 'col-span-4' },
          {
            key: 'graduating_school',
            label: 'Graduating School',
            colsSpan: 'col-span-2',
          },
          {
            key: 'graduating_date',
            label: 'Date Of Graduation',
            colsSpan: 'col-span-2',
            getData: (c) => {
              return moment(c?.graduating_date).format('MM-DD-YYYY');
            },
          },

          {
            key: 'createdAt',
            label: 'Uploaded Date',
            colsSpan: 'col-span-2',
            getData: (c) => {
              return moment(c?.createdAt).format('MM-DD-YYYY');
            },
          },
        ]);
      } else if (currentFolderType === 'license') {
        setCols([
          { key: 'fileName', label: 'File Name', colsSpan: 'col-span-4' },
          { key: 'state', label: 'State', colsSpan: 'col-span-2' },
          {
            key: 'expiry_date',
            label: 'Expire Date',
            colsSpan: 'col-span-2',
            getData: (c) => {
              return moment(c?.expiry_date).format('MM-DD-YYYY');
            },
          },

          {
            key: 'createdAt',
            label: 'Uploaded Date',
            colsSpan: 'col-span-2',
            getData: (c) => {
              return moment(c?.createdAt).format('MM-DD-YYYY');
            },
          },
        ]);
      }
      dispatch(setLoading());
      driveService
        .viewFiles({ folder: folderType, provider_id: selectedUser?.id })
        .then((res) => {
          if (res?.data?.data?.files) {
            setData([]);
            setData(res?.data?.data?.files);
          } else {
            setData([]);
          }
          dispatch(clearLoading());
        })
        .catch(() => {
          dispatch(clearLoading());
        });
    }
  };
  const onChangeHandler = (e, index) => {
    let lArray = [...listArr];
    listArr[index].data = {
      ...listArr[index].data,
      [e.target.name]: e.target.value,
    };
    setListArr([...lArray]);
  };

  const handleChange = (event, newValue) => {
    if (newValue === '1') {
      setCurrentFolderType(
        childTabForCurrent?.length > 0 && childTabForCurrent[0]?.value
      );
      setChildValue(
        childTabForCurrent?.length > 0 && childTabForCurrent[0].value
      );
    } else if (newValue === '2') {
      setCurrentFolderType(
        childTabForExpired?.length > 0 && childTabForExpired[0]?.value
      );
      setChildValue(
        childTabForExpired?.length > 0 && childTabForExpired[0].value
      );
    }
    setValue(newValue);
  };
  const handleChangeChild = (event, newValue) => {
    setData([]);
    setChildValue(newValue);
    setCurrentFolderType(newValue);
  };
  const deleteFileHandler = (v) => {
    dispatch(setLoading());
    driveService
      .deleteFiles({ fileId: seelctedFileId, type: childValue })
      .then((res) => {
        if (res?.data?.msg) {
          dispatch(clearLoading());
          setTimeout(() => getDataFromDriveFolder(currentFolderType), 200);
          setConfirmDelete(false);
          enqueueSnackbar('File Delete SuccessFully', {
            variant: 'success',
            timeout: 3000,
          });
        }
      })
      .catch((err) => {
        dispatch(clearLoading());
      });
  };

  const previewFileHandler = (event) => {
    setOpenFileUploadDialog(true);
  };

  const uploadFileAsPerDocumentHanlder = (event, data, index) => {
    let lArray = [...listArr];
    lArray[index].imagePreview = event.target.files[0];
    setListArr([...lArray]);
  };
  const submitHandler = () => {
    for (let i = 0; i < listArr?.length; i++) {
      if (listArr[i]?.folderID && listArr[i]?.imagePreview) {
        let formData = new FormData();
        formData.append('liability', listArr[i]?.liability);
        for (var key in listArr[i]?.data) {
          formData.append(key, listArr[i]?.data[key]);
        }
        formData.append('cv', listArr[i]?.cv);
        formData.append('license', listArr[i]?.license);
        formData.append('diploma', listArr[i]?.diploma);
        formData.append('1099', listArr[i]?.['1099']);
        formData.append('file', listArr[i]?.imagePreview);
        formData.append('folder', listArr[i]?.folderID);
        formData.append('user_id', selectedUser?.id);
        formData.append(
          'folder_name',
          listArr[i]?.fileStartingName || 'DIPLOMA'
        );
        formData.append('provider_id', selectedUser?.id);
        formData.append('provider_name', selectedUserDetail?.provider_name);
        dispatch(setLoading());

        driveService
          .uploadFiles(formData)
          .then((response) => {
            setOpenFileUploadDialog(false);
            enqueueSnackbar('All File Upload SuccessFully', {
              variant: 'success',
              timeout: 2000,
            });
            setTimeout(() => {
              getDataFromDriveFolder(listArr[i]?.type);
            }, 200);
            dispatch(clearLoading());
          })
          .catch((error) => {
            console.error('File upload error:', error);
            dispatch(clearLoading());
          });
      }
      let resetListData = listArr?.map((v, k) => ({ ...v, imagePreview: '' }));
      setListArr([...resetListData]);
      setOpenFileUploadDialog(false);
    }
  };
  return (
    <>
      {confirmDelete && (
        <>
          <DialogComponent
            maxWidth={'xs'}
            open={confirmDelete}
            setOpen={setConfirmDelete}
            content={
              <>
                <p className="text-center font-semibold text-lg">
                  Are You Sure You Want to Delete - This Action Can Not Be
                  Undone
                </p>
                <div className="flex items-center justify-center mt-7">
                  <button
                    className="bg-gray-100 to-blue-500 px-10 py-2 rounded"
                    onClick={() => setConfirmDelete(false)}
                  >
                    Cancel
                  </button>
                  <button
                    className="ml-3 bg-gradient-to-r from-cyan-500  text-white to-blue-500 px-10 py-2 rounded"
                    onClick={() => deleteFileHandler()}
                  >
                    Delete
                  </button>
                </div>
              </>
            }
          />
        </>
      )}
      {openFileUploadDialog && (
        <DialogComponent
          maxWidth={'md'}
          open={openFileUploadDialog}
          setOpen={setOpenFileUploadDialog}
          content={
            <>
              <>
                <div className="">
                  <p className="text-center font-semibold text-lg">
                    Document <span className="text-sky-500"> upload </span>{' '}
                    Center
                  </p>
                  <p className="text-center text-sm mt-0.5">
                    The following are the file formats that you are able to
                    upload - Png, Doc, Docx, Jpeg, Pdf
                  </p>
                  {listArr?.map((v, k) => (
                    <>
                      {v?.type === childValue && (
                        <div className="grid grid-col-12 mx-10 mt-7">
                          <p className="col-span-12 font-semibold flex items-center mb-1">
                            {v?.title}
                            <span className="">{v?.icon}</span>
                          </p>
                          <div className="border border-gray-300 col-span-12">
                            {v?.title !== 'CV' && v?.title !== '1099' && (
                              <div className="col-span-12 grid grid-cols-12 gap-2 p-3">
                                {v?.title === 'Diploma Proof' ? (
                                  <>
                                    <div className="col-span-6">
                                      <Select
                                        name={'graduating_school'}
                                        onChange={(e) => onChangeHandler(e, k)}
                                        value={v?.data?.graduating_school}
                                        label={'Name of Graduating School'}
                                        data={[
                                          {
                                            value: 'Andrews University',
                                            name: 'Andrews University',
                                          },
                                          {
                                            value: 'Argosy University',
                                            name: 'Argosy University',
                                          },
                                          {
                                            value:
                                              'California Institute of Integral Studies',
                                            name: 'California Institute of Integral Studies',
                                          },
                                          {
                                            value: 'Chatham University',
                                            name: 'Chatham University',
                                          },
                                          {
                                            value: 'Catholic University',
                                            name: 'Catholic University',
                                          },
                                          {
                                            value: 'Clark University',
                                            name: 'Clark University',
                                          },
                                          {
                                            value: 'Columbia University',
                                            name: 'Columbia University',
                                          },
                                          {
                                            value:
                                              'Fuller Theological Seminary',
                                            name: 'Fuller Theological Seminary',
                                          },
                                          {
                                            value:
                                              'George Washington University',
                                            name: 'George Washington University',
                                          },
                                          {
                                            value: 'Howard University',
                                            name: 'Howard University',
                                          },
                                          {
                                            value: 'Johns Hopkins University',
                                            name: 'Johns Hopkins University',
                                          },
                                          {
                                            value: 'Louisiana State University',
                                            name: 'Louisiana State University',
                                          },
                                          {
                                            value: 'Loyola University',
                                            name: 'Loyola University',
                                          },
                                          {
                                            value:
                                              'NOVA Southeastern University',
                                            name: 'NOVA Southeastern University',
                                          },
                                          {
                                            value: 'Ohio State University',
                                            name: 'Ohio State University',
                                          },
                                          {
                                            value: 'Salisbury University',
                                            name: 'Salisbury University',
                                          },
                                          {
                                            value: 'Smith College',
                                            name: 'Smith College',
                                          },
                                          {
                                            value: 'Towson University',
                                            name: 'Towson University',
                                          },
                                          {
                                            value: 'University of Cincinnati',
                                            name: 'University of Cincinnati',
                                          },
                                          {
                                            value: 'University of Maryland',
                                            name: 'University of Maryland',
                                          },
                                          {
                                            value:
                                              'University of Maryland at Baltimore School of Social Work',
                                            name: 'University of Maryland at Baltimore School of Social Work',
                                          },
                                          {
                                            value: 'University of Michigan',
                                            name: 'University of Michigan',
                                          },
                                          {
                                            value:
                                              'Virginia Commonwealth University',
                                            name: 'Virginia Commonwealth University',
                                          },
                                          {
                                            value: 'Yeshiva University',
                                            name: 'Yeshiva University',
                                          },
                                          { value: 'Other', name: 'Other' },
                                        ]}
                                      />
                                    </div>

                                    <div className="col-span-6">
                                      <Input
                                        onChange={(e) => onChangeHandler(e, k)}
                                        value={v?.data?.graduating_date}
                                        name={'graduating_date'}
                                        label={'Date of Graduation'}
                                        type="date"
                                      />
                                    </div>
                                    {v?.data?.graduating_school === 'Other' && (
                                      <div className="col-span-12">
                                        <TextArea
                                          onChange={(e) =>
                                            onChangeHandler(e, k)
                                          }
                                          value={v?.data?.other}
                                          name="other"
                                          label={'Other'}
                                          type=""
                                        />
                                      </div>
                                    )}
                                  </>
                                ) : v?.title === 'License Proof' ? (
                                  <>
                                    <div className="col-span-4">
                                      <Select
                                        onChange={(e) => onChangeHandler(e, k)}
                                        value={v?.data?.state || 'Maryland'} // Sets default to Maryland if v.data.state is not set
                                        name="state"
                                        label={'Licensed State'}
                                        data={[
                                          { value: 'Alabama', name: 'Alabama' },
                                          { value: 'Alaska', name: 'Alaska' },
                                          { value: 'Arizona', name: 'Arizona' },
                                          {
                                            value: 'Arkansas',
                                            name: 'Arkansas',
                                          },
                                          {
                                            value: 'California',
                                            name: 'California',
                                          },
                                          {
                                            value: 'Colorado',
                                            name: 'Colorado',
                                          },
                                          {
                                            value: 'Connecticut',
                                            name: 'Connecticut',
                                          },
                                          {
                                            value: 'Delaware',
                                            name: 'Delaware',
                                          },
                                          { value: 'Florida', name: 'Florida' },
                                          { value: 'Georgia', name: 'Georgia' },
                                          { value: 'Hawaii', name: 'Hawaii' },
                                          { value: 'Idaho', name: 'Idaho' },
                                          {
                                            value: 'Illinois',
                                            name: 'Illinois',
                                          },
                                          { value: 'Indiana', name: 'Indiana' },
                                          { value: 'Iowa', name: 'Iowa' },
                                          { value: 'Kansas', name: 'Kansas' },
                                          {
                                            value: 'Kentucky',
                                            name: 'Kentucky',
                                          },
                                          {
                                            value: 'Louisiana',
                                            name: 'Louisiana',
                                          },
                                          { value: 'Maine', name: 'Maine' },
                                          {
                                            value: 'Maryland',
                                            name: 'Maryland',
                                          },
                                          {
                                            value: 'Massachusetts',
                                            name: 'Massachusetts',
                                          },
                                          {
                                            value: 'Michigan',
                                            name: 'Michigan',
                                          },
                                          {
                                            value: 'Minnesota',
                                            name: 'Minnesota',
                                          },
                                          {
                                            value: 'Mississippi',
                                            name: 'Mississippi',
                                          },
                                          {
                                            value: 'Missouri',
                                            name: 'Missouri',
                                          },
                                          { value: 'Montana', name: 'Montana' },
                                          {
                                            value: 'Nebraska',
                                            name: 'Nebraska',
                                          },
                                          { value: 'Nevada', name: 'Nevada' },
                                          {
                                            value: 'New Hampshire',
                                            name: 'New Hampshire',
                                          },
                                          {
                                            value: 'New Jersey',
                                            name: 'New Jersey',
                                          },
                                          {
                                            value: 'New Mexico',
                                            name: 'New Mexico',
                                          },
                                          {
                                            value: 'New York',
                                            name: 'New York',
                                          },
                                          {
                                            value: 'North Carolina',
                                            name: 'North Carolina',
                                          },
                                          {
                                            value: 'North Dakota',
                                            name: 'North Dakota',
                                          },
                                          { value: 'Ohio', name: 'Ohio' },
                                          {
                                            value: 'Oklahoma',
                                            name: 'Oklahoma',
                                          },
                                          { value: 'Oregon', name: 'Oregon' },
                                          {
                                            value: 'Pennsylvania',
                                            name: 'Pennsylvania',
                                          },
                                          {
                                            value: 'Rhode Island',
                                            name: 'Rhode Island',
                                          },
                                          {
                                            value: 'South Carolina',
                                            name: 'South Carolina',
                                          },
                                          {
                                            value: 'South Dakota',
                                            name: 'South Dakota',
                                          },
                                          {
                                            value: 'Tennessee',
                                            name: 'Tennessee',
                                          },
                                          { value: 'Texas', name: 'Texas' },
                                          { value: 'Utah', name: 'Utah' },
                                          { value: 'Vermont', name: 'Vermont' },
                                          {
                                            value: 'Virginia',
                                            name: 'Virginia',
                                          },
                                          {
                                            value: 'Washington',
                                            name: 'Washington',
                                          },
                                          {
                                            value: 'West Virginia',
                                            name: 'West Virginia',
                                          },
                                          {
                                            value: 'Wisconsin',
                                            name: 'Wisconsin',
                                          },
                                          { value: 'Wyoming', name: 'Wyoming' },
                                        ]}
                                      />
                                    </div>

                                    <div className="col-span-4">
                                      <Input
                                        onChange={(e) => onChangeHandler(e, k)}
                                        value={v?.data?.received_date}
                                        name="received_date"
                                        label={'Original Date Received'}
                                        type="date"
                                      />
                                    </div>
                                    <div className="col-span-4">
                                      <Input
                                        onChange={(e) => onChangeHandler(e, k)}
                                        value={v?.data?.expiry_date}
                                        name="expiry_date"
                                        label={'Expires On'}
                                        type="date"
                                      />
                                    </div>
                                    <div className="col-span-12">
                                      <RadioButton
                                        onChange={(e) => onChangeHandler(e, k)}
                                        value={v?.data?.is_member}
                                        name="is_member"
                                        label={'PsyPact Member'}
                                        data={[
                                          { id: 'true', label: 'Yes' },
                                          { id: 'false', label: 'No' },
                                        ]}
                                      />
                                    </div>
                                    {v?.data?.is_member === 'true' && (
                                      <>
                                        {' '}
                                        <div className="col-span-6">
                                          <Input
                                            onChange={(e) =>
                                              onChangeHandler(e, k)
                                            }
                                            value={v?.data?.member_joining_date}
                                            name="member_joining_date"
                                            label={'Original Date Received'}
                                            type="date"
                                          />
                                        </div>
                                        <div className="col-span-6">
                                          <Input
                                            onChange={(e) =>
                                              onChangeHandler(e, k)
                                            }
                                            value={v?.data?.member_expiry_date}
                                            name="member_expiry_date"
                                            label={'Expires On'}
                                            type="date"
                                          />
                                        </div>
                                      </>
                                    )}
                                  </>
                                ) : v.title === 'Liability' ? (
                                  <>
                                    <div className="col-span-6">
                                      <Select
                                        onChange={(e) => onChangeHandler(e, k)}
                                        value={v?.data?.insurance}
                                        name="insurance"
                                        label={'Name of Malpractice Insurance'}
                                        data={[
                                          {
                                            value: 'Allied World',
                                            name: 'Allied World',
                                          },
                                          {
                                            value: 'APA Trust',
                                            name: 'APA Trust',
                                          },
                                          {
                                            value: 'CPH & Associates',
                                            name: 'CPH & Associates',
                                          },
                                          {
                                            value: 'HPSO',
                                            name: 'HPSO',
                                          },
                                          {
                                            value: 'NASW',
                                            name: 'NASW',
                                          },
                                          {
                                            value: 'Preferra',
                                            name: 'Preferra',
                                          },
                                          {
                                            value: 'Other',
                                            name: 'Other',
                                          },
                                        ]}
                                      />
                                    </div>

                                    <div className="col-span-6">
                                      <Input
                                        onChange={(e) => onChangeHandler(e, k)}
                                        value={v?.data?.expiry_date}
                                        name="expiry_date"
                                        label={'Expiration Date'}
                                        type="date"
                                      />
                                    </div>
                                    {v?.data?.insurance === 'other' && (
                                      <div className="col-span-12">
                                        <TextArea
                                          onChange={(e) =>
                                            onChangeHandler(e, k)
                                          }
                                          value={v?.data?.other}
                                          name="other"
                                          label={'Other'}
                                          type=""
                                        />
                                      </div>
                                    )}
                                  </>
                                ) : (
                                  ''
                                )}
                              </div>
                            )}
                            <div className="grid grid-cols-1 flex items-center">
                              <div
                                className={`border border-l border-gray-300 px-2 py-3 border-b ${
                                  v?.imagePreview
                                    ? ' border-b-green-600 '
                                    : ' border-b-blue-500 '
                                }  border-b-2 `}
                              >
                                {v?.imagePreview ? (
                                  <div className="flex justify-between items-center px-3">
                                    <a
                                      href={
                                        v?.imagePreview
                                          ? URL.createObjectURL(v?.imagePreview)
                                          : ''
                                      }
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      className="text-sky-400 underline "
                                    >
                                      {v?.imagePreview?.name || 'testing.jpg'}
                                    </a>
                                    <div className="flex">
                                      <Button
                                        variant="contained"
                                        size="small"
                                        style={{
                                          backgroundColor: 'white',
                                          color: 'skyblue',
                                          boxShadow: 'none',
                                        }}
                                        component="label"
                                      >
                                        <input
                                          type="file"
                                          accept=".doc, .docx, .jpeg, .pdf"
                                          hidden
                                          onChange={(e) =>
                                            uploadFileAsPerDocumentHanlder(
                                              e,
                                              v,
                                              k
                                            )
                                          }
                                        />
                                        <div className="flex flex-col items-center justify-center  cursor-pointer">
                                          <PublishedWithChangesIcon className="text-green-600" />
                                          <p
                                            className="text-xs  "
                                            style={{
                                              fontSize: '11px',
                                              color: 'black',
                                              textTransform: 'capitalize',
                                            }}
                                          >
                                            Replace
                                          </p>
                                        </div>
                                      </Button>

                                      <div
                                        className="flex flex-col items-center justify-center  mr-3 cursor-pointer"
                                        onClick={
                                          () => {
                                            let lArray = [...listArr];
                                            lArray[k].imagePreview = '';
                                            setListArr([...lArray]);
                                          }
                                          // hiddenFileInput?.current?.click()
                                        }
                                      >
                                        <ClearIcon className="text-red-600" />
                                        <p className="text-xs">Clear</p>
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  <p className="text-center">
                                    <input
                                      id="file_input"
                                      key={k}
                                      type="file"
                                      onChange={(e) =>
                                        uploadFileAsPerDocumentHanlder(e, v, k)
                                      }
                                      accept=".doc, .docx, .jpeg, .pdf"
                                      // ref={hiddenFileInput}
                                      style={{ display: 'none' }} // Make the file input element invisible
                                    />
                                    <Button
                                      variant="contained"
                                      size="small"
                                      style={{
                                        backgroundColor: 'white',
                                        color: 'skyblue',
                                        boxShadow: 'none',
                                      }}
                                      component="label"
                                    >
                                      Click
                                      <input
                                        type="file"
                                        accept=".doc, .docx, .jpeg, .pdf"
                                        hidden
                                        onChange={(e) =>
                                          uploadFileAsPerDocumentHanlder(
                                            e,
                                            v,
                                            k
                                          )
                                        }
                                      />
                                    </Button>
                                    here to Upload a File
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  ))}

                  <div className="flex items-center justify-center text-white mt-10">
                    <button
                      className="bg-gradient-to-r from-cyan-500  text-white to-blue-500 px-10 py-2 rounded"
                      onClick={submitHandler}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </>
            </>
          }
        />
      )}
      <Tabs
        className=""
        onChange={handleChange}
        value={value}
        sx={{
          '& button.Mui-selected': { backgroundColor: '#ebeded' },
          borderBottom: 1,
          borderColor: 'divider',
        }}
      >
        {parentTabs?.length > 0 &&
          parentTabs?.map((v, k) => (
            <Tab
              sx={{
                '&.Mui-selected': {
                  outline: 'none',
                },
              }}
              label={v?.label}
              value={v?.value}
            />
          ))}
      </Tabs>

      {value === '1' && (
        <FilesMapping
          moveToExpiredHandler={moveToExpiredHandler}
          currentFolderType={currentFolderType}
          setConfirmDelete={setConfirmDelete}
          setSelectedFileId={setSelectedFileId}
          value={value}
          cols={cols}
          showUploadButton={true}
          childValue={childValue}
          handleChangeChild={handleChangeChild}
          childTab={childTabForCurrent}
          uploadFileHandler={previewFileHandler}
          data={data}
        />
      )}

      {value === '2' && (
        <>
          <FilesMapping
            moveToExpiredHandler={moveToExpiredHandler}
            currentFolderType={currentFolderType}
            setConfirmDelete={setConfirmDelete}
            setSelectedFileId={setSelectedFileId}
            value={value}
            cols={cols}
            childValue={childValue}
            handleChangeChild={handleChangeChild}
            childTab={childTabForExpired}
            uploadFileHandler={previewFileHandler}
            data={data}
          />
        </>
      )}
    </>
  );
}

export default memo(FilesOnDrive);
