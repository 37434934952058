import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Button,
  Modal,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Paper,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { createConfirmation } from 'react-confirm';
import { notificationService } from '../services';
import { setNotificationCount } from '../actions/notification';
import { clearLoading, setLoading } from '../actions/loading';
import { tsToLocalDtStr } from '../common/utility';
import ConfirmDialog from './UI/ConfirmDialog';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const Notifications = () => {
  const dispatch = useDispatch();

  const [open, setOpen] = React.useState(false);

  const handleClose = () => setOpen(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const { user: currentUser } = useSelector((state) => state.auth);
  const [notifications, setNotifications] = useState([]);
  const [, setCount] = useState(0);
  const { notification } = useSelector((state) => state.notification);

  const confirm = createConfirmation(ConfirmDialog);

  const fetchNotifications = async () => {
    showLoading();
    try {
      const notificationsResp = await notificationService.getNotifications();
      let notificationsData = notificationsResp.data.data.notifications;
      setNotifications(notificationsData);

      let read = 0;
      for (let i = 0; i < notificationsData.length; i++) {
        let not = notificationsData[i];
        if (not.read_at) {
          read++;
        }
      }
      setCount(read);
      dispatch(setNotificationCount(notificationsData.length - read));
    } catch (e) {
      console.log('error : ', e);
    } finally {
      hideLoading();
    }
  };
  useEffect(() => {
    fetchNotifications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notification]);
  const confirmContent = (notification) => {
    const keys = Object.keys(notification.fields || []);
    return (
      <div>
        <p>Hi, {currentUser.username}!</p>
        <br />
        <p>This is a notification that {notification.message}</p>
        <br />
        <p>
          <i>
            Note: There is no action you need to take. This is just a
            notification for you to be aware of this change.
          </i>
        </p>
        <br />
        <p>Summary of Changes</p>
        {keys.length > 0 ? (
          keys.map((key) => {
            return (
              <div key={key}>
                <li>
                  <b>{key}</b>:{JSON.stringify(notification.fields[key])}
                </li>
              </div>
            );
          })
        ) : (
          <h3>No Changes</h3>
        )}
      </div>
    );
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const extractProviderName = (message) => {
    if (!message) {
      return '';
    }
    const startIndex = message.indexOf('User ') + 'User '.length;
    const endIndex = message.indexOf(' updated');

    if (startIndex !== -1 && endIndex !== -1) {
      return message.substring(startIndex, endIndex);
    }

    return '';
  };

  const extractRelatedProvider = (message) => {
    if (!message) {
      return '';
    }
    const startIndex = message.indexOf('updated ') + 'updated '.length;
    const endIndex = message.indexOf("'s Appropriateness configuration");

    if (startIndex !== -1 && endIndex !== -1) {
      return message.substring(startIndex, endIndex);
    }

    return '';
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const viewNotification = async (id, notification_obj) => {
    await confirm({
      confirmation: confirmContent(notification_obj),
      title: 'Submit Changes',
    });
    // await fetchNotifications();

    let theNotification = notifications.find((it) => it.id === id);
    if (theNotification.read_at) {
      // already read. so return
      return;
    }
    theNotification.read_at = new Date();
    setNotifications(notifications);
    let read = 0;
    for (let i = 0; i < notifications.length; i++) {
      let not = notifications[i];
      if (not.read_at) {
        read++;
      }
    }
    setCount(read);
    dispatch(setNotificationCount(notifications.length - read));
    await notificationService.markRead(id);
  };

  const deleteNotification = async (id) => {
    await notificationService.deleteNotification(id);
    await fetchNotifications();
  };

  const showLoading = useCallback(() => {
    dispatch(setLoading());
  }, [dispatch]);

  const hideLoading = useCallback(() => {
    dispatch(clearLoading());
  }, [dispatch]);

  return (
    <>
      <React.Fragment>
        <div className="mainContainer">
          <TableContainer
            component={Paper}
            sx={{ marginTop: '20px', maxHeight: 500 }}
          >
            {notifications.length === 0 && (
              <Box
                sx={{
                  minHeight: 100,
                  display: 'flex',
                  justifyContent: 'center',
                }}
                alignItems="center"
              >
                No Notifications{' '}
              </Box>
            )}
            <>
              {notifications.length > 0 && (
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">Timestamp</TableCell>
                      <TableCell>Person That Made Update</TableCell>
                      <TableCell align="left">
                        Provider's Approp Config Changed
                      </TableCell>

                      <TableCell align="left">Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {notifications
                      .sort((a, b) => a.id - b.id)
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => (
                        <TableRow
                          key={row.id}
                          sx={
                            ({
                              '&:last-child td, &:last-child th': { border: 0 },
                            },
                            { background: row.read_at ? '#D3D3D3' : '' })
                          }
                        >
                          <TableCell align="left">
                            {tsToLocalDtStr(row.created_at)}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {extractProviderName(row.notification_obj.message)}{' '}
                          </TableCell>
                          <TableCell align="left">
                            {extractRelatedProvider(
                              row.notification_obj.message
                            )}{' '}
                            {/* Here */}
                          </TableCell>

                          <TableCell align="left">
                            {' '}
                            <Button
                              variant="contained"
                              onClick={() =>
                                viewNotification(row.id, row.notification_obj)
                              }
                            >
                              View
                            </Button>
                            <Button
                              variant="contained"
                              onClick={() => deleteNotification(row.id)}
                              sx={{ marginLeft: 2 }}
                            >
                              Delete
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              )}
            </>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 15]}
            component="div"
            count={notifications.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />

          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={modalStyle}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Important Info
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                Today, we’d like to center our focus around announcement about
                testing.
              </Typography>
            </Box>
          </Modal>
        </div>
      </React.Fragment>
    </>
  );
};

export default Notifications;
